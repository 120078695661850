import React, { useState } from 'react';
import { useHover } from '../../../../hooks/useHover';
import { Chart } from './Chart/Chart';
import './ChecqueCard.scss';
import { ChequeHover } from './ChequeHover/ChequeHover';

export const ChecqueCard = ({ data, index }) => {
  const { sum, title, per, color, img, hoverText, hoverNum } = data;
  const [hoverRef, isHovered] = useHover();
  const [hoverInfo, setHoverInfo] = useState(false);
  const handleHoverInfo = () => {
    setHoverInfo((prev) => !prev);
  };

  return (
    <div
      className={
        title === 'Средний чек' ? 'checque-card__container-hovered' : 'checque-card__container'
      }
      ref={hoverRef}
    >
      <div className={title === 'Средний чек' ? 'checque-card__top-hovered' : 'checque-card__top'}>
        <div
          className={
            title === 'Средний чек' ? 'checque-card__top-info-hovered' : 'checque-card__top-info'
          }
        >
          <div
            className={
              title === 'Средний чек' ? 'checque-card__top-sum-hovered' : 'checque-card__top-sum'
            }
          >
            {sum}
          </div>
          <div
            className={
              title === 'Средний чек'
                ? 'checque-card__top-title-hovered'
                : 'checque-card__top-title'
            }
          >
            {title}
          </div>
        </div>
        <div className={`checque-card__top-result ${color}`}>
          <p
            onMouseEnter={handleHoverInfo}
            onMouseLeave={handleHoverInfo}
            className={`checque-card__top-result_${color}`}
          >
            {per}
          </p>
          {hoverInfo && (
            <div className="checque-card__top-result_hover">
              <ChequeHover hoverText={hoverText} hoverNum={hoverNum} />
            </div>
          )}
        </div>
        <div>{img}</div>
        {/* <Chart isHovered={title === 'Средний чек'} index={index} /> */}
      </div>
    </div>
  );
};
