import { useCallback } from 'react';
import React, { useState, useEffect } from 'react';
import Chart from './Circle/Circle';
import { SelectW } from '../../../pages/reportsPages/write-off/components/TopTenWidget/SelectW/SelectW';
import { ModalSales } from './ModalSales/ModalSales';
import { Select } from '../../common/Select/Select';
import { ArrowRightIcon } from '../../../icons/reports/day-result/arrow-right-icon';
import { Button } from '../../common/Button/Button';
import { OPTIONS } from './const';
import { useLocation } from 'react-router-dom';
import './Sales.scss';
import { Statistics } from '../Statistics/Statistics';
import { PathRoutes } from '../../../const/routes';

export const Sales = ({ isHoveredSales }) => {
  const { pathname } = useLocation();

  const [chartData, setCharData] = useState([
    { percent: 45.42, reason_name: 'Кухня', value: 32, sectionPercentage: 45.42 },
    { percent: 34.02, reason_name: 'Бар', value: 7, sectionPercentage: 34.02 },
    { percent: 14.54, reason_name: 'Кальяны', value: 7, sectionPercentage: 14.54 },
    { percent: 3.94, reason_name: 'Программа', value: 7, sectionPercentage: 3.94 },
    { percent: 2.09, reason_name: 'Прочее', value: 7, sectionPercentage: 2.09 },
  ]);

  const [selectedOptionSales, setSelectedOptionSales] = useState('По группам');
  const [optionsSales, setOptionsSales] = useState([
    { name: 'По группам', activeOpt: true, value: 'by_dishes' },
    { name: 'По блюдам', activeOpt: false, value: 'by_groop' },
  ]);

  useEffect(() => {
    if (selectedOptionSales == 'По группам') {
      setCharData([
        { percent: 45, reason_name: 'Кухня', value: 45.42, sectionPercentage: 45.42 },
        { percent: 34, reason_name: 'Бар', value: 34.02, sectionPercentage: 34.02 },
        { percent: 15, reason_name: 'Кальяны', value: 14.54, sectionPercentage: 14.54 },
        { percent: 4, reason_name: 'Программа', value: 3.94, sectionPercentage: 3.94 },
        { percent: 2, reason_name: 'Прочее', value: 2.09, sectionPercentage: 2.09 },
      ]);
    } else if (selectedOptionSales == 'По блюдам') {
      setCharData([
        { percent: 7, reason_name: 'Классический кальян', value: 6.85, sectionPercentage: 6.85 },
        {
          percent: 5,
          reason_name: 'Неоклассический кальян',
          value: 5,
          sectionPercentage: 5.16,
        },
        {
          percent: 3,
          reason_name: 'Ролл с тигровой креветкой...',
          value: 2.87,
          sectionPercentage: 2.87,
        },
        {
          percent: 3,
          reason_name: 'Филадельфия с авокадо',
          value: 3.94,
          sectionPercentage: 3.94,
        },
        { percent: 82, reason_name: 'Прочее', value: 81.8, sectionPercentage: 81.8 },
      ]);
    }
  }, [selectedOptionSales]);

  const [isSalesModal, isSetSalesModal] = useState(false);
  const onCloseModal = useCallback(() => {
    isSetSalesModal(false);
  }, []);
  const onOpenModal = useCallback(() => {
    isSetSalesModal(true);
  }, []);

  return (
    <div>
      {isSalesModal && (
        <ModalSales
          isOpen={isSalesModal}
          onClose={onCloseModal}
          chartData={chartData}
          commonSum={PathRoutes.SalesRoute === pathname ? '9 523 088,92 ₽' : '278 960,50 ₽'}
        />
      )}
      <div className="day-result__top-text">
        <div>Продажи</div>
        <SelectW
          selectedO={selectedOptionSales}
          setSelectedO={setSelectedOptionSales}
          options={optionsSales}
          setO={setOptionsSales}
          style={{ width: '140px' }}
        />
      </div>
      <div className="day-result__sales">
        <div className="day-result__sales__chart">
          <Chart
            data={chartData}
            commonSum={PathRoutes.SalesRoute === pathname ? '9 523 088,92 ₽' : '278 960,50 ₽'}
          />
        </div>
        <div className="day-result__sales__statistics">
          <Statistics data={chartData} />
        </div>
      </div>
      <div className={isHoveredSales ? 'day-result__about-btn-hovered' : 'day-result__about-btn'}>
        <Button styleType="about" height={15} icon={<ArrowRightIcon />} onClick={onOpenModal}>
          Подробнее
        </Button>
      </div>
    </div>
  );
};
