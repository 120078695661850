import React, { useState } from 'react';
import Popover from '@mui/material/Popover';

// import Button from '@mui/material/Button';
import './ProfilePopover.scss';
import { Button } from '../../Button/Button';
import { SettingsIcon } from '../../../../icons/profile/settings';
import { Addicon } from '../../../../icons/profile/add-icon';
import { LogOutIcon } from '../../../../icons/profile/log-out';
import { Link, useNavigate } from 'react-router-dom';
import { UserDefaultIcon } from '../../../../icons/profile/user-default-icon';
import kk from '../../../../images/demo/avatar.JPG';

export const ProfilePopover = ({ children, userProfile, avatar }) => {
  const { first_name, last_name, user_avatar } = userProfile || {};
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isHoveredSettings, setIsHoveredSettings] = useState(false);
  const [isHoveredAddAccount, setIsHoveredAddAccount] = useState(false);
  const [isHoveredLogOut, setIsHoveredLogOut] = useState(false);
  const navigate = useNavigate();

  const handleMouseEnterSettings = () => {
    setIsHoveredSettings(true);
  };

  const handleMouseLeaveSettings = () => {
    setIsHoveredSettings(false);
  };

  const handleMouseEnterAddAccount = () => {
    setIsHoveredAddAccount(true);
  };

  const handleMouseLeaveAddAccount = () => {
    setIsHoveredAddAccount(false);
  };

  const handleMouseEnterLogOut = () => {
    setIsHoveredLogOut(true);
  };

  const handleMouseLeaveLogOut = () => {
    setIsHoveredLogOut(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const addAccount = () => {
    handleClose();
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    navigate('/registration');
  };

  const logOut = () => {
    handleClose();
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    navigate('/login');
  };

  return (
    <div className="profile-popover">
      <Button onClick={handleClick} customClass="profile-popover__open-btn">
        {children}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 76, left: 1402 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 130,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            boxShadow: '2px 6px 20px 0px rgba(0, 0, 0, 0.09)',
            borderRadius: '15px',
          },
        }}
      >
        <>
          <div className="profile-popover__container">
            <div className="profile-popover__header">
              <div>
                <img
                  src={kk}
                  width={60}
                  height={60}
                  alt="logo"
                  className="profile-popover__header-icon"
                />
              </div>
              <div className="profile-popover__header-info">
                <div className="profile-popover__header-name">Козлович Кирилл</div>
                <div className="profile-popover__header-plan">
                  Подключен тариф <span>Demo</span>
                </div>
              </div>
            </div>
            <div className="profile-popover__btn">
              {/* <Link to="/settings/user-info">
                <Button styleType="profile" height={25}>
                  Редактировать профиль
                </Button>
              </Link> */}
            </div>
            {/* <div className="profile-popover__icons">
              <div
                className="profile-popover__icons-item"
                onMouseEnter={handleMouseEnterSettings}
                onMouseLeave={handleMouseLeaveSettings}
              >
                <SettingsIcon fill={isHoveredSettings ? '#67A8F4' : '#E3E3E3'} />
                <a
                  href="/settings/company-info"
                  style={{ color: isHoveredSettings ? '#67A8F4' : '#1E1E1E' }}
                >
                  Настройки
                </a>
              </div>
              <div
                className="profile-popover__icons-item"
                onClick={addAccount}
                onMouseEnter={handleMouseEnterAddAccount}
                onMouseLeave={handleMouseLeaveAddAccount}
              >
                <Addicon fill={isHoveredAddAccount ? '#67A8F4' : '#E3E3E3'} />
                <p style={{ color: isHoveredAddAccount ? '#67A8F4' : '#1E1E1E' }}>
                  Добавить аккаунт
                </p>
              </div>
              <div
                className="profile-popover__icons-item"
                onClick={logOut}
                onMouseEnter={handleMouseEnterLogOut}
                onMouseLeave={handleMouseLeaveLogOut}
              >
                <LogOutIcon fill={isHoveredLogOut ? '#67A8F4' : '#E3E3E3'} />
                <p style={{ color: isHoveredLogOut ? '#67A8F4' : '#1E1E1E' }}>Выйти</p>
              </div>
            </div> */}
          </div>
        </>
      </Popover>
    </div>
  );
};
