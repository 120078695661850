import { Operation } from './Operation/Operation';
import { ReportIcon } from '../../../icons/reports/day-result/report-icon';
import { TableIcon } from '../../../icons/reports/day-result/table-icon';
import { ThreeDotsIcon } from '../../../icons/three-dots-icon';
import { SaleIcon } from '../../../icons/reports/day-result/sale-icon';
import './DangerousOperations.scss';

const test = [
  {
    quantiy: 11,
    checksText: 'Кол-во чеков',
    warning: 'Не просмотрено',
    num: '5',
    icon: <ReportIcon />,
  },
  {
    quantiy: 3,
    checksText: 'Cкидки',
    warning: 'Не просмотрено',
    num: '2',
    icon: <SaleIcon />,
  },
  {
    quantiy: 10,
    checksText: 'Кол-во столов',
    warning: 'Не просмотрено',
    num: '2',
    icon: <TableIcon />,
  },
];

export const DangerousOperations = () => {
  return (
    <div className="operations-container">
      <div className="operations-top">
        <div className="operations-top-title">
          <div>Потенциально опасные операции</div>
          {/* <div>
            <ThreeDotsIcon />
          </div> */}
        </div>
        <div className="operations-top-desc">
          <span>28 567,60 ₽</span>
          <div>Сумма опасных операций</div>
        </div>
      </div>
      <div className="operations-list">
        {test.map((item) => (
          <Operation data={item} />
        ))}
      </div>
    </div>
  );
};
