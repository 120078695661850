import { NavLink, useLocation } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import SidebarMenu from './SideBarMenu';
import { LogoIcon } from '../../../icons/logo-icon';
import { NavigationArrowIcon } from '../../../icons/navigation/navigation-arrow-icon';
import { routes } from './const';
import { Company } from './Company/Company';
import './SideBar.scss';

const SideBar = ({ children, setIsMenuOpen }) => {
  const [isOpen, setIsOpen] = useState(true);

  const [collapsed, setCollapsed] = useState(() => {
    const storedNavigationClass = localStorage.getItem('navigationClass');
    return storedNavigationClass ? storedNavigationClass : 'open';
  });
  useEffect(() => {
    localStorage.setItem('navigationClass', collapsed);
  }, [collapsed]);

  const toggle = () => {
    setIsOpen(!isOpen);
    setCollapsed(isOpen ? 'open' : 'close');
    setIsMenuOpen(isOpen ? 'open' : 'close');
  };

  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: '140px',
      padding: '5px 15px',
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.3,
      },
    },
    show: {
      opacity: 1,
      width: 'auto',
      transition: {
        duration: 0.5,
      },
    },
  };

  const location = useLocation();

  return (
    <>
      <div className="sidebar-main-container">
        <motion.div
          animate={{
            width: isOpen ? '200px' : '100px',
            transition: {
              duration: 0.7,
              type: 'spring',
            },
          }}
          className="sidebar-wrapper"
        >
          <section className="sidebar-routes">
            <div className="sidebar-logo">
              <LogoIcon />
            </div>
            <div className={cn('sidebar-btn', collapsed)} onClick={toggle}>
              <NavigationArrowIcon />
            </div>
            {routes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                    setColapsed={setIsMenuOpen}
                  />
                );
              }
              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="sidebar-link"
                  activeClassName="active"
                >
                  <div className="icon">{route.generateIcon(route.path === location.pathname)}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="sidebar-link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>
          <div>
            <Company collapsed={collapsed} />
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default SideBar;
