import React from 'react';
import cn from 'classnames';
import blueBg from '../../../../../images/settings/reviewsPlatform/nine-to-sixteen-blue.jpg';
import orangeBg from '../../../../../images/settings/reviewsPlatform/nine-to-sixteen-orange.jpg';
import './QrForDownloads.scss';

export const QrForDownloads = ({ companyData, selectedItemSide, selectedItemBg }) => {
  const { avatar, qr_code } = companyData;

  let qrCodeHeight, qrCodeWidth, btnFirstWidth, btnSecondWidth;

  if (selectedItemSide === 'threeToFour') {
    qrCodeHeight = 860;
    qrCodeWidth = 860;
    btnFirstWidth = 670;
    btnSecondWidth = 905;
  } else if (selectedItemSide === 'oneToOne') {
    qrCodeHeight = 667;
    qrCodeWidth = 667;
    btnFirstWidth = 561;
    btnSecondWidth = 744;
  } else if (selectedItemSide === 'nineToSixteen') {
    qrCodeHeight = 1276;
    qrCodeWidth = 1276;
  } else {
    qrCodeHeight = 220;
    qrCodeWidth = 220;
  }

  let firstBtnBg, thirdBtnBg;

  if (selectedItemBg === 'orange') {
    firstBtnBg = '#FFC700';
    thirdBtnBg = '#FF6B00';
  } else {
    firstBtnBg = '#67A8F4';
    thirdBtnBg = '#0075FF';
  }

  const backgroundImage = selectedItemBg === 'orange' ? `url(${orangeBg})` : `url(${blueBg})`;

  return (
    <div
      className={cn('tofu-qr-download__container', {
        threeToFourDownload: selectedItemSide === 'threeToFour',
        oneToOneDownload: selectedItemSide === 'oneToOne',
      })}
      style={{ backgroundImage }}
    >
      <div
        className={cn('tofu-qr-download__logo', {
          threeToFourLogoDownload: selectedItemSide === 'threeToFour',
          oneToOneLogoDownload: selectedItemSide === 'oneToOne',
        })}
      >
        <img
          src={avatar ? `${avatar}?not-from-cache-please}` : ''}
          height={321}
          width={321}
          alt="Logo"
          crossOrigin="anonymous"
          id="chosen-image"
        />
      </div>
      <div
        className={cn('tofu-text', {
          threeToFourTextDownload: selectedItemSide === 'threeToFour',
          oneToOneTextDownload: selectedItemSide === 'oneToOne',
        })}
      >
        {selectedItemSide === 'oneToOne' ? (
          <div className="tofu-qr-download__wrapper">
            <div
              className={cn(
                'tofu-qr-download__wrapper-first',
                { threeToFourWrapperFirstDownload: selectedItemSide === 'threeToFour' },
                { oneToOneWrapperFirstDownload: selectedItemSide === 'oneToOne' }
              )}
            >
              <div
                className={cn(
                  'tofu-qr-download__text-first',
                  { threeToFourTextFirstDownload: selectedItemSide === 'threeToFour' },
                  { oneToOneTextFirstDownload: selectedItemSide === 'oneToOne' }
                )}
                style={{ width: btnFirstWidth, backgroundColor: firstBtnBg }}
              >
                Благодаря Вам
              </div>
            </div>
            <div
              className={cn(
                'tofu-qr-download__wrapper-second',
                { threeToFourWrapperSecondDownload: selectedItemSide === 'threeToFour' },
                { oneToOneWrapperSecondDownload: selectedItemSide === 'oneToOne' }
              )}
            >
              <div
                className={cn(
                  'tofu-qr-download__text-second',
                  { threeToFourTextSecondDownload: selectedItemSide === 'threeToFour' },
                  { oneToOneTextSecondDownload: selectedItemSide === 'oneToOne' }
                )}
                style={{ width: btnSecondWidth }}
              >
                мы становимся лучше
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div
              className={cn(
                'tofu-qr-download__wrapper-first',
                { threeToFourWrapperFirstDownload: selectedItemSide === 'threeToFour' },
                { oneToOneWrapperFirstDownload: selectedItemSide === 'oneToOne' }
              )}
            >
              <div
                className={cn(
                  'tofu-qr-download__text-first',
                  { threeToFourTextFirstDownload: selectedItemSide === 'threeToFour' },
                  { oneToOneTextFirstDownload: selectedItemSide === 'oneToOne' }
                )}
                style={{ width: btnFirstWidth, backgroundColor: firstBtnBg }}
              >
                Благодаря Вам
              </div>
            </div>
            <div
              className={cn(
                'tofu-qr-download__wrapper-second',
                { threeToFourWrapperSecondDownload: selectedItemSide === 'threeToFour' },
                { oneToOneWrapperSecondDownload: selectedItemSide === 'oneToOne' }
              )}
            >
              <div
                className={cn(
                  'tofu-qr-download__text-second',
                  { threeToFourTextSecondDownload: selectedItemSide === 'threeToFour' },
                  { oneToOneTextSecondDownload: selectedItemSide === 'oneToOne' }
                )}
                style={{ width: btnSecondWidth }}
              >
                мы становимся лучше
              </div>
            </div>
          </div>
        )}

        <div
          className={cn('tofu-qr-download__wrapper-third', {
            threeToFourWrapperThirdDownload: selectedItemSide === 'threeToFour',
            oneToOneWrapperThirdDownload: selectedItemSide === 'oneToOne',
          })}
        >
          <div
            className={cn(
              'tofu-qr-download__text-third',
              { threeToFourTextThirdDownload: selectedItemSide === 'threeToFour' },
              { oneToOneTextThirdDownload: selectedItemSide === 'oneToOne' }
            )}
            style={{ backgroundColor: thirdBtnBg }}
          >
            поделитесь впечатлениями
          </div>
        </div>
      </div>
      <div
        className={cn(
          'tofu-qr-download__code-wrapper',
          { threeToFourCodeWrapper: selectedItemSide === 'threeToFour' },
          { oneToOneCodeWrapper: selectedItemSide === 'oneToOne' }
        )}
      >
        <div
          className={cn(
            'tofu-qr-download__code-content',
            { threeToFourCodeDownload: selectedItemSide === 'threeToFour' },
            { oneToOneCodeDownload: selectedItemSide === 'oneToOne' }
          )}
        >
          <p>оставить отзыв </p>
          <img
            src={qr_code}
            height={qrCodeHeight}
            width={qrCodeWidth}
            alt="QR Code"
            crossOrigin="anonymous"
          />
        </div>
      </div>
    </div>
  );
};
