import React, { useEffect, useRef, useState } from 'react';
import HoverInfo from '../HoverInfo/HoverInfo';
import { Loader } from '../Loader/Loader';
import { ABCanalysis } from './ABCanalysis/ABCanalysis';
import { TableInfo } from './TableInfo/TableInfo';
import { TableSelect } from './TableSelect/TableSelect';
import './NewTable.scss';
import { COST_PRICE_INFO, FOODCOST_INFO, PRICE_INFO } from './const';

export const NewTable = ({
  gridColumns,
  columnsName,
  Component,
  customStyle,
  headerStyle,
  isFoodcost,
  isWriteOff,
  tableData,
  ...props
}) => {
  const className = 'bc-fused-table__item';
  const style = { gridTemplateColumns: gridColumns };

  const [items, setItems] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isShowABC, setIsShowABC] = useState(false);

  useEffect(() => {
    setItems(tableData);
    setIsLoaded(true);
  }, [tableData]);

  const tableRef = useRef();

  const handleScroll = () => {
    const tableContainer = tableRef.current;
    const tableHeader = tableContainer.querySelector('.table__header');

    if (tableContainer && tableHeader) {
      const rect = tableContainer.getBoundingClientRect();
      const isScrolled = rect.top < 0;

      if (isScrolled) {
        tableHeader.classList.add('sticky');
      } else {
        tableHeader.classList.remove('sticky');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      {items && items.length > 0 && isLoaded ? (
        <div className="table__container" ref={tableRef}>
          <div
            className="table__selects"
            style={{ justifyContent: isWriteOff ? 'right' : 'space-between' }}
          >
            {isWriteOff ? null : <div className="table__title">Таблица по меню</div>}
            <div className="table__selects-select">
              {isWriteOff ? null : (
                <ABCanalysis setIsShowABC={setIsShowABC} isShowABC={isShowABC} />
              )}
              {!isWriteOff && <TableSelect />}
            </div>
          </div>
          <div className="table__header" style={style}>
            {columnsName.map((columnName) => (
              <div className="table__column-title">
                {columnName}
                {columnName === 'Фудкост, %' && (
                  <div className="table__column-hover-info">
                    <HoverInfo width={16} height={16} containerHeight={0}>
                      <TableInfo text={FOODCOST_INFO} />
                    </HoverInfo>
                  </div>
                )}
                {columnName === 'Себестоимость' && (
                  <div className="table__column-hover-info">
                    <HoverInfo width={16} height={16} containerHeight={0}>
                      <TableInfo text={COST_PRICE_INFO} width={205} top={-35} right={-100} />
                    </HoverInfo>
                  </div>
                )}
                {columnName === 'Цена' && (
                  <div className="table__column-hover-info">
                    <HoverInfo width={16} height={16} containerHeight={0}>
                      <TableInfo text={PRICE_INFO} width={205} right={-100} />
                    </HoverInfo>
                  </div>
                )}
              </div>
            ))}
          </div>
          {items.map((item) => (
            <Component
              item={item}
              className={className}
              style={{ ...style, ...customStyle }}
              isShowABC={isShowABC}
              {...props}
              isFoodcost={isFoodcost}
            />
          ))}
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </>
  );
};
