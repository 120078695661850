import { useEffect, useRef, useState } from 'react';
import Datepicker from '../../../../components/common/DatePicker/DatePicker';
import { DatePickerFirst } from './DatePickerFirst/DatePickerFirst';
import { DatePickerSecond } from './DatePickerSecond/DatePickerSecond';
import './PeriodSelect.scss';

export const PeriodSelect = () => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };
  const selectRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [expanded]);

  return (
    <div ref={selectRef}>
      <div className="like-for-like-period-select__container" onClick={handleToggleExpand}>
        <div className="like-for-like-period-select__title">Период</div>
        <div className={`custom-select-arrow ${expanded ? 'expanded' : ''}`}></div>
      </div>
      {expanded && (
        <div className="like-for-like-period-select__wrapper">
          <DatePickerFirst />
          <div className="like-for-like-period-select__line"></div>
          <DatePickerSecond />
        </div>
      )}
    </div>
  );
};
