import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { authApi } from '../../../api/authApi';
import { CustomSelect } from '../CustomSelect/CustomSelect';
import { dateOptions, defaultoptions, options } from '../../../const/selectReviews';
import { DownloadIcon } from '../../../icons/download';
import { Button } from '../Button/Button';
import { ArrowDownSelect } from '../../../icons/appHeader/arrow-down-icon';
import './PageHeader.scss';
import Datepicker from '../DatePicker/DatePicker';
import { DateSelect } from './DateSelect/DateSelect';

export const PageHeader = ({
  title,
  platformsOptions,
  handlePlatformsSelectedChange,
  setCustomDate,
  setEndDate,
  isDayResults,
  isWriteOff,
  isFoodcost,
}) => {
  const [calendarDate, setCalendarDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedDate, setSelectedDate] = useState(dateOptions[1]);
  const startDate = moment(calendarDate.startDate).format('DD.MM.YYYY');
  const endDate = moment(calendarDate.endDate).format('DD.MM.YYYY');

  const handleSelectedDateChange = (newSelectedOption) => {
    setSelectedDate(newSelectedOption);
  };
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };
  const selectRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setExpanded(false);
      }
    };

    if (expanded) {
      document.addEventListener('click', handleOutsideClick);
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [expanded]);

  return (
    <div className="app-header__container">
      <div className="app-header__left-side">
        <div className="app-header__left-side-text">{title}</div>
        {platformsOptions && (
          <CustomSelect
            options={options}
            selectedOptions={platformsOptions}
            onChange={handlePlatformsSelectedChange}
          />
        )}
        {/* {donwnload && (
          <div className="app-header__left-side-icon">
            <DownloadIcon />
          </div>
        )} */}
        <div className="app-header__select-container">
          <div className="app-header__select-header" onClick={handleToggleExpand}>
            <div className="app-header__select-header-label">
              {selectedDate.type === 'Календарь' ? `${startDate} - ${endDate}` : selectedDate.type}
              <div className={`app-header__select-header-label__icon ${expanded ? 'rotate' : ''}`}>
                <ArrowDownSelect />
              </div>
            </div>
          </div>
          {expanded && (
            <div>
              <Datepicker
                selectedDate={selectedDate}
                handleSelectedDateChange={handleSelectedDateChange}
                setCustomDate={setCustomDate}
                selectedOption={selectedDate}
                setExpanded={setExpanded}
                expanded={expanded}
                startDate={startDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setCalendarDate={setCalendarDate}
                calendarDate={calendarDate}
                setSelectedDate={setSelectedDate}
                setSelectedOption={setSelectedDate}
              />
            </div>
          )}
        </div>
        {/* <div>
          <Button onClick={() => postData(email, password)}>connect iiko</Button>
        </div> */}
      </div>
    </div>
  );
};
