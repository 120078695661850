import * as React from 'react';
import Popover from '@mui/material/Popover';

import Button from '@mui/material/Button';
import { Forward } from '../../../../../../../../icons/reviewsPlatforms/forward';
import { Message } from '../../../../../../../../icons/reviewsPlatforms/message';
import './Popover.scss';

export const CommentPopover = ({ children, providerType, author, contacts, url }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        disableRipple
        onClick={handleClick}
        sx={{
          '&:hover': { backgroundColor: '#FFFF' },
          minHeight: 0,
          minWidth: 0,
          padding: 0,
          textTransform: 'capitalize',
        }}
      >
        {children}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 18,
          horizontal: -175,
        }}
        anchorPosition={{ left: 20 }}
        PaperProps={{
          style: {
            boxShadow: '2px 6px 20px 0px rgba(0, 0, 0, 0.09)',
            borderRadius: '',
          },
        }}
      >
        {providerType === 4 ? (
          <div className="tofy__window">
            <div className="tofy__first-row">
              <div>
                <Message />
              </div>
              <div>
                <div className="custom-link">Связаться с автором</div>
              </div>
            </div>
            <div className="tofy__second-row">
              <div>{author}</div>
              <div>{contacts}</div>
            </div>
          </div>
        ) : (
          <div className="popover__window">
            <div>
              <Forward />
            </div>
            <div className="popover__text">
              <a href={url} target="_blank" rel="noreferrer" className="custom-link">
                Перейти к источнику
              </a>
            </div>
          </div>
        )}
      </Popover>
    </div>
  );
};
