import { Tabs } from './Tabs/Tabs';
import './Revenue.scss';

const test = [
  {
    sum: '12',
    title: 'средний чек',
    per: '- 5р -36.5',
  },
  {
    sum: '12',
    title: 'средний чек',
    per: '- 5р -36.5',
  },
  {
    sum: '12',
    title: 'средний чек',
    per: '- 5р -36.5',
  },
];

export const Revenue = () => {
  return (
    <div>
      <Tabs />
      <div className="revenue-chart">{/* <Chart /> */}</div>
    </div>
  );
};
