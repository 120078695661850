import React from 'react';

export const ThreeToFour = () => {
  return (
    <svg width="63" height="73" viewBox="0 0 63 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6514_57458)">
        <rect width="63" height="73" rx="10" fill="#F6F6F6" />
        <rect
          x="15.5"
          y="16.5"
          width="33"
          height="40"
          rx="4.5"
          stroke="#C4C4C2"
          stroke-dasharray="2 2"
        />
      </g>
      <defs>
        <clipPath id="clip0_6514_57458">
          <rect width="63" height="73" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
