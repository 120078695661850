import React, { useRef } from 'react';
import { Button } from '../../../../../components/common/Button/Button';
import { Modal } from '../../../../../components/common/ModalWindow/ModalWindow';
import { CloseIcon } from '../../../../../icons/close-icon';
import { TofuQr } from '../TofuQr/TofuQr';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import './TofuModal.scss';
import { QrForDownloads } from '../QrForDownloads/QrForDownloads';
import { toast } from 'react-toastify';

export const TofuModal = ({
  isOpen,
  onClose,
  companyData,
  selectedItemSide,
  selectedItemBg,
  selectedItemFormat,
}) => {
  const tofuModalRef = useRef();

  const handleDownloadPNG = async () => {
    if (selectedItemSide === null || selectedItemBg === null || selectedItemFormat === null) {
      toast.error('Please select all fields before opening the modal.');
    } else {
      const element = tofuModalRef.current;
      const canvas = await html2canvas(element, { useCORS: true, allowTaint: true });

      const data = canvas.toDataURL('image/png');
      const link = document.createElement('a');

      if (typeof link.download === 'string') {
        link.href = data;
        link.download = 'image.png';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    }
  };

  const downloadPDF = async () => {
    if (selectedItemSide === null || selectedItemBg === null || selectedItemFormat === null) {
      toast.error('Please select all fields before downloading ');
      return;
    }

    const pdf = new jsPDF('portrait', 'pt', 'a4');

    const data = await html2canvas(tofuModalRef.current, {
      useCORS: true,
      allowTaint: true,
    });
    const img = data.toDataURL('image/png');
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    if (selectedItemSide === 'nineToSixteen') {
      pdf.addImage(img, 'PNG', 0, 0, 550, 850);
    } else {
      pdf.addImage(img, 'PNG', 0, 0, pdfWidth, pdfHeight);
    }
    pdf.save('shipping_label.pdf');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} width={437}>
      <div className="tofu-modal__top">
        <div>Ваш Макет</div>
        <div onClick={onClose} className="tofu-modal__top-icon">
          <CloseIcon height={28} width={28} />
        </div>
      </div>
      <div className="tofu-modal__content">
        <div className="tofu-modal__frame">
          <TofuQr
            companyData={companyData}
            selectedItemSide={selectedItemSide}
            selectedItemBg={selectedItemBg}
          />
          <div
            id="#report"
            style={{ marginBottom: '0px', position: 'absolute', top: '-5500px' }}
            ref={tofuModalRef}
          >
            <QrForDownloads
              companyData={companyData}
              selectedItemSide={selectedItemSide}
              selectedItemBg={selectedItemBg}
            />
          </div>
        </div>
        <div className="tofu-modal__btn">
          <Button
            styleType="primary"
            onClick={selectedItemFormat === 'PNG' ? handleDownloadPNG : downloadPDF}
          >
            Скачать
          </Button>
        </div>
      </div>
    </Modal>
  );
};
