import { useMonth } from '@datepicker-react/hooks';
import { useState, useEffect, useRef } from 'react';
import { dateOptions } from '../../../../const/selectReviews';
import { MONTH_NAMES } from './const';
import Day from '../Day/Day';
import { NavButton } from '../NavButton/NavButton';
import './Month.scss';
import { PeriodSelect } from '../PeriodSelect/PeriodSelect';

function Month({
  year,
  month,
  firstDayOfWeek,
  setCalendarDate,
  state,
  setIsApplyButtonClick,
  setSelectedDate,
  expanded,
  setExpanded,
}) {
  const [currentYear, setCurrentYear] = useState(year);
  const [currentMonth, setCurrentMonth] = useState(month);
  const { days } = useMonth({
    year: currentYear,
    month: currentMonth,
    firstDayOfWeek,
  });

  const handleToggleExpand = (event) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  const nextMonth = () => {
    if (currentMonth < 11) {
      setCurrentMonth((prev) => prev + 1);
    } else {
      setCurrentMonth(0);
      setCurrentYear((prev) => prev + 1);
    }
  };

  const prevMonth = () => {
    if (currentMonth > 0) {
      setCurrentMonth((prev) => prev - 1);
    } else {
      setCurrentMonth(11);
      setCurrentYear((prev) => prev - 1);
    }
  };

  const applyDate = () => {
    setCalendarDate({ ...state });
    setSelectedDate(dateOptions[3]);
    setExpanded(!expanded);
  };

  const weekdayLabels = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];
  return (
    <div className="month-wrapper">
      <div className="month-container">
        <div onClick={prevMonth}>
          <NavButton direction="left" />
        </div>
        <div onClick={handleToggleExpand}>
          <p className="month-periods">
            {MONTH_NAMES[currentMonth].name} {currentYear}
          </p>
        </div>
        <div onClick={nextMonth}>
          <NavButton direction="right" />
        </div>
      </div>
      <div className="days-of-week-container">
        {weekdayLabels.map((dayLabel) => (
          <div key={dayLabel} className="days-of-week">
            {dayLabel}
          </div>
        ))}
      </div>
      <div className="calendar">
        {days.map((day, index) => {
          if (typeof day === 'object') {
            return <Day date={day.date} key={day.date.toString()} dayLabel={day.dayLabel} />;
          }

          return <div key={index} />;
        })}
      </div>
      <div className="month-btn-wrapper">
        <button onClick={applyDate} className="month-btn">
          Применить
        </button>
      </div>
    </div>
  );
}

export default Month;
