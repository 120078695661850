import { useCallback, useState } from 'react';
import { CheckBox } from '../../../../components/common/CheckBox/CheckBox';
import { SettingsIcon } from '../../../../icons/settings/settings-icon';
import { TABLE_COLUMNS_TITLE, test } from '../const';
import { ModalAccessReports } from '../ModalAccessReports/ModalAccessReports';
import './AccessStaff.scss';

export const AccessStaff = () => {
  const [openItemIndex, setOpenItemIndex] = useState(null);
  const [hoveredItemIndex, setHoveredItemIndex] = useState(null);

  const onCloseModal = useCallback(() => {
    setOpenItemIndex(null);
  }, []);

  const onOpenModal = useCallback((index) => {
    setOpenItemIndex(index);
  }, []);

  return (
    <div className="access-staff__container">
      <div className="access-staff__columns">
        {TABLE_COLUMNS_TITLE.map((item) => (
          <div key={item.title} className="access-staff__columns-title">
            {item.title}
          </div>
        ))}
      </div>
      <div className="access-staff__table">
        {test.map((item, index) => (
          <div key={item.deparment} className="access-staff__table-columns">
            <div className="access-staff__table-columns-department">{item.deparment}</div>
            <div className="access-staff__table-columns-info">
              {item.staff.map((person) => (
                <div
                  key={person.index}
                  className="access-staff__table-columns-info-container"
                  onMouseEnter={() => setHoveredItemIndex(person.index)}
                  onMouseLeave={() => setHoveredItemIndex(null)}
                >
                  <div className="access-staff__table-columns-info-person">
                    <div className="access-staff__table-columns-info-img-container">
                      <img
                        src={person.avatar}
                        alt="123"
                        className="access-staff__table-columns-info-img"
                        width={40}
                        height={40}
                      />
                    </div>
                    <div className="access-staff__table-columns-info-staff">
                      <div className="access-staff__table-columns-info-staff-name">
                        {person.name}
                      </div>
                      <div className="access-staff__table-columns-info-staff-role">
                        {person.role}
                      </div>
                    </div>
                  </div>
                  <div className="access-staff__table-columns-info-checkbox">
                    <CheckBox />
                  </div>
                  <div className="access-staff__table-columns-info-checkbox">
                    <CheckBox />
                  </div>
                  <div className="access-staff__table-columns-info-checkbox">
                    <CheckBox />
                    {hoveredItemIndex === person.index && (
                      <div
                        onClick={() => onOpenModal(person.index)}
                        className="access-staff__table-columns-info-settings"
                      >
                        <SettingsIcon />
                      </div>
                    )}
                    {openItemIndex === person.index && (
                      <ModalAccessReports
                        isOpen={true}
                        onClose={onCloseModal}
                        avatar={person.avatar}
                        role={person.role}
                        name={person.name}
                      />
                    )}
                  </div>
                  <div className="access-staff__table-columns-info-checkbox">
                    <CheckBox />
                  </div>
                  <div className="access-staff__table-columns-info-checkbox">
                    <CheckBox />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
