import React from 'react';

export const DefaultUserIcon = ({ height = 80, width = 80, color = '#67A8F4' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="99" height="99" rx="49.5" fill="#F9F9F9" />
      <path
        d="M50 51.3337C53.194 51.3337 56.1004 52.2586 58.2369 53.5623C59.3048 54.2139 60.2161 54.9821 60.8747 55.8157C61.5227 56.6359 62 57.6177 62 58.667C62 59.7935 61.4519 60.6818 60.6631 61.3149C59.9157 61.9149 58.9309 62.3118 57.8837 62.5891C55.7804 63.1461 52.972 63.3337 50 63.3337C47.028 63.3337 44.2195 63.1461 42.1162 62.5891C41.0691 62.3118 40.0843 61.9149 39.3369 61.3149C38.5482 60.6818 38 59.7935 38 58.667C38 57.6177 38.4774 56.6359 39.1253 55.8157C39.7838 54.9821 40.6952 54.2139 41.763 53.5623C43.8996 52.2586 46.806 51.3337 50 51.3337ZM50 36.667C53.6819 36.667 56.6667 39.6518 56.6667 43.3337C56.6667 47.0156 53.6819 50.0003 50 50.0003C46.3181 50.0003 43.3333 47.0156 43.3333 43.3337C43.3333 39.6518 46.3181 36.667 50 36.667Z"
        fill="#67A8F4"
      />
      <rect
        x="0.5"
        y="0.5"
        width="99"
        height="99"
        rx="49.5"
        stroke="#E3E3E3"
        stroke-dasharray="5 5"
      />
    </svg>
  );
};
