import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './ChartFirst.scss';
import { BarChart, Bar, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: '01.07-02.07',
    uv: 224097,
    pv: 240000,
  },
  {
    name: '03.07 - 09.07',
    uv: 520000,
    pv: 522892,
  },
  {
    name: '10.07 - 16.07',
    uv: 560242,
    pv: 610000,
  },
  {
    name: '17.07 - 23.07',
    uv: 535342,
    pv: 580000,
  },
  {
    name: '24.07 - 31.07',
    uv: 647390,
    pv: 700000,
  },
];

export default function ChartFirst() {
  return (
    <ResponsiveContainer width="99.5%" height={195}>
      <BarChart
        width={500}
        height={300}
        data={data}
        barGap={0}
        margin={{
          top: 13,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="name"
          //   minTickGap={40}
          tickLine={true}
          //   tickFormatter={(unixTime) => {
          //     return moment.utc(unixTime).local().format(' MM.YY');
          //   }}
          tick={{
            fill: '#9C9C9C',
            fontSize: '12px',
            lineHeight: '15px',
          }}
          stroke="#F6F6F6"
          dx={2}
        />
        <YAxis
        tickFormatter={(value) => value / 1000 + "k"}
          tick={{
            fill: '#9C9C9C',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '15px',
          }}
          stroke="#F6F6F6"
          allowDecimals={false}
          width={45}
          dy={-8}
          tickMargin={2}
        />
        <Tooltip
          wrapperStyle={{ outline: 'none' }}
          content={CustomToolTip}
          cursor={{ fill: 'white', strokeWidth: 0, radius: 0 }}
          strokeDasharray={[1, 1]}
        />
        <Bar dataKey="pv" fill="#84D9BF" barSize={15} radius={[3, 3, 0, 0]} />
        <Bar dataKey="uv" fill="#E3E3E3" barSize={15} radius={[3, 3, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
}

function CustomToolTip({ active, payload, label }) {
  const [convertedStringPV, setConvertedStringPV] = useState('');
  const [convertedStringUV, setConvertedStringUV] = useState('');

  const convert = (payload, key) => {
    const numberString = payload[0].payload[key].toString();
    const firstPart = numberString.slice(0, 3);
    const secondPart = numberString.slice(3, 6);
    return firstPart + ' ' + secondPart + " ₽";
  }

  useEffect(() => {
    if (payload && payload.length > 0) {
    setConvertedStringPV(convert(payload, "pv"));
    setConvertedStringUV(convert(payload, "uv"));
  }
  }, [payload]);

  if (active) {
    return (
      <div className="tooltip-revenue-container" style={{ border: 'none' }}>
        <ul className="tooltip-revenue-list">
          {payload ? (
            <>
              <li>
                Факт: <span>{convertedStringPV}</span>{' '}
              </li>
              <li>
                План: <span>{convertedStringUV}</span>
              </li>
            </>
          ) : null}
        </ul>
      </div>
    );
  }
}
