  
export const sortData = (serverData) => {

    if (!Array.isArray(serverData)) {
        return []; 
      }
    const sortedData = serverData.reduce((acc, curr) => {
        const ratingValue = curr.rating;
        const existingRatingIndex = acc.findIndex((item) => item.rating === ratingValue);
        if (existingRatingIndex !== -1) {
            acc[existingRatingIndex].value += 1;
        } else {
            acc.push({ rating: ratingValue, value: 1 });
        }
        const sortedAcc = [...acc].sort((a, b) => b.value - a.value);
        return sortedAcc.filter((obj) => obj.rating !== 0);
    }, [])
    return sortedData
}