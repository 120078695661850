import './ChequeHover.scss';

export const ChequeHover = ({ hoverText, hoverNum }) => {
  return (
    <div className="cheque-hover_container">
      <p className="cheque-hover_text">
        <span className="cheque-hover_text-bold">Вт</span> прошлой недели (23.05)
      </p>
      <p className="cheque-hover_text">
        {hoverText} <span className="cheque-hover_text_num">{hoverNum}</span>
      </p>
    </div>
  );
};
