import Chart from '../Circle/Circle';
import { Statistics } from '../../Statistics/Statistics';
import { TableSales } from './TableSales/TableSales';
import { Modal } from '../../../common/ModalWindow/ModalWindow';
import { CloseIcon } from '../../../../icons/close-icon';
import './ModalSales.scss';
const test = [
  { id: 1, product_name: 'CAVIAR.', coast: '147', sum: '82 320' },
  { id: 2, product_name: 'Jim Beam', coast: '79', sum: '20 540' },
  { id: 3, product_name: 'Классический кальян', coast: '182', sum: '171 080' },
  { id: 4, product_name: 'Неоклассический кальян', coast: '107', sum: '128 400' },
  {
    id: 5,
    product_name: 'Ролл с тигровой креветкой, лососем и свежим томатом',
    coast: '155',
    sum: '88 350',
  },
  { id: 6, product_name: 'Филадельфия с авокадо', coast: '145', sum: '88 450' },
  { id: 7, product_name: 'Стейк из свиной шеи со свежими овощами', coast: '28', sum: '28 365' },
  {
    id: 8,
    product_name: 'Стейк Рибай с трюфельным пюре и соусом демигляс',
    coast: '90',
    sum: '64 800',
  },
  { id: 9, product_name: 'Салат с креветкой и апельсиновым песто', coast: '96', sum: '51 840' },
  { id: 10, product_name: 'Карбонара с муссом пармезан', coast: '82', sum: '45 920' },
];

export const ModalSales = ({ isOpen, onClose, chartData, commonSum }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} width={700}>
      <div className="modal-sales__container">
        <div className="modal-sales__header">
          <div>Продажи</div>
          <div onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <div className="modal-sales__stat">
          <Chart data={chartData} inner={78} outer={90} width={200} commonSum={commonSum} />
          <Statistics data={chartData} />
        </div>
        <div className="modal-sales__table">
          <TableSales list={test} />
        </div>
      </div>
    </Modal>
  );
};
