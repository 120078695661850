import moment from 'moment';
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import './Chart.scss';

export default function AverageRating({ data }) {
  const formatTick = (tick) => {
    // if (tick === 5) {
    //   return '4.9';
    // }
    if (tick > 5.01) {
      return '';
    }
    return tick.toFixed(1);
  };

  return (
    <ResponsiveContainer height="100%">
      <AreaChart data={data} margin={{ top: 20, right: 0, bottom: 0, left: 0 }}>
        <defs>
          <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#67A8F4" stopOpacity={0.2} />
            <stop offset="75%" stopColor="#67A8F4" stopOpacity={0.05} />
          </linearGradient>
        </defs>
        <Area
          dataKey="average_rating"
          stroke="#67A8F4"
          fill="url(#color)"
          strokeWidth={2}
          type="monotone"
          activeDot={{ r: 6, fillRule: 'url(#color' }}
        />
        <XAxis
          dataKey="date"
          axisLine={false}
          minTickGap={40}
          tickLine={true}
          tickFormatter={(unixTime) => {
            return moment.utc(unixTime).local().format('MM.YY');
          }}
          tick={{
            fill: '#9C9C9C',
            fontWeight: '400',
            fontSize: '10.6px',
            lineHeight: '15px',
          }}
          padding={{ left: 0, right: 25 }}
          stroke="#F6F6F6"
          dx={11.8}
          // interval={Math.ceil(data.length / 8)}
        />

        <YAxis
          dataKey="average_rating"
          allowDuplicatedCategory={false}
          tickLine={false}
          tick={{
            fill: '#9C9C9C',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '15px',
          }}
          stroke="#F6F6F6"
          width={30}
          orientation="left"
          type="number"
          tickCount={6}
          tickFormatter={formatTick}
          domain={[
            (dataMin) => (dataMin === 1 ? dataMin : dataMin - 0.2), // Subtract 1 from dataMin only if it's not 1
            (dataMax) => (dataMax > 4 ? dataMax : dataMax + 0.2), // Add 1 to dataMax only if it's greater than 4
          ]}
          dy={-8}
        />
        <Tooltip
          wrapperStyle={{ outline: 'none' }}
          content={CustomToolTip}
          cursor={{ fill: 'black', strokeWidth: 0, radius: 0 }}
          strokeDasharray={[5, 5]}
        />

        <CartesianGrid opacity={0.001} vertical={false} />
      </AreaChart>
    </ResponsiveContainer>
  );
}

function CustomToolTip({ active, payload, label }) {
  if (active) {
    return (
      <div className="tooltip" style={{ border: 'none' }}>
        {payload ? (
          <>
            <p className="tooltip__label">{payload[0].value}</p>
            <h4>{moment.utc(label).local().format('MM.YY')}</h4>{' '}
          </>
        ) : null}
      </div>
    );
  }
}
