import React from 'react';
import './SliderBannerCard.scss';
import { BorderCard } from '../../../../../../icons/borderCard';
export const SliderBannerCard = ({ item }) => {
  return (
    <>
      <div className="sales_slider-banner-list_card">
        <p className="sales_slider-banner-list_card_title">{item.title}</p>
        <p className="sales_slider-banner-list_card_sum">
          {item.sum} <span className="sales_slider-banner-list_card_sum_rubl">₽</span>
        </p>

        <p className="sales_slider-banner-list_card_percent">
          От выручки{' '}
          <span className="sales_slider-banner-list_card_percent_bold"> {item.percent} %</span>
        </p>
      </div>
      <BorderCard />
    </>
  );
};
