import React from 'react';

export const TofuLogoBig = ({height = 77, width = 77}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 77 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5 9L8.5 9C6.84315 9 5.5 7.65686 5.5 6V5C5.5 3.34315 6.84315 2 8.5 2L17.4956 2C17.497 2 17.4985 2 17.5 2H18.5C18.5015 2 18.503 2 18.5045 2L27.5 2C29.1569 2 30.5 3.34315 30.5 5V6C30.5 7.65686 29.1569 9 27.5 9L21.5 9V30C21.5 31.6569 20.1569 33 18.5 33H17.5C15.8431 33 14.5 31.6569 14.5 30V9Z"
        fill="#67A8F4"
      />
      <path
        d="M71.5 17.5C71.5 26.0604 64.5604 33 56 33C47.4396 33 40.5 26.0604 40.5 17.5C40.5 8.93959 47.4396 2 56 2C64.5604 2 71.5 8.93959 71.5 17.5ZM47.475 17.5C47.475 22.2082 51.2918 26.025 56 26.025C60.7082 26.025 64.525 22.2082 64.525 17.5C64.525 12.7918 60.7082 8.975 56 8.975C51.2918 8.975 47.475 12.7918 47.475 17.5Z"
        fill="#67A8F4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.5 46C5.5 45.4305 5.65871 44.898 5.93432 44.4444C6.08867 44.1904 6.27967 43.9611 6.5 43.7639C7.03076 43.2889 7.73165 43 8.5 43H9.5H27.5C29.1569 43 30.5 44.3431 30.5 46V47C30.5 48.6569 29.1569 50 27.5 50L12.5 50V55L27.5 55C29.1569 55 30.5 56.3431 30.5 58V59C30.5 60.6569 29.1569 62 27.5 62L12.5 62V71C12.5 72.6569 11.1569 74 9.5 74H8.5C6.84315 74 5.5 72.6569 5.5 71V59V58V47V46Z"
        fill="#D5D5D5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44 50C45.933 50 47.5 48.433 47.5 46.5C47.5 44.567 45.933 43 44 43C42.067 43 40.5 44.567 40.5 46.5C40.5 48.433 42.067 50 44 50ZM71.5 46.5C71.5 48.433 69.933 50 68 50C66.067 50 64.5 48.433 64.5 46.5C64.5 44.567 66.067 43 68 43C69.933 43 71.5 44.567 71.5 46.5ZM68.6041 58.5C70.1337 58.5 71.3987 59.7497 71.1269 61.2549C70.9306 62.3415 70.6184 63.4066 70.1938 64.4316C69.4148 66.3121 68.2731 68.0208 66.8338 69.4602C65.3945 70.8995 63.6858 72.0412 61.8053 72.8201C59.9247 73.5991 57.9091 74 55.8737 74C53.8382 74 51.8226 73.5991 49.9421 72.8201C48.0615 72.0412 46.3528 70.8995 44.9135 69.4602C43.4742 68.0208 42.3325 66.3121 41.5535 64.4316C41.129 63.4066 40.8167 62.3415 40.6204 61.2549C40.3486 59.7497 41.6137 58.5 43.1433 58.5H44.8891C46.4187 58.5 47.6151 59.7739 48.1211 61.2174C48.1714 61.3609 48.2257 61.503 48.284 61.6437C48.6968 62.6404 49.3019 63.546 50.0648 64.3089C50.8276 65.0717 51.7332 65.6768 52.7299 66.0897C53.7266 66.5025 54.7948 66.715 55.8737 66.715C56.9525 66.715 58.0207 66.5025 59.0174 66.0897C60.0141 65.6768 60.9197 65.0717 61.6825 64.3089C62.4454 63.546 63.0505 62.6404 63.4633 61.6437C63.5216 61.503 63.5759 61.3609 63.6262 61.2174C64.1322 59.7739 65.3287 58.5 66.8583 58.5H68.6041Z"
        fill="#D5D5D5"
      />
    </svg>
  );
};
