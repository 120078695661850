import { useEffect, useRef, useState } from 'react';
import { Button } from '../../../../components/common/Button/Button';
import { CheckBox } from '../../../../components/common/CheckBox/CheckBox';
import { GROUPS_DATA_FIRST, RESTAURANTS_DATA } from '../const';
import { HoverInfoTable } from '../periodsComparison/tableItem/HoverInfoTable/HoverInfoTable';
import './companiesSelect.scss';

export const CompaniesSelect = ({ options, setOptions, isSecondTable, isFirstTable }) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState( isFirstTable ? ['Московская область', 'Ленинградская область'] : []);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleToggleOption = (value) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const handleCheckBoxChange = (value) => {
    handleToggleOption(value);
  };

  const selectRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [expanded]);

  const onButtonClick = () => {
    setOptions(selectedOptions);
    setExpanded(false);
  };

  return (
    <div className="companies-select__container" ref={selectRef}>
      <div className="companies-select__select" onClick={handleToggleExpand}>
        <div className="companies-select__select-title">Заведения</div>
        <div className={`custom-select-arrow ${expanded ? 'expanded' : ''}`}></div>
      </div>
      {expanded && (
        <div className="companies-select__content">
          <div className="companies-select__content-wrapper">
            <div>
              <div className="companies-select__title">
                <div>Заведения</div>
                {isSecondTable ? (
                  <div className="companies-select__title-icon">
                    <HoverInfoTable isPopap right={-110} bottom={35} />
                  </div>
                ) : null}
              </div>
              <div className="companies-select__list">
                {RESTAURANTS_DATA.map((item, index) => (
                  <div
                    key={index}
                    className={`companies-select__item ${
                      selectedOptions.includes(item.title) ? 'selected' : ''
                    }`}
                  >
                    <div>
                      <CheckBox
                        checked={selectedOptions.includes(item.address)}
                        onChange={() => handleCheckBoxChange(item.address)}
                      />
                    </div>
                    <div className="companies-select__item-title">
                      <div>{item.title}</div>
                      <div>{item.address}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className="companies-select__title">Группы</div>
              <div className="companies-select__list-second">
                {GROUPS_DATA_FIRST.map((item) => (
                  <div
                    key={item.title}
                    className={`companies-select__item ${
                      selectedOptions.includes(item.title) ? 'selected' : ''
                    }`}
                  >
                    <div>
                      <CheckBox
                        checked={selectedOptions.includes(item.title)}
                        onChange={() => handleCheckBoxChange(item.title)}
                      />
                    </div>
                    <div className="companies-select__item-title">{item.title}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="companies-select__btn-wrapper">
            <Button
              width={127}
              height={32}
              customClass="companies-select__btn-first"
              onClick={onButtonClick}
            >
              Настройки групп
            </Button>
            <Button
              styleType="primary"
              width={89}
              height={32}
              customClass="companies-select__btn"
              onClick={onButtonClick}
            >
              Применить
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
