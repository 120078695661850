import React, { useCallback, useState } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { COLORS } from '../../../const/nps';
import './Circle.scss';

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, info } = props;

  return (
    <g>
      <text
        x={cx}
        y={cy - 20}
        dy={8}
        textAnchor="middle"
        fill={'#2B2B2B'}
        fontSize="24"
        fontWeight="800"
      >
       {info.percent}
      </text>
      <text x={cx} y={cy} dy={5} textAnchor="middle" fill={'#2B2B2B'} fontSize="10" fontWeight="400">
        {info.tittle}
      </text>
      <text x={cx} y={cy} dy={5+14} textAnchor="middle" fill={'#9C9C9C'} fontSize="12">
        {info.discription}
      </text>
      {info.discription ? (
        <text
        x={cx}
        y={cy + 30}
        dy={5}
        textAnchor="middle"
        fill={'#9C9C9C'}
        fontSize="14"
        fontWeight="700"
      >
        {info.sum}
      </text>) : (
        <text
        x={cx}
        y={cy + 17}
        dy={5}
        textAnchor="middle"
        fill={'#9C9C9C'}
        fontSize="14"
        fontWeight="700"
      >
        {info.sum}
      </text>) }

      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 1}
        outerRadius={outerRadius + 4}
        fill={'#FFF'}
      />
    </g>
  );
};

export default function Chart({ data, info}) {
  const [activeIndex, setActiveIndex] = useState(4);
  
  return (
    <ResponsiveContainer width={210} height={168}>
      <PieChart>
        <Pie
          stroke="none"
          activeIndex={activeIndex}
          activeShape={(props) => renderActiveShape({ ...props, info})}
          isAnimationActive={false}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={70}
          outerRadius={80}
          dataKey="percent"
        >
          {COLORS.map((entry, index) => (
            <Cell style={{ outline: 'none' }} key={`cell-${index}`} fill={entry} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
