import React from 'react';

export const Editicon = ({ fill }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icons">
        <path
          id="edit"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.7755 2.63409C15.7992 1.65778 14.2163 1.65778 13.24 2.63409L12.6508 3.22334L16.7755 7.34813L17.3648 6.75888C18.3411 5.78257 18.3411 4.19966 17.3648 3.22334L16.7755 2.63409ZM15.597 8.52667L11.4722 4.40186L3.89641 11.9777C3.73075 12.1433 3.61499 12.3523 3.56231 12.5805L2.70559 16.2929C2.56696 16.8937 3.10538 17.4319 3.70597 17.2933L7.41839 16.4366C7.64667 16.3839 7.85554 16.2682 8.0212 16.1025L15.597 8.52667Z"
          fill="#E3E3E3"
        />
      </g>
    </svg>
  );
};
