import { NPS_TYPES } from '../../../../const/nps';

export const sortServerData = (serverData) => {
  // keep only nps
  const filteredData = Object.keys(serverData)
    .filter(
      (key) =>
        !['mentions_count', 'total_negative', 'total_neutral', 'total_positive'].includes(key)
    )
    .reduce((obj, key) => {
      obj[key] = serverData[key];
      return obj;
    }, []);

  const dataWithNps = filteredData
    .map((item, index) => {
      const npsType = NPS_TYPES.find((type) => type.id === index);
      if (npsType) {
        return {
          ...item,
          nps_type: npsType.type,
        };
      }
      return null;
    })
    .filter((item) => item);

  // get rid of empty object
  const filteredNps = dataWithNps.filter((obj) => Object.keys(obj).length > 1);

  const recalculateNps = filteredNps.map((item) => {
    const { neutral, total, ...rest } = item;
    return {
      ...rest,
      value: rest.positive + rest.negative,
    };
  });

  return recalculateNps;
};
