import { useEffect, useState } from 'react';
import { reviewsApi } from '../../../../api/reviewsApi';
import Chart from './Chart/Chart';
import './AverageRating.scss';
import { Select } from '../../../../components/common/Select/Select';
import { TofuErrorIcon } from '../../../../icons/tofu-error';
import { ERROR_TEXT_FOR_SELECT } from '../../../../const/commonConst';
import { ErrorWidget } from '../../../../components/common/ErrorWidget/ErrorWidget';

export const AverageRating = ({ selectedDate, npsTypeOptions, selectedPlatform, account_id }) => {
  const [serverData, setServerData] = useState([]);
  const [selectedOptionAverageRating, setSelectedOptionAverageRating] = useState({
    ...npsTypeOptions.find((item) => item.type === 'Общий'),
  });

  const handleSelectedOptionsAverageRatingChange = (newSelectedOptions) => {
    reviewsApi
      .getFilteredReviewsByAverageRating(
        account_id,
        selectedDate,
        newSelectedOptions.id,
        selectedPlatform
      )
      .then((res) => {
        setServerData(res.data);
      })
      .catch((err) => {
        console.log(err, 'catch');
      });
    setSelectedOptionAverageRating({ ...newSelectedOptions });
  };

  useEffect(() => {
    reviewsApi
      .getFilteredReviewsByAverageRating(account_id, selectedDate, 0, selectedPlatform)
      .then((res) => {
        setServerData(res.data);
      })
      .catch((err) => {
        console.log(err, 'catch');
      });
  }, [account_id, selectedDate, selectedPlatform]);

  return (
    <div>
      <div className="content-wrapper">
        <div className="reviews__cloud-container-title">Средний рейтинг</div>
        <div>
          <Select
            options={npsTypeOptions}
            selectedOptions={selectedOptionAverageRating}
            onChange={handleSelectedOptionsAverageRatingChange}
            secondBg
          />
        </div>
      </div>
      {serverData.length === 0 ? (
        <ErrorWidget text={ERROR_TEXT_FOR_SELECT} withMargin />
      ) : (
        <div className="average_rating">
          <Chart data={serverData} />
        </div>
      )}
    </div>
  );
};
