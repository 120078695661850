import React, { useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { StaffCard } from './StaffCard/StaffCard';
import './StaffRatingSlider.scss';
import first from '../../../icons/reports/first.svg';
import second from '../../../icons/reports/second.svg';
import third from '../../../icons/reports/third.svg';
import four from '../../../icons/reports/four.svg';

const test = [
  {
    icon: first,
    name: 'Маргарита Петрова',
    revenue: '65 892,10',
    avgCheck: '2 704,60',
    deepCheck: '9',
  },
  {
    icon: four,
    name: 'Евгений Коваль',
    revenue: '42 690,33',
    avgCheck: '2 388,77',
    deepCheck: '7',
  },
  {
    icon: third,
    name: 'Екатерина Молодчинкова',
    revenue: '28 721,00',
    avgCheck: '2 604,22',
    deepCheck: '5',
  },
  {
    icon: second,
    name: 'Михаил Корольков',
    revenue: '11 841,34',
    avgCheck: '2 189,14',
    deepCheck: '6',
  },
  // {
  //   icon: four,
  //   name: 'Михаил Корольков',
  //   revenue: '224 096,67',
  //   avgCheck: '2 593,65',
  //   deepCheck: '7',
  // },
  // {
  //   icon: first,
  //   name: 'Васильчук Виктория',
  //   revenue: '99 598,52',
  //   avgCheck: '2 085,12',
  //   deepCheck: '6',
  // },
];

export const StaffRatingSlider = ({ isMenuOpen}) => {
  return (
    <div className="staff-rating-container">
      <ul className="staff-rating-list">
        {test.map((item, index) => {
          return (
            <StaffCard
              key={index}
              data={item}
              index={index}
              isMenuOpen={isMenuOpen}
            />
          );
        })}
      </ul>
    </div>
  );
};
