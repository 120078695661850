import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import './TofuSelect.scss';

export const TofuSelect = ({
  disabled,
  onChange,
  value,
  error,
  label,
  options,
  name,
  isCloseMenu,
  onBlur,
  formError,
  placeholder,
  ...rest
}) => {
  const [err, setErr] = useState({ isActive: false, textMessage: '' });
  useEffect(() => {
    error && setErr(error);
  }, [error]);
  const handleCloseMenu = () => {};

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      handleCloseMenu();
    }
  };

  const colors = {
    blue: '#67A8F4',
    gray: '#D9DFE3',
    white: '#FFF',
    lightGray: '#EFF2F3',
    black: '#29292F',
    red: 'rgba(245, 108, 123, 1)',
  };

  const styles = {
    control: (baseStyles, state) => {
      const {
        selectProps: { isError },
      } = state;

      return {
        ...baseStyles,
        height: '52px',
        borderRadius: '10px',
        fontWeight: '400',
        fontSize: '15px',
        lineHeight: '20px',
        background: '#F9F9F9',
        boxShadow: 'none',
        border: formError ? '1px solid rgba(245, 108, 123, 1)' : 'none',
        '&:hover': {
          borderColor: `rgba(${isError ? colors.red : '17, 135, 244'}, ${
            state.isFocused ? '1' : '.5'
          })`,
        },
      };
    },
    input: (baseStyles) => ({
      ...baseStyles,
      input: {
        // width: '40px !important',
        caretColor: 'transparent',
        opacity: '1 !important',
      },
    }),
    dropdownIndicator: (baseStyles, state) => ({
      ...baseStyles,
      color: '#67A8F4',
      transition: 'all 0.2s ease-out',
      transform: `rotate(${state.selectProps.menuIsOpen ? 180 : 0}deg)`,
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      zIndex: '100',
      minWidth: '155px',
      borderRadius: '10px',
      boxShadow: '2px 6px 20px rgba(85, 85, 85, 0.09)',
      background: '#FFFFFF',
    }),
    menuList: (baseStyles) => ({
      ...baseStyles,
      overflowY: 'auto',
      height: 'auto',
      // overflow: 'hidden',
      padding: '0',
    }),

    groupHeading: () => ({
      display: 'none',
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      display: 'flex',

      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '19px',
      background: 'transparent',
      color: state.isSelected ? colors.blue : colors.black,
      '&:hover': {
        color: '#67A8F4',
      },
    }),
    singleValue: () => ({
      display: 'none',
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      fontSize: '14px',
    }),
  };

  return (
    <>
      <div>
        <div className="tofu-select-label">{label}</div>
        <Select
          menuPosition="fixed"
          menuPlacement="auto"
          isError={err.isActive}
          disabled={disabled}
          closeMenuOnSelect={true}
          blurInputOnSelect={false}
          captureMenuScroll={false}
          onMenuClose={handleCloseMenu}
          inputValue={value}
          onKeyDown={handleKeyDown}
          onBlur={onBlur}
          onChange={(e) => onChange(e, name)}
          components={{
            // Input: CustomSelectInput,
            IndicatorSeparator: null,
            // Option: CustomOption,
          }}
          options={options}
          placeholder={placeholder}
          styles={styles}
          filterOption={() => {
            return true;
          }}
          {...rest}
        />
      </div>
    </>
  );
};

TofuSelect.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.shape({
    isActive: PropTypes.bool,
    textMessage: PropTypes.string,
  }),
};
