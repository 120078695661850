import React from 'react';
import { COLORS_FIRST_TWO, COLORS_LAST_TWO } from '../../../../../const/colors';
import { calculate } from '../../../../../helpers/calculate';
import './MenthionStatistics.scss';

export const MenthionStatisctis = ({ sortedData }) => {
  const statisticData = calculate(sortedData);

  const finalStatisticData = statisticData.sort(
    (a, b) => b.sectionPercentage - a.sectionPercentage
  );

  let hasNpsType = false;

  for (const obj of finalStatisticData) {
    if (obj.nps_type === 'Время ожидания') {
      hasNpsType = true;
      break;
    }
  }

  const firstTwoElements = finalStatisticData.slice(0, 2);
  const lastTwoElements = finalStatisticData.slice(-2);

  return (
    <div className="menthion-statisctic__container">
      <ul
        className={hasNpsType ? 'menthion-statisctic__list-exeption' : 'menthion-statisctic__list'}
      >
        {firstTwoElements.map((item, index) => (
          <li key={index}>
            <span
              className="menthion-statisctic__color"
              style={{
                backgroundColor: COLORS_FIRST_TWO[index % COLORS_FIRST_TWO.length],
              }}
            ></span>
            <span className="menthion-statisctic__percent">{item.sectionPercentage}%</span>{' '}
            <span className="menthion-statisctic__stars">{item.nps_type}</span>
          </li>
        ))}
      </ul>
      <ul
        className={hasNpsType ? 'menthion-statisctic__list-exeption' : 'menthion-statisctic__list'}
      >
        {lastTwoElements.map((item, index) => (
          <li key={index}>
            <span
              className="menthion-statisctic__color"
              style={{
                backgroundColor: COLORS_LAST_TWO[index % COLORS_LAST_TWO.length],
              }}
            ></span>
            <span className="menthion-statisctic__percent">{item.sectionPercentage}%</span>{' '}
            <span className="menthion-statisctic__stars">{item.nps_type}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
