import moment from 'moment';
import React from 'react';
import { XAxis, YAxis, Tooltip, Area, AreaChart, ResponsiveContainer } from 'recharts';
import './Chart.scss';
const data = [
  {
    name: '01.07 - 02.07',
    uv: 56550.84,
    amt: 61800.08,
    percent: 0,
    foodcostWithDiscout: 5249.24,
    foodcost: 2.16,
  },
  {
    name: '03.07 - 09.07',
    uv: 122435.36,
    amt: 125635.61,
    percent: 2,
    foodcostWithDiscout: 3200.25,
    foodcost: 0.62,
  },
  {
    name: '10.07 - 16.07',
    uv: 126255.02,
    amt: 134771.81,
    percent: 4,
    foodcostWithDiscout: 8516.79,
    foodcost: 1.65,
  },
  {
    name: '17.07 - 23.07',
    uv: 126634.91,
    amt: 142038.79,
    percent: 6,
    foodcostWithDiscout: 15403.88,
    foodcost: 2.67,
  },
  {
    name: '24.07 - 31.07',
    uv: 139715.58,
    amt: 162161.9,
    percent: 8,
    foodcostWithDiscout: 18859.28,
    foodcost: 2.96,
  },
];

const graphHeigth = 130;

export const Chart = ({ dynamicsWriteOff, numAdditionStore, numAdditionReason }) => {
  const multiplyGraph = numAdditionStore * numAdditionReason;
  const newData = data.map((item) => ({
    name: item.name,
    uv: item.uv * multiplyGraph,
    amt: item.amt * multiplyGraph,
    percent: item.percent,
    foodcostWithDiscout: Math.round(item.foodcostWithDiscout * multiplyGraph),
    foodcost: Math.round(item.foodcost * multiplyGraph),
  }));

  return (
    <ResponsiveContainer width="100%" height={graphHeigth}>
      <AreaChart data={newData} margin={{ top: 10, right: 40, left: 3, bottom: 0 }}>
        <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="70%" stopColor="#67A8F4" stopOpacity={0.05} />
            <stop offset="90%" stopColor="#FFFF" stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={true}
          tick={{
            fill: '#9C9C9C',
            fontWeight: '400',
            fontSize: '10px',
            lineHeight: '15px',
          }}
          padding={{ left: 0, right: 0 }}
          stroke="#F6F6F6"
        />
        <YAxis
          tickFormatter={(tick) => tick.toFixed(0) + '%'}
          tick={{
            fill: '#9C9C9C',
            fontWeight: '400',
            fontSize: '10px',
            lineHeight: '15px',
          }}
          stroke="#F6F6F6"
          width={37}
          orientation="left"
          padding={{ bottom: 10 }}
          domain={['dataMin', 'dataMax']}
          dataKey="percent"
        />
        <Tooltip
          wrapperStyle={{ outline: 'none' }}
          content={CustomToolTip}
          cursor={{ fill: 'black', strokeWidth: 0, radius: 0 }}
          strokeDasharray={[5, 5]}
        />
        <Area
          type="monotone"
          dataKey="foodcost"
          stroke="#67A8F4"
          fillOpacity={1}
          fill="url(#colorPv)"
          strokeWidth={2}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

function CustomToolTip({ active, payload, label }) {
  if (active) {
    return (
      <div className="tooltip-writeOff" style={{ border: 'none' }}>
        {payload ? (
          <div>
            <div>
              <p className="tooltip-writeOff__date">{payload[0].payload.name}</p>
            </div>
            <div>
              <div className="tooltip-writeOff__column">
                <div>
                  <p className="tooltip-writeOff__label">Сумма:</p>
                  <p className="tooltip-writeOff__label">Процент:</p>
                </div>
                <div>
                  <p className="tooltip-writeOff__label">
                    <span>{payload[0].payload.foodcostWithDiscout} ₽</span>
                  </p>
                  <p className="tooltip-writeOff__label">
                    <span>{payload[0].payload.foodcost} %</span>
                  </p>
                </div>
              </div>
              {/* <h4>{moment.utc(label).local().format('MM.YY')}</h4>{' '} */}
              {/* <p className="tooltip-writeOff__label">{payload[0].value}</p> */}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
