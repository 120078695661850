import React, { useRef, useState } from 'react';
import cn from 'classnames';
import './subRow.scss';
import { formatNumbers } from '../../../../../helpers/formatNumbers';

export const SubRow = ({
  item,
  TableSectionInner,
  isShowDeletedPos,
  marginLeft,
  isShowABC,
  isHidePosWithZero,
}) => {
  const shouldDisplayItem =
    ((isShowDeletedPos && item.deleted) || !item.deleted) &&
    !(isHidePosWithZero && item.sales_number.value === 0);
  const textRef = useRef();

  return shouldDisplayItem ? (
    <div className="sub-row__container">
      <div className={'sub-row__wrapper'}>
        <div
          className="sub-row__main-title"
          style={{
            color: isShowDeletedPos && item.deleted ? '#D35860' : '#2B2B2B',
            marginLeft: 45,
          }}
        >
          <div className={cn('sub-row__abc', {})} ref={textRef}>
            {TableSectionInner.map((elem, index) => (
              <div key={index}>
                {isShowABC && typeof item[elem] === 'object' ? item[elem].abc_category : ''}
              </div>
            ))}
          </div>
          {item.name}
        </div>
        <>
          {TableSectionInner.map((elem, index) => (
            <>
              {item[elem] && (
                <div key={index} className="sub-row__title">
                  {typeof item[elem] === 'object' ? (
                    <div key={index} className={cn('sub-row__title-abc', {})}>
                      <div>{item[elem].value}</div>
                    </div>
                  ) : elem === 'network_dynamic' || elem === 'comparable_dynamic' ? (
                    <div
                      className={
                        elem < 0
                          ? 'like-for-like-table-row-negative'
                          : 'like-for-like-table-row-positive'
                      }
                    >
                      {item[elem]}%
                    </div>
                  ) : (
                    formatNumbers(item[elem])
                  )}
                </div>
              )}
            </>
          ))}
        </>
      </div>
    </div>
  ) : null;
};
