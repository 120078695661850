import moment from 'moment';
import React from 'react';
import './Chart.scss';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

export const Chart = ({ final }) => {
  // Check if dataKey="positive" equals 0
  const addRadiusNegative = final.some((data) => data.positive === 0);


  // Check if dataKey="neutral" equals 0 and dataKey="negative" equals 0
  const addRadiusNeutral = final.every((data) => data.positive === 0 && data.negative === 0);

  const trs = final.map((item) => {
    return item.positive === 0;
  });
  


  return (
    <ResponsiveContainer width="99.5%" height={285}>
      <BarChart data={final} margin={{ top: 20, right: 0, bottom: 0, left: -35 }}>
        <XAxis
          dataKey="name"
          minTickGap={40}
          tickLine={true}
          tickFormatter={(unixTime) => {
            return moment.utc(unixTime).local().format(' MM.YY');
          }}
          tick={{
            fill: '#9C9C9C',
            fontSize: '12px',
            lineHeight: '15px',
          }}
          stroke="#F6F6F6"
          dx={2}
        />
        <YAxis
          tick={{
            fill: '#9C9C9C',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '15px',
          }}
          stroke="#F6F6F6"
          allowDecimals={false}
          width={63}
          dy={-8}
          tickMargin={2}
        />
        <Tooltip
          wrapperStyle={{ left: 10, outline: 'none' }}
          content={CustomToolTip}
          cursor={{ fill: 'white', strokeWidth: 0, radius: 0 }}
          strokeDasharray={[5, 5]}
          animationDuration={200}
        />
        <Bar dataKey="neutral" stackId="a" fill="#E3E3E3" width={24} radius={addRadiusNeutral ? [3, 3, 0, 0] : [0, 0, 0, 0]} />
        <Bar dataKey="negative" stackId="a" fill="#F56C7B" width={24} radius={item => (item.positive === 0 ? [3, 3, 0, 0] : [0, 0, 0, 0])} />
        <Bar dataKey="positive" stackId="a" fill="#84D9BF" radius={  [3, 3, 0, 0] } width={24} />
      </BarChart>
    </ResponsiveContainer>
  );
};

function CustomToolTip({ active, payload, label }) {
  if (active) {
    return (
      <div className="tooltip-container" style={{ border: 'none' }}>
        <p className="tooltip-date">{moment.utc(label).local().format('MM.YY')}</p>
        <ul className="tooltip-list">
          {payload ? (
            <>
              <li>
                Положительные: <span>{payload[0].payload.positive}</span>
              </li>
              <li>
                Негативные: <span>{payload[0].payload.negative}</span>
              </li>
              <li>
                Нейтральные: <span>{payload[0].payload.neutral}</span>{' '}
              </li>
            </>
          ) : null}
        </ul>
      </div>
    );
  }
}
