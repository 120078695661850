import React, { useState } from 'react';
import { InfoIcon } from '../../../icons/info-icon';
import './HoverInfo.scss';

export default function HoverInfo({ children, height, width, containerHeight }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="info-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ height: containerHeight }}
    >
      <div className="info-icon">
        <InfoIcon className={isHovered ? 'hovered' : ''} height={height} width={width} />
      </div>
      {isHovered && <>{children}</>}
    </div>
  );
}