import React from 'react';

export const BlackArrowForSelect = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icons">
        <path
          id="arrow down small"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.7705 16.2705C12.4645 16.5765 11.9683 16.5765 11.6623 16.2705L7.22952 11.8377C6.92349 11.5317 6.92349 11.0355 7.22952 10.7295C7.53554 10.4235 8.0317 10.4235 8.33771 10.7295L12.2164 14.6082L16.0951 10.7295C16.4011 10.4235 16.8972 10.4235 17.2033 10.7295C17.5093 11.0355 17.5093 11.5317 17.2033 11.8377L12.7705 16.2705Z"
          fill="#09244B"
        />
      </g>
    </svg>
  );
};
