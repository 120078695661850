import { useEffect, useRef, useState } from 'react';
import { Check } from '../../../../../../../../icons/reviewsPlatforms/check';
import './CommentsSelect.scss';

export const CommentsSelect = ({
  options,
  onChange,
  setSelectedOptions,
  npsForOrdering,
  isWhite,
  setOptionsSelect,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const selectRef = useRef(null);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setSelectedOptions([option]);
    onChange(npsForOrdering, option.value);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setExpanded(false);
      }
    };

    if (expanded) {
      document.addEventListener('click', handleOutsideClick);
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [expanded]);
  return (
    <div
      className={'comments-select-options'}
      ref={selectRef}
      style={{ background: isWhite ? '#fff' : null, height: isWhite ? 36 : '' }}
    >
      <div className="comments-select-header" onClick={handleToggleExpand}>
        <div className="comments-select-header-label">
          {selectedOption ? selectedOption.label : options[0].label}
        </div>
        <div className={`comments-select-arrow ${expanded ? 'expanded' : ''}`}></div>
      </div>
      {expanded && (
        <div className="comments-select-options-list">
          {options.map((option) => (
            <div className="comments-select-options-list-item" key={option.value}>
              <label
                className={`comments-select-option ${
                  selectedOption && selectedOption.value === option.value ? 'selected' : ''
                }`}
                onClick={() => handleOptionChange(option)}
                style={{
                  color:
                    selectedOption && selectedOption.value === option.value ? '#67A8F4' : '#1E1E1E',
                }}
                onMouseEnter={(e) => (e.target.style.color = '#67A8F4')}
                onMouseLeave={(e) =>
                  (e.target.style.color =
                    selectedOption &&
                    selectedOption.type === option.type &&
                    selectedOption.label === option.label
                      ? '#67A8F4'
                      : '#1E1E1E')
                }
              >
                <div onClick={handleToggleExpand}>{option.label}</div>
              </label>
              <div className="icon">
                {selectedOption && selectedOption.value === option.value && <Check />}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
