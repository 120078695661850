import { useState, useEffect } from 'react';
import classNames from 'classnames';
import './PageWrapper.scss';
import { NotFound } from '../../../pages/notFound/NotFound';
import { AppHeader } from '../AppHedaer/AppHeader';
import SideBar from '../SideBar/SideBar';

export const PageWrapper = ({
  children,
  title,
  handleSelectedDateChange,
  selectedDate,
  platformsOptions,
  handlePlatformsSelectedChange,
  donwnload,
  reviewsPage,
  dateSelect,
  account_id,
  notFound,
  isMenuOpen,
  setIsMenuOpen,

}) => {
  // const [isMenuOpen, setIsMenuOpen] = useState('close');
  // const [isMenuOpen, setIsMenuOpen] = useState(() => {
  //   const storedNavigationClass = localStorage.getItem('navigationClass');
  //   return storedNavigationClass ? storedNavigationClass : 'close';
  // });
  useEffect(() => {
    localStorage.setItem('navigationClass', isMenuOpen);
  }, [isMenuOpen]);

  return (
    <>
      {notFound ? (
        <NotFound />
      ) : (
        <div className={classNames('page-wrapper__container', isMenuOpen)}>
          <SideBar collapsed={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
          <div className={classNames('page-wrapper__content-section', isMenuOpen)}>
            <AppHeader
              title={title}
              handleSelectedDateChange={handleSelectedDateChange}
              selectedDate={selectedDate}
              platformsOptions={platformsOptions}
              handlePlatformsSelectedChange={handlePlatformsSelectedChange}
              donwnload={donwnload}
              dateSelect={dateSelect}
              collapsed={isMenuOpen}
            />
            <div
              className={classNames('page-wrapper__content-section_body', {
                reviewsPage: reviewsPage,
              })}
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
