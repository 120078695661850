import './Operation.scss';

export const Operation = ({ data }) => {
  return (
    <div className="operation-container">
      <div className="operation-icon">{data.icon}</div>
      <div className="operation-top">
        <div className="operation-quantity">{data.quantiy}</div>
        <div className="operation-text">{data.checksText}</div>
      </div>
      <div>
        <div className="operation-warning">Не просмотрено</div>
        <div className='operation-num-text'>{data.num} шт.</div>
      </div>
    </div>
  );
};
