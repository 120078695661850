export const AUTH = 'AUTH';
export const REG = 'REG';
export const REG_SUCCESS = 'REG_SUCCESS';
export const REG_FAILURE = 'REG_FAILURE';
export const SET_AUTH_DATA = 'SET_AUTH_DATA';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SET_USER_ID = 'SET_USER_ID';
export const IS_EMAIL_REGISTRATION_IN_PROGRESS = 'IS_EMAIL_REGISTRATION_IN_PROGRESS';
export const HIDE_RESEND_EMAIL_CONFIRMATION = 'HIDE_RESEND_EMAIL_CONFIRMATION';
export const SHOW_RESEND_EMAIL_CONFIRMATION = 'SHOW_RESEND_EMAIL_CONFIRMATION';
