import { useState } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Telegram.scss';
import { Switchers } from '../Switchers/Switchers';
import { GoogleIcon } from '../../../icons/google-icon';
import { TofuField } from '../../../components/common/fields/TofuField/TofuField';
import { Button } from '../../../components/common/Button/Button';

export const Telegram = ({ googlePoint }) => {
  const [restaurant_name_on_map, setRestaurant_name_on_map] = useState('');

  const [point_address, setPoint_address] = useState('');
  //   const changeData = () => {
  //     settingsApi
  //       .changePoint(googlePoint.id, restaurant_name_on_map, point_address, googlePoint.account)
  //       .then((res) => {
  //         toast.success('Data changed successfully');
  //       });
  //   };

  return (
    <div className="telegram-container">
      <div className="telegram-wrapper">
        <div>
          <div>
            <GoogleIcon width={48} height={48} />
          </div>
          <div className="telegram-title">Telegram</div>
        </div>
        <div>
          <div className="telegram-title">Ваш ID</div>
          <div className="telegram-descripthion">
            Заполните поля ниже, чтобы Вы могли отслеживать отзывы с платформы
          </div>
          <div className="telegram-fields">
            <TofuField
              name="restaurant_name"
              value={restaurant_name_on_map}
              placeholder="Введите свой ID "
              onChange={(e) => setRestaurant_name_on_map(e.target.value)}
              label="Логин для api*"
            />
          </div>
          <div className="telegram-info">
            Не знаете, где взять ID аккаунта? Посмотрите инструкцию{' '}
            <a href="/123" target="_blank">
              здесь
            </a>
          </div>
          <div>
            <Button styleType="primary" width={300} height={50}>
              Сохранить
            </Button>
          </div>
        </div>
        <ToastContainer position="top-right" />
      </div>
      <Switchers />
    </div>
  );
};
