import React from 'react';
import ChartSecond from '../../ChartSecond/ChartSecond';
import './SecondTab.scss';
const SecondTab = () => {
  return (
    <div className="SecondTab">
      <ChartSecond />
      {/* Second  tab content will go here */}
    </div>
  );
};
export default SecondTab;
