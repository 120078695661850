import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation, useFetcher } from 'react-router-dom';
import { PathRoutes } from './const/routes';
import { Dashboard } from './pages/dashboard/Dashboard';
import { Reviews } from './pages/reviews/Reviews';
import { LandingReview } from './pages/landingReview/LandingReview';
import api from './api/baseApi';
import axios from 'axios';
import { authApi, initAuthorization } from './api/authApi';
import { BlockModal } from './components/common/ModalWindow/BlockModal/BlockModal';
import { SettingsCompanyInfo } from './pages/settingsCompanyInfo/settingsCompanyInfo';
import { SettingsPlaTform } from './pages/settingsPlatformInfo/settingsPlatform';
import { SettingsUserInfo } from './pages/settingsUserInfo/settingsUserInfo';
import { NotFound } from './pages/notFound/NotFound';
import { Loader } from './components/common/Loader/Loader';
import { PageWrapper } from './components/common/PageWrapper/PageWrapper';
import ScrollToTop from './helpers/scroll';
import { WriteOffPage } from './pages/reportsPages/write-off/WriteOff';
import { Foodcost } from './pages/reportsPages/foodcost/Foodcost';
import { MailVerification } from './pages/registrationPages/mailVerification/MailVerification';
import { UserCreation } from './pages/registrationPages/userCreation/UserCreation';
import { Registration } from './pages/registrationPages/registration/Registration';
import { CompanyCreation } from './pages/registrationPages/companyCreation/CompanyCreation';
import { SubscriptionOne } from './pages/registrationPages/subscriptionOne/SubscriptionOne';
import { Subscription } from './pages/registrationPages/subscription/Subscription';
import { SalesReport } from './pages/reportsPages/salesReport/SalesReport';
import { DayResults } from './pages/reportsPages/dayResult/DayResult';
import { Login } from './pages/loginPages/login/login';
import { NewPassword } from './pages/loginPages/newPassword/NewPassword';
import { ResetPassword } from './pages/loginPages/resetPassword/ResetPassword';
import { PosIntegration } from './pages/settingsPosIntegration/PosIntegration';
import { Notifications } from './pages/settingsNotifications/Notifications';
import { SettingsSubscription } from './pages/settingsSubscription/Subscription';
import { SettingsReports } from './pages/settingsReports/SettingsReports';
import './App.scss';
import { WarningModal } from './components/common/WarningModal/WarningModal';
import { LikeForLike } from './pages/reportsPages/likeForLike/likeForLike';

function App() {
  const [isWarningModal, setIsWarningModal] = useState(false);

  const [isAuth, setIsAuth] = useState(false);
  const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthLoaded, setIsAuthLoaded] = useState(false);
  const [account_id, setAccount_id] = useState(7);
  const [isMenuOpen, setIsMenuOpen] = useState('close');

  ScrollToTop();

  useEffect(() => {
    const handleResize = () => {
      setIsWarningModal(window.innerWidth <= 1150);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // useEffect(() => {
  //   const token = localStorage.getItem('authToken');
  //   if (token) {
  //     api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  //     axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  //     setIsAuth(true);
  //   }
  // setIsAuthLoaded(true);
  // setIsLoading(false);
  // }, []);

  useEffect(() => {
    authApi.auth('caviar.mozyr@gmail.com', 'Cav26082020iar').then((res) => {
      localStorage.setItem('authToken', res.data.access);
      localStorage.setItem('refreshToken', res.data.refresh);
      initAuthorization();
      setIsAuth(true);
      setIsAuthLoaded(true);
      setIsLoading(false);
    }, []);
  });

  if (!isAuthLoaded) {
    return <Loader />;
  }

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            isAuth ? (
              <Navigate to={PathRoutes.ReviewsRoute} replace />
            ) : (
              <Navigate to={PathRoutes.LoginRoute} replace />
            )
          }
        />
        {!isAuth && <Route path="*" element={<NotFound />} />}
      </Routes>

      {!isLoading && (
        <div>
          <PageWrapper isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}>
            <div className="app">
              {isAuth && (
                <>
                  {/* {!isSubscriptionExpired ? ( */}
                  <Routes>
                    <Route path={PathRoutes.DashboardRoute} element={<Dashboard />} />
                    <Route
                      path={PathRoutes.ReviewsRoute}
                      element={<Reviews email={email} password={password} />}
                    />

                    <Route
                      path={PathRoutes.Foodcost}
                      element={<Foodcost account_id={account_id} />}
                    />
                    <Route
                      path={PathRoutes.SalesRoute}
                      element={<SalesReport isMenuOpen={isMenuOpen} />}
                    />
                    <Route
                      path={PathRoutes.WriteOff}
                      element={<WriteOffPage account_id={account_id} />}
                    />
                    <Route
                      path={PathRoutes.DayResult}
                      element={<DayResults isMenuOpen={isMenuOpen} />}
                    />
                    <Route path={PathRoutes.Settings} element={<SettingsCompanyInfo />} />
                    <Route path={PathRoutes.UserInfo} element={<SettingsUserInfo />} />
                    <Route path={PathRoutes.PlatformsInfo} element={<SettingsPlaTform />} />
                    <Route path={PathRoutes.Reports} element={<SettingsReports />} />
                    <Route path={PathRoutes.PosIntegrations} element={<PosIntegration />} />
                    <Route path={PathRoutes.Notification} element={<Notifications />} />
                    <Route
                      path={PathRoutes.LikeForLike}
                      element={<LikeForLike isMenuOpen={isMenuOpen} />}
                    />
                    <Route
                      path={PathRoutes.SettingsSubscription}
                      element={<SettingsSubscription />}
                    />
                    <Route path={PathRoutes.CompanyInfo} element={<SettingsCompanyInfo />} />
                    {/* <Route path="*" element={<NotFound />} /> */}
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                  {/* ) : (
                <BlockModal />
              ) */}
                  {isWarningModal && (
                    <WarningModal
                      isOpen={isWarningModal}
                      onClose={() => setIsWarningModal(!isWarningModal)}
                    />
                  )}
                </>
              )}
            </div>
          </PageWrapper>
        </div>
      )}
    </>
  );
}

export default App;
