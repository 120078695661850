import * as React from 'react';

export const PhoneIcon = () => {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.5523 22.1327C15.1114 22.0797 11.0307 21.5163 6.75735 17.2429C2.48396 12.9695 1.92056 8.88886 1.86754 7.44791C1.78674 5.25245 3.46854 3.11926 5.41215 2.28629C5.86821 2.09083 6.4206 2.1266 6.85728 2.44513C8.45607 3.61135 9.56071 5.37459 10.509 6.7619C10.9374 7.38859 10.835 8.24424 10.2531 8.74796L8.30197 10.197C8.10858 10.3406 8.04428 10.6016 8.16027 10.8127C8.60172 11.6163 9.38818 12.8121 10.2881 13.7121C11.1891 14.613 12.4414 15.4502 13.3002 15.9414C13.5229 16.0688 13.803 15.995 13.9438 15.7805L15.2131 13.8471C15.6998 13.1993 16.6088 13.0578 17.2695 13.5151C18.677 14.4892 20.3188 15.5739 21.521 17.1135C21.8625 17.5509 21.9133 18.1229 21.7096 18.5983C20.8728 20.5509 18.7552 22.2138 16.5523 22.1327Z" fill="#C4C4C2"/>
</svg>

  );
};
