import React, { useState } from 'react';

import './HoverTitle.scss';

export default function HoverTitle({ children, isNetwork }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="hover-title__container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="hover-title__title">{children}</div>
      {isHovered && (
        <div className="hover-title__text">
          {isNetwork ? (
            <div>
              {' '}
              Сравнение показателей сети с прошлым периодом,
              <br /> учитывая заведения, которые открылись/закрылись.
              <br /> Показатели выявлены в <span>10 из 10</span> заведений
            </div>
          ) : (
            <div>
              Сравнение показателей сети с прошлым периодом,
              <br /> не учитывая заведения, которые открылись/закрылись.
              <br /> Показатели за прошлый и отчетный периоды выявлены
              <br /> в <span>4 из 10</span> заведений
            </div>
          )}
        </div>
      )}
    </div>
  );
}
