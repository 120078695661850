import React, { useState } from 'react';
import { Select } from '../../../../../components/common/Select/Select';
import { ThreeDotsIcon } from '../../../../../icons/three-dots-icon';
import { TOP_TEN_PRODUCTS_OPTION } from '../../../foodcost/const';
import { REVENUE_TEXT } from '../const';
import FirstTab from './FirstTab/FirstTab';
import SecondTab from './SecondTab/SecondTab';
import './Tabs.scss';
export const Tabs = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTab1 = () => {
    setActiveTab('tab1');
  };
  const handleTab2 = () => {
    setActiveTab('tab2');
  };
  return (
    <div className="tabs-revenue">
      <div className="tabs-revenue__head">
        <div className="tabs-revenue__head-left">
          <div className="tabs-revenue__head-left-title">Выручка</div>
          <ul className="nav">
            <li className={activeTab === 'tab1' ? 'active' : ''} onClick={handleTab1}>
              В динамике
            </li>
            <li className={activeTab === 'tab2' ? 'active' : ''} onClick={handleTab2}>
              По часам
            </li>
          </ul>
        </div>
        {/* <div>
          <Select options={TOP_TEN_PRODUCTS_OPTION} secondBg isOneRow />
        </div> */}
      </div>
      <div>
        {activeTab === 'tab1' ? (
          <div className="revenue-colorst">
            <ul className="revenue-list">
              {REVENUE_TEXT.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="empty"></div>
        )}
      </div>
      <div className="outlet">{activeTab === 'tab1' ? <FirstTab /> : <SecondTab />}</div>
    </div>
  );
};
