export const FOODCOST_INFO = (
    <>
      {' '}
      Сумма себестоимости
      <br /> от выручки с учетом скидок
    </>
  );
  
  export const COST_PRICE_INFO = 'Средняя себестоимость продаж';
  
  export const PRICE_INFO = (
    <>
      Средняя цена продаж
      <br />с учетом скидок и наценок
    </>
  );