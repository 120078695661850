import { useState } from 'react';
import { Button } from '../../../components/common/Button/Button';
import { TofuField } from '../../../components/common/fields/TofuField/TofuField';
import { GoogleIcon } from '../../../icons/google-icon';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './RKepper.scss';

export const RKepper = ({ googlePoint }) => {
  const [restaurant_name_on_map, setRestaurant_name_on_map] = useState('');

  const [point_address, setPoint_address] = useState('');
  //   const changeData = () => {
  //     settingsApi
  //       .changePoint(googlePoint.id, restaurant_name_on_map, point_address, googlePoint.account)
  //       .then((res) => {
  //         toast.success('Data changed successfully');
  //       });
  //   };

  return (
    <div className="google-container">
      <div>
        <div>
          <GoogleIcon width={48} height={48} />
        </div>
        <div className="google-title">r-keeper</div>
      </div>
      <div>
        <div className="google-title">Ключ API</div>
        <div className="google-descripthion">
          Заполните поля ниже, чтобы Вы могли отслеживать отзывы с платформы
        </div>
        <div className="google-fields">
          <TofuField
            name="restaurant_name"
            value={restaurant_name_on_map}
            placeholder="Вставьте логин для API"
            onChange={(e) => setRestaurant_name_on_map(e.target.value)}
            label="Логин для api*"
          />
          <TofuField
            name="address"
            value={point_address}
            placeholder="Вставьте пароль для API"
            onChange={(e) => setPoint_address(e.target.value)}
            label="Пароль для api*"
          />
        </div>
        <div className="google-info">
          Не знаете, где взять информацию? Посмотрите инструкцию{' '}
          <a href="/123" target="_blank">
            здесь
          </a>
        </div>
        <div>
          <Button styleType="primary" width={300} height={50}>
            Интегрировать
          </Button>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};
