import { TofuErrorIcon } from '../../../icons/tofu-error';
import cn from 'classnames';
import './ErrorWidget.scss';

export const ErrorWidget = ({ text, withMargin, reviews }) => {
  return (
    <div className={(cn('error', { withMargin: withMargin }, {reviews:reviews}))}>
      <TofuErrorIcon />
      <div className="error-text" dangerouslySetInnerHTML={{ __html: text }}></div>
    </div>
  );
};
