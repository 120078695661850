import { DATA } from './const';
export const NpsDescription = () => {
  return (
    <div className="info-container-popover">
      <div className="info-text">
        <p>
          <span className="boldText">NPS (Net Promoter Score) Общий</span> отвечает на вопросы:
        </p>
        <ul className="info-text-list">
          {DATA.map((elem) => (
            <li key={elem.id}>{elem.text}</li>
          ))}
        </ul>
        <p className="info-text-about">
          <span className="boldText">NPS по разделам</span> (кухня, бар, кальян и т.п.) <br />
          TOFU на основе ChatGPT анализирует текст отзыва <br />
          на упоминание раздела и присваивает оценку разделу
        </p>
        <p>Формула выглядит так:</p>
        <p className="info-text-formula">NPS = (% Промоутеров) – (% Критиков)</p>
        <p>Промоутер = 5 звезд</p>
        <p>Критик = 1-3 звезды</p>
        <p className="info-text-example">
          К примеру, высокие оценки поставили <br />
          73% опрошенных, а низкие – 5%
          <br />
          Разница равна 68%
          <br />
          Значит, рейтинг <span className="boldText">NPS</span> компании равен{' '}
          <span className="boldText">68%</span>
        </p>
      </div>
    </div>
  );
};
