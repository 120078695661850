import React from 'react';

export const NavigationArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.464165 6.46442C0.0736651 6.85492 0.0736647 7.48812 0.464165 7.87862L6.12107 13.5355C6.51155 13.926 7.1447 13.926 7.53523 13.5355C7.92575 13.145 7.92575 12.5118 7.53523 12.1213L2.58546 7.17152L7.53523 2.22182C7.92575 1.83122 7.92575 1.19812 7.53524 0.807523C7.14471 0.417023 6.51155 0.417023 6.12107 0.807523L0.464165 6.46442Z"
        fill="#C4C4C2"
      />
    </svg>
  );
};
