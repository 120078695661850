import React from 'react';
import { NPS_TYPES } from '../../../../const/nps';
import { AddCircle } from './AddCircle/AddCircle';
import CircularProgress from './CircleProgress/CircleProgress';
import { EmptyCircleProgress } from './EmptyCircle/EmptyCircle';
import NodataCircle from './NoDataCircle/NoDataCircle';

import './Nps.scss';
import Popover from './Popover/Popover';

export default function Nps({
  serverData,
  setServerData,
  dataWithSelected,
  setDataWithSelected,
  setCheckedItems,
  checkedItems,
  account_id,
}) {
  const emptyCircles = [];

  for (let i = serverData.length; i <= 3; i++) {
    emptyCircles.push(<EmptyCircleProgress key={i} percentage={0} />);
  }

  const sortedData = [...serverData].sort((a, b) => b.has_data - a.has_data || a.nps_type - b.nps_type);
  
  return (
    <div className="nps">
      {sortedData.map((item) =>
        item.has_data ? (
          <div className="nps__item" key={item.id}>
            <CircularProgress
              percentage={item.percent}
              nps_name={NPS_TYPES.find((dataItem) => dataItem.id === item.nps_type)?.type}
            />
          </div>
        ) : (
          <NodataCircle
            key={item.id}
            nps_name={NPS_TYPES.find((dataItem) => dataItem.id === item.nps_type)?.type}
          />
        )
      )}
      {sortedData.length === 5 ? null : (
        <Popover
          setCheckedItems={setCheckedItems}
          checkedItems={checkedItems}
          serverData={serverData}
          setServerData={setServerData}
          dataWithSelected={dataWithSelected}
          setDataWithSelected={setDataWithSelected}
          account_id={account_id}
          vertical={80}
          horizontal={10}
          popoverTitle="Добавить раздел"
        >
          <div className=" nps__item-add">
            <AddCircle percentage={0} />
          </div>
        </Popover>
      )}
      {sortedData.length === 4 || sortedData.length === 5 ? null : <>{emptyCircles}</>}
    </div>
  );
}
