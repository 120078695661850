import { AnimatePresence, motion } from 'framer-motion';
import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ArrowDownIcon } from '../../../icons/arrow-down';
import './SideBar.scss';

const menuAnimation = {
  hidden: {
    opacity: 0,
    height: 0,
    padding: 0,
    transition: { duration: 0.3, when: 'afterChildren' },
  },
  show: {
    opacity: 1,
    height: 'auto',
    transition: {
      duration: 0.3,
      when: 'beforeChildren',
    },
  },
};
const menuItemAnimation = {
  hidden: (i) => ({
    padding: 0,
    x: '-100%',
    transition: {
      duration: (i + 1) * 0.1,
    },
  }),
  show: (i) => ({
    x: 0,
    transition: {
      duration: (i + 1) * 0.1,
    },
  }),
};

const SidebarMenu = ({ route, showAnimation, isOpen, setIsOpen, setColapsed }) => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsOpen(true);
    setColapsed(!isOpen ? 'close' : 'close');
  };

  useEffect(() => {
    if (!isOpen) {
      setIsMenuOpen(false);
    }
  }, [isOpen]);
  return (
    <>
      <div className="sidebar-menu" onClick={toggleMenu}>
        <div className="sidebar-menu_item">
          <div className="sidebar-icon">{route.icon}</div>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="sidebar-link_text"
              >
                {route.name}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {isOpen && (
          <div className={isMenuOpen ? 'sidebar-arrow-up' : 'sidebar-arrow-down'}>
            <ArrowDownIcon />
          </div>
        )}
      </div>{' '}
      {isMenuOpen && (
        <motion.div variants={menuAnimation} initial="hidden" animate="show" exit="hidden">
          {route.subRoutes.map((subRoute, i) => (
            <div key={i} custom={i}>
              <NavLink to={subRoute.path} className="sidebar-link">
                <div className="sidebar-icon">
                  {subRoute.generateIcon(subRoute.path === location.pathname)}
                </div>
                <div className="sidebar-link_text">{subRoute.name}</div>
              </NavLink>
            </div>
          ))}
        </motion.div>
      )}{' '}
    </>
  );
};

export default SidebarMenu;
