import React from 'react';

export const MailIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.06773 5.48201L10.9435 14.3578C11.4927 14.907 12.3618 14.9413 12.9509 14.4608L13.0648 14.3578L21.934 5.48861C21.9627 5.59744 21.9825 5.70989 21.9925 5.82513L22 6V18C22 19.0544 21.1841 19.9182 20.1493 19.9945L20 20H4C2.94564 20 2.08183 19.1841 2.00549 18.1493L2 18V6C2 5.88057 2.01047 5.76359 2.03053 5.64992L2.06773 5.48201ZM20 4C20.1211 4 20.2396 4.01075 20.3547 4.03135L20.5247 4.06953L12.0042 12.59L3.48191 4.06776C3.59207 4.03829 3.70598 4.018 3.82276 4.00775L4 4H20Z"
        fill="#C4C4C2"
      />
    </svg>
  );
};
