import React from 'react';
import Logo from '../../../../../images/sadLogoTofu.svg'; 
import './SearchError.scss';

export const SearchError = () => {
  return (
    <div className="search-error__container">
      <div className="search-error__logo">
        <img src={Logo} alt="logo"></img>
      </div>
      <div className="search-error__tittle">
        <p className="search-error__tittle__text">
          Нет данных за выбранный период или в выбранной причине,
        </p>
        <p> или на выбранном складе. Попробуйте изменить фильтр</p>
      </div>
    </div>
  );
};
