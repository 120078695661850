import React from 'react';

export const SaleIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.40535 2.89695C10.845 1.67008 12.9374 1.62464 14.4254 2.76063L14.5943 2.89695L14.9708 3.21777C15.2453 3.45175 15.5772 3.60639 15.9306 3.66688L16.1089 3.68921L16.602 3.72855C18.4925 3.87941 20.0071 5.33408 20.25 7.19661L20.2711 7.39769L20.3104 7.89075C20.3391 8.25035 20.4644 8.59433 20.6716 8.88699L20.7819 9.02888L21.1027 9.40535C22.3295 10.845 22.375 12.9374 21.239 14.4253L21.1027 14.5943L20.7819 14.9708C20.5479 15.2453 20.3932 15.5772 20.3327 15.9306L20.3104 16.1089L20.2711 16.602C20.1202 18.4925 18.6656 20.0072 16.8031 20.25L16.602 20.2711L16.1089 20.3105C15.7493 20.3391 15.4053 20.4644 15.1127 20.6716L14.9708 20.7819L14.5943 21.1027C13.1547 22.3296 11.0623 22.375 9.57429 21.239L9.40535 21.1027L9.02888 20.7819C8.75432 20.5479 8.42248 20.3932 8.06908 20.3328L7.89074 20.3105L7.39768 20.2711C5.50717 20.1203 3.99251 18.6656 3.74966 16.8031L3.72855 16.602L3.6892 16.1089C3.6605 15.7493 3.5352 15.4053 3.32808 15.1127L3.21777 14.9708L2.89695 14.5943C1.67008 13.1547 1.62464 11.0623 2.76063 9.57429L2.89695 9.40535L3.21777 9.02888C3.45175 8.75432 3.60638 8.42248 3.66687 8.06908L3.6892 7.89075L3.72855 7.39769C3.87941 5.50717 5.33408 3.99251 7.19661 3.74966L7.39768 3.72855L7.89074 3.68921C8.25035 3.66051 8.59433 3.5352 8.88699 3.32808L9.02888 3.21777L9.40535 2.89695ZM14.4998 12.9999C13.6714 12.9999 12.9998 13.6715 12.9998 14.4999C12.9998 15.3283 13.6714 15.9999 14.4998 15.9999C15.3282 15.9999 15.9998 15.3283 15.9998 14.4999C15.9998 13.6715 15.3282 12.9999 14.4998 12.9999ZM14.2927 8.29277L8.29271 14.2928C7.90219 14.6833 7.90219 15.3165 8.29271 15.707C8.68323 16.0975 9.3164 16.0975 9.70692 15.707L15.7069 9.70699C16.0974 9.31646 16.0974 8.6833 15.7069 8.29277C15.3164 7.90225 14.6832 7.90225 14.2927 8.29277ZM9.49982 7.99988C8.67139 7.99988 7.99982 8.67145 7.99982 9.49988C7.99982 10.3283 8.67139 10.9999 9.49982 10.9999C10.3282 10.9999 10.9998 10.3283 10.9998 9.49988C10.9998 8.67145 10.3282 7.99988 9.49982 7.99988Z"
        fill="#F56C7B"
      />
    </svg>
  );
};
