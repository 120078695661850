import React from 'react';

export const CloseIcon = ({width = 24, height = 24}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.67958 9.08435C7.36875 8.69183 7.39465 8.12 7.75729 7.75736C8.14782 7.36682 8.78097 7.36683 9.1715 7.75736L11.9999 10.5858L14.8284 7.75736L14.9156 7.67965C15.3081 7.36882 15.8799 7.39472 16.2426 7.75736C16.6331 8.14789 16.6331 8.78104 16.2426 9.17157L13.4141 12L16.2426 14.8284L16.3203 14.9157C16.6311 15.3082 16.6052 15.88 16.2426 16.2426C15.852 16.6332 15.2189 16.6332 14.8284 16.2426L11.9999 13.4142L9.1715 16.2426L9.08428 16.3203C8.69177 16.6312 8.11993 16.6053 7.75729 16.2426C7.36675 15.8521 7.36677 15.2189 7.75729 14.8284L10.5857 12L7.75729 9.17157L7.67958 9.08435Z"
        fill="#C4C4C2"
      />
    </svg>
  );
};
