import { ProductsInfo } from './ProductsInfo/ProductsInfo';
import { TopBanner } from './TopBanner/TopBanner';
import './TopTenProductsInfo.scss';

export const TopTenProductsInfo = ({ data, topTenProductsData }) => {
  return (
    <div>
      <TopBanner banner={data} bannerText={topTenProductsData} />
      <ProductsInfo products={data} columns={topTenProductsData} />
    </div>
  );
};
