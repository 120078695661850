import { useCallback, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Chart } from './Chart/Chart';
import './ThreeCardsTop.scss';
import { HoverPercent } from './HoverPercent/HoverPercent';
import { Hover } from '../../../../../components/common/Hover/Hover';
import { ModalCommon } from './ModalCommon/ModalCommon';
import { ArrowUpIcon } from '../../../../../icons/day-results/arrow-up';
import { ArrowDownIcon } from '../../../../../icons/day-results/arrow-down';
import { Button } from '../../../../../components/common/Button/Button';
import { ArrowRightIcon } from '../../../../../icons/reports/day-result/arrow-right-icon';

const dataHoverPercent = [
  {
    percent: '22,13%',
    sum: '64 657, 60',
  },
  {
    percent: '4,95%',
    sum: '7 144,88',
  },
  {
    percent: '3,1%',
    sum: '8 647,77',
  },
];

export const ThreeCardsTop = ({ data }) => {
  const [isSalesModal, isSetSalesModal] = useState(false);
  const onCloseModal = useCallback(() => {
    isSetSalesModal(false);
  }, []);

  const onOpenModal = useCallback(() => {
    isSetSalesModal(true);
  }, []);

  return (
    <div className="three-cards-top__container">
      {data.map((item, index) => (
        <div className="three-cards-top__content">
          <div className="three-cards-top__title">{item.title}</div>
          <div className="three-cards-top__data">
            <div className="three-cards-top__main-percent">{item.main_percent}</div>
            <Hover
              containerClassName={`three-cards-top__change-percent ${item.color}`}
              hoverContainerClassName={'three-cards-top__hover'}
              title={item.change_percent + ' %'}
              index={index}
              isArray
            >
              <HoverPercent hoveredIndex={index} dataHoverPercent={dataHoverPercent} />
            </Hover>
          </div>
          <div className="three-cards-top__sum">
            Сумма <span style={{ fontWeight: '700' }}>{item.sum}</span> ₽
          </div>
          <div className="three-cards-top__chart-container">
            <img style={{ maxWidth: '100%' }} src={item.img} />
            {/* <Chart /> */}
            {/* <CSSTransition
              in={index === hoveredIndex}
              classNames="three-cards-top__btn"
              unmountOnExit
              timeout={200}
            >
              {(state) => (
                <div className={`three-cards-top__button-wrapper ${state}`}>
                  <Button
                    styleType="about"
                    height={22}
                    icon={<ArrowRightIcon />}
                    onClick={onOpenModal}
                  >
                    <p> {item.btn_text}</p>
                  </Button>
                </div>
              )}
            </CSSTransition> */}
          </div>
          {/* {index === hoveredIndex && (
            <ModalCommon isOpen={isSalesModal} onClose={onCloseModal} item={item} />
          )} */}
        </div>
      ))}
    </div>
  );
};
