import React, { useEffect, useState } from 'react';
import './EmptyCircle.scss';

export const EmptyCircleProgress = ({ size = 77, strokeWidth = 7, percentage, nps_name }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(percentage);
  }, [percentage]);

  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;

  return (
        <svg width={size} height={size} viewBox={viewBox}>
          <circle
            fill="none"
            stroke="#E3E3E3"
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`2px`}
            strokeDasharray="5"
          />
          <circle
            fill="#F9F9F9"
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
            transform={`rotate(-90 ${size / 2} ${size / 2})`}
            strokeDasharray={[dash, circumference - dash]}
            strokeLinecap="round"
            style={{ transition: 'all 0.5s' }}
          />
        </svg>
  );
};
