import React, { useState } from 'react';
import ProgressBar from './ProgressBar/ProgressBar';
import ProgressBarSmall from './ProgressBarSmall/ProgressBarSmall';
import './Banner.scss';
import { BannerStatistics } from './BannerStatistics/BannerStatistics';
import { SliderBannerStatistics } from './SliderBannerStatistics/SliderBannerStatistics';
import { HoverPercent } from '../../dayResult/components/ThreeCardsTop/HoverPercent/HoverPercent';
import { HoverPlan } from '../../../../components/common/HoverPlan/HoverPlan';
import { HoverSettings } from '../../../../components/common/HoverSettings/HoverSettings';
import { HoverDescription } from '../../../../components/common/HoverSettings/HoverDescription/HoverDescription';
import { Hover } from '../../../../components/common/Hover/Hover';

const dataSlider = [
  {
    title: 'Наличные',
    sum: '3 523 542,90',
    percent: '37',
  },
  {
    title: 'Оплата картой',
    sum: '4 952 006,24',
    percent: '52',
  },
  {
    title: 'Бонусы',
    sum: '666 616,22',
    percent: '7',
  },
  {
    title: 'Бартер',
    sum: '276 169,58',
    percent: '2,9',
  },
  {
    title: 'Предоплата',
    sum: '104 753,98',
    percent: '1,1',
  },
];

const dataHoverPercentLeftSide = [
  {
    sum: '9 289 773,24',
  },
];

const PROGRESS_BAR_PERCENT = 125;
const PROGRESS_BAR_HEIGHT = 17;

export const Banner = ({ banner, revenue }) => {
  return (
    <div className="banner-sales__conatiner">
      <div className="banner-sales__left-side">
        <div className="banner-sales__left-side-top">
          <div className="banner-sales__left-side-title">{banner.title}</div>
          <HoverSettings>
            <HoverDescription />
          </HoverSettings>
        </div>
        <div className="banner-sales__left-side-percent">
          <div className="banner-sales__left-side-percent-left">{banner.total_percent} ₽</div>
          <Hover
            containerClassName={'banner-sales__left-side-percent-right'}
            hoverContainerClassName={'banner-sales__left-side-percent-right_hover'}
            title={banner.change_percent}
          >
            <HoverPercent
              hoveredIndex={0}
              dataHoverPercent={dataHoverPercentLeftSide}
              revenue={revenue}
            />
          </Hover>
        </div>
        <div
          className="banner-sales__left-side-sum"
        >
          План <span>{banner.total_sum} ₽</span>
          <ProgressBar completed={PROGRESS_BAR_PERCENT} height={PROGRESS_BAR_HEIGHT} />
        </div>
      </div>
      <div className="banner-sales__right">
        <p className="banner-sales__right-side-title">Типы оплат</p>
        <SliderBannerStatistics data={dataSlider} />
      </div>
    </div>
  );
};
