import { FoodcostSettings } from './FoodcostSettings/FoodcostSettings';
import { WriteOffSettings } from './WriteOffSettings/WriteOffSettings';
import './FoodcostAndWriteOff.scss';

export const FoodcostAndWriteOff = () => {
  return (
    <div className="foodcost-and-write-off">
      <FoodcostSettings />
      <WriteOffSettings />
    </div>
  );
};
