import { CalendarIcon } from '../../../../../icons/calendar';
import { DefaultUserIcon } from '../../../../../icons/default-user';
import { MailIcon } from '../../../../../icons/mail';
import { PhoneIcon } from '../../../../../icons/phone';
import { useHover } from '../../../../../hooks/useHover';
import './Card.scss';
import { Button } from '../../../../../components/common/Button/Button';
import { TrashIcon } from '../../../../../icons/trash-icon';
import { SmallEditIcon } from '../../../../../icons/small-edit-icon';
import { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { AddStaffModal } from '../../AddStaffModal/AddStaffModal';
import { authApi } from '../../../../../api/authApi';
import { UserDefaultIcon } from '../../../../../icons/profile/user-default-icon';

export const Card = ({ data, avatar, setAvatar }) => {
  const { first_name, last_name, email, phone, user_avatar } = data;
  const [hoverRef, isHovered] = useHover();
  const [isCompanyModal, isSetCompanyModal] = useState(false);

  const onCloseModal = useCallback(() => {
    isSetCompanyModal(false);
  }, []);

  const onOpenModal = useCallback(() => {
    isSetCompanyModal(true);
  }, []);

  return (
    <div className="staf-settings__card-container" ref={hoverRef}>
      {isCompanyModal && (
        <AddStaffModal
          isOpen={isCompanyModal}
          onClose={onCloseModal}
          data={data}
          user_avatar={user_avatar}
          setAvatar={setAvatar}
          avatar={avatar}
        />
      )}

      <div className="staf-settings__card-info">
        <div>
          {user_avatar ? (
            <img src={user_avatar} width={80} height={80} alt="123" className="profile-info__img" />
          ) : (
            <UserDefaultIcon />
          )}
        </div>
        <div className="staf-settings__card-person">
          <div className="staf-settings__card-person__name">
            {first_name} {last_name}
          </div>
          {/* <div className="staf-settings__card-person__role">Директор</div> */}
        </div>
      </div>
      <div className="staf-settings__card-contacts">
        {/* <div className="staf-settings__card-item">
          <CalendarIcon />
          <span></span>
        </div> */}
        <div className="staf-settings__card-item">
          <MailIcon />
          <span>{email}</span>
        </div>
        <div className="staf-settings__card-item">
          <PhoneIcon />
          <span>{phone}</span>
        </div>
      </div>
      {isHovered && (
        <div className="staf-settings__card-buttons">
          {/* <Button styleType="delete" height={36}>
            <TrashIcon />
            <span>Удалить</span>
          </Button> */}
          <Button styleType="light-blue" height={36} onClick={onOpenModal}>
            <SmallEditIcon />
            <span>Редактировать</span>
          </Button>
        </div>
      )}
    </div>
  );
};
