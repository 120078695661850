import { useState } from 'react';
import './Switcher.scss';

export const Switcher = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTab1 = () => {
    setActiveTab('tab1');
  };
  const handleTab2 = () => {
    setActiveTab('tab2');
  };
  return (
    <div className="switcher">
      <div className="switcher__head">
        <div className="switcher__head-left">
          <ul className="switcher__nav">
            <li className={activeTab === 'tab1' ? 'active' : ''} onClick={handleTab1}>
              Все
            </li>
            <li className={activeTab === 'tab2' ? 'active' : ''} onClick={handleTab2}>
              Доставка
            </li>
          </ul>
        </div>
      </div>
      <div></div>
    </div>
  );
};
