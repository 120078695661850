import React from 'react';

export const DotsIcon = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="menu">
        <rect id="Rectangle 29" x="8" y="8" width="8" height="8" rx="4" fill={color} />
      </g>
    </svg>
  );
};
