import React from 'react';

export const SettingsDefaultCompanyLogo = () => {
  return (
    <svg width="80" height="80" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="18" fill="#F9F9F9" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.8333 10.5C19.712 10.5 20.4318 11.1799 20.4954 12.0423L20.5 12.1667V23.8333H21.3333V15.9167C21.3333 15.7121 21.4807 15.542 21.6751 15.5067L21.75 15.5H23C23.8786 15.5 24.5985 16.1799 24.6621 17.0423L24.6667 17.1667V23.8333H25.5C25.9602 23.8333 26.3333 24.2064 26.3333 24.6667C26.3333 25.094 26.0116 25.4463 25.5972 25.4944L25.5 25.5H10.5C10.0398 25.5 9.66666 25.1269 9.66666 24.6667C9.66666 24.2393 9.98836 23.8871 10.4028 23.8389L10.5 23.8333H11.3333V12.1667C11.3333 11.288 12.0132 10.5682 12.8756 10.5046L13 10.5H18.8333ZM17.1667 20.5H14.6667V22.1667H17.1667V20.5ZM17.1667 17.1667H14.6667V18.8333H17.1667V17.1667ZM17.1667 13.8333H14.6667V15.5H17.1667V13.8333Z"
        fill="#67A8F4"
      />
    </svg>
  );
};
