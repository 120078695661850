import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Check } from '../../../../icons/reviewsPlatforms/check';
import './PeriodSelect.scss';

export const PeriodSelect = ({
  options,
  onChange,
  secondBg,
  setCustomDate,
  isDateSelect,
  selectedOption,
  setSelectedOption,
  expanded,
  setExpanded,
  selectRef,
  startDate,
  endDate,
}) => {
  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    onChange(option);
  };

  return (
    <div className="period-options" ref={selectRef}>
      <div
        className={cn('period-header', {
          secondBg: secondBg,
        })}
        onClick={handleToggleExpand}
      ></div>
      <div className={cn('period-options-list', { isDateSelect: isDateSelect })}>
        {options.slice(0, -1).map((option) => (
          <div
            className={cn('period-options-list-item', { isDateSelectItem: isDateSelect })}
            key={option.type && option.type}
          >
            <label
              className={`period-optionn ${
                selectedOption &&
                selectedOption.type === option.type &&
                selectedOption.label === option.label
                  ? 'selected'
                  : ''
              }`}
              onClick={() => handleOptionChange(option)}
              style={{
                  color:
                  (!startDate && !endDate) &&
                  selectedOption &&
                  selectedOption.type === option.type &&
                  selectedOption.label === option.label
                    ? '#007AFF'
                    : '#1E1E1E',
                    border:
                    (!startDate && !endDate) &&
                    selectedOption &&
                    selectedOption.type === option.type &&
                    selectedOption.label === option.label
                      ? '1px solid #67a8f4'
                      : '1px solid #F6F6F6',
                borderRadius: '6px',
              }}
              onMouseEnter={(e) => (e.target.style.color = '#67A8F4')}
              onMouseLeave={(e) =>
                (e.target.style.color =
                  selectedOption &&
                  selectedOption.type === option.type &&
                  selectedOption.label === option.label
                    ? '#67A8F4'
                    : '#1E1E1E')
              }
            >
              <div onClick={handleToggleExpand}>{option.label ? option.label : option.type}</div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
