import { useEffect } from 'react';
import { useCallback, useState } from 'react';
import { Button } from '../../../components/common/Button/Button';
import useSetBodyScroll from '../../../hooks/useSetBodyScroll';
import { AddStaffModal } from './AddStaffModal/AddStaffModal';
import { Cards } from './Cards/Cards';
import { Tabs } from './Tabs/Tabs';
import './StaffSettings.scss';

export const StaffSettings = ({ companyData }) => {
  return (
    <div className="staff-settings__container">
      <Tabs companyData={companyData} />
    </div>
  );
};

// const [isCompanyModal, isSetCompanyModal] = useState(false);

// const onCloseModal = useCallback(() => {
//   isSetCompanyModal(false);
// }, []);

// const onOpenModal = useCallback(() => {
//   isSetCompanyModal(true);
// }, []);

{
  /* {isCompanyModal && <AddStaffModal isOpen={isCompanyModal} onClose={onCloseModal} />} */
}
{
  /* <AddStaffModal isOpen={isCompanyModal} onClose={onCloseModal} /> */
}
{
  /* <div className="staff-settings__btn-add-wrapper">
        <Button styleType="small" width={200} height={40} onClick={onOpenModal}>
          + Добавить сотрудника
        </Button>
      </div> */
}
{
  /* <div className='staff-settings__empty'>
        <div>Пока что Вы не добавили ни одного сотрудника </div>
        <div>
          <Button styleType="primary" width={340}>
            Добавить сотрудника
          </Button>
        </div>
        <div>
          <Button styleType="primary" width={340}>
            Импортировать из POS-системы
          </Button>
        </div>
          </div> */
}
