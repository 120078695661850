import React, { useState } from 'react';
import { AccessStaff } from '../AccessStaff/AccessStaff';
import { Cards } from '../Cards/Cards';
import './Tabs.scss';
export const Tabs = ({ companyData }) => {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTab1 = () => {
    setActiveTab('tab1');
  };
  const handleTab2 = () => {
    setActiveTab('tab2');
  };
  return (
    <div className="tabs-settings-user-info">
      <div className="tabs-settings-user-info__head">
        <div className="tabs-settings-user-info__head-left">
          <ul className="tabs-settings-user-info__nav">
            <li className={activeTab === 'tab1' ? 'active' : ''} onClick={handleTab1}>
              Карточки сотрудников
            </li>
            <li className={activeTab === 'tab2' ? 'active' : ''} onClick={handleTab2}>
              Настройка планов выручки
            </li>
          </ul>
        </div>
      </div>
      <div></div>
      <div className="outlet">{activeTab === 'tab1' && <Cards companyData={companyData} />}</div>
      <div className="outlet">{activeTab === 'tab2' && <AccessStaff />}</div>
    </div>
  );
};
