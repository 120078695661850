import { CloseIcon } from '../../../../icons/close-icon';
import { Modal } from '../../../common/ModalWindow/ModalWindow';
import { TableCheque } from '../TableCheque/TableCheque';
import './ModalCheque.scss';
import { ModalChequeInfo } from './ModalChequeInfo/ModalChequeInfo';

const list = [
  {
    id: 1,
    product_name: 'Бургер с рваной свининой и помидорами',
    time: '2024-01-09',
    coast: '1',
    sum: '5 320,25',
  },
  {
    id: 2,
    product_name: 'Бургер с рваной свининой и помидорами',
    time: '2024-01-09',
    coast: '2',
    sum: '1 170,20',
  },
  { id: 3, product_name: 'Мидии', time: '2024-01-09', coast: '12', sum: '2 850,60' },
  { id: 4, product_name: 'Анчоусы', time: '2024-01-09', coast: '9', sum: '3 564,10' },
  { id: 5, product_name: 'Сельдерей', time: '2024-01-09', coast: '5', sum: '1 700,00' },
  { id: 6, product_name: 'Паста', time: '2024-01-09', coast: '4', sum: '3 120.20' },
  {
    id: 7,
    product_name: 'Бургер с рваной свининой и помидорами',
    time: '2024-01-09',
    coast: '7',
    sum: '3 230,80',
  },
  {
    id: 8,
    product_name: 'Бургер с рваной свининой и помидорами',
    time: '2024-01-09',
    coast: '5',
    sum: '2 250,60',
  },
  {
    id: 9,
    product_name: 'Бургер с рваной свининой и помидорами',
    time: '2024-01-09',
    coast: '3',
    sum: '2 850,60',
  },
  {
    id: 10,
    product_name: 'Бургер с рваной свининой и помидорами',
    time: '2024-01-09',
    coast: '1',
    sum: '2 720,30',
  },
];

export const ModalCheque = ({ isOpen, onClose, data }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} width={700} padding={0}>
      <div className="modal-cheque__container">
        <div className="modal-cheque__header">
          <div>Чеки</div>
          <div onClick={onClose} className="modal-cheque__header-close">
            <CloseIcon />
          </div>
        </div>
        <div className="modal-cheque__info">
          {data.map((item) => (
            <ModalChequeInfo item={item} />
          ))}
        </div>
        <div className="modal-cheque__table">
          <TableCheque list={list} onCloseFirstModal={onClose} />
        </div>
      </div>
    </Modal>
  );
};
