import { useCallback, useEffect, useState } from 'react';
import { Button } from '../../../components/common/Button/Button';
import { TofuField } from '../../../components/common/fields/TofuField/TofuField';
import { SmallEditIcon } from '../../../icons/small-edit-icon';
import { ModalWindow } from './ModalWindow/ModalWindow';
import { ProfileInfo } from './ProfileInfo/ProfileInfo';
import { TimePicker } from './TimePicker/TimePicker';
import { Loader } from '../../../components/common/Loader/Loader';
import { TofuSelect } from '../../../components/common/fields/TofuSelect/TofuSelect';
import { settingsApi } from '../../../api/settingsApi';
import { TIME_ZONES } from './const';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CompanyInfo.scss';
import { authApi } from '../../../api/authApi';
import { COUNT_OF_WORKERS_OPTHION, RESTARAUNT_TYPE_OPTHIONS } from '../../../const/commonConst';

export const CompanyInfo = ({ companyData }) => {
  const token = localStorage.getItem('authToken');
  const {
    avatar,
    bank,
    bic,
    company_email,
    count_of_workers,
    instagram_username,
    legal_entity_name,
    restaurant_type,
    timezone,
    place_closing,
    place_opening,
    unp,
    checking_account,
    legal_address,
    restaurant_address_for_settings,
    restaurant_name_for_settings,
  } = companyData;

  const [isCompanyModal, isSetCompanyModal] = useState(false);
  const [restaurant_typeState, setRestaurant_type] = useState(
    RESTARAUNT_TYPE_OPTHIONS[restaurant_type].label
  );
  const [count_of_workersState, setCount_of_workers] = useState(
    COUNT_OF_WORKERS_OPTHION[count_of_workers].label
  );
  const [instagram, setInsagram] = useState(instagram_username);
  const [time_zone, setTime_zone] = useState(timezone);
  const [startDay, setStartDay] = useState(place_opening);
  const [endDay, setEndDay] = useState(place_closing);
  const [legal_entity, setLegal_entity] = useState(legal_entity_name);
  const [YNP, setYNP] = useState(unp);
  const [bankState, setBank] = useState(bank);
  const [BIK, setBIK] = useState(bic);
  const [payment_account, setPayment_account] = useState(checking_account);
  const [legal_address_state, setLegal_address_state] = useState(legal_address);
  const [email, setEmail] = useState(company_email);
  const [restaurant_address, setRestaurant_address] = useState(restaurant_address_for_settings);
  const [restaurant_name, setRestaurant_name] = useState(restaurant_name_for_settings);
  const [photo, setPhoto] = useState(avatar);
  const [fieldModified, setFieldModified] = useState(false);
  const companyPhoto = avatar;

  const restaurantTypeIndex = RESTARAUNT_TYPE_OPTHIONS.findIndex(
    (option) => option.label === restaurant_typeState
  );
  const countOfWorkersIndex = COUNT_OF_WORKERS_OPTHION.findIndex(
    (option) => option.label === count_of_workersState
  );

  const onCloseModal = useCallback(() => {
    isSetCompanyModal(false);
  }, []);

  const onOpenModal = useCallback(() => {
    isSetCompanyModal(true);
  }, []);

  const handleFieldChange = () => {
    setFieldModified(true);
  };

  const sendDataToServer = () => {
    const updatedData = {
      account_id: companyData.id,
      bank: bankState,
      bic: BIK,
      company_email: email,
      count_of_workers: countOfWorkersIndex,
      instagram_username: instagram,
      legal_entity_name: legal_entity,
      place_opening: startDay,
      place_closing: endDay,
      // // plan_type: plan_type,
      restaurant_type: restaurantTypeIndex,
      timezone: time_zone,
      unp: YNP,
      user: companyData.user,
      checking_account: payment_account,
      legal_address: legal_address_state,
    };

    settingsApi
      .changeCompany(
        companyData.id,
        updatedData.bank,
        updatedData.bic,
        updatedData.company_email,
        updatedData.count_of_workers,
        updatedData.instagram_username,
        updatedData.legal_entity_name,
        updatedData.place_opening,
        updatedData.place_closing,
        updatedData.restaurant_type,
        updatedData.timezone,
        updatedData.unp,
        updatedData.user,
        updatedData.checking_account,
        updatedData.legal_address
      )
      .then(() => {
        if (fieldModified) {
          toast.success('Данные успешно сохранены!');
        }
        setFieldModified(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    authApi.getUser(token).then((res) => {
      setEmail(res.data.email);
    });
  }, [token]);

  const sendEmailToServer = () => {
    const formData = new FormData();
    formData.append('email', email);
    authApi.addUserName(formData, token).then(() => {
      if (fieldModified) {
        toast.success('Данные успешно сохранены!');
      }
      setFieldModified(false);
    });
  };

  return (
    <>
      {companyData.id > 0 ? (
        <div className="company-info__container">
          <div className="company-info__title">Данные заведения</div>
          <div className="company-info__photo">
            <ProfileInfo
              avatar={photo}
              address={restaurant_address}
              name={restaurant_name}
              setPhoto={setPhoto}
              companyPhoto={companyPhoto}
            />
            <Button styleType="secondary" width={152} height={36} onClick={onOpenModal}>
              <SmallEditIcon />
              <span>Редактировать</span>
            </Button>
          </div>
          {isCompanyModal && (
            <ModalWindow
              isOpen={isCompanyModal}
              onClose={onCloseModal}
              avatar={photo}
              address={restaurant_address}
              name={restaurant_name}
              setAddress={setRestaurant_address}
              setName={setRestaurant_name}
              setPhoto={setPhoto}
              account_id={companyData.id}
            />
          )}
          <div className="company-info__fields">
            <TofuSelect
              name="restaurant_type"
              value={restaurant_typeState}
              type="select"
              options={RESTARAUNT_TYPE_OPTHIONS}
              placeholder="Формат заведения"
              onChange={(value) => {
                setRestaurant_type(value.label);
                handleFieldChange();
              }}
              label="ФОРМАТ ЗАВЕДЕНИЯ *"
              onBlur={sendDataToServer}
            />
            <TofuField
              name="instagram"
              value={instagram}
              placeholder="Ник заведения"
              onChange={(e) => {
                setInsagram(e.target.value);
                handleFieldChange();
              }}
              label="ник заведения в instagram *"
              onBlur={sendDataToServer}
            />
            <TofuSelect
              name="count_of_workers"
              value={count_of_workersState}
              placeholder="Количество сотрудников"
              onChange={(value) => {
                setCount_of_workers(value.label);
                handleFieldChange();
              }}
              type="select"
              options={COUNT_OF_WORKERS_OPTHION}
              label="Количество сотрудников *"
              onBlur={sendDataToServer}
            />
          </div>
          <div className="company-info__fields-one">
            <TofuSelect
              name="time_zone"
              value={time_zone}
              type="select"
              options={TIME_ZONES}
              placeholder="Часовой пояс"
              onChange={(value) => {
                setTime_zone(value.label);
                handleFieldChange();
              }}
              label="ЧАСОВОЙ ПОЯС*"
              isCloseMenu
              onBlur={sendDataToServer}
            />
          </div>
          {/* <div className="company-info__fields-middle">
            <TimePicker
              value={startDay}
              placeholder="Начало рабочего дня"
              onChange={(value) => setStartDay(value)}
              label="Начало рабочего дня *"
              onBlur={sendDataToServer}
              time={startDay}
              setTime={setStartDay}
            />
            <div>
              <TimePicker
                value={endDay}
                placeholder="Конец рабочего дня"
                onChange={(value) => setEndDay(value)}
                label="Конец рабочего дня *"
                onBlur={sendDataToServer}
                time={endDay}
                setTime={setEndDay}
              />
            </div>
          </div> */}
          <div className="company-info__fields">
            <TofuField
              name="legal_entity"
              value={legal_entity}
              placeholder="Название юридического лица или ип"
              onChange={(e) => {
                setLegal_entity(e.target.value);
                handleFieldChange();
              }}
              label="Название юридического лица или ип"
              onBlur={sendDataToServer}
            />
            <TofuField
              name="YNP"
              value={YNP}
              placeholder="УНП"
              onChange={(e) => {
                setYNP(e.target.value);
                handleFieldChange();
              }}
              label="унп"
              onBlur={sendDataToServer}
            />
            <TofuField
              name="bank"
              value={bankState}
              placeholder="Банк"
              onChange={(e) => {
                setBank(e.target.value);
                handleFieldChange();
              }}
              label="Банк"
              onBlur={sendDataToServer}
            />
            <TofuField
              name="BIK"
              value={BIK}
              placeholder="БИК"
              onChange={(e) => {
                setBIK(e.target.value);
                handleFieldChange();
              }}
              label="БИК"
              onBlur={sendDataToServer}
            />
            <TofuField
              name="payment_account"
              value={payment_account}
              placeholder="Расчетный счет"
              onChange={(e) => {
                setPayment_account(e.target.value);
                handleFieldChange();
              }}
              label="расчетный счет"
              onBlur={sendDataToServer}
            />
            <TofuField
              name="legal_address"
              value={legal_address_state}
              placeholder="Юридический адрес"
              onChange={(e) => {
                setLegal_address_state(e.target.value);
                handleFieldChange();
              }}
              label="Юридический адрес"
              onBlur={sendDataToServer}
            />
            <TofuField
              name="email"
              value={email}
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
                handleFieldChange();
              }}
              label="Email"
              onBlur={sendEmailToServer}
            />
          </div>
          {/* <div className="company-info__btn-wrapper">
        <Button styleType="secondary" customClass="company-info__btn">
          Импортировать из POS-системы
        </Button>
      </div> */}
          <ToastContainer position="top-right" />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};
