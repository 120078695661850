import * as React from 'react';

export const CompanyDefaultLogo = ({ width = 100, height = 100 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="99" height="99" rx="49.5" fill="#F9F9F9" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M51.3332 38C52.739 38 53.8907 39.0878 53.9925 40.4677L53.9998 40.6667V59.3333H55.3332V46.6667C55.3332 46.3394 55.569 46.0672 55.88 46.0107L55.9998 46H57.9998C59.4057 46 60.5574 47.0878 60.6592 48.4677L60.6665 48.6667V59.3333H61.9998C62.7362 59.3333 63.3332 59.9303 63.3332 60.6667C63.3332 61.3505 62.8185 61.914 62.1553 61.991L61.9998 62H37.9998C37.2635 62 36.6665 61.4031 36.6665 60.6667C36.6665 59.9829 37.1812 59.4193 37.8443 59.3423L37.9998 59.3333H39.3332V40.6667C39.3332 39.2609 40.421 38.1091 41.8008 38.0073L41.9998 38H51.3332ZM48.6665 54H44.6665V56.6667H48.6665V54ZM48.6665 48.6667H44.6665V51.3333H48.6665V48.6667ZM48.6665 43.3333H44.6665V46H48.6665V43.3333Z"
        fill="#67A8F4"
      />
      <rect
        x="0.5"
        y="0.5"
        width="99"
        height="99"
        rx="49.5"
        stroke="#E3E3E3"
        stroke-dasharray="5 5"
      />
    </svg>
  );
};
