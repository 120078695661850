import { useEffect } from 'react';
import { PageHeader } from '../../components/common/PageHeader/PageHeader';
import { TabsSettings } from '../../components/settings/tabs/tabs';
import useSetBodyScroll from '../../hooks/useSetBodyScroll';
import { Banner } from './Banner/Banner';
import { Info } from './Info/Info';
import './Subscription.scss';

export const SettingsSubscription = () => {
  // const setBodyScroll = useSetBodyScroll();
  // useEffect(() => {
  //   setBodyScroll(false);
  //   return () => {
  //     setBodyScroll(true);
  //   };
  // }, [setBodyScroll]);
  return (
    <div className="settings-container">
      <PageHeader title="Настройки" />
      <TabsSettings tabIndex={6}>
        <div className="settings-subscription">
          <Banner />
          <Info />
        </div>
      </TabsSettings>
    </div>
  );
};
