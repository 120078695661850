import React from 'react';

export const GrayStarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M6.36995 1.67272C6.65124 1.18982 7.3489 1.18982 7.63013 1.67272L9.26054 4.47198L12.4266 5.15758C12.9728 5.27586 13.1884 5.93934 12.8161 6.35607L10.6576 8.77171L10.9839 11.9947C11.0402 12.5507 10.4759 12.9607 9.96445 12.7354L7.00007 11.4291L4.03566 12.7354C3.52427 12.9607 2.95987 12.5507 3.01617 11.9947L3.34249 8.77171L1.18407 6.35607C0.81172 5.93934 1.0273 5.27586 1.57348 5.15758L4.73956 4.47198L6.36995 1.67272Z"
        fill="#E3E3E3"
      />
    </svg>
  );
};
