import './TableSales.scss';

export const TableSales = ({ list }) => {
  return (
    <div>
      <div className="table-sales__container">
        <div className="table-sales__name">Название</div>
        <div className="table-sales__coast">Кол-во</div>
        <div className="table-sales__sum">Сумма, ₽</div>
      </div>
      <div className="table-sales__wrapper">
        {list.map((item) => (
          <div className="table-sales__info">
            <div className="table-sales__info-name">{item.product_name}</div>
            <div className="table-sales__info-coast">{item.coast}</div>
            <div className="table-sales__info-sum">{item.sum}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
