import React from 'react';

export const DepthChecque = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="144"
      height="119"
      viewBox="0 0 144 119"
      fill="none"
    >
      <path
        d="M1 12.0001V113C1 116.314 3.68629 119 7 119H137C140.314 119 143 116.314 143 113V12C143 12 101.86 12 75.5 12.0003L24.5 12.0009L1 12.0001Z"
        fill="url(#paint0_linear_12386_76032)"
        fill-opacity="0.5"
      />
      <rect y="11" width="144" height="2" rx="1" fill="#2B2B2B" />
      <defs>
        <linearGradient
          id="paint0_linear_12386_76032"
          x1="193.279"
          y1="-1.90099"
          x2="188.819"
          y2="119.562"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2B2B2B" stop-opacity="0.13" />
          <stop offset="1" stop-color="#2B2B2B" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
