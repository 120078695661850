import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useDatepicker, START_DATE } from '@datepicker-react/hooks';
import Month from './Month/Month';
import DatepickerContext from './datepickerContext';
import './DatePicker.scss';
import { PeriodSelect } from './PeriodSelect/PeriodSelect';
import { dateOptions } from '../../../const/selectReviews';

function Datepicker({
  setCustomDate,
  selectedDate,
  handleSelectedDateChange,
  selectedOption,
  setSelectedOption,
  setExpanded,
  expanded,
  selectRef,
  startDate,
  endDate,
  setCalendarDate,
  calendarDate,
  setSelectedDate,
  setIsApplyButtonClick,
}) {
  const [dateStatus, setDateSatus] = useState({
    startDate: calendarDate.startDate,
    endDate: calendarDate.endDate,
    focusedInput: START_DATE,
  });

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonthsByOneMonth,
    goToNextMonthsByOneMonth,
  } = useDatepicker({
    startDate: dateStatus.startDate,
    endDate: dateStatus.endDate,
    focusedInput: dateStatus.focusedInput,
    onDatesChange: handleDateChange,
  });

  function handleDateChange(data) {
    if (!data.focusedInput) {
      setDateSatus({ ...data, focusedInput: START_DATE });
    } else {
      setDateSatus(data);
    }
  }

  const month = activeMonths[0]; // Get the first month from activeMonths
  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
      }}
    >
      <div className="date-container">
        <div className="date-header">
          <PeriodSelect
            options={dateOptions}
            selectedOptions={selectedDate}
            onChange={handleSelectedDateChange}
            staticWidth
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            setCustomDate={setCustomDate}
            setExpanded={setExpanded}
            expanded={expanded}
            selectRef={selectRef}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
        <Month
          key={`${month.year}-${month.month}`}
          year={month.year}
          month={month.month}
          firstDayOfWeek={firstDayOfWeek}
          goToPreviousMonthsByOneMonth={goToPreviousMonthsByOneMonth}
          goToNextMonthsByOneMonth={goToNextMonthsByOneMonth}
          setCalendarDate={setCalendarDate}
          state={dateStatus}
          setIsApplyButtonClick={setIsApplyButtonClick}
          setSelectedDate={setSelectedDate}
          setExpanded={setExpanded}
          expanded={expanded}
        />
      </div>
    </DatepickerContext.Provider>
  );
}

export default Datepicker;
