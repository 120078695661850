import './FirstWidget.scss';

export const FirstWidget = () => {
  const data = [
    {
      title: 'Крутое достоинство номер 1',
    },
    {
      title: 'Крутое достоинство номер 1',
    },
    {
      title: 'Крутое достоинство номер 1',
    },
    {
      title: 'Крутое достоинство номер 1',
    },
    {
      title: 'Крутое достоинство номер 1',
    },
    {
      title: 'Крутое достоинство номер 1',
    },
    {
      title: 'Крутое достоинство номер 1',
    },
  ];

  return (
    <div className="first-widget__container">
      <div className="first-widget__top">
        <div className="first-widget__top-info">Информация о подписке</div>
        <div className="first-widget__top-status">Тариф “Pro” <span>Активен</span></div>
        <div className="first-widget__top-date">Действует до: <span>15 июня 2023 года</span> </div>
      </div>
      <div className="first-widget__bottom">
        <div>Возможности вашего тарифа:</div>
        <ul className="first-widget__list">
          {data.map((item) => (
            <li>{item.title}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
