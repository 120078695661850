import { useRef, useState } from 'react';
import { StyledEvent, PortalWrapper } from './calendar.styled';
import { DAYS, MOCKAPPS } from './const';
import {
  datesAreOnSameDay,
  getDaysInMonth,
  getMonthYear,
  getSortedDays,
  nextMonth,
  prevMonth,
} from './utils';
import './calendar.scss';
import { Button } from '../../../components/common/Button/Button';
import { ArrowDownIcon } from '../../../icons/arrow-down';
import { WeeksReport } from './WeeksReport/WeeksReport';
import { PopoverRevenue } from './PopoverRevenue/PopoverRevenue';

export const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState(MOCKAPPS);
  const dragDateRef = useRef();
  const dragindexRef = useRef();
  const [showPortal, setShowPortal] = useState(false);
  const [portalData, setPortalData] = useState({});
  const [selectedDates, setSelectedDates] = useState(new Set());
  const addEvent = (date, event) => {
    if (!event.target.classList.contains('StyledEvent')) {
      // const text = window.prompt('name');
      // if (text) {
      //   date.setHours(0);
      //   date.setSeconds(0);
      //   date.setMilliseconds(0);
      //   setEvents((prev) => [
      //     ...prev,
      //     { date, title: text, color: 'red' }
      //   ]);

      const dateKey = `${date.getFullYear()}/${date.getMonth()}/${date.getDate()}`;
      setSelectedDates((prevDates) => {
        const newSelectedDates = new Set(prevDates);
        if (newSelectedDates.has(dateKey)) {
          newSelectedDates.delete(dateKey);
        } else {
          newSelectedDates.add(dateKey);
        }
        return newSelectedDates;
      });
      // }
    }
  };

  const drag = (index, e) => {
    dragindexRef.current = { index, target: e.target };
  };

  const onDragEnter = (date, e) => {
    e.preventDefault();
    dragDateRef.current = { date, target: e.target.id };
  };

  const drop = (ev) => {
    ev.preventDefault();

    setEvents((prev) =>
      prev.map((ev, index) => {
        if (index === dragindexRef.current.index) {
          ev.date = dragDateRef.current.date;
        }
        return ev;
      })
    );
  };

  const handleOnClickEvent = (event) => {
    setShowPortal(true);
    setPortalData(event);
  };

  const handlePotalClose = () => setShowPortal(false);

  const handleDelete = () => {
    setEvents((prevEvents) => prevEvents.filter((ev) => ev.title !== portalData.title));
    handlePotalClose();
  };

  return (
    <div>
      <div className="calendar-wrapper">
        <div className="calendar-header">
          <div className="calendar-header__text">
            <h2>Настройка планов выручки</h2>
            <p>
              Чтобы настроить планы выручки на каждый день, выберите один или
              <br /> несколько нужных дней и укажите сумму выручки
            </p>
          </div>
          <div className="calendar-header__btn">
            <PopoverRevenue>
              <Button styleType="primary" height={40}>
                + Добавить выручку
              </Button>
            </PopoverRevenue>
          </div>
        </div>
        <div className="calendar-date-controls">
          <div
            onClick={() => prevMonth(currentDate, setCurrentDate)}
            name="arrow-back-circle-outline"
            className="calendar-arrow-left"
          >
            <ArrowDownIcon />
          </div>
          {getMonthYear(currentDate)}
          <div
            onClick={() => nextMonth(currentDate, setCurrentDate)}
            name="arrow-forward-circle-outline"
            className="calendar-arrow-right"
          >
            <ArrowDownIcon />
          </div>
        </div>
        <div className="calendar-seven-col-grid">
          {DAYS.map((day) => (
            <div className="calendar-header-colmns">{day}</div>
          ))}
        </div>

        <div
          className="calendar-seven-col-grid"
          fullheight={true}
          is28Days={getDaysInMonth(currentDate) === 28}
        >
          {getSortedDays(currentDate).map((day, index) => (
            <div
              id={`${currentDate.getFullYear()}/${currentDate.getMonth()}/${day}`}
              onDragEnter={(e) =>
                onDragEnter(new Date(currentDate.getFullYear(), currentDate.getMonth(), day), e)
              }
              onDragOver={(e) => e.preventDefault()}
              onDragEnd={drop}
              onClick={(e) =>
                addEvent(new Date(currentDate.getFullYear(), currentDate.getMonth(), day), e)
              }
              className={`calendar-column ${
                selectedDates.has(`${currentDate.getFullYear()}/${currentDate.getMonth()}/${day}`)
                  ? 'clicked'
                  : ''
              }`}
            >
              <span
                className={`nonDRAG ${
                  datesAreOnSameDay(
                    new Date(),
                    new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
                  )
                    ? 'active'
                    : ''
                }`}
              >
                {day}
              </span>
              <EventWrapper>
                {events.map(
                  (ev, index) =>
                    datesAreOnSameDay(
                      ev.date,
                      new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
                    ) && (
                      <StyledEvent
                        onDragStart={(e) => drag(index, e)}
                        onClick={() => handleOnClickEvent(ev)}
                        draggable
                        className="StyledEvent"
                        id={`${ev.color} ${ev.title}`}
                        key={ev.title}
                        bgColor={ev.color}
                      >
                        {ev.title}
                      </StyledEvent>
                    )
                )}
              </EventWrapper>
            </div>
          ))}
        </div>
        {showPortal && (
          <Portal {...portalData} handleDelete={handleDelete} handlePotalClose={handlePotalClose} />
        )}
        <WeeksReport />
      </div>
    </div>
  );
};

const EventWrapper = ({ children }) => {
  if (children.filter((child) => child).length)
    return (
      <>
        {children}
        {children.filter((child) => child).length > 2 && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              console.log('clicked p');
            }}
          >
            see more...
          </div>
        )}
      </>
    );
};

const Portal = ({ title, date, handleDelete, handlePotalClose }) => {
  return (
    <PortalWrapper>
      <h2>{title}</h2>
      <p>{date.toDateString()}</p>
      <ion-icon onClick={handleDelete} name="trash-outline"></ion-icon>
      <ion-icon onClick={handlePotalClose} name="close-outline"></ion-icon>
    </PortalWrapper>
  );
};
