import { useEffect, useState } from 'react';
import { foodcostApi } from '../../../../../api/foodcost';
import Chart from '../../../../../components/reports/Circle/Circle';
import { Statistics } from '../../../../../components/reports/Statistics/Statistics';
import './WriteOff.scss';

export const WriteOff = ({ account_id, token, selectedDate }) => {
  const [writeOffDiagramData, setWriteOffDiagramData] = useState([]);

  const writeOffDiagramDataTest = [
    { reason_name: 'Питание персонала', sum_of_cost: '51 299,44', percent: 35 },

    { reason_name: 'Удаление блюд со списанием', sum_of_cost: '12 293,96', percent: 24 },

    { reason_name: 'Порча', sum_of_cost: '11 743,70', percent: 23 },

    { reason_name: 'Бракераж', sum_of_cost: '6 055,70', percent: 12 },

    { reason_name: 'Служебные оплаты', sum_of_cost: '2 986,99', percent: 6 },
  ];

  const infoForDiagramma = {
    percent: '2,06%',
    tittle: 'Сумма списаний',
    sum: '51 229,44 ₽',
    discription: false,
  };

  useEffect(() => {
    foodcostApi.writeOffDiagram(account_id, token, selectedDate).then((response) => {
      setWriteOffDiagramData(response.data);
    });
  }, [account_id, token, selectedDate]);
  return (
    <div className="write-off">
      <div className="write-off__chart">
        <Chart data={writeOffDiagramDataTest} info={infoForDiagramma} />
      </div>
      <div className="write-off__statistics">
        <Statistics data={writeOffDiagramDataTest} />
      </div>
    </div>
  );
};
