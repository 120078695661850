import * as React from 'react';
import Popover from '@mui/material/Popover';

import Button from '@mui/material/Button';
import './NpsPopover.scss';
import CustomizedSwitches from './Switch/Switch';

export const NpsPopover = ({ children, setIsChecked, isChecked }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        disableRipple
        onClick={handleClick}
        sx={{
          '&:hover': { backgroundColor: '#FFFF' },
          minHeight: 0,
          minWidth: 0,
          padding: 0,
          textTransform: 'capitalize',
        }}
      >
        {children}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 45,
          horizontal: -209,
        }}
        anchorPosition={{ left: 20 }}
        PaperProps={{
          style: {
            boxShadow: '2px 6px 20px 0px rgba(85, 85, 85, 0.09)',
            borderRadius: '6px',
          },
        }}
      >
        <div className="nps-popover-container">
          <CustomizedSwitches
            setIsChecked={setIsChecked}
            isChecked={isChecked}
            handleClose={handleClose}
          />
        </div>
      </Popover>
    </div>
  );
};
export default NpsPopover;
