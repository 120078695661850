import React from 'react';
import './HoverRevenue.scss';

const percentForMarkups = 0.054;
const percentForDiscount = 0.109;

export const HoverRevenue = ({ text }) => {
  const num = parseFloat(text.replace(/\s/g, ''));

  const dataHover = [
    { text: 'Выручка без скидок:', value: num + num * percentForMarkups + ' ₽' },
    { text: 'Сумма скидок:', value: num * percentForDiscount + ' ₽' },
    { text: 'Сумма наценок:', value: num * percentForMarkups + ' ₽' },
    { text: 'Выручка:', value: num },
  ];
  return (
    <div className="hover-revenue_container">
      <div className="hover-revenue_wrapper">
        {dataHover.map((item) => {
          return (
            <div className="hover-revenue_group">
              <p className="hover-revenue_text">{item.text}</p>
              <p className="hover-revenue_text-bold">{item.value}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
