import React, { useState, useEffect, useRef } from 'react';
import { Check } from '../../../../../icons/reviewsPlatforms/check';
import { Select } from '../../../../../components/common/Select/Select';
import { ThreeDotsIcon } from '../../../../../icons/three-dots-icon';
import { TOP_TEN_PRODUCTS_OPTION } from '../../../foodcost/const';
import './Header.scss';
import ArrowSelect from "../../../../../icons/arrow-select.svg"

export const Header = ({
  newOptions,
  handleSelectedTopTenChange,
  onMainOptionChange,
  setSelectedOption,
  selectedOption,
  setIsOpen,
  isOpen,
}) => {
  const [options, setOptions] = useState([
    { name: 'Все', activeOpt: true },
    { name: 'Кухня', activeOpt: false },
    { name: 'Бар', activeOpt: false },
    { name: 'Кальян', activeOpt: false },
    { name: 'Чайная карта', activeOpt: false },
    { name: 'STUFF ONLY', activeOpt: false },
  ]);

  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };
  const selectRef = useRef(null);

  const handleOptionClick = (option, index) => {
    setSelectedOption(option.name);
    setIsOpen(false);
    clearList();
    const arr = options[index];
    arr.activeOpt = true;
    const editArr = [...options];
    editArr[index] = arr;
    setOptions(editArr);
  };

  const clearList = () => {
    const arr = options.map((item) => {
      item.activeOpt = false;
    });
    setOptions(arr);
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  return (
    <div className="header-container">
      <div className="header-title">Топ 10 блюд по выручке</div>
      <div className="header-btns">
      <div className={`sales-custom-select ${isOpen ? 'open' : ''}`} ref={selectRef}>
          <div className="sales-select-selected" onClick={toggleOptions}>
            <p className='select-option__text'>{selectedOption}</p>
            <div className={isOpen ? 'arrow-open' : 'arrow-close'}>
              <img src={ArrowSelect}></img>
            </div>
          </div>

          {isOpen && (
            <ul className="sales-select-options">
              {options.map((option, index) => (
                <li key={index} onClick={() => handleOptionClick(option, index)}>
                  <div
                    className={
                      option.activeOpt
                        ? 'select-options_active__text'
                        : 'select-options_off__text'
                    }
                  >
                    {option.name}
                  </div>
                  <div
                    className={option.activeOpt ? 'select-options_active' : 'select-options_off'}
                  >
                    <Check />
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
        {/* <div className="header-dots">
          <ThreeDotsIcon />
        </div> */}
      </div>
    </div>
  );
};
