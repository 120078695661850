export const TOOLTIP__VARIABLE_TEXT = {
  AAA: {
    title: 'Высокая популярность, высокая выручка, высокая валовая прибыль.',
    subtitle: 'Суперпозиция. Лидер продаж.',
    description: 'Поддерживайте качество. всегда имейте в наличии.',
  },
  AAB: {
    title: 'Высокая популярность, высокая выручка, средняя валовая прибыль.',
    subtitle: 'Лидер продаж. ',
    description: 'Снизьте себестоимость или повысьте цену.',
  },
  AAC: {
    title: 'Высокая популярность, высокая выручка, низкая валовая прибыль.',
    subtitle: 'Лидер продаж.',
    description: 'Снизьте себестоимость, повысьте цену или выведите из меню.',
  },
  ABA: {
    title: 'Высокая популярность, средняя выручка, высокая валовая прибыль.',
    subtitle: 'Лидер продаж.',
    description: 'Поддерживайте качество. Всегда имейте в наличии.',
  },
  ABB: {
    title: 'Высокая популярность, средняя выручка, средняя валовая прибыль.',
    subtitle: 'Лидер продаж.',
    description: ' Снизьте себестоимость или повысьте цену.',
  },
  ABC: {
    title: 'Высокая популярность, средняя выручка, низкая валовая прибыль.',
    subtitle: 'Лидер продаж.',
    description: 'Снизьте себестоимость, повысьте цену или выведите из меню.',
  },
  ACA: {
    title: 'Высокая популярность, низкая выручка, высокая валовая прибыль.',
    subtitle: 'Лидер продаж.',
    description: 'Попробуйте вывести более дешевый аналог в меню.',
  },
  ACB: {
    title: 'Высокая популярность, низкая выручка, средняя валовая прибыль.',
    subtitle: 'Лидер продаж.',
    description: 'Попробуйте вывести более дешевый аналог в меню.',
  },
  ACC: {
    title: 'Высокая популярность, низкая выручка, низкая валовая прибыль.',
    subtitle: 'Лидер продаж. ',
    description: 'Снизьте себестоимость, повысьте цену или выведите из меню.',
  },
  BAA: {
    title: 'Средняя популярность, высокая выручка, высокая валовая прибыль.',
    subtitle: 'Стимулируйте продажи или снизьте цену',
    description: '',
  },
  BAB: {
    title: 'Средняя популярность, высокая выручка, средняя валовая прибыль.',
    subtitle: 'Стимулируйте продажи, снизьте себестоимость или повысьте цену',
    description: '',
  },
  BAC: {
    title: 'Средняя популярность, высокая выручка, низкая валовая прибыль.',
    subtitle: 'Снизьте себестоимость, повысьте цену или выведите из меню.',
    description: '',
  },
  BBA: {
    title: 'Средняя популярность, средняя выручка, высокая валовая прибыль.',
    subtitle: 'Стимулируйте продажи или снизьте цену.',
    description: '',
  },
  BBB: {
    title: 'Средняя популярность, средняя выручка, средняя валовая прибыль.',
    subtitle: 'Стимулируйте продажи, снизьте себестоимость или повысьте цену.',
    description: '',
  },
  BBC: {
    title: 'Средняя популярность, средняя выручка, низкая валовая прибыль.',
    subtitle: 'Стимулируйте продажи, снизьте себестоимость, повысьте цену или выведите из меню.',
    description: '',
  },
  BCA: {
    title: 'Средняя популярность, низкая выручка, высокая валовая прибыль.',
    subtitle: 'Стимулируйте продажи или повысьте цену, если позволяет конкурентный анализ.',
    description: '',
  },
  BCB: {
    title: 'Средняя популярность, средняя выручка, средняя валовая прибыль.',
    subtitle: 'Стимулируйте продажи, снизьте себестоимость или повысьте цену.',
    description: '',
  },
  BCC: {
    title: 'Средняя популярность, низкая выручка, низкая валовая прибыль.',
    subtitle: 'Снизьте себестоимость, повысьте цену или выведите из меню.',
    description: '',
  },
  CAA: {
    title: 'Низкая популярность, высокая выручка, высокая валовая прибыль.',
    subtitle: 'Проведите бракераж, замерьте время приготовления, стимулировать продажи.',
    description: '',
  },
  CAB: {
    title: 'Низкая популярность, высокая выручка, средняя валовая прибыль.',
    subtitle:
      'Проведите бракераж, замерьте время приготовления, снизьте себестоимость или поднимите цену.',
    description: '',
  },
  CAC: {
    title: 'Низкая популярность, высокая выручка, низкая валовая прибыль.',
    subtitle:
      'Проведите бракераж, замерьте время приготовления, снизьте себестоимость, поднимите цену или выведите из меню.',
    description: '',
  },
  CBA: {
    title: 'Низкая популярность, средняя выручка, высокая валовая прибыль.',
    subtitle:
      'Проведите бракераж, замерьте время приготовления, стимулируйте продажи или снизьте цену.',
    description: '',
  },
  CBB: {
    title: 'Низкая популярность, средняя выручка, средняя валовая прибыль.',
    subtitle: 'Проведите бракераж, замерьте время приготовления, стимулируйте продажи.',
    description: '',
  },
  CBC: {
    title: 'Низкая популярность, средняя выручка, низкая валовая прибыль.',
    subtitle:
      'Проведите бракераж, замерьте время приготовления, снизьте себестоимость или поднять цену или вывести из меню.',
    description: '',
  },
  CCA: {
    title: 'Низкая популярность, низкая выручка, высокая валовая прибыль.',
    subtitle: 'Выведите из меню.',
    description: '',
  },
  CCB: {
    title: 'Низкая популярность, низкая выручка, средняя валовая прибыль.',
    subtitle: 'Выведите из меню.',
    description: '',
  },
  CCC: {
    title: 'Низкая популярность, низкая выручка, Низкая валовая прибыль.',
    subtitle: 'Выведите из меню.',
    description: '',
  },
};
