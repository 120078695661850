import { BlueTofuBgIcon } from '../../../../icons/reviewsPlatforms/blue-tofu-bg';
import { NineToSixTeenIcon } from '../../../../icons/reviewsPlatforms/nine-to-sixteen';
import { OneToOne } from '../../../../icons/reviewsPlatforms/one-to-one';
import { OrangeTofuBgIcon } from '../../../../icons/reviewsPlatforms/orange-tofu-bg';
import { ThreeToFour } from '../../../../icons/reviewsPlatforms/three-to-four';

export const aspectRatioOptions = [
  {
    id: 'nineToSixteen',
    icon: <NineToSixTeenIcon />,
    title: '9 к 16',
    description: 'Подойдет для треугольных и обычных стендов',
  },
  {
    id: 'threeToFour',
    icon: <ThreeToFour />,
    title: '3 к 4',
    description: 'Подойдет для стендов, стикеров на витрину или окно',
  },
  {
    id: 'oneToOne',
    icon: <OneToOne />,
    title: '1 к 1',
    description: 'Подойдет для стикеров на стол, витрину или окно',
  },
];

export const colorOptions = [
  {
    id: 'blue',
    icon: <BlueTofuBgIcon />,
    title: 'Голубая',
  },
  {
    id: 'orange',
    icon: <OrangeTofuBgIcon />,
    title: 'Оранжевая',
  },
];
