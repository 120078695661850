export const calculate = (newArray) => {
    const sectionValues = newArray.map((entry) => entry.value);
    const totalValue = sectionValues.reduce((a, b) => a + b, 0);
    const sectionPercentages = newArray.map(
      (value) => `${((value.value / totalValue) * 100).toFixed(0)}`
    );

    const final = newArray.map((item, index) => ({
        ...item,
        sectionPercentage: Number(sectionPercentages[index]),
    }));
  
    
    return  final.sort((a, b) => b.sectionPercentages - a.sectionPercentages);
}