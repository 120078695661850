import { all, fork } from 'redux-saga/effects';

import { authSagas } from './auth/authSagas';


export const rootSaga = function* rootSaga() {
  yield all([
    fork(authSagas),
  ]);
};
