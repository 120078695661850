import { TableAbout } from './TableAbout/TableAbout';
import { ArrowLeftIcon } from '../../../../../icons/day-results/arrow-left';
import './ModalAbout.scss';
import { Modal } from '../../../../common/ModalWindow/ModalWindow';
import { CloseIcon } from '../../../../../icons/close-icon';

const list = [
  { id: 1, product_name: 'Бургер с рваной свининой и помидорами', coast: '150 кг', sum: '10' },
  { id: 2, product_name: 'Бургер с рваной свининой и помидорами', coast: '150 кг', sum: '10' },
  { id: 3, product_name: 'Мидии', coast: '150 кг', sum: '10' },
];

export const ModalAbout = ({ isOpen, onClose, onCloseFirstModal, item, cheque }) => {
  const onCloseModals = () => {
    onCloseFirstModal();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} width={700} padding={0}>
      <div className="modal-about__container">
        <div className="modal-about__header">
          <div className="modal-about__header-left">
            <div onClick={onClose} className="modal-about__header-left-arrow">
              <ArrowLeftIcon />
            </div>
            <div>Чек №{cheque}</div>
          </div>
          <div onClick={onCloseModals} className="modal-about__header-right">
            <CloseIcon />
          </div>
        </div>
        <div className="modal-about__info">
          <div className="modal-about__info-top">
            <div className="modal-about__info-top-data">
              <div className="modal-about__info-text">
                Заказ: <span>{cheque}</span>
              </div>
              <div className="modal-about__info-text">
                Стол: <span>{item.coast}</span>
              </div>
              <div className="modal-about__info-text">
                Гостей: <span>3</span>
              </div>
            </div>
            <div className="modal-about__info-text">20 Апреля 2023 16:34</div>
          </div>
          <div className="modal-about__info-bottom">
            <div className="modal-about__info-text">
              Сотрудник: <span>Малова Дарья</span>
            </div>
            <div className="modal-about__info-text">
              Организация: <span>CAVIAR.MOZYR</span>
            </div>
          </div>
        </div>
        <div>
          <TableAbout list={list} item={item} />
        </div>
      </div>
    </Modal>
  );
};
