import './MenthionDesc.scss';

export const MenthionDescription = () => {
  return (
    <div className="info-container-popover-menthion">
      <div className="info-text">
        <p className="info-text-top">
          <span className="boldText">Упоминания</span> показывают, про какой раздел <br />
          (Кухня, Персонал и т.п.) наиболее часто <br /> писали в отзыве.
        </p>
        <p className="info-text-about">
          На основании этой информации можно
          <br />
          понять, за чем к вам идут гости
        </p>
        <p>
          В этой диаграмме показаны <span className="boldText">4</span> наиболее
          <br />
          упоминаемых раздела
        </p>
        <ul className="info-list">
          <li>Положительные</li>
          <li>Негативные</li>
        </ul>
      </div>
    </div>
  );
};
