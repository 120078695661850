import { useState } from 'react';
import { Button } from '../../../../components/common/Button/Button';
import { TofuSelect } from '../../../../components/common/fields/TofuSelect/TofuSelect';
import { COUNT_OF_WORKERS_OPTHION } from '../../../../const/commonConst';
import './FoodcostSettings.scss';

export const FoodcostSettings = () => {
  const [mounth, setMounth] = useState([]);
  const handleChangeMounth = (value) => {
    setMounth(value);
    // setFormErrors((prevErrors) => ({ ...prevErrors, count_of_workers: '' }));
  };
  return (
    <div className="foodcost-settings__container">
      <div className="foodcost-settings__header">
        <h2>Настройка планов фудкоста</h2>
        <p>Чтобы настроить планы фудкоста, выберите нужный вам месяц, а затем введите сумму</p>
      </div>
      <div className="foodcost-settings__selects">
        <div>
          <TofuSelect
            value={mounth}
            onChange={(value) => handleChangeMounth(value.label)}
            name="count_of_workers"
            type="select"
            options={COUNT_OF_WORKERS_OPTHION}
            label="Месяц *"
            placeholder="wef"
            // onBlur={handleBlurField}
            // formError={!!formErrors.count_of_workers}
          />
        </div>
        <div>
          <TofuSelect
            value={mounth === 0 ? '' : mounth}
            onChange={(value) => handleChangeMounth(value.label)}
            name="count_of_workers"
            type="select"
            options={COUNT_OF_WORKERS_OPTHION}
            label="Выручка *"
            placeholder="Количество сотрудников"
            // onBlur={handleBlurField}
            // formError={!!formErrors.count_of_workers}
          />
        </div>
        <div className="foodcost-settings__selects-btn">
          <Button styleType="primary" height={52}>
            Сохранить
          </Button>
        </div>
      </div>
    </div>
  );
};
