import { Editicon } from '../../../../../../icons/like-for-like/edit-icon';
import { TrashIcon } from '../../../../../../icons/like-for-like/trash-icon';
import './ThreeDotsPopover.scss';

export const ThreeDotsPopover = ({ left}) => {
  return (
    <div className='tree-dots-popover__container' style={{left}}>
      <div className='tree-dots-popover__item'>
        <div>
          <Editicon />
        </div>
        Переименовать группу
      </div>
      <div className='tree-dots-popover__item'>
        <div>
          <TrashIcon />
        </div>
        Удалить группу
      </div>
    </div>
  );
};
