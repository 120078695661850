import React, { useState } from 'react';
import { Calendar } from '../calendar/calendar';
import { FoodcostAndWriteOff } from '../FoodcostAndWriteOff/FoodcostAndWriteOff';
import { Reportsnav } from '../ReportsNav/ReportsNav';
import './Tabs.scss';
export const Tabs = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTab1 = () => {
    setActiveTab('tab1');
  };
  const handleTab2 = () => {
    setActiveTab('tab2');
  };
  const handleTab3 = () => {
    setActiveTab('tab3');
  };
  return (
    <div className="tabs-settings-reports">
      <div className="tabs-settings-reports__head">
        <div className="tabs-settings-reports__head-left">
          <ul className="tabs-settings-reports__nav">
            <li className={activeTab === 'tab1' ? 'active' : ''} onClick={handleTab1}>
              Отображение отчётов в баре
            </li>
            <li className={activeTab === 'tab2' ? 'active' : ''} onClick={handleTab2}>
              Настройка планов выручки
            </li>
            <li className={activeTab === 'tab3' ? 'active' : ''} onClick={handleTab3}>
              Настройка планов фудкоста и списаний
            </li>
          </ul>
        </div>
      </div>
      <div></div>
      <div className="outlet">{activeTab === 'tab1' && <Reportsnav />}</div>
      <div className="outlet">{activeTab === 'tab2' && <Calendar />}</div>
      <div className="outlet">{activeTab === 'tab3' && <FoodcostAndWriteOff/>}</div>
    </div>
  );
};
