import React from 'react';
import { ReviewsContentHeader } from './ReviewsContentHeader/ReviewsContentHeader';
import { Review } from './Review/Review';
import './ReviewsWindowContent.scss';
import { ERROR_TEXT_FOR_SELECT } from '../../../../../../const/commonConst';
import { ErrorWidget } from '../../../../../../components/common/ErrorWidget/ErrorWidget';
import { InfiniteScroll } from './InfiniteScroll/InfiniteScroll';
import { reviewsApi } from '../../../../../../api/reviewsApi';
import { useState } from 'react';

export const ReviewsWindowContent = ({
  reviews,
  selectedOptions,
  setSelectedOptions,
  pagination,
  setReviews,
  setPagination,
  selectedOpthionsReviews,
  handleSelectedOptionsReviewsChange,
  npsTypeOptions,
}) => {

  const [isChecked, setIsChecked] = useState(false);
  const handleLoadMoreReviews = () => {
    reviewsApi.getSortedAllReviews(pagination).then((res) => {
      setReviews((prevState) => [...prevState, ...res.data.results]);
      setPagination(res.data.next);
    });
  };

  return (
    <div className="reviews-window-content_container">
      <ReviewsContentHeader
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        selectedOpthionsReviews={selectedOpthionsReviews}
        handleSelectedOptionsReviewsChange={handleSelectedOptionsReviewsChange}
        npsTypeOptions={npsTypeOptions}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />
      <div>
        {reviews.length !== 0 ? (
          <div id="#BCScrollingElement" className="reviews-content__container">
            <InfiniteScroll
              loadMore={handleLoadMoreReviews}
              root={document.getElementById('#BCScrollingElement')}
              threshold={1.0}
              rootMargin={'0px'}
              next={pagination}
            >
              <div>
                {reviews.map((item) => {
                  return (
                    <Review
                      key={item.id}
                      providerType={item.provider_type}
                      date={item.date_created}
                      rating={item.rating}
                      author={item.review_author}
                      text={item.review_text}
                      contacts={item.back_contacts}
                      url={item.source_url}
                      npsCategory={item.nps_category_rate}
                      isChecked={isChecked}
                    />
                  );
                })}
              </div>
            </InfiniteScroll>
          </div>
        ) : (
          <ErrorWidget text={ERROR_TEXT_FOR_SELECT} reviews />
        )}
      </div>
    </div>
  );
};
