import React from 'react';
import cn from 'classnames';

import './tableItem.scss';
import { SubRow } from '../subRow/subRow';
import { ExpendableButton } from '../../../../../components/reports/Table/ExpandedButton/ExpandedButton';
import useOpenController from '../../../../../hooks/useOpenController';
import { formatNumbers } from '../../../../../helpers/formatNumbers';
import { HoverInfoTable } from './HoverInfoTable/HoverInfoTable';

export const TableItem = (props) => {
  const { isOpen, toggle } = useOpenController(false);
  const shouldDisplayExpendableButton =
    (props.item.deleted && props.isCheckedDeletedPositions) || !props.item.deleted;

  const shouldDisplayItem = (props.isShowDeletedPos && props.item.deleted) || !props.item.deleted;
  return (
    <>
      {props.item['revenue'] !== 0 || props.item['sales_number'] ? (
        <div
          className={cn(
            `${props.row ? 'like-for-like-table-row' : 'like-for-like-table-section'}__container`,
            {
              isHiddenWrapper: !shouldDisplayExpendableButton,
            }
          )}
          style={{ ...props.style }}
        >
          <div
            className={`${
              props.row ? 'like-for-like-table-row' : 'like-for-like-table-section'
            }__wrapper`}
          >
            <div
              className={`${
                props.row ? 'like-for-like-table-row' : 'like-for-like-table-section'
              }__main-title`}
              style={{ marginLeft: props.marginLeft }}
            >
              {props.item.items?.length > 0 || props.item.child_objects?.length > 0 ? (
                <div className="button-div">
                  {shouldDisplayExpendableButton && (
                    <ExpendableButton isOpen={isOpen} toggle={toggle}>
                      <div
                        className={`${
                          props.row ? 'like-for-like-table-row' : 'like-for-like-table-section'
                        }__title`}
                        style={{ color: shouldDisplayItem ? '#2B2B2B' : '#D35860' }}
                      >
                        {props.item['name'] === 'Сумма' ? (
                          <div className="like-for-like-table-info">
                            <div>{props.item['name']}</div> <HoverInfoTable />
                          </div>
                        ) : (
                          props.item['name']
                        )}
                      </div>
                    </ExpendableButton>
                  )}
                </div>
              ) : (
                <div
                  className={`${
                    props.row ? 'like-for-like-table-row' : 'like-for-like-table-section'
                  }__title`}
                  style={{ color: shouldDisplayItem ? '#2B2B2B' : '#D35860' }}
                >
                  {props.item['name']}
                </div>
              )}
            </div>
            <>
              {props.TableSectionInner.map((item, index) => {
                if (item === 'network_dynamic' || item === 'comparable_dynamic') {
                  return (
                    <div
                      key={index}
                      className={`${
                        props.row ? 'like-for-like-table-row' : 'like-for-like-table-section'
                      }__title`}
                    >
                      {props.item[item] && (
                        <div
                          className={
                            props.item[item] < 0
                              ? 'like-for-like-table-row-negative'
                              : 'like-for-like-table-row-positive'
                          }
                        >
                          {props.item[item]}%
                        </div>
                      )}
                    </div>
                  );
                }

                const value =
                  item === 'food_cost' || item === 'percent'
                    ? props.item[item] + '%'
                    : item === 'sales_number'
                    ? props.item[item]
                    : props.item[item];

                return (
                  <div
                    key={item}
                    className={`${
                      props.row
                        ? 'like-for-like-table-row__title'
                        : 'like-for-like-table-section__numbers'
                    }`}
                  >
                    {formatNumbers(value)}
                  </div>
                );
              })}
            </>
          </div>
          <div
            className={
              isOpen ? 'like-for-like-table-section__open' : 'like-for-like-table-section__close'
            }
          >
            {props.item.items &&
              props.item.items.map((i) => (
                <SubRow
                  key={i.id}
                  item={i}
                  isShowDeletedPos={props.isCheckedDeletedPositions}
                  isShowABC={props.isShowABC}
                  TableSectionInner={props.TableSectionInner}
                  style={props.style}
                  marginLeft={props.marginLeft + 15}
                  isHidePosWithZero={props.isHidePosWithZero}
                />
              ))}
            {props.item.child_objects &&
              props.item.child_objects.map((i) => (
                <TableItem
                  key={i.id}
                  row
                  item={i}
                  TableSectionInner={props.TableSectionInner}
                  style={props.style}
                  marginLeft={props.marginLeft + 15}
                  isFoodcost={props.isFoodcost}
                  isShowABC={props.isShowABC}
                  selectedDate={props.selectedDate}
                  account_id={props.account_id}
                  isHidePosWithZero={props.isHidePosWithZero}
                />
              ))}
            <div></div>
          </div>
        </div>
      ) : null}
    </>
  );
};
