import React, { useState, useEffect, useRef } from 'react';
import { ThreeDotsIcon } from '../../../../../../icons/three-dots-icon';
import './Header.scss';
import { SelectW } from '../SelectW/SelectW';

export const Header = ({setGetOptions,currentTab,setGetSecondOptions}) => {
  const [selectedOption, setSelectedOption] = useState('Склад');
  const [selectedOptionSecond, setSelectedOptionSecond] = useState('Причины');
  const [selectedСancelableDishes, setSelectedСancelableDishes] = useState('Группа');

  const [options, setOptions] = useState([
    { name: 'Все', activeOpt: true },
    { name: 'Кухня', activeOpt: false },
    { name: 'Бар', activeOpt: false },
  ]);

  const [secondOptions, setSecondOptions] = useState([
    { name: 'Все', activeOpt: true },
    { name: 'Порча', activeOpt: false },
    { name: 'Питание персонала', activeOpt: false },
    { name: 'Бракераж', activeOpt: false },
  ]);

  const [cancelableDishes, setCancelableDishes] = useState([
    { name: 'Все', activeOpt: true },
    { name: 'Кухня', activeOpt: false },
    { name: 'Бар', activeOpt: false },
    { name: 'Кальяны', activeOpt: false },
  ]);

 

  const handleFirstSelect = (option) => {
    setGetOptions((prevState) => ({
      ...prevState,
      firstName: option,
    }));
  };

  const handleSecondSelect = (option) => {
    setGetOptions((prevState) => ({
      ...prevState,
      secondName: option,
    }));
  };

  const handleCancebleSelect = (option) => {
    setGetSecondOptions(option)
  };

  return (
    <div className="demo-header-container">
      <div className="demo-header-title">Топ 10 </div>
      <div className="demo-header-btns">
      {currentTab == 0 ? (
      <>
        <SelectW
          selectedO={selectedOption}
          setSelectedO={setSelectedOption}
          options={options}
          setO={setOptions}
          onSelect={handleFirstSelect}
          currentTab={currentTab}
        />
        <SelectW
          selectedO={selectedOptionSecond}
          setSelectedO={setSelectedOptionSecond}
          options={secondOptions}
          setO={setSecondOptions}
          onSelect={handleSecondSelect}
          currentTab={currentTab}
          style={{ width: "200px" }}
        />
      </>
    ) : currentTab == 1 ? (
      <SelectW
        selectedO={selectedСancelableDishes}
        setSelectedO={setSelectedСancelableDishes}
        options={cancelableDishes}
        setO={setCancelableDishes}
        onSelectCanceble={handleCancebleSelect}
        currentTab={currentTab}
      />
    ) : null}
  
        {/* <div className="demo-header-dots">
          <ThreeDotsIcon />
        </div> */}
      </div>
    </div>
  );
};
