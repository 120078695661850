export const TOP_TEN_PRODUCTS_OPTION = [
  { value: 'by_dishes', type: 'По блюдам' },
  { value: 'by_groop', type: 'По группам' },
  { value: 'by_subgroop', type: 'По подгруппам' },
  { value: 'by_mod', type: 'По модификаторам' },
];

export const TABLE__DATA = [
  {
    name: 'Бар',
    sales_number: '3 395,63',
    price: '',
    cost_price: '',
    sales_cost_price: '225 800,94',
    revenue: '847 032,98',
    gross_profit: '621 232,04',
    food_cost: '26,66%',
    child_objects: [
      {
        name: 'Вермуты',
        sales_number: '6,5',
        price: '',
        cost_price: '',
        sales_cost_price: '545,97',
        revenue: '1 495,00',
        gross_profit: '949,03',
        food_cost: '36,52%',
        items: [
          {
            name: 'Martini Bianco',
            sales_number: { value: '5', abc_category: 'C' },
            price: '230',
            cost_price: '84,00',
            child_objects: [],
            sales_cost_price: '419,98',
            revenue: { value: '1 150,00', abc_category: 'C' },
            gross_profit: { value: '730,02', abc_category: 'C' },
            food_cost: '36,52%',
            child_objects: [],
          },
          {
            name: 'Martini Rosso',
            sales_number: { value: '1', abc_category: 'C' },
            price: '230',
            cost_price: '84,00',
            sales_cost_price: '84,00',
            revenue: { value: '230,00', abc_category: 'C' },
            gross_profit: { value: '146,00', abc_category: 'C' },
            food_cost: '36,52%',
            child_objects: [],
          },
          {
            name: 'Martini Extra Dry',
            sales_number: { value: '0,5', abc_category: 'C' },
            price: '230',
            cost_price: '84,00',
            sales_cost_price: '42,00',
            revenue: { value: '115,00', abc_category: 'C' },
            gross_profit: { value: '73,00', abc_category: 'C' },
            food_cost: '36,52%',
            child_objects: [],
          },
        ],
      },
      {
        name: 'Винная карта',
        sales_number: '416',
        price: ' ',
        cost_price: '',
        sales_cost_price: '73 198,41',
        revenue: '225 267,48',
        gross_profit: '152 069,07',
        food_cost: '32,49%',
        isThirdRow: true,
        child_objects: [
          {
            name: 'Белое вино',
            sales_number: '160',
            price: '',
            cost_price: '',
            sales_cost_price: '15 362,09',
            revenue: '44 170,00',
            gross_profit: '28 807,91',
            food_cost: '34,78%',
            items: [
              {
                name: 'Cono Sur Bicicleta Gewurztraminer',
                sales_number: { value: '35', abc_category: 'A' },
                price: '315',
                cost_price: '111,99',
                sales_cost_price: '3 919,80',
                revenue: { value: '11 025,00', abc_category: 'A' },
                gross_profit: { value: '7 105,20', abc_category: 'A' },
                food_cost: '35,55%',
                child_objects: [],
              },
              {
                name: 'Freschello Bianco Semi-Sweet',
                sales_number: { value: '35', abc_category: 'A' },
                price: '230',
                cost_price: '75,14',
                sales_cost_price: '2 930,42',
                revenue: { value: '8 970,00', abc_category: 'A' },
                gross_profit: { value: '6 039,58', abc_category: 'A' },
                food_cost: '32,67%',
                child_objects: [],
              },
              {
                name: '770 Miles Chardonnay',
                sales_number: { value: '21', abc_category: 'A' },
                price: '315',
                cost_price: '107,71',
                sales_cost_price: '2261,89',
                revenue: { value: '6 615,00', abc_category: 'A' },
                gross_profit: { value: '4353,11', abc_category: 'A' },
                food_cost: '34,19%',
                child_objects: [],
              },
              {
                name: "Baron d'Arignac Muskat",
                sales_number: { value: '21', abc_category: 'A' },
                price: '260',
                cost_price: '76,85',
                sales_cost_price: '1 613,92',
                revenue: { value: '5 460,00', abc_category: 'A' },
                gross_profit: { value: '3 846,08', abc_category: 'A' },
                food_cost: '29,56%',
                child_objects: [],
              },
              {
                name: 'Heinz Eifel Dry Riesling, Rheinhessen QbA',
                sales_number: { value: '15', abc_category: 'B' },
                price: '290',
                cost_price: '114,28',
                sales_cost_price: '1 714,20',
                revenue: { value: '4 350,00', abc_category: 'B' },
                gross_profit: { value: '2 635,80', abc_category: 'B' },
                food_cost: '39,41%',
                child_objects: [],
              },
              {
                name: 'Lama di Pietra Chardonnay',
                sales_number: { value: '10', abc_category: 'B' },
                price: '290',
                cost_price: '123,71',
                sales_cost_price: '1 237,08',
                revenue: { value: '2 900,00', abc_category: 'B' },
                gross_profit: { value: '1 662,92', abc_category: 'B' },
                food_cost: '42,66%',
                child_objects: [],
              },
              {
                name: 'Weinlandkeller Welschriesling',
                sales_number: { value: '7', abc_category: 'C' },
                price: '260',
                cost_price: '85,14',
                sales_cost_price: '595,97',
                revenue: { value: '1 820,00', abc_category: 'B' },
                gross_profit: { value: '1 224,03', abc_category: 'C' },
                food_cost: '32,75%',
                child_objects: [],
              },
              {
                name: "Devil's Rock Rielsling",
                sales_number: { value: '7', abc_category: 'C' },
                price: '260',
                colorNumber: 'C',
                colorCost: 'B',
                colorProfit: 'C',
                cost_price: '101,14',
                sales_cost_price: '707,96',
                revenue: { value: '1 820,00', abc_category: 'B' },
                gross_profit: { value: '1 112,04', abc_category: 'C' },
                food_cost: '38,90%',
                child_objects: [],
              },
              {
                name: 'Lorenzo Moskatti Trebbiano',
                sales_number: { value: '3', abc_category: 'C' },
                price: '230',
                cost_price: '60,85',
                sales_cost_price: '182,56',
                revenue: { value: '690,00', abc_category: 'C' },
                gross_profit: { value: '507,44', abc_category: 'C' },
                food_cost: '26,46%',
                child_objects: [],
              },
              {
                name: 'Bolla Pinot Grigio delle Venezie',
                sales_number: { value: '2', abc_category: 'C' },
                price: '260',
                cost_price: '99,14',
                sales_cost_price: '198,28',
                revenue: { value: '520,00', abc_category: 'C' },
                gross_profit: { value: '321,72', abc_category: 'C' },
                food_cost: '38,13%',
                child_objects: [],
              },
            ],
          },
          {
            name: 'Игристое виино',
            sales_number: '185',
            price: '',
            cost_price: '',
            sales_cost_price: '14 146,15',
            revenue: '50 279,98',
            gross_profit: '36 133,83',
            food_cost: '28,13%',
            items: [
              {
                name: 'I Castelli Romeo e Giulietta Prosecco',
                sales_number: { value: '72', abc_category: 'A' },
                price: '340',
                cost_price: '104,85',
                sales_cost_price: '7 549,34',
                revenue: { value: '24 480,00', abc_category: 'A' },
                gross_profit: { value: '16 930,66', abc_category: 'A' },
                food_cost: '30,84%',
                child_objects: [],
              },
              {
                name: 'Lambrusco Rosso',
                sales_number: { value: '52', abc_category: 'A' },
                price: '230',
                cost_price: '56,85',
                sales_cost_price: '2 956,42',
                revenue: { value: '11 960,00', abc_category: 'A' },
                gross_profit: { value: '9 003,58', abc_category: 'A' },
                food_cost: '24,72%',
                child_objects: [],
              },
              {
                name: "Baron d'Arignac, полусладкое",
                sales_number: { value: '24', abc_category: 'A' },
                price: '200',
                cost_price: '48,28',
                sales_cost_price: '1 158,80',
                revenue: { value: '4 800,00', abc_category: 'B' },
                gross_profit: { value: '3 641,20', abc_category: 'B' },
                food_cost: '24,14%',
                child_objects: [],
              },
              {
                name: 'Freschello Sparkling',
                sales_number: '14',
                sales_number: { value: '14', abc_category: 'B' },
                price: '290',
                cost_price: '84,00',
                sales_cost_price: '1 175,94',
                revenue: { value: '4 060,00', abc_category: 'B' },
                gross_profit: { value: '2 884,06', abc_category: 'B' },
                food_cost: '28,96%',
                child_objects: [],
              },
              {
                name: "Baron d'Arignac, полусухое",
                sales_number: { value: '12', abc_category: 'B' },
                price: '200',
                cost_price: '46,00',
                sales_cost_price: '551,97',
                revenue: { value: '2 400,00', abc_category: 'B' },
                gross_profit: { value: '1 848,03', abc_category: 'B' },
                food_cost: '23,00%',
                child_objects: [],
              },
              {
                name: 'Lambrusco Bianco',
                sales_number: { value: '8', abc_category: 'B' },
                price: '230',
                cost_price: '64,85',
                sales_cost_price: '518,83',
                revenue: { value: '1 840,00', abc_category: 'B' },
                gross_profit: { value: '1 321,17', abc_category: 'C' },
                food_cost: '28,20%',
                child_objects: [],
              },
              {
                name: "Baron d'Arignac, сухое",
                sales_number: { value: '2', abc_category: 'C' },
                price: '200',
                cost_price: '46,00',
                sales_cost_price: '92,00',
                revenue: { value: '399,98', abc_category: 'C' },
                gross_profit: { value: '307,98', abc_category: 'C' },
                food_cost: '23,00%',
                child_objects: [],
              },
              {
                name: 'Perlino, Prosecco',
                sales_number: '1',
                sales_number: { value: '1', abc_category: 'C' },
                price: '340',
                cost_price: '142,85',
                sales_cost_price: '142,85',
                revenue: { value: '340,00', abc_category: 'C' },
                gross_profit: { value: '197,15', abc_category: 'C' },
                food_cost: '42,01%',
                child_objects: [],
              },
            ],
          },
          {
            name: 'Красное вино',
            sales_number: '66',
            price: '',
            cost_price: '',
            sales_cost_price: '5 582,01',
            revenue: '16 900,00',
            gross_profit: '11 317,99',
            food_cost: '33,03%',
            items: [
              {
                name: "Devil's Rock Pinot Noir",
                sales_number: { value: '16', abc_category: 'B' },
                price: '260',
                cost_price: '67,43',
                sales_cost_price: '1 078,80',
                revenue: { value: '4 160,00', abc_category: 'B' },
                gross_profit: { value: '3 081,20', abc_category: 'B' },
                food_cost: '25,93%',
                child_objects: [],
              },
              {
                name: '770 Miles Zinfandel',
                sales_number: { value: '10', abc_category: 'B' },
                price: '315',
                cost_price: '107,71',
                sales_cost_price: '1 077,09',
                revenue: { value: '3 150,00', abc_category: 'B' },
                gross_profit: { value: '2 072,91', abc_category: 'B' },
                food_cost: '34,19%',
                child_objects: [],
              },
              {
                name: 'Nature Syrah безалкогольное вино',
                sales_number: { value: '13', abc_category: 'B' },
                price: '260',
                cost_price: '104,85',
                sales_cost_price: '1 363,07',
                revenue: { value: '3 380,00', abc_category: 'B' },
                gross_profit: { value: '2 016,93', abc_category: 'B' },
                food_cost: '40,33%',
                child_objects: [],
              },
              {
                name: 'Donini Cabernet Sauvignon',
                sales_number: { value: '11', abc_category: 'B' },
                price: '230',
                cost_price: '68,28',
                sales_cost_price: '751,11',
                revenue: { value: '2 530,00', abc_category: 'B' },
                gross_profit: { value: '1 778,89', abc_category: 'B' },
                food_cost: '29,69%',
                child_objects: [],
              },
              {
                name: "Baron d'Arignac Moelleux",
                sales_number: { value: '11', abc_category: 'B' },
                price: '230',
                cost_price: '74,85',
                sales_cost_price: '823,39',
                revenue: { value: '2 530,00', abc_category: 'B' },
                gross_profit: { value: '1 706,61', abc_category: 'B' },
                food_cost: '32,54%',
                child_objects: [],
              },
              {
                name: "Baron d'Arignac Cabernet-Sauvignon",
                sales_number: { value: '5', abc_category: 'C' },
                price: '230',
                cost_price: '97,71',
                sales_cost_price: '488,55',
                revenue: { value: '1 150,00', abc_category: 'C' },
                gross_profit: { value: '661,45', abc_category: 'C' },
                food_cost: '42,48%',
                child_objects: [],
              },
            ],
          },
          {
            name: 'Розовое вино',
            sales_number: '5',
            price: '',
            cost_price: '',
            sales_cost_price: '606,26',
            revenue: '1 650,00',
            gross_profit: '1 043,74',
            food_cost: '36,74%',
            items: [
              {
                name: 'Santa Digna Cabernet Sauvignon',
                sales_number: { value: '4', abc_category: 'C' },
                price: '340',
                cost_price: '126,28',
                sales_cost_price: '505,12',
                revenue: { value: '1 360,00', abc_category: 'C' },
                gross_profit: { value: '854,88', abc_category: 'C' },
                food_cost: '37,14%',
                child_objects: [],
              },
              {
                name: 'B&G Rose Reserve',
                sales_number: { value: '1', abc_category: 'C' },
                price: '290',
                cost_price: '101,14',
                sales_cost_price: '101,14',
                revenue: { value: '290,00', abc_category: 'C' },
                gross_profit: { value: '188,86', abc_category: 'C' },
                food_cost: '34,88%',
                child_objects: [],
              },
            ],
          },
        ],
      },
      {
        name: 'Виски',
        sales_number: '362,75',
        price: '',
        cost_price: '',
        sales_cost_price: '30 933,95',
        revenue: '91 727,50',
        gross_profit: '60 793,55',
        food_cost: '33,72%',
        items: [
          {
            name: 'Jim Beam',
            sales_number: { value: '79', abc_category: 'A' },
            price: '260',
            cost_price: '83,14',
            sales_cost_price: '6 567,96',
            revenue: { value: '20 540,00', abc_category: 'A' },
            gross_profit: { value: '13 972,04', abc_category: 'A' },
            food_cost: '31,98%',
            child_objects: [],
          },
          {
            name: 'Ballantine’s',
            sales_number: { value: '90', abc_category: 'A' },
            price: '230',
            cost_price: '76,85',
            sales_cost_price: '6 916,80',
            revenue: { value: '20 700,00', abc_category: 'A' },
            gross_profit: { value: '13 783,20', abc_category: 'A' },
            food_cost: '33,41%',
            child_objects: [],
          },
          {
            name: "William Lawson's",
            sales_number: { value: '65', abc_category: 'A' },
            price: '200',
            cost_price: '61,43',
            sales_cost_price: '3 992,66',
            revenue: { value: '13 000,00', abc_category: 'A' },
            gross_profit: { value: '9 007,34', abc_category: 'A' },
            food_cost: '30,71%',
            child_objects: [],
          },
          {
            name: 'Monkey Shoulder',
            sales_number: { value: '28,75', abc_category: 'A' },
            price: '400',
            cost_price: '172,85',
            sales_cost_price: '4 969,39',
            revenue: { value: '11 500,00', abc_category: 'A' },
            gross_profit: { value: '6 530,61', abc_category: 'A' },
            food_cost: '43,21%',
            child_objects: [],
          },
          {
            name: 'Grant‘s',
            sales_number: '46,5',
            sales_number: { value: '46,5', abc_category: 'A' },
            price: '200',
            cost_price: '72,28',
            sales_cost_price: '3 361,12',
            revenue: { value: '9 300,00', abc_category: 'A' },
            gross_profit: { value: '5 938,88', abc_category: 'A' },
            food_cost: '36,14%',
            child_objects: [],
          },
          {
            name: 'Jameson',
            sales_number: { value: '26', abc_category: 'A' },
            price: '315',
            cost_price: '100,28',
            sales_cost_price: '2 607,30',
            revenue: { value: '8 190,00', abc_category: 'A' },
            gross_profit: { value: '5 582,70', abc_category: 'A' },
            food_cost: '31,84%',
            child_objects: [],
          },
          {
            name: 'Tullamore D.E.W',
            sales_number: { value: '18,25', abc_category: 'B' },
            price: '260',
            cost_price: '90,28',
            sales_cost_price: '1 647,63',
            revenue: { value: '4 745,00', abc_category: 'B' },
            gross_profit: { value: '3 097,37', abc_category: 'B' },
            food_cost: '34,72%',
            child_objects: [],
          },
          {
            name: 'Monkey Shoulder "Smokey Monkey"',
            sales_number: { value: '3,25', abc_category: 'C' },
            price: '490',
            cost_price: '0,00',
            sales_cost_price: '0,00',
            revenue: { value: '1 592,50', abc_category: 'C' },
            gross_profit: { value: '1 592,50', abc_category: 'C' },
            food_cost: '0,00%',
            child_objects: [],
          },
          {
            name: "Jack Daniel's Old №7",
            sales_number: { value: '4', abc_category: 'C' },
            price: '340',
            cost_price: '132,56',
            sales_cost_price: '530,26',
            revenue: { value: '1 360,00', abc_category: 'C' },
            gross_profit: { value: '829,74', abc_category: 'C' },
            food_cost: '38,99%',
            child_objects: [],
          },
          {
            name: 'Nikka Coffey Grain',
            sales_number: { value: '1', abc_category: 'C' },
            price: '460',
            cost_price: '214,56',
            sales_cost_price: '214,56',
            revenue: { value: '460,00', abc_category: 'C' },
            gross_profit: { value: '245,44', abc_category: 'C' },
            food_cost: '46,64%',
            child_objects: [],
          },
          {
            name: 'Teeling Small Batch',
            sales_number: { value: '1', abc_category: 'C' },
            price: '340',
            cost_price: '126,28',
            sales_cost_price: '126,28',
            revenue: { value: '340,00', abc_category: 'C' },
            gross_profit: { value: '213,72', abc_category: 'C' },
            food_cost: '37,14%',
            child_objects: [],
          },
        ],
      },
      {
        name: 'Водка',
        sales_number: '223',
        price: '',
        cost_price: '',
        sales_cost_price: '5 630,58',
        revenue: '25 595,00',
        gross_profit: '19 964,42',
        food_cost: '22,00%',
        items: [
          {
            name: 'Сябры',
            sales_number: { value: '184,5', abc_category: 'A' },
            price: '100',
            cost_price: '22,86',
            sales_cost_price: '4 216,93',
            revenue: { value: '18 450,00', abc_category: 'A' },
            gross_profit: { value: '14 233,07', abc_category: 'A' },
            food_cost: '22,86%',
            child_objects: [],
          },
          {
            name: 'Mad Dog клюква',
            sales_number: { value: '28,75', abc_category: 'A' },
            price: '140',
            cost_price: '25,14',
            sales_cost_price: '722,82',
            revenue: { value: '4 025,00', abc_category: 'B' },
            gross_profit: { value: '3 302,18', abc_category: 'B' },
            food_cost: '17,96%',
            child_objects: [],
          },
          {
            name: 'Finlandia Lime',
            sales_number: { value: '9,75', abc_category: 'B' },
            price: '320',
            cost_price: '70,85',
            sales_cost_price: '690,82',
            revenue: { value: '3 120,00', abc_category: 'B' },
            gross_profit: { value: '2 429,18', abc_category: 'B' },
            food_cost: '22,14%',
            child_objects: [],
          },
        ],
      },
      {
        name: 'Джин',
        sales_number: '41,75',
        price: '',
        cost_price: '',
        sales_cost_price: '2 505,52',
        revenue: '10 322,50',
        gross_profit: '7 816,98',
        food_cost: '24,27%',
        items: [
          {
            name: 'Larios',
            sales_number: { value: '23,5', abc_category: 'A' },
            price: '200',
            cost_price: '24,57',
            sales_cost_price: '577,40',
            revenue: { value: '4 700,00', abc_category: 'B' },
            gross_profit: { value: '4 122,60', abc_category: 'A' },
            food_cost: '12,29%',
            child_objects: [],
          },
          {
            name: 'Bombay Sapphire',
            sales_number: { value: '15,25', abc_category: 'B' },
            price: '290',
            cost_price: '91,14',
            sales_cost_price: '1 389,86',
            revenue: { value: '4 422,50', abc_category: 'B' },
            gross_profit: { value: '3 032,64', abc_category: 'B' },
            food_cost: '31,43%',
            child_objects: [],
          },
          {
            name: "Hendrick's",
            sales_number: { value: '3', abc_category: 'C' },
            price: '400',
            cost_price: '179,42',
            sales_cost_price: '538,26',
            revenue: { value: '1 200,00', abc_category: 'C' },
            gross_profit: { value: '661,74', abc_category: 'C' },
            food_cost: '44,85%',
            child_objects: [],
          },
        ],
      },
      {
        name: 'Допы',
        sales_number: '136',
        price: '',
        cost_price: '',
        sales_cost_price: '1 249,08',
        revenue: '8 736,00',
        gross_profit: '7 486,92',
        food_cost: '14,30%',
        items: [
          {
            name: 'Лимон',
            sales_number: { value: '51', abc_category: 'A' },
            price: '60',
            cost_price: '1,71',
            sales_cost_price: '87,42',
            revenue: { value: '3 060,00', abc_category: 'B' },
            gross_profit: { value: '2 972,58', abc_category: 'B' },
            food_cost: '2,86%',
            child_objects: [],
          },
          {
            name: 'Сироп "Monin"',
            sales_number: { value: '41', abc_category: 'A' },
            price: '70',
            cost_price: '24,28',
            sales_cost_price: '995,66',
            revenue: { value: '2 870,00', abc_category: 'B' },
            gross_profit: { value: '1 874,34', abc_category: 'B' },
            food_cost: '34,69%',
            child_objects: [],
          },
          {
            name: 'Лайм',
            sales_number: { value: '18', abc_category: 'B' },
            price: '60',
            cost_price: '3,71',
            sales_cost_price: '66,85',
            revenue: { value: '1 080,00', abc_category: 'C' },
            gross_profit: { value: '1 013,15', abc_category: 'C' },
            food_cost: '6,19%',
            child_objects: [],
          },
          {
            name: 'Мед',
            sales_number: '11',
            sales_number: { value: '11', abc_category: 'B' },
            price: '86',
            cost_price: '0,00',
            sales_cost_price: '0,00',
            revenue: { value: '946,00', abc_category: 'C' },
            gross_profit: { value: '946,00', abc_category: 'C' },
            food_cost: '0,00%',
            child_objects: [],
          },
          {
            name: 'Мята',
            sales_number: { value: '5', abc_category: 'C' },
            price: '60',
            cost_price: '6,00',
            sales_cost_price: '60',
            revenue: { value: '300,00', abc_category: 'C' },
            gross_profit: { value: '270,00', abc_category: 'C' },
            food_cost: '10,00%',
            child_objects: [],
          },
          {
            name: 'Апельсин',
            sales_number: { value: '4', abc_category: 'C' },
            price: '60',
            cost_price: '1,14',
            sales_cost_price: '4,57',
            revenue: { value: '240,00', abc_category: 'C' },
            gross_profit: { value: '235,43', abc_category: 'C' },
            food_cost: '1,90%',
            child_objects: [],
          },
          {
            name: 'Молоко',
            sales_number: '4',
            sales_number: { value: '4', abc_category: 'C' },
            price: '30',
            cost_price: '5,43',
            sales_cost_price: '21,71',
            revenue: { value: '120,00', abc_category: 'C' },
            gross_profit: { value: '98,29', abc_category: 'C' },
            food_cost: '18,09%',
            child_objects: [],
          },
          {
            name: 'Облепиха',
            sales_number: { value: '2', abc_category: 'C' },
            price: '60',
            cost_price: '21,43',
            sales_cost_price: '42,86',
            revenue: { value: '120,00', abc_category: 'C' },
            gross_profit: { value: '77,15', abc_category: 'C' },
            food_cost: '35,71%',
            child_objects: [],
          },
        ],
      },
      {
        name: 'Коктейли',
        sales_number: '800',
        price: '',
        cost_price: '',
        sales_cost_price: '72 192,68',
        revenue: '35 5310,00',
        gross_profit: '283 117,32',
        food_cost: '20,32%',
        items: [
          {
            name: 'Aperol Spritz',
            sales_number: { value: '58', abc_category: 'A' },
            price: '570',
            cost_price: '214,28',
            sales_cost_price: '12 427,95',
            revenue: { value: '33 060,00', abc_category: 'A' },
            gross_profit: { value: '20 632,05', abc_category: 'A' },
            food_cost: '37,59%',
            child_objects: [],
          },
          {
            name: 'Whiskey Sour',
            sales_number: { value: '20', abc_category: 'B' },
            price: '400',
            cost_price: '109,99',
            sales_cost_price: '2 199,89',
            revenue: { value: '8 000,00', abc_category: 'A' },
            gross_profit: { value: '5 800,11', abc_category: 'A' },
            food_cost: '27,50%',
            child_objects: [],
          },
          {
            name: 'Old fashioned',
            sales_number: { value: '19', abc_category: 'B' },
            price: '370',
            cost_price: '108,57',
            sales_cost_price: '2062,75',
            revenue: { value: '7 030,00', abc_category: 'A' },
            gross_profit: { value: '4 967,25', abc_category: 'A' },
            food_cost: '29,34%',
            child_objects: [],
          },
          {
            name: 'Bramble',
            sales_number: { value: '14', abc_category: 'B' },
            price: '400',
            cost_price: '86,57',
            sales_cost_price: '1 211,94',
            revenue: { value: '5 600,00', abc_category: 'A' },
            gross_profit: { value: '4 388,06', abc_category: 'A' },
            food_cost: '21,64%',
            child_objects: [],
          },
          {
            name: 'Long Island',
            sales_number: { value: '6', abc_category: 'C' },
            price: '740',
            cost_price: '166,85',
            sales_cost_price: '1 001,09',
            revenue: { value: '4 440,00', abc_category: 'B' },
            gross_profit: { value: '3 438,91', abc_category: 'B' },
            food_cost: '22,55%',
            child_objects: [],
          },
          {
            name: 'Daiquiri',
            sales_number: { value: '10', abc_category: 'B' },
            price: '370',
            cost_price: '90,85',
            sales_cost_price: '908,53',
            revenue: { value: '3 700,00', abc_category: 'B' },
            gross_profit: { value: '2 791,47', abc_category: 'B' },
            food_cost: '24,55%',
            child_objects: [],
          },
          {
            name: 'Clover Club',
            sales_number: { value: '5', abc_category: 'C' },
            price: '370',
            cost_price: '47,43',
            sales_cost_price: '237,13',
            revenue: { value: '1 850,00', abc_category: 'B' },
            gross_profit: { value: '1 612,87', abc_category: 'B' },
            food_cost: '12,82%',
            child_objects: [],
          },
          {
            name: 'Hugo Spritz',
            sales_number: { value: '7', abc_category: 'C' },
            price: '430',
            cost_price: '197,99',
            sales_cost_price: '1 385,93',
            revenue: { value: '3 010,00', abc_category: 'B' },
            gross_profit: { value: '1 624,07', abc_category: 'B' },
            food_cost: '46,04%',
            child_objects: [],
          },
          {
            name: 'Negroni',
            sales_number: { value: '4', abc_category: 'C' },
            price: '400',
            cost_price: '116,28',
            sales_cost_price: '465,12',
            revenue: { value: '1 600,00', abc_category: 'C' },
            gross_profit: { value: '1 134,88', abc_category: 'C' },
            food_cost: '29,07%',
            child_objects: [],
          },
          {
            name: 'Porn Star Martini',
            sales_number: { value: '2', abc_category: 'C' },
            price: '570',
            cost_price: '66,00',
            sales_cost_price: '131,99',
            revenue: { value: '1 140,00', abc_category: 'C' },
            gross_profit: { value: '1 008,01', abc_category: 'C' },
            food_cost: '11,58%',
            child_objects: [],
          },
          {
            name: 'White Russian',
            sales_number: { value: '3', abc_category: 'C' },
            price: '370',
            cost_price: '86,00',
            sales_cost_price: '257,99',
            revenue: { value: '1 110,00', abc_category: 'C' },
            gross_profit: { value: '852,01', abc_category: 'C' },
            food_cost: '23,24%',
            child_objects: [],
          },
          {
            name: 'Campari Spritz',
            sales_number: { value: '1', abc_category: 'C' },
            price: '430',
            cost_price: '197,99',
            sales_cost_price: '197,99',
            revenue: { value: '430,00', abc_category: 'C' },
            gross_profit: { value: '232,01', abc_category: 'C' },
            food_cost: '46,04%',
            child_objects: [],
          },
        ],
        child_objects: [
          {
            name: 'Авторские',
            sales_number: '651',
            price: '',
            cost_price: '',
            sales_cost_price: '49 704,37',
            revenue: '284 340,00',
            gross_profit: '234 635,63',
            food_cost: '17,48%',
            items: [
              {
                name: 'CAVIAR.',
                sales_number: { value: '147', abc_category: 'A' },
                price: '560',
                cost_price: '95,71',
                sales_cost_price: '14 069,30',
                revenue: { value: '82 320,00', abc_category: 'A' },
                gross_profit: { value: '68 250,70', abc_category: 'A' },
                food_cost: '17,09%',
                child_objects: [],
              },
              {
                name: 'KOLIBRI',
                sales_number: { value: '124', abc_category: 'A' },
                price: '400',
                cost_price: '62,57',
                sales_cost_price: '7 758,47',
                revenue: { value: '49 600,00', abc_category: 'A' },
                gross_profit: { value: '41 841,53', abc_category: 'A' },
                food_cost: '15,64%',
                child_objects: [],
              },
              {
                name: 'FLAMINGO',
                sales_number: { value: '85', abc_category: 'A' },
                price: '430',
                cost_price: '66,00',
                sales_cost_price: '5 609,72',
                revenue: { value: '36 550,00', abc_category: 'A' },
                gross_profit: { value: '30 940,28', abc_category: 'A' },
                food_cost: '15,35%',
                child_objects: [],
              },
              {
                name: 'BERGAMO',
                sales_number: { value: '62', abc_category: 'A' },
                price: '370',
                cost_price: '42,57',
                sales_cost_price: '2 639,30',
                revenue: { value: '22 940,00', abc_category: 'A' },
                gross_profit: { value: '20 300,70', abc_category: 'A' },
                food_cost: '11,51%',
                child_objects: [],
              },
              {
                name: 'SPOT 74²',
                sales_number: '64',
                sales_number: { value: '64', abc_category: 'A' },
                price: '370',
                cost_price: '64,85',
                sales_cost_price: '4 150,65',
                revenue: { value: '23 680,00', abc_category: 'A' },
                gross_profit: { value: '19 529,35', abc_category: 'A' },
                food_cost: '17,53%',
                child_objects: [],
              },
              {
                name: 'Love',
                sales_number: '45',
                sales_number: { value: '45', abc_category: 'A' },
                price: '370',
                cost_price: '69,71',
                sales_cost_price: '3 136,99',
                revenue: { value: '16 650,00', abc_category: 'A' },
                gross_profit: { value: '13 513,01', abc_category: 'A' },
                food_cost: '18,84%',
                child_objects: [],
              },
              {
                name: 'Blue haze',
                sales_number: { value: '39', abc_category: 'A' },
                price: '460',
                cost_price: '127,42',
                sales_cost_price: '4 969,47',
                revenue: { value: '17 940,00', abc_category: 'A' },
                gross_profit: { value: '12 970,53', abc_category: 'A' },
                food_cost: '27,70%',
                child_objects: [],
              },
              {
                name: 'APERITIF',
                sales_number: { value: '37', abc_category: 'A' },
                price: '400',
                cost_price: '131,42',
                sales_cost_price: '4 862,61',
                revenue: { value: '14 800,00', abc_category: 'A' },
                gross_profit: { value: '9 937,39', abc_category: 'A' },
                food_cost: '32,86%',
                child_objects: [],
              },
              {
                name: 'Джин-тоник 2000-х',
                sales_number: '22',
                sales_number: { value: '22', abc_category: 'A' },
                price: '430',
                cost_price: '37,71',
                sales_cost_price: '829,67',
                revenue: { value: '9 460,00', abc_category: 'A' },
                gross_profit: { value: '8 630,33', abc_category: 'A' },
                food_cost: '8,77%',
                child_objects: [],
              },
              {
                name: 'Brown',
                sales_number: { value: '22', abc_category: 'A' },
                price: '400',
                cost_price: '60,85',
                sales_cost_price: '1 338,79',
                revenue: { value: '8 800,00', abc_category: 'A' },
                gross_profit: { value: '7 461,21', abc_category: 'A' },
                food_cost: '15,21%',
                child_objects: [],
              },
              {
                name: 'GOLDIE',
                sales_number: '4',
                sales_number: { value: '4', abc_category: 'C' },
                price: '400',
                cost_price: '84,85',
                sales_cost_price: '339,41',
                revenue: { value: '1 600,00', abc_category: 'C' },
                gross_profit: { value: '1 260,59', abc_category: 'C' },
                food_cost: '21,21%',
                child_objects: [],
              },
            ],
          },
        ],
      },
      {
        name: 'Коньяк/бренди',
        sales_number: '17',
        price: '',
        cost_price: '',
        sales_cost_price: '1 400,93',
        revenue: '4 590,00',
        gross_profit: '3 189,07',
        food_cost: '30,52%',
        items: [
          {
            name: 'Torres 10',
            sales_number: '8,5',
            sales_number: { value: '8,5', abc_category: 'B' },
            price: '290',
            cost_price: '67,43',
            sales_cost_price: '573,11',
            revenue: { value: '2 465,00', abc_category: 'B' },
            gross_profit: { value: '1 891,89', abc_category: 'B' },
            food_cost: '23,25%',
            child_objects: [],
          },
          {
            name: 'АРАРАТ АНИ',
            sales_number: '7,5',
            sales_number: { value: '7,5', abc_category: 'B' },
            price: '230',
            cost_price: '93,42',
            sales_cost_price: '700,68',
            revenue: { value: '1 725,00', abc_category: 'B' },
            gross_profit: { value: '1 024,32', abc_category: 'C' },
            food_cost: '40,62%',
            child_objects: [],
          },
          {
            name: 'Courvoisier VS',
            sales_number: { value: '1', abc_category: 'C' },
            price: '400',
            cost_price: '127,14',
            sales_cost_price: '127,14',
            revenue: { value: '400,00', abc_category: 'C' },
            gross_profit: { value: '272,86', abc_category: 'C' },
            food_cost: '31,78%',
            child_objects: [],
          },
        ],
      },
      {
        name: 'Кофе',
        sales_number: '211',
        price: '',
        cost_price: '',
        sales_cost_price: '5 860,28',
        revenue: '25 185,00',
        gross_profit: '19 324,72',
        food_cost: '23,27%',
        items: [
          {
            name: 'Капучино',
            sales_number: { value: '65', abc_category: 'A' },
            price: '115',
            cost_price: '24,57',
            sales_cost_price: '1 597,06',
            revenue: { value: '7 475,00', abc_category: 'A' },
            gross_profit: { value: '5 877,94', abc_category: 'A' },
            food_cost: '21,37%',
            child_objects: [],
          },
          {
            name: 'Латте',
            sales_number: { value: '31', abc_category: 'A' },
            price: '115',
            cost_price: '32,86',
            sales_cost_price: '1 018,52',
            revenue: { value: '3 565,00', abc_category: 'B' },
            gross_profit: { value: '2 546,48', abc_category: 'B' },
            food_cost: '28,57%',
            child_objects: [],
          },
          {
            name: 'Айс Латте',
            sales_number: { value: '17', abc_category: 'B' },
            price: '140',
            cost_price: '22,86',
            sales_cost_price: '388,55',
            revenue: { value: '2 380,00', abc_category: 'B' },
            gross_profit: { value: '1 991,45', abc_category: 'B' },
            food_cost: '16,33%',
            child_objects: [],
          },
          {
            name: 'Эспрессо',
            sales_number: { value: '25', abc_category: 'A' },
            price: '90',
            cost_price: '16,00',
            sales_cost_price: '399,98',
            revenue: { value: '2 250,00', abc_category: 'B' },
            gross_profit: { value: '1 850,02', abc_category: 'B' },
            food_cost: '17,78%',
            child_objects: [],
          },
          {
            name: 'Раф',
            sales_number: { value: '16', abc_category: 'B' },
            price: '140',
            cost_price: '42,57',
            sales_cost_price: '681,11',
            revenue: { value: '2 240,00', abc_category: 'B' },
            gross_profit: { value: '1 558,89', abc_category: 'B' },
            food_cost: '30,41%',
            child_objects: [],
          },
          {
            name: 'Американо',
            sales_number: '17',
            sales_number: { value: '17', abc_category: 'B' },
            price: '90',
            cost_price: '16,00',
            sales_cost_price: '271,99',
            revenue: { value: '1 530,00', abc_category: 'C' },
            gross_profit: { value: '1 258,01', abc_category: 'C' },
            food_cost: '17,78%',
            child_objects: [],
          },
          {
            name: 'Бамбл',
            sales_number: { value: '11', abc_category: 'B' },
            price: '145',
            cost_price: '36,00',
            sales_cost_price: '395,98',
            revenue: { value: '1 595,00', abc_category: 'C' },
            gross_profit: { value: '1 199,02', abc_category: 'C' },
            food_cost: '24,83%',
            child_objects: [],
          },
          {
            name: 'Флэт Уайт',
            sales_number: '10',
            sales_number: { value: '10', abc_category: 'B' },
            price: '145',
            cost_price: '34,57',
            sales_cost_price: '345,70',
            revenue: { value: '1 450,00', abc_category: 'C' },
            gross_profit: { value: '1 104,30', abc_category: 'C' },
            food_cost: '23,84%%',
            child_objects: [],
          },
          {
            name: 'Доппио',
            sales_number: { value: '8', abc_category: 'B' },
            price: '145',
            cost_price: '32,28',
            sales_cost_price: '258,27',
            revenue: { value: '1 160,00', abc_category: 'C' },
            gross_profit: { value: '901,73', abc_category: 'C' },
            food_cost: '22,26%',
            child_objects: [],
          },
          {
            name: 'Эспрессо-тоник',
            sales_number: { value: '7', abc_category: 'C' },
            price: '145',
            cost_price: '60,28',
            sales_cost_price: '421,98',
            revenue: { value: '1 015,00', abc_category: 'C' },
            gross_profit: { value: '593,02', abc_category: 'C' },
            food_cost: '41,57%',
            child_objects: [],
          },
          {
            name: 'Айс Капучино',
            sales_number: { value: '3', abc_category: 'C' },
            price: '145',
            cost_price: '21,71',
            sales_cost_price: '65,14',
            revenue: { value: '435', abc_category: 'C' },
            gross_profit: { value: '369,86', abc_category: 'C' },
            food_cost: '14,97%',
            child_objects: [],
          },
          {
            name: 'Лунго',
            sales_number: { value: '1', abc_category: 'C' },
            price: '90',
            cost_price: '16,00',
            sales_cost_price: '16,00',
            revenue: { value: '90,00', abc_category: 'C' },
            gross_profit: { value: '74,00', abc_category: 'C' },
            food_cost: '17,78%',
            child_objects: [],
          },
        ],
      },
      {
        name: 'Ликеры',
        sales_number: '17,5',
        price: '',
        cost_price: '',
        sales_cost_price: '1 169,51',
        revenue: '4 205,00',
        gross_profit: '3 035,49',
        food_cost: '27,81%',
        items: [
          {
            name: 'Sambuca',
            sales_number: { value: '9', abc_category: 'B' },
            price: '260',
            cost_price: '86,85',
            sales_cost_price: '781,68',
            revenue: { value: '2 340,00', abc_category: 'B' },
            gross_profit: { value: '1 558,32', abc_category: 'B' },
            food_cost: '33,40%',
            child_objects: [],
          },
          {
            name: 'Amarett, Marie Brizard',
            sales_number: { value: '5,5', abc_category: 'C' },
            price: '230',
            cost_price: '43,71',
            sales_cost_price: '240,42',
            revenue: { value: '1 265,00', abc_category: 'C' },
            gross_profit: { value: '1 024,58', abc_category: 'C' },
            food_cost: '19,01%',
            child_objects: [],
          },
          {
            name: "O'Mara's",
            sales_number: { value: '2', abc_category: 'C' },
            price: '200',
            cost_price: '45,14',
            sales_cost_price: '90,28',
            revenue: { value: '400,00', abc_category: 'C' },
            gross_profit: { value: '309,72', abc_category: 'C' },
            food_cost: '22,57%',
            child_objects: [],
          },
          {
            name: 'Malibu',
            sales_number: '1',
            sales_number: { value: '1', abc_category: 'C' },
            price: '200',
            cost_price: '57,14',
            sales_cost_price: '57,14',
            revenue: { value: '200,00', abc_category: 'C' },
            gross_profit: { value: '142,86', abc_category: 'C' },
            food_cost: '28,57%',
            child_objects: [],
          },
        ],
      },
      {
        name: 'Лимонады',
        sales_number: '224',
        price: '',
        cost_price: '',
        sales_cost_price: '10 210,06',
        revenue: '54 299,50',
        gross_profit: '44 089,44',
        food_cost: '18,80%',
        items: [
          {
            name: 'Лимонад от Шефа',
            sales_number: { value: '81', abc_category: 'A' },
            price: '260',
            cost_price: '0,00',
            sales_cost_price: '0,00',
            revenue: { value: '21 060,00', abc_category: 'A' },
            gross_profit: { value: '21 060,00', abc_category: 'A' },
            food_cost: '0,00%',
            child_objects: [],
          },
          {
            name: 'Лимонад 1л',
            sales_number: { value: '16', abc_category: 'B' },
            price: '490',
            cost_price: '0,00',
            sales_cost_price: '0,00',
            revenue: { value: '7 840,00', abc_category: 'A' },
            gross_profit: { value: '7 840,00', abc_category: 'A' },
            food_cost: '0,00%',
            child_objects: [],
          },
          {
            name: 'Фиджи',
            sales_number: { value: '50', abc_category: 'A' },
            price: '200',
            cost_price: '73,42',
            sales_cost_price: '3 671,25',
            revenue: { value: '9 999,50', abc_category: 'A' },
            gross_profit: { value: '6 328,26', abc_category: 'A' },
            food_cost: '36,71%',
            child_objects: [],
          },
          {
            name: 'Белуга',
            sales_number: { value: '53', abc_category: 'A' },
            price: '200',
            cost_price: '83,14',
            sales_cost_price: '4 406,35',
            revenue: { value: '10 600,00', abc_category: 'A' },
            gross_profit: { value: '6 193,65', abc_category: 'A' },
            food_cost: '41,57%',
            child_objects: [],
          },
          {
            name: 'Лааму',
            sales_number: { value: '24', abc_category: 'A' },
            price: '200',
            cost_price: '88,85',
            sales_cost_price: '2 132,46',
            revenue: { value: '4 800,00', abc_category: 'B' },
            gross_profit: { value: '2 667,54', abc_category: 'B' },
            food_cost: '44,43%',
            child_objects: [],
          },
        ],
        child_objects: [
          {
            name: 'Модификаторы',
            sales_number: '16',
            price: '',
            cost_price: '',
            sales_cost_price: '3 837,52',
            revenue: '0,00',
            gross_profit: '-3 837,52',
            food_cost: '100,00%',
            items: [
              {
                name: 'Фиджи',
                sales_number: { value: '9', abc_category: 'V' },
                price: '0',
                cost_price: '224,27',
                sales_cost_price: '2 018,47',
                revenue: { value: '0,00', abc_category: 'C' },
                gross_profit: { value: '-2 018,47', abc_category: 'C' },
                food_cost: '100,00%',
                child_objects: [],
              },
              {
                name: 'Белуга',
                sales_number: { value: '5', abc_category: 'C' },
                price: '0',
                cost_price: '241,99',
                sales_cost_price: '1 209,94',
                revenue: { value: '0,00', abc_category: 'C' },
                gross_profit: { value: '-1 209,94', abc_category: 'C' },
                food_cost: '100,00%',
                child_objects: [],
              },
              {
                name: 'Лааму',
                sales_number: { value: '2', abc_category: 'C' },
                price: '0',
                cost_price: '304,56',
                sales_cost_price: '609,11',
                revenue: { value: '0,00', abc_category: 'C' },
                gross_profit: { value: '-609,11', abc_category: 'C' },
                food_cost: '100,00%',
                child_objects: [],
              },
            ],
          },
        ],
      },
      {
        name: 'Настойки',
        sales_number: '39',
        price: '',
        cost_price: '',
        sales_cost_price: '6 229,40',
        revenue: '33 080,00',
        gross_profit: '26 850,60',
        food_cost: '18,83%',
        items: [
          {
            name: 'Элементы 200',
            sales_number: { value: '9', abc_category: 'B' },
            price: '660',
            cost_price: '25,71',
            sales_cost_price: '231,42',
            revenue: { value: '5 940,00', abc_category: 'A' },
            gross_profit: { value: '5 708,58', abc_category: 'A' },
            food_cost: '3,90%',
            child_objects: [],
          },
          {
            name: 'Мексиканский сет 10',
            sales_number: { value: '15', abc_category: 'B' },
            price: '515',
            cost_price: '144,56',
            sales_cost_price: '2 168,46',
            revenue: { value: '7 725,00', abc_category: 'A' },
            gross_profit: { value: '5 556,54', abc_category: 'A' },
            food_cost: '28,07%',
            child_objects: [],
          },
          {
            name: 'Элементы 400',
            sales_number: { value: '5', abc_category: 'C' },
            price: '1 115',
            cost_price: '51,43',
            sales_cost_price: '257,13',
            revenue: { value: '5 575,00', abc_category: 'A' },
            gross_profit: { value: '5 317,87', abc_category: 'A' },
            food_cost: '4,61%',
            child_objects: [],
          },
          {
            name: 'Мексиканский сет 40',
            sales_number: { value: '4', abc_category: 'C' },
            price: '1 860',
            cost_price: '578,26',
            sales_cost_price: '2 313,03',
            revenue: { value: '7 440,00', abc_category: 'A' },
            gross_profit: { value: '5 126,97', abc_category: 'A' },
            food_cost: '31,09%',
            child_objects: [],
          },
          {
            name: 'Мексиканский сет 20',
            sales_number: { value: '4', abc_category: 'C' },
            price: '970',
            cost_price: '289,13',
            sales_cost_price: '1 156,51',
            revenue: { value: '3 880,00', abc_category: 'B' },
            gross_profit: { value: '2 723,49', abc_category: 'B' },
            food_cost: '29,81%',
            child_objects: [],
          },
          {
            name: 'Элементы 400 (на выбор)',
            sales_number: { value: '2', abc_category: 'C' },
            price: '1 260',
            cost_price: '51,43',
            sales_cost_price: '102,85',
            revenue: { value: '2 520,00', abc_category: 'B' },
            gross_profit: { value: '2 417,15', abc_category: 'B' },
            food_cost: '4,08%',
            child_objects: [],
          },
        ],
      },
      {
        name: 'Пиво',
        sales_number: '259',
        price: '',
        cost_price: '',
        sales_cost_price: '19 728,44',
        revenue: '44 960,00',
        gross_profit: '25 231,56',
        food_cost: '43,88%',
        items: [
          {
            name: 'Corona Extra',
            sales_number: { value: '2', abc_category: 'C' },
            price: '260',
            cost_price: '132,56',
            sales_cost_price: '265,13',
            revenue: { value: '520,00', abc_category: 'C' },
            gross_profit: { value: '254,87', abc_category: 'C' },
            food_cost: '50,99%',
            child_objects: [],
          },
          {
            name: 'Kronenbourg 1664 Blanc',
            sales_number: { value: '48', abc_category: 'A' },
            price: '200',
            cost_price: '99,71',
            sales_cost_price: '4 786,05',
            revenue: { value: '9 600,00', abc_category: 'A' },
            gross_profit: { value: '4 813,95', abc_category: 'A' },
            food_cost: '49,85%',
            child_objects: [],
          },
          {
            name: 'Tuborg A',
            sales_number: { value: '58', abc_category: 'A' },
            price: '170',
            cost_price: '71,43',
            sales_cost_price: '4 142,65',
            revenue: { value: '9 860,00', abc_category: 'A' },
            gross_profit: { value: '5 717,35', abc_category: 'A' },
            food_cost: '42,01%',
            child_objects: [],
          },
          {
            name: 'Аливария Белое Золото',
            sales_number: { value: '89', abc_category: 'A' },
            price: '170',
            cost_price: '67,14',
            sales_cost_price: '5 975,42',
            revenue: { value: '15 130,00', abc_category: 'A' },
            gross_profit: { value: '9 154,58', abc_category: 'A' },
            food_cost: '39,49%',
            child_objects: [],
          },
          {
            name: 'Аливария Портер',
            sales_number: { value: '39', abc_category: 'A' },
            price: '170',
            cost_price: '77,14',
            sales_cost_price: '3 008,42',
            revenue: { value: '6 630,00', abc_category: 'A' },
            gross_profit: { value: '3 621,58', abc_category: 'B' },
            food_cost: '45,38%',
            child_objects: [],
          },
          {
            name: 'Балтика №0',
            sales_number: { value: '23', abc_category: 'A' },
            price: '140',
            cost_price: '67,43',
            sales_cost_price: '1 550,78',
            revenue: { value: '3 220,00', abc_category: 'B' },
            gross_profit: { value: '1 669,22', abc_category: 'B' },
            food_cost: '48,16%',
            child_objects: [],
          },
        ],
      },
      {
        name: 'Портвейн / херес	',
        sales_number: '2',
        price: '',
        cost_price: '',
        sales_cost_price: '4,48',
        revenue: '460,00',
        gross_profit: '455,52',
        food_cost: '0,97%',
        items: [
          {
            name: 'Graham’s Fine White',
            sales_number: '2',
            sales_number: { value: '2', abc_category: 'C' },
            price: '230',
            cost_price: '64,00',
            sales_cost_price: '4,48',
            revenue: { value: '460,00', abc_category: 'C' },
            gross_profit: { value: '455,52', abc_category: 'C' },
            food_cost: '0,97%',
            child_objects: [],
          },
        ],
      },
      {
        name: 'Ром',
        sales_number: '39,13',
        price: '',
        cost_price: '',
        sales_cost_price: '2 859,18',
        revenue: '10 092,50',
        gross_profit: '7 233,32',
        food_cost: '28,33%',
        items: [
          {
            name: 'Bacardi Oakheart',
            sales_number: { value: '18,13', abc_category: 'B' },
            price: '260',
            cost_price: '67,71',
            sales_cost_price: '1 227,26',
            revenue: { value: '4 712,50', abc_category: 'B' },
            gross_profit: { value: '3 485,24', abc_category: 'B' },
            food_cost: '26,04%',
            child_objects: [],
          },
          {
            name: 'Bacardi Carta Blanca',
            sales_number: { value: '12', abc_category: 'B' },
            price: '230',
            cost_price: '67,71',
            sales_cost_price: '812,53',
            revenue: { value: '2 760,00', abc_category: 'C' },
            gross_profit: { value: '1 947,47', abc_category: 'B' },
            food_cost: '29,44%',
            child_objects: [],
          },
          {
            name: 'Plantation O.F.T.D. Overproof',
            sales_number: { value: '5', abc_category: 'C' },
            price: '340',
            cost_price: '109,71',
            sales_cost_price: '548,54',
            revenue: { value: '1 700,00', abc_category: 'C' },
            gross_profit: { value: '1 151,46', abc_category: 'C' },
            food_cost: '32,27%',
            child_objects: [],
          },
          {
            name: 'Bacardi Carta Negra',
            sales_number: { value: '4', abc_category: 'C' },
            price: '230',
            cost_price: '67,71',
            sales_cost_price: '270,84',
            revenue: { value: '920,00', abc_category: 'C' },
            gross_profit: { value: '649,16', abc_category: 'C' },
            food_cost: '29,44%',
            child_objects: [],
          },
        ],
      },
      {
        name: 'Текила',
        sales_number: '20',
        price: '',
        cost_price: '',
        sales_cost_price: '3 674,10',
        revenue: '8 765,00',
        gross_profit: '5 090,90',
        food_cost: '41,92%',
        items: [
          {
            name: 'Grand Mezcal La Escondida',
            sales_number: { value: '17', abc_category: 'B' },
            price: '460',
            cost_price: '193,99',
            sales_cost_price: '3 297,84',
            revenue: { value: '7 820,00', abc_category: 'A' },
            gross_profit: { value: '4 522,16', abc_category: 'A' },
            food_cost: '42,17%',
            child_objects: [],
          },
          {
            name: 'Legenda Del Milagro Silver',
            sales_number: { value: '3', abc_category: 'C' },
            price: '315',
            cost_price: '125,42',
            sales_cost_price: '376,27',
            revenue: { value: '945,00', abc_category: 'C' },
            gross_profit: { value: '568,73', abc_category: 'C' },
            food_cost: '39,82%',
            child_objects: [],
          },
        ],
      },
      {
        name: 'Фреши',
        sales_number: '57',
        price: '',
        cost_price: '',
        sales_cost_price: '5 496,15',
        revenue: '11 400,00',
        gross_profit: '5 903,85',
        food_cost: '48,21%',
        items: [
          {
            name: 'Апельсин фреш',
            sales_number: { value: '31,5', abc_category: 'A' },
            price: '200',
            cost_price: '60,00',
            sales_cost_price: '1 889,91',
            revenue: { value: '6 300,00', abc_category: 'A' },
            gross_profit: { value: '4 410,09', abc_category: 'A' },
            food_cost: '30,00%',
            child_objects: [],
          },
          {
            name: 'Грейпрфут фреш',
            sales_number: { value: '25,5', abc_category: 'A' },
            price: '200',
            cost_price: '141,42',
            sales_cost_price: '3 606,25',
            revenue: { value: '5 100,00', abc_category: 'A' },
            gross_profit: { value: '1 493,75', abc_category: 'B' },
            food_cost: '70,71%',
            child_objects: [],
          },
        ],
      },
      {
        name: 'Cola / sprite / burn ...',
        sales_number: '508',
        price: '90',
        cost_price: '',
        sales_cost_price: '16 576,60',
        revenue: '43 810,00',
        gross_profit: '27 233,40',
        food_cost: '37,84%',
        items: [
          {
            name: 'Coca-Cola',
            sales_number: { value: '226', abc_category: 'A' },
            price: '',
            cost_price: '34,28',
            sales_cost_price: '7 748,18',
            revenue: { value: '20 340,00', abc_category: 'A' },
            gross_profit: { value: '12 591,82', abc_category: 'A' },
            food_cost: '38,09%',
            child_objects: [],
          },
          {
            name: 'Сок "Rich" Яблоко',
            sales_number: { value: '72', abc_category: 'A' },
            price: '70',
            cost_price: '19,43',
            sales_cost_price: '1 398,79',
            revenue: { value: '5 040,00', abc_category: 'A' },
            gross_profit: { value: '3 641,21', abc_category: 'B' },
            food_cost: '27,75%',
            child_objects: [],
          },
          {
            name: 'Schweppes',
            sales_number: { value: '50', abc_category: 'A' },
            price: '90',
            cost_price: '48,00',
            sales_cost_price: '2 399,88',
            revenue: { value: '4 500,00', abc_category: 'B' },
            gross_profit: { value: '2 100,12', abc_category: 'B' },
            food_cost: '53,33%',
            child_objects: [],
          },
          {
            name: 'Fanta',
            sales_number: '34',
            sales_number: { value: '34', abc_category: 'A' },
            price: '90',
            cost_price: '34,57',
            sales_cost_price: '1 175,37',
            revenue: { value: '3 060,00', abc_category: 'B' },
            gross_profit: { value: '1 884,63', abc_category: 'B' },
            food_cost: '38,41%',
            child_objects: [],
          },
          {
            name: 'Bonaqua газированная',
            sales_number: { value: '23', abc_category: 'A' },
            price: '100',
            cost_price: '34,00',
            sales_cost_price: '781,96',
            revenue: { value: '2 300,00', abc_category: 'B' },
            gross_profit: { value: '1 518,04', abc_category: 'B' },
            food_cost: '34,00%',
            child_objects: [],
          },
          {
            name: 'Coca-Cola Zero',
            sales_number: { value: '22', abc_category: 'A' },
            price: '90',
            cost_price: '33,14',
            sales_cost_price: '729,11',
            revenue: { value: '1 980,00', abc_category: 'B' },
            gross_profit: { value: '1 250,89', abc_category: 'C' },
            food_cost: '36,82%',
            child_objects: [],
          },
          {
            name: 'Сок "Rich" Вишня',
            sales_number: { value: '21', abc_category: 'A' },
            price: '70',
            cost_price: '19,43',
            sales_cost_price: '407,98',
            revenue: { value: '1 470,00', abc_category: 'C' },
            gross_profit: { value: '1 062,02', abc_category: 'C' },
            food_cost: '27,75%',
            child_objects: [],
          },
          {
            name: 'Сок "Rich" Ананас',
            sales_number: { value: '18', abc_category: 'B' },
            price: '70',
            cost_price: '18,00',
            sales_cost_price: '323,98',
            revenue: { value: '1 260,00', abc_category: 'C' },
            gross_profit: { value: '936,02', abc_category: 'C' },
            food_cost: '25,71%',
            child_objects: [],
          },
          {
            name: 'Сок "Rich" Грейпфрут',
            sales_number: { value: '14', abc_category: 'B' },
            price: '70',
            cost_price: '19,43',
            sales_cost_price: '271,99',
            revenue: { value: '980,00', abc_category: 'C' },
            gross_profit: { value: '708,01', abc_category: 'C' },
            food_cost: '27,75%',
            child_objects: [],
          },
          {
            name: 'Sprite',
            sales_number: { value: '11', abc_category: 'B' },
            price: '90',
            cost_price: '34,28',
            sales_cost_price: '377,12',
            revenue: { value: '990,00', abc_category: 'C' },
            gross_profit: { value: '612,88', abc_category: 'C' },
            food_cost: '38,09%',
            child_objects: [],
          },
          {
            name: 'Сок "Rich" Томат',
            sales_number: { value: '10', abc_category: 'B' },
            price: '70',
            cost_price: '19,43',
            sales_cost_price: '194,28',
            revenue: { value: '700,00', abc_category: 'C' },
            gross_profit: { value: '505,72', abc_category: 'C' },
            food_cost: '27,75%',
            child_objects: [],
          },
          {
            name: 'C Bull',
            sales_number: { value: '7', abc_category: 'C' },
            price: '170',
            cost_price: '109,71',
            sales_cost_price: '767,96',
            revenue: { value: '1 190,00', abc_category: 'C' },
            gross_profit: { value: '422,04', abc_category: 'C' },
            food_cost: '64,53%',
            child_objects: [],
          },
        ],
      },
    ],
  },
  //////////////////////////////////////////////////////////////////////////////////////
  {
    name: 'Бой посуды	',
    sales_number: '1',
    price: '',
    cost_price: '0,00',
    sales_cost_price: '0,00',
    revenue: '430,00',
    gross_profit: '430,00',
    food_cost: '0,00%',
    items: [
      {
        name: 'Бокал',
        sales_number: { value: '1', abc_category: 'A' },
        price: '430',
        cost_price: '0,00',
        sales_cost_price: '0,00',
        revenue: { value: '430,00', abc_category: 'A' },
        gross_profit: { value: '430,00', abc_category: 'A' },
        food_cost: '0,00%',
        child_objects: [],
      },
    ],
  },

  /////////////////////////////////////////////////////////////////////////////////////
  {
    name: 'Кальяны',
    sales_number: '390',
    price: '',
    cost_price: '',
    sales_cost_price: '61 641,49',
    revenue: '361 970,00',
    gross_profit: '300 328,51',
    food_cost: '17,03%',
    items: [
      {
        name: 'Классический кальян',
        sales_number: '182',
        sales_number: { value: '182', abc_category: 'A' },
        price: '940',
        cost_price: '159,99',
        sales_cost_price: '29 118,54',
        revenue: { value: '171 080,00', abc_category: 'A' },
        gross_profit: { value: '141 961,46', abc_category: 'A' },
        food_cost: '17,02%',
        child_objects: [],
      },
      {
        name: 'Неоклассический кальян',
        sales_number: { value: '107', abc_category: 'A' },
        price: '1200',
        cost_price: '160,56',
        sales_cost_price: '17 180,28',
        revenue: { value: '128 400,00', abc_category: 'A' },
        gross_profit: { value: '111 219,72', abc_category: 'A' },
        food_cost: '13,38%',
        child_objects: [],
      },
      {
        name: 'ПИНА КОЛАДА С ПРЯНЫМ БАНАНОМ',
        sales_number: { value: '18', abc_category: 'B' },
        price: '1460',
        cost_price: '157,14',
        sales_cost_price: '2 828,43',
        revenue: { value: '26 280,00', abc_category: 'B' },
        gross_profit: { value: '23 451,57', abc_category: 'B' },
        food_cost: '10,76%',
        child_objects: [],
      },
      {
        name: 'ПЛЯЖ БУХТЫ ХАНАУМА',
        sales_number: { value: '7', abc_category: 'B' },
        price: '1600',
        cost_price: '158,85',
        sales_cost_price: '1 111,94',
        revenue: { value: '11 200,00', abc_category: 'B' },
        gross_profit: { value: '10 088,06', abc_category: 'B' },
        food_cost: '9,93%',
        child_objects: [],
      },
      {
        name: 'ТИБЕТСКИЕ ЯГОДЫ',
        sales_number: { value: '6', abc_category: 'B' },
        price: '1600',
        cost_price: '156,85',
        sales_cost_price: '941,10',
        revenue: { value: '9 600,00', abc_category: 'B' },
        gross_profit: { value: '8 658,90', abc_category: 'B' },
        food_cost: '9,80%',
        child_objects: [],
      },
      {
        name: 'Дегустация',
        sales_number: { value: '2', abc_category: 'C' },
        price: '2000',
        cost_price: '439,12',
        sales_cost_price: '878,24',
        revenue: { value: '4 000,00', abc_category: 'B' },
        gross_profit: { value: '3 121,76', abc_category: 'B' },
        food_cost: '21,96%',
        child_objects: [],
      },
      {
        name: 'НЕФТЬ',
        sales_number: { value: '2', abc_category: 'C' },
        price: '1460',
        cost_price: '166,28',
        sales_cost_price: '332,55',
        revenue: { value: '2 920,00', abc_category: 'B' },
        gross_profit: { value: '2 587,45', abc_category: 'C' },
        food_cost: '11,39%',
        child_objects: [],
      },
      {
        name: 'Кальян для Никсона',
        sales_number: { value: '5', abc_category: 'B' },
        price: '460',
        cost_price: '0,00',
        sales_cost_price: '0,00',
        revenue: { value: '2300,00', abc_category: 'C' },
        gross_profit: { value: '2300,00', abc_category: 'C' },
        food_cost: '0,00%',
        child_objects: [],
      },
      {
        name: 'Кальян для Саши',
        sales_number: { value: '6', abc_category: 'C' },
        price: '460',
        cost_price: '149,14',
        sales_cost_price: '894,81',
        revenue: { value: '2 760,00', abc_category: 'C' },
        gross_profit: { value: '1 865,19', abc_category: 'C' },
        food_cost: '32,42%',
        child_objects: [],
      },
      {
        name: 'ТРОПИКВОЛКЕР',
        sales_number: { value: '1', abc_category: 'C' },
        price: '1915',
        cost_price: '156,85',
        sales_cost_price: '156,85',
        revenue: { value: '1 915,00', abc_category: 'C' },
        gross_profit: { value: '1 758,15', abc_category: 'C' },
        food_cost: '8,19%',
        child_objects: [],
      },
      {
        name: 'КАНЕЛЬБУЛЛАР С МЕНТОЛОВЫМ КРЕМОМ',
        sales_number: { value: '1', abc_category: 'C' },
        price: "1515",
        cost_price: '170,85',
        sales_cost_price: '170,85',
        revenue: { value: '1 515,00', abc_category: 'C' },
        gross_profit: { value: '1 344,15', abc_category: 'C' },
        food_cost: '11,28%',
        child_objects: [],
      },
      {
        name: 'Кальян для Никиты/Кирилла',
        sales_number: { value: '24', abc_category: 'B' },
        price: '0',
        cost_price: '152,56',
        sales_cost_price: '3 661,53',
        revenue: { value: '0,00', abc_category: 'C' },
        gross_profit: { value: '-3 661,53', abc_category: 'C' },
        food_cost: '100,00%',
        child_objects: [],
      },
      {
        name: 'Раскурочный кальян',
        sales_number: { value: '29', abc_category: 'A' },
        price: '0',
        cost_price: '150,56',
        sales_cost_price: '4 366,35',
        revenue: { value: '0,00', abc_category: 'C' },
        gross_profit: { value: '-4 366,35', abc_category: 'C' },
        food_cost: '100,00%',
        child_objects: [],
      },
    ],
  },
  ////////////////////////////////////////////////////////////////////////////////////

  {
    name: 'Кухня',
    sales_number: '1232',
    price: '',
    cost_price: '',
    sales_cost_price: '265 630,43',
    revenue: '1 130 830,00',
    gross_profit: '865 199,57',
    food_cost: '23,49%',
    child_objects: [
      {
        name: 'Основное меню',
        sales_number: '1232',
        price: '',
        cost_price: '',
        sales_cost_price: '133 154,77',
        revenue: '616 770,00',
        gross_profit: '483 615,23',
        food_cost: '21,59%',
        items: [
          {
            name: 'Стейк Рибай с трюфельным пюре и соусом демигляс',
            sales_number: { value: '90', abc_category: 'A' },
            price: '720',
            cost_price: '205,13',
            sales_cost_price: '18 461,93',
            revenue: { value: '64 800,00', abc_category: 'A' },
            gross_profit: { value: '46 338,07', abc_category: 'A' },
            food_cost: '28,49%',
            child_objects: [],
          },
          {
            name: 'Салат с креветкой и апельсиновым песто',
            sales_number: { value: '96', abc_category: 'A' },
            price: '540',
            cost_price: '108,57',
            sales_cost_price: '10 422,34',
            revenue: { value: '51 840,00', abc_category: 'A' },
            gross_profit: { value: '41 417,66', abc_category: 'A' },
            food_cost: '20,10%',
            child_objects: [],
          },
          {
            name: 'Карбонара с муссом пармезан',
            sales_number: { value: '82', abc_category: 'A' },
            price: '560',
            cost_price: '80,00',
            sales_cost_price: '6 559,67',
            revenue: { value: '45 920,00', abc_category: 'A' },
            gross_profit: { value: '39 360,33', abc_category: 'A' },
            food_cost: '14,29%',
            child_objects: [],
          },
          {
            name: 'Ребра BBQ с трюфельным пюре',
            sales_number: { value: '59', abc_category: 'A' },
            price: '630',
            cost_price: '223,13',
            sales_cost_price: '13 164,77',
            revenue: { value: '37 170,00', abc_category: 'A' },
            gross_profit: { value: '24 005,23', abc_category: 'A' },
            food_cost: '35,42%',
            child_objects: [],
          },
          {
            name: 'Тар-тар из говядины с муссом из пармезана и вяленым томатом',
            sales_number: { value: '60', abc_category: 'A' },
            price: '510',
            cost_price: '125,14',
            sales_cost_price: '7 508,20',
            revenue: { value: '30 600,00', abc_category: 'A' },
            gross_profit: { value: '23 091,80', abc_category: 'A' },
            food_cost: '24,54%',
            child_objects: [],
          },
          {
            name: 'Мясное ассорти',
            sales_number: { value: '29', abc_category: 'B' },
            price: '1000',
            cost_price: '204,56',
            sales_cost_price: '5 932,27',
            revenue: { value: '29 000,00', abc_category: 'A' },
            gross_profit: { value: '23 067,73', abc_category: 'A' },
            food_cost: '20,46%',
            child_objects: [],
          },
          {
            name: 'Трюфельный орзо с креветкой',
            sales_number: { value: '46', abc_category: 'A' },
            price: '600',
            cost_price: '116,57',
            sales_cost_price: '5 362,02',
            revenue: { value: '27 600,00', abc_category: 'A' },
            gross_profit: { value: '22 237,98', abc_category: 'A' },
            food_cost: '19,43%',
            child_objects: [],
          },
          {
            name: 'Салат с ростбифом, соусом Вителло и каперсом',
            sales_number: { value: '54', abc_category: 'A' },
            price: '510',
            cost_price: '115,71',
            sales_cost_price: '6 248,26',
            revenue: { value: '27 540,00', abc_category: 'A' },
            gross_profit: { value: '21 291,74', abc_category: 'A' },
            food_cost: '22,69%',
            child_objects: [],
          },
          {
            name: 'Драники с беконом и луковым муссом',
            sales_number: { value: '59', abc_category: 'A' },
            price: '460',
            cost_price: '109,99',
            sales_cost_price: '6 489,68',
            revenue: { value: '27 140,00', abc_category: 'A' },
            gross_profit: { value: '20 650,32', abc_category: 'A' },
            food_cost: '23,91%',
            child_objects: [],
          },
          {
            name: 'Стейк лосось с морковным орзо, апельсином и бадьяном',
            sales_number: { value: '41', abc_category: 'B' },
            price: '710',
            cost_price: '221,42',
            sales_cost_price: '9 078,12',
            revenue: { value: '29 110,00', abc_category: 'A' },
            gross_profit: { value: '20 031,88', abc_category: 'A' },
            food_cost: '31,19%',
            child_objects: [],
          },
          {
            name: 'Брауни с шоколадным муссом, вишней и соленым ирисом',
            sales_number: { value: '72', abc_category: 'A' },
            price: '430',
            cost_price: '83,14',
            sales_cost_price: '5 985,99',
            revenue: { value: '30 960,00', abc_category: 'A' },
            gross_profit: { value: '24 974,01', abc_category: 'A' },
            food_cost: '19,33%',
            child_objects: [],
          },
          {
            name: 'Ассорти брускетт | 3 шт',
            sales_number: { value: '31', abc_category: 'B' },
            price: '600',
            cost_price: '0,00',
            sales_cost_price: '0,00',
            revenue: { value: '18 600,00', abc_category: 'A' },
            gross_profit: { value: '18 600,00', abc_category: 'A' },
            food_cost: '0,00%',
            child_objects: [],
          },
          {
            name: 'Мусс из белого шоколада с гречневым попкорном и манго',
            sales_number: { value: '72', abc_category: 'A' },
            price: '310',
            cost_price: '66,00',
            sales_cost_price: '4 751,76',
            revenue: { value: '22 320,00', abc_category: 'A' },
            gross_profit: { value: '17 568,24', abc_category: 'A' },
            food_cost: '21,29%',
            child_objects: [],
          },
          {
            name: 'Ассорти брускетт | 6 шт',
            sales_number: { value: '16', abc_category: 'C' },
            price: '1110',
            cost_price: '0,00',
            sales_cost_price: '0,00',
            revenue: { value: '17 760,00', abc_category: 'A' },
            gross_profit: { value: '17 760,00', abc_category: 'A' },
            food_cost: '0,00%',
            child_objects: [],
          },
          {
            name: 'Бургер с рваной свининой и луковым конфитюром',
            sales_number: { value: '37', abc_category: 'B' },
            price: '510',
            cost_price: '104,85',
            sales_cost_price: '3 879,52',
            revenue: { value: '1 8870,00', abc_category: 'A' },
            gross_profit: { value: '14 990,48', abc_category: 'A' },
            food_cost: '20,56%',
            child_objects: [],
          },
          {
            name: 'Ассорти сыров',
            sales_number: { value: '22', abc_category: 'B' },
            price: '1110',
            cost_price: '323,70',
            sales_cost_price: '7 121,36',
            revenue: { value: '22 000,00', abc_category: 'A' },
            gross_profit: { value: '14 878,64', abc_category: 'A' },
            food_cost: '32,37%',
            child_objects: [],
          },
          {
            name: 'Паста с прошутто, манго и горгонзолой',
            sales_number: { value: '35', abc_category: 'B' },
            price: '490',
            cost_price: '75,71',
            sales_cost_price: '2 649,87',
            revenue: { value: '17 150,00', abc_category: 'A' },
            gross_profit: { value: '14 500,13', abc_category: 'A' },
            food_cost: '15,45%',
            child_objects: [],
          },
          {
            name: 'Кокосовые сырники с шоколадом, манго и апельсиновой карамелью',
            sales_number: { value: '46', abc_category: 'A' },
            price: '340',
            cost_price: '38,57',
            sales_cost_price: '1 774,20',
            revenue: { value: '15 640,00', abc_category: 'A' },
            gross_profit: { value: '13 865,80', abc_category: 'A' },
            food_cost: '11,34%',
            child_objects: [],
          },
          {
            name: 'Крем-суп из шампиньонов с трюфельной сметаной',
            sales_number: { value: '60', abc_category: 'A' },
            price: '260',
            cost_price: '65,14',
            sales_cost_price: '3 908,38',
            revenue: '15 600,00',
            gross_profit: '11 691,62',
            revenue: { value: '1 150,00', abc_category: 'B' },
            gross_profit: { value: '730,02', abc_category: 'B' },
            food_cost: '25,05%',
            child_objects: [],
          },
          {
            name: 'Бургер с говяжьей котлетой и соусом BBQ',
            sales_number: { value: '20', abc_category: 'C' },
            price: '630',
            cost_price: '129,42',
            sales_cost_price: '2 588,44',
            revenue: { value: '12 600,00', abc_category: 'B' },
            gross_profit: { value: '10 011,56', abc_category: 'B' },
            food_cost: '20,54%',
            child_objects: [],
          },
          {
            name: 'Домашний хлеб с ароматным маслом',
            sales_number: { value: '95', abc_category: 'A' },
            price: '140',
            cost_price: '46,28',
            sales_cost_price: '4 396,92',
            revenue: { value: '13 300,00', abc_category: 'B' },
            gross_profit: { value: '8 903,08', abc_category: 'B' },
            food_cost: '33,06%',
            child_objects: [],
          },
          {
            name: 'Карпаччо из говядины с соусом Вителло и вяленым томатом',
            sales_number: { value: '21', abc_category: 'C' },
            price: '460',
            cost_price: '40,57',
            sales_cost_price: '851,96',
            revenue: { value: '9 660,00', abc_category: 'B' },
            gross_profit: { value: '8 808,04', abc_category: 'B' },
            food_cost: '8,82%',
            child_objects: [],
          },
          {
            name: 'Тар-тар из лосося с кунжутным муссом',
            sales_number: { value: '19', abc_category: 'C' },
            price: '600',
            cost_price: '139,99',
            sales_cost_price: '2 659,87',
            revenue: { value: '11 400,00', abc_category: 'B' },
            gross_profit: { value: '8 740,13', abc_category: 'B' },
            food_cost: '23,33%',
            child_objects: [],
          },
          {
            name: 'Хумус с вяленным томатом',
            sales_number: { value: '42', abc_category: 'A' },
            price: '200',
            cost_price: '37,43',
            sales_cost_price: '1 571,92',
            revenue: { value: '8 400,00', abc_category: 'B' },
            gross_profit: { value: '6 828,08', abc_category: 'B' },
            food_cost: '18,71%',
            child_objects: [],
          },
          {
            name: 'Бургер с курицей терияки и пюре морковь-апельсин',
            sales_number: { value: '9', abc_category: 'C' },
            price: '630',
            cost_price: '47,14',
            sales_cost_price: '424,26',
            revenue: { value: '5 670,00', abc_category: 'C' },
            gross_profit: { value: '5 245,74', abc_category: 'C' },
            food_cost: '7,48%',
            child_objects: [],
          },
          {
            name: "It's TACO time",
            sales_number: { value: '7', abc_category: 'C' },
            price: '400',
            cost_price: '84,00',
            sales_cost_price: '587,97',
            revenue: { value: '2 800,00', abc_category: 'C' },
            gross_profit: { value: '2 212,03', abc_category: 'C' },
            food_cost: '21,00%',
            child_objects: [],
          },
          {
            name: 'Крокеты с прошутто, горгонзолой и мускатным орехом',
            sales_number: { value: '7', abc_category: 'C' },
            price: '310',
            cost_price: '62,57',
            sales_cost_price: '437,98',
            revenue: { value: '2 170,00', abc_category: 'C' },
            gross_profit: { value: '1 732,02', abc_category: 'C' },
            food_cost: '20,18%',
            child_objects: [],
          },
          {
            name: 'Картофельный крем-суп с луковым конфитюром и языком',
            sales_number: { value: '5', abc_category: 'C' },
            price: '230',
            cost_price: '67,43',
            sales_cost_price: '337,13',
            revenue: { value: '1 150,00', abc_category: 'C' },
            gross_profit: { value: '812,87', abc_category: 'C' },
            food_cost: '29,32%',
            child_objects: [],
          },
        ],

        child_objects: [
          {
            name: 'Модификаторы',
            sales_number: '47',
            price: '',
            cost_price: '',
            sales_cost_price: '2 523,87',
            revenue: '0,00',
            gross_profit: '-2 523,87',
            food_cost: '100,00%',
            items: [
              {
                name: 'Ростбиф',
                sales_number: { value: '30', abc_category: 'B' },
                price: '0',
                cost_price: '52,57',
                sales_cost_price: '1 577,06',
                revenue: { value: '0,00', abc_category: 'C' },
                gross_profit: { value: '-1 577,06', abc_category: 'C' },
                food_cost: '100,00%',
                child_objects: [],
              },
              {
                name: 'Горгонзола',
                sales_number: { value: '8', abc_category: 'C' },
                price: '0',
                cost_price: '71,43',
                sales_cost_price: '571,40',
                revenue: { value: '0,00', abc_category: 'C' },
                gross_profit: { value: '-571,40', abc_category: 'C' },
                food_cost: '100,00%',
                child_objects: [],
              },
              {
                name: 'Вяленый томат',
                sales_number: { value: '9', abc_category: 'C' },
                price: '0',
                cost_price: '41,71',
                sales_cost_price: '375,41',
                revenue: { value: '0,00', abc_category: 'C' },
                gross_profit: { value: '-375,41', abc_category: 'C' },
                food_cost: '100,00%',
                child_objects: [],
              },
            ],
          },
        ],
      },
      {
        name: 'Роллы',
        sales_number: '736',
        price: '',
        cost_price: '',
        sales_cost_price: '110 617,61',
        revenue: '424 770,00',
        gross_profit: '314 152,39',
        food_cost: '26,04%',
        items: [
          {
            name: 'Ролл с тигровой креветкой, лососем и свежим томатом',
            sales_number: { value: '155', abc_category: 'A' },
            price: '570',
            cost_price: '173,42',
            sales_cost_price: '26 880,08',
            revenue: { value: '88 350,00', abc_category: 'A' },
            gross_profit: { value: '61 469,92', abc_category: 'A' },
            food_cost: '30,42%',
            child_objects: [],
          },
          {
            name: 'Филадельфия с авокадо',
            sales_number: { value: '145', abc_category: 'A' },
            price: '610',
            cost_price: '187,13',
            sales_cost_price: '27 134,36',
            revenue: { value: '88 450,00', abc_category: 'A' },
            gross_profit: { value: '61 315,64', abc_category: 'A' },
            food_cost: '30,68%',
            child_objects: [],
          },
          {
            name: 'Ролл с сыром Чеддер, копченым лососем и свежим томатом',
            sales_number: { value: '92', abc_category: 'A' },
            price: '490',
            cost_price: '124,57',
            sales_cost_price: '11 460,00',
            revenue: { value: '45 080,00', abc_category: 'A' },
            gross_profit: { value: '33 620,00', abc_category: 'A' },
            food_cost: '25,42%',
            child_objects: [],
          },
          {
            name: 'Калифорния с креветкой, авокадо и огурцом',
            sales_number: { value: '75', abc_category: 'A' },
            price: '490',
            cost_price: '106,85',
            sales_cost_price: '8 013,89',
            revenue: { value: '36 750,00', abc_category: 'A' },
            gross_profit: { value: '28 736,12', abc_category: 'A' },
            food_cost: '21,81%',
            child_objects: [],
          },
          {
            name: 'Ролл с угрем унаги, авокадо и огурцом',
            sales_number: { value: '64', abc_category: 'A' },
            price: '540',
            cost_price: '124,57',
            sales_cost_price: '7 972,17',
            revenue: { value: '34 560,00', abc_category: 'A' },
            gross_profit: { value: '26 587,83', abc_category: 'A' },
            food_cost: '23,07%',
            child_objects: [],
          },
          {
            name: 'Ролл с чукой и креветкой темпура',
            sales_number: { value: '78', abc_category: 'A' },
            price: '430',
            cost_price: '104,28',
            sales_cost_price: '8 133,88',
            revenue: { value: '33 540,00', abc_category: 'A' },
            gross_profit: { value: '25 406,12', abc_category: 'A' },
            food_cost: '24,25%',
            child_objects: [],
          },
          {
            name: 'Бонито с копченным лососем и огурцом',
            sales_number: { value: '78', abc_category: 'A' },
            price: '460',
            cost_price: '133,99',
            sales_cost_price: '10 451,48',
            revenue: { value: '35 880,00', abc_category: 'A' },
            gross_profit: { value: '25 428,52', abc_category: 'A' },
            food_cost: '29,13%',
            child_objects: [],
          },
          {
            name: 'Ролл в огурце с курицей терияки и блинчиком Тамаго',
            sales_number: { value: '49', abc_category: 'A' },
            price: '400',
            cost_price: '82,28',
            sales_cost_price: '4 031,80',
            revenue: { value: '19 600,00', abc_category: 'A' },
            gross_profit: { value: '15 568,20', abc_category: 'A' },
            food_cost: '20,57%',
            child_objects: [],
          },
        ],
        child_objects: [
          {
            name: 'Гарниры',
            sales_number: '783',
            price: '',
            cost_price: '',
            sales_cost_price: '6 539,96',
            revenue: '42 560,00',
            gross_profit: '36 020,04',
            food_cost: '15,37%',
            items: [
              {
                name: 'Соевый соус',
                sales_number: { value: '325', abc_category: 'A' },
                price: '40',
                cost_price: '7,14',
                sales_cost_price: '2 321,31',
                revenue: { value: '13 000,00', abc_category: 'B' },
                gross_profit: { value: '10 678,69', abc_category: 'B' },
                food_cost: '17,86%',
                child_objects: [],
              },
              {
                name: 'Вместе: соевый, имбирь, васаби',
                sales_number: { value: '106', abc_category: 'A' },
                price: '110',
                cost_price: '15,14',
                sales_cost_price: '1 605,06',
                revenue: { value: '11 660,00', abc_category: 'B' },
                gross_profit: { value: '10 054,94', abc_category: 'B' },
                food_cost: '13,77%',
                child_objects: [],
              },
              {
                name: 'Васаби мусс',
                sales_number: { value: '85', abc_category: 'A' },
                price: '60',
                cost_price: '8,29',
                sales_cost_price: '704,25',
                revenue: { value: '5 100,00', abc_category: 'C' },
                gross_profit: { value: '4 395,75', abc_category: 'C' },
                food_cost: '13,81%',
                child_objects: [],
              },
              {
                name: 'Имбирь',
                sales_number: { value: '101', abc_category: 'A' },
                price: '40',
                cost_price: '4,29',
                sales_cost_price: '432,84',
                revenue: { value: '4 040,00', abc_category: 'C' },
                gross_profit: { value: '3 607,16', abc_category: 'C' },
                food_cost: '10,71%',
                child_objects: [],
              },
              {
                name: 'Кунжутный-орехово соус',
                sales_number: { value: '84', abc_category: 'A' },
                price: '60',
                cost_price: '12,29',
                sales_cost_price: '1 031,95',
                revenue: { value: '5 040,00', abc_category: 'C' },
                gross_profit: { value: '4 008,05', abc_category: 'C' },
                food_cost: '20,48%',
                child_objects: [],
              },
              {
                name: 'Васаби',
                sales_number: { value: '60', abc_category: 'A' },
                price: '40',
                cost_price: '3,43',
                sales_cost_price: '205,70',
                revenue: { value: '2 400,00', abc_category: 'C' },
                gross_profit: { value: '2 194,30', abc_category: 'C' },
                food_cost: '8,57%',
                child_objects: [],
              },
              {
                name: 'Унаги',
                sales_number: { value: '11', abc_category: 'C' },
                price: '60',
                cost_price: '9,71',
                sales_cost_price: '106,85',
                revenue: { value: '660,00', abc_category: 'C' },
                gross_profit: { value: '553,15', abc_category: 'C' },
                food_cost: '16,19%',
                child_objects: [],
              },
              {
                name: 'Терияки',
                sales_number: { value: '11', abc_category: 'C' },
                price: '60',
                cost_price: '12,00',
                sales_cost_price: '131,99',
                revenue: { value: '660,00', abc_category: 'C' },
                gross_profit: { value: '528,01', abc_category: 'C' },
                food_cost: '20,00%',
                child_objects: [],
              },
            ],
          },
        ],
      },

      {
        name: 'Завтраки',
        sales_number: '262',
        price: '',
        cost_price: '',
        sales_cost_price: '19 334,18',
        revenue: '89 290,00',
        gross_profit: '69 955,82',
        food_cost: '21,65%',
        items: [
          {
            name: 'Завтрак по-домашнему SS2023',
            sales_number: { value: '41', abc_category: 'B' },
            price: '370',
            cost_price: '68,28',
            sales_cost_price: '2 799,57',
            revenue: { value: '15 170,00', abc_category: 'B' },
            gross_profit: { value: '12 370,43', abc_category: 'B' },
            food_cost: '18,45%',
            child_objects: [],
          },
          {
            name: 'Клаб-сэндвич с курицей и овощами SS2023',
            sales_number: { value: '30', abc_category: 'B' },
            price: '460',
            cost_price: '112,28',
            sales_cost_price: '3 368,40',
            revenue: { value: '13 800,00', abc_category: 'B' },
            gross_profit: { value: '10 431,60', abc_category: 'B' },
            food_cost: '24,41%',
            child_objects: [],
          },
          {
            name: 'Филадельфия бекон для завтрака SS2023',
            sales_number: { value: '38', abc_category: 'B' },
            price: '310',
            cost_price: '64,00',
            sales_cost_price: '2 431,88',
            revenue: { value: '11 780,00', abc_category: 'B' },
            gross_profit: { value: '9 348,12', abc_category: 'B' },
            food_cost: '20,64%',
            child_objects: [],
          },
          {
            name: 'Блинчики с курицей и грибами SS2023',
            sales_number: { value: '39', abc_category: 'B' },
            price: '310',
            cost_price: '70,85',
            sales_cost_price: '2 763,29',
            revenue: { value: '12 090,00', abc_category: 'B' },
            gross_profit: { value: '9 326,71', abc_category: 'B' },
            food_cost: '22,86%',
            child_objects: [],
          },
          {
            name: 'Блинчики с лососем SS2023',
            sales_number: { value: '25', abc_category: 'B' },
            price: '490',
            cost_price: '145,14',
            sales_cost_price: '3 628,39',
            revenue: { value: '12 250,00', abc_category: 'B' },
            gross_profit: { value: '8 621,61', abc_category: 'B' },
            food_cost: '29,62%',
            child_objects: [],
          },
          {
            name: 'Американский бургер-завтрак SS2023',
            sales_number: { value: '17', abc_category: 'C' },
            price: '430',
            cost_price: '72,57',
            sales_cost_price: '1 233,65',
            revenue: { value: '7 310,00', abc_category: 'C' },
            gross_profit: { value: '6 076,35', abc_category: 'C' },
            food_cost: '16,88%',
            child_objects: [],
          },
          {
            name: 'Скрэмбл с грибами SS2023',
            sales_number: { value: '28', abc_category: 'B' },
            price: '260',
            cost_price: '59,43',
            sales_cost_price: '1 663,92',
            revenue: { value: '7 280,00', abc_category: 'C' },
            gross_profit: { value: '5 616,08', abc_category: 'C' },
            food_cost: '22,86%',
            child_objects: [],
          },
          {
            name: 'Блинчики сюзетт в соленой карамели',
            sales_number: { value: '27', abc_category: 'B' },
            price: '230',
            cost_price: '40,57',
            sales_cost_price: '1 095,37',
            revenue: { value: '6 210,00', abc_category: 'C' },
            gross_profit: { value: '5 114,63', abc_category: 'C' },
            food_cost: '17,64%',
            child_objects: [],
          },
          {
            name: 'Блинчики сюзетт в апельсиновой карамели SS2023',
            sales_number: { value: '17', abc_category: 'C' },
            price: '200',
            cost_price: '20,57',
            sales_cost_price: '349,70',
            revenue: { value: '3 400,00', abc_category: 'C' },
            gross_profit: { value: '3 050,30', abc_category: 'C' },
            food_cost: '10,29%',
            child_objects: [],
          },
        ],
      },
    ],
  },
  ////////////////////////////////////////////////////////////////////////////////////
  {
    name: 'Программа',
    sales_number: '307',
    price: '',
    cost_price: '',
    sales_cost_price: '0,00',
    revenue: '98 180,00',
    gross_profit: '98 180,00',
    food_cost: '0,00%',
    items: [
      {
        name: 'MASQUERADE | Без маски',
        sales_number: { value: '185', abc_category: 'A' },
        price: '290',
        cost_price: '0,00',
        sales_cost_price: '0,00',
        revenue: { value: '53 650,00', abc_category: 'A' },
        gross_profit: { value: '53 650,00', abc_category: 'A' },
        food_cost: '0,00%',
        child_objects: [],
      },
      {
        name: 'Платный вход',
        sales_number: { value: '92', abc_category: 'A' },
        price: '430',
        cost_price: '0,00',
        sales_cost_price: '0,00',
        revenue: { value: '39 560,00', abc_category: 'A' },
        gross_profit: { value: '39 560,00', abc_category: 'A' },
        food_cost: '0,00%',
        child_objects: [],
      },
      {
        name: 'Программа Caviar для именинника',
        sales_number: { value: '19', abc_category: 'C' },
        price: '140',
        cost_price: '0,00',
        sales_cost_price: '0,00',
        revenue: { value: '2 660,00', abc_category: 'C' },
        gross_profit: { value: '2 660,00', abc_category: 'C' },
        food_cost: '0,00%',
        child_objects: [],
      },
      {
        name: 'CAVIAR EVENING | День рождения',
        sales_number: { value: '11', abc_category: 'C' },
        price: '210',
        cost_price: '0,00',
        sales_cost_price: '0,00',
        revenue: { value: '2 310,00', abc_category: 'C' },
        gross_profit: { value: '2 310,00', abc_category: 'C' },
        food_cost: '0,00%',
        child_objects: [],
      },
    ],
  },

  /////////////////////////////////////////////////////////////////////////////////////

  {
    name: 'Чайная карта',
    sales_number: '172',
    price: '',
    cost_price: '0',
    sales_cost_price: '5 284,31',
    revenue: '46 530,00',
    gross_profit: '41 245,69',
    food_cost: '11,36%',
    child_objects: [
      {
        name: 'Индийский чай',
        sales_number: '15',
        price: '260',
        cost_price: '0',
        sales_cost_price: '270,84',
        revenue: '3 810,00',
        gross_profit: '3 539,16',
        food_cost: '7,11%',
        items: [
          {
            name: 'Эрл Грей',
            sales_number: { value: '12', abc_category: 'A' },
            price: '260',
            cost_price: '19,71',
            sales_cost_price: '236,56',
            revenue: { value: '3 120,00', abc_category: 'B' },
            gross_profit: { value: '2 883,44', abc_category: 'B' },
            food_cost: '7,58%',
            child_objects: [],
          },
          {
            name: 'Ассам',
            sales_number: { value: '3', abc_category: 'C' },
            price: '230',
            cost_price: '11,43',
            sales_cost_price: '34,28',
            revenue: { value: '690,00', abc_category: 'C' },
            gross_profit: { value: '655,72', abc_category: 'C' },
            food_cost: '4,97%',
            child_objects: [],
          },
        ],
      },
      {
        name: 'Китайский чай',
        sales_number: '84',
        price: '',
        cost_price: '0',
        sales_cost_price: '4 007,80',
        revenue: '23 590,00',
        gross_profit: '19 582,20',
        food_cost: '16,99%',
        items: [
          {
            name: 'Те Гуань Инь',
            sales_number: '28',
            sales_number: { value: '28', abc_category: 'A' },
            price: '240',
            cost_price: '43,71',
            sales_cost_price: '1 223,94',
            revenue: { value: '6 720,00', abc_category: 'A' },
            gross_profit: { value: '5 496,06', abc_category: 'A' },
            food_cost: '18,21%',
            child_objects: [],
          },
          {
            name: 'Лунцзин «Колодец Дракона»',
            sales_number: { value: '15', abc_category: 'A' },
            price: '240',
            cost_price: '42,57',
            sales_cost_price: '638,54',
            revenue: { value: '3 600,00', abc_category: 'A' },
            gross_profit: { value: '2 961,46', abc_category: 'A' },
            food_cost: '17,74%',
            child_objects: [],
          },
          {
            name: 'Лао Ча Тоу Юньнань Линцан 2012 г',
            sales_number: { value: '15', abc_category: 'A' },
            price: '310',
            cost_price: '66',
            sales_cost_price: '989,95',
            revenue: { value: '4 650,00', abc_category: 'A' },
            gross_profit: { value: '3 660,05', abc_category: 'A' },
            food_cost: '21,29%',
            child_objects: [],
          },
          {
            name: 'Да Хун Пао',
            sales_number: '13',
            sales_number: { value: '13', abc_category: 'A' },
            price: '310',
            cost_price: '54',
            sales_cost_price: '701,96',
            revenue: { value: '4 030,00', abc_category: 'A' },
            gross_profit: { value: '3 328,04', abc_category: 'A' },
            food_cost: '17,42%',
            child_objects: [],
          },
          {
            name: 'Люй Я Бао',
            sales_number: '10',
            sales_number: { value: '10', abc_category: 'B' },
            price: '370',
            cost_price: '40',
            sales_cost_price: '399,98',
            revenue: { value: '3 700,00', abc_category: 'A' },
            gross_profit: { value: '3 300,02', abc_category: 'A' },
            food_cost: '10,81%',
            child_objects: [],
          },
          {
            name: 'Моли Хуа Ча',
            sales_number: '2',
            sales_number: { value: '2', abc_category: 'C' },
            price: '290',
            cost_price: '14,86',
            sales_cost_price: '29,71',
            revenue: { value: '580,00', abc_category: 'C' },
            gross_profit: { value: '550,29', abc_category: 'C' },
            food_cost: '5,12%',
            child_objects: [],
          },
          {
            name: 'Женьшень Улун',
            sales_number: { value: '1', abc_category: 'C' },
            price: '310',
            cost_price: '23,71',
            sales_cost_price: '23,71',
            revenue: { value: '310,00', abc_category: 'C' },
            gross_profit: { value: '286,29', abc_category: 'C' },
            food_cost: '7,65%',
            child_objects: [],
          },
        ],
      },
      {
        name: 'Чайные напитки',
        sales_number: '73',
        price: '',
        cost_price: '0',
        sales_cost_price: '1 005,66',
        revenue: '19 130,00',
        gross_profit: '18 124,34',
        food_cost: '5,26%',
        items: [
          {
            name: 'Манго Улун',
            sales_number: { value: '19', abc_category: 'A' },
            price: '290',
            cost_price: '12,57',
            sales_cost_price: '238,85',
            revenue: { value: '5 510,00', abc_category: 'A' },
            gross_profit: { value: '5 271,15', abc_category: 'A' },
            food_cost: '4,33%',
            child_objects: [],
          },
          {
            name: 'Персик Улун',
            sales_number: { value: '14', abc_category: 'A' },
            price: '290',
            cost_price: '16,86',
            sales_cost_price: '235,99',
            revenue: { value: '4 060,00', abc_category: 'A' },
            gross_profit: { value: '3 824,01', abc_category: 'A' },
            food_cost: '5,81%',
            child_objects: [],
          },
          {
            name: 'Сибирский Иван-Чай',
            sales_number: { value: '17', abc_category: 'A' },
            price: '230',
            cost_price: '13,14',
            sales_cost_price: '223,42',
            revenue: { value: '3 910,00', abc_category: 'A' },
            gross_profit: { value: '3 686,58', abc_category: 'A' },
            food_cost: '5,71%',
            child_objects: [],
          },
          {
            name: 'Молочный Улун',
            sales_number: { value: '12', abc_category: 'B' },
            price: '260',
            cost_price: '21,43',
            sales_cost_price: '257,13',
            revenue: { value: '3 120,00', abc_category: 'B' },
            gross_profit: { value: '2 862,87', abc_category: 'B' },
            food_cost: '8,24%',
            child_objects: [],
          },
          {
            name: 'Гречишный',
            sales_number: { value: '11', abc_category: 'B' },
            price: '230',
            cost_price: '4,57',
            sales_cost_price: '50,28',
            revenue: { value: '2 530,00', abc_category: 'B' },
            gross_profit: { value: '2 479,72', abc_category: 'B' },
            food_cost: '1,99%',
            child_objects: [],
          },
        ],
      },
    ],
  },
  ///////////////////////////////////////////////////////////////////////
  {
    name: 'STUFF ONLY',
    sales_number: '124',
    price: '',
    cost_price: '',
    sales_cost_price: '3 536,39',
    revenue: '4 990,00',
    gross_profit: '1 453,61',
    food_cost: '70,87%',
    items: [
      {
        name: 'Филадельфия бекон для завтрака STUFF',
        sales_number: { value: '6', abc_category: 'B' },
        price: '140',
        cost_price: '65,14',
        sales_cost_price: '390,84',
        revenue: { value: '840,00', abc_category: 'A' },
        gross_profit: { value: '449,16', abc_category: 'A' },
        food_cost: '46,53%',
        child_objects: [],
      },
      {
        name: 'Блинчики с курицей и грибами STUFF',
        sales_number: { value: '3', abc_category: 'B' },
        price: '170',
        cost_price: '73,14',
        sales_cost_price: '219,42',
        revenue: { value: '510,00', abc_category: 'A' },
        gross_profit: { value: '290,58', abc_category: 'A' },
        food_cost: '43,02%',
        child_objects: [],
      },
      {
        name: 'Скрэмбл с грибами STUFF',
        sales_number: { value: '3', abc_category: 'B' },
        price: '140',
        cost_price: '63,14',
        sales_cost_price: '189,42',
        revenue: { value: '420,00', abc_category: 'A' },
        gross_profit: { value: '230,58', abc_category: 'A' },
        food_cost: '45,10%',
        child_objects: [],
      },
      {
        name: 'Эспрессо стаф',
        sales_number: { value: '28', abc_category: 'A' },
        price: '20',
        cost_price: '16,00',
        sales_cost_price: '447,98',
        revenue: { value: '560,00', abc_category: 'A' },
        gross_profit: { value: '112,02', abc_category: 'A' },
        food_cost: '80,00%',
        child_objects: [],
      },
      {
        name: 'Клаб-сэндвич с курицей и овощами STUFF',
        sales_number: { value: '1', abc_category: 'B' },
        price: '230',
        cost_price: '109,99',
        sales_cost_price: '109,99',
        revenue: '230,00',
        gross_profit: '120,01',
        revenue: { value: '230,00', abc_category: 'B' },
        gross_profit: { value: '120,01', abc_category: 'A' },
        food_cost: '47,82%',
        child_objects: [],
      },
      {
        name: 'Капучино стаф',
        sales_number: { value: '29', abc_category: 'A' },
        price: '30',
        cost_price: '24,57',
        sales_cost_price: '712,54',
        revenue: { value: '870,00', abc_category: 'A' },
        gross_profit: { value: '157,46', abc_category: 'B' },
        food_cost: '81,90%',
        child_objects: [],
      },
      {
        name: 'Завтрак по-домашнему STUFF',
        sales_number: { value: '1', abc_category: 'C' },
        price: '170',
        cost_price: '66,57',
        sales_cost_price: '66,57',
        revenue: { value: '170,00', abc_category: 'B' },
        gross_profit: { value: '103,43', abc_category: 'B' },
        food_cost: '39,16%',
        child_objects: [],
      },
      {
        name: 'Американо стаф',
        sales_number: '11',
        sales_number: { value: '11', abc_category: 'A' },
        price: '20',
        cost_price: '16,00',
        sales_cost_price: '175,99',
        revenue: { value: '220,00', abc_category: 'B' },
        gross_profit: { value: '44,01', abc_category: 'C' },
        food_cost: '80,00%',
        child_objects: [],
      },
      {
        name: 'Латте стаф',
        sales_number: { value: '20', abc_category: 'A' },
        price: '30',
        cost_price: '32,86',
        sales_cost_price: '657,11',
        revenue: { value: '600,00', abc_category: 'A' },
        gross_profit: { value: '-57,11', abc_category: 'C' },
        food_cost: '109,52%',
        child_objects: [],
      },
      {
        name: 'Раф стаф',
        sales_number: { value: '2', abc_category: 'C' },
        price: '60',
        cost_price: '51,14',
        sales_cost_price: '102,28',
        revenue: { value: '120,00', abc_category: 'C' },
        gross_profit: { value: '17,72', abc_category: 'C' },
        food_cost: '85,23%',
        child_objects: [],
      },
      {
        name: 'Сиропчик',
        sales_number: { value: '15', abc_category: 'A' },
        price: '20',
        cost_price: '19,43',
        sales_cost_price: '291,41',
        revenue: { value: '300,00', abc_category: 'C' },
        gross_profit: { value: '8,59', abc_category: 'C' },
        food_cost: '97,14%',
        child_objects: [],
      },
      {
        name: 'Флэт уайт стаф',
        sales_number: { value: '5', abc_category: 'B' },
        price: '30',
        cost_price: '34,57',
        sales_cost_price: '172,85',
        revenue: { value: '150,00', abc_category: 'B' },
        gross_profit: { value: '-22,85', abc_category: 'C' },
        food_cost: '115,23%',
        child_objects: [],
      },
    ],
  },
];
