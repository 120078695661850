import React, { useState, useEffect } from 'react';
import { SettingsDefaultCompanyLogo } from '../../../../icons/settings/settings-default-company';
import './ProfileInfo.scss';

export const ProfileInfo = ({ avatar, address, name, companyPhoto }) => {
  const [previewImg, setPreviewImg] = useState('');

  useEffect(() => {
    if (avatar instanceof File) {
      setPreviewImg(URL.createObjectURL(avatar));
    }
  }, [avatar]);

  return (
    <div className="profile-info__container">
      <div className="profile-info__photo">
        { companyPhoto ? (
          <img src={companyPhoto} width={80} height={80} alt="123" className="profile-info__img" />
        ) : (
          <SettingsDefaultCompanyLogo />
        )}
      </div>
      <div className="profile-info__text">
        <div className="profile-info__name">{name}</div>
        <div className="profile-info__adress">{address}</div>
      </div>
    </div>
  );
};
