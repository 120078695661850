import { useState } from 'react';
import { useCallback } from 'react';
import { ChecqueCard } from './ChecqueCard/ChecqueCard';
import { ModalCheque } from './ModalCheque/ModalCheque';
import './Checque.scss';
import { ThreeDotsIcon } from '../../../icons/three-dots-icon';
import { Button } from '../../common/Button/Button';
import { ArrowRightIcon } from '../../../icons/reports/day-result/arrow-right-icon';

export const Checque = ({ isHoveredCheque, data }) => {
  const [isSalesModal, isSetSalesModal] = useState(false);
  const onCloseModal = useCallback(() => {
    isSetSalesModal(false);
  }, []);

  const onOpenModal = useCallback(() => {
    isSetSalesModal(true);
  }, []);

  return (
    <div>
      {isSalesModal && <ModalCheque isOpen={isSalesModal} onClose={onCloseModal} data={data} />}
      <div className="day-result__top-text">
        <div>Чеки</div>
        {/* <ThreeDotsIcon /> */}
      </div>
      <div className="checque">
        {data.map((item, index) => (
          <ChecqueCard data={item} index={index} />
        ))}
      </div>
      <div className={isHoveredCheque ? 'day-result__about-btn-hovered' : 'day-result__about-btn'}>
        <Button styleType="about" height={15} icon={<ArrowRightIcon />} onClick={onOpenModal}>
          Подробнее
        </Button>
      </div>
    </div>
  );
};
