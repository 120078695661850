import React, { useState, useEffect, useRef } from 'react';
import ArrowSelect from '../../../../../../icons/arrow-select.svg'; ///icons/arrow-select.svg
import { Check } from '../../../../../../icons/reviewsPlatforms/check'; //cons/reviewsPlatforms/check
import "./SelectW.scss"

export const SelectW = ({
  options,
  selectedO,
  setSelectedO,
  setO,
  onSelect,
  currentTab,
  onSelectCanceble,
  style
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option, index) => {
    setSelectedO(option.name);
    setIsOpen(false);
    clearList();
    const arr = options[index];
    arr.activeOpt = true;
    const editArr = [...options];
    editArr[index] = arr;
    setO(editArr);
    if (currentTab === "1") {
      onSelectCanceble(option.name);
    } else {
      onSelect(option.name);
    }
  };

  const clearList = () => {
    const arr = options.map((item) => {
      item.activeOpt = false;
    });
    setO(arr);
  };

  const selectRef = useRef(null);

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div style={style} className={`custom-select ${isOpen ? 'open' : ''}`} ref={selectRef}>
      <div className="select-selected" onClick={toggleOptions}>
        <p className="select-option__text">{selectedO}</p>
        <div className={isOpen ? 'arrow-open' : 'arrow-close'}>
          <img src={ArrowSelect}></img>
        </div>
      </div>

      {isOpen && (
        <ul className="select-options">
          {options.map((option, index) => (
            <li style={style} key={index}  onClick={() => handleOptionClick(option, index,currentTab)}>
              <div
                className={
                  option.activeOpt ? 'select-options_active__text' : 'select-options_off__text'
                }
              >
                {option.name}
              </div>
              <div className={option.activeOpt ? 'select-options_active' : 'select-options_off'}>
                <Check />
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
