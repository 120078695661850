import { Button } from '../../../components/common/Button/Button';
import { TofuLogoBig } from '../../../icons/tofu-logo-big';
import './Banner.scss';

export const Banner = () => {
  return (
    <div className="settings-banner__container">
      <div className="settings-banner__left">
        <div>
          <TofuLogoBig width={65} height={65} />
        </div>
        <div className="settings-banner__left-text">
          <div className="settings-banner__left-text__title">Тариф “Enterprise”</div>
          <div className="settings-banner__left-text__desc">
            За $19,99/месяц вы можете получить еще две важные функции, которые помогут Вам в работе!
          </div>
        </div>
      </div>
      <div className="settings-banner__right">
        <Button  customClass="settings-banner__right-btn" width={260} height={39}>
          Сменить тариф
        </Button>
      </div>
    </div>
  );
};
