import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ReviewsPlatform } from './ReviewsPlatform/ReviewsPlatform';
import useSetBodyScroll from '../../hooks/useSetBodyScroll';
import { authApi } from '../../api/authApi';
import { settingsApi } from '../../api/settingsApi';
import { Loader } from '../../components/common/Loader/Loader';
import { Link } from 'react-router-dom';
import { PathRoutes } from '../../const/routes';
import { PageHeader } from '../../components/common/PageHeader/PageHeader';
import { TabsSettings } from '../../components/settings/tabs/tabs';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const SettingsPlaTform = () => {
  const [value, setValue] = useState(2);
  const [account_id, setAccount_id] = useState('');
  const [companyData, setCompanyData] = useState('');
  const [tofuPoint, setTofuPoint] = useState([]);
  const [yandexPoint, setYandexPoint] = useState([]);
  const [googlePoint, setGooglePoint] = useState([]);
  const [tripPoint, setTripPoint] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authApi.getAccountId();
        const accountId = response.data[0].id;
        setAccount_id(accountId);
        const [companyRes, pointsRes] = await Promise.all([
          settingsApi.getCompany(accountId),
          settingsApi.getAllPoints(accountId),
        ]);
        setCompanyData(companyRes.data);

        setGooglePoint(pointsRes.data[0]);
        setYandexPoint(pointsRes.data[1]);
        setTripPoint(pointsRes.data[2]);
        setTofuPoint(pointsRes.data[3]);
      } catch (err) {
        console.log(err, 'catch');
      }
    };

    fetchData();
  }, []);

  const setBodyScroll = useSetBodyScroll();
  React.useEffect(() => {
    setBodyScroll(true);
    return () => {
      setBodyScroll(false);
    };
  }, [setBodyScroll]);

  return (
    <div className="settings-container">
      <PageHeader title="Настройки" />

      <TabsSettings tabIndex={2}>
        {companyData ? (
          <ReviewsPlatform
            yandexPoint={yandexPoint}
            googlePoint={googlePoint}
            tripPoint={tripPoint}
            companyData={companyData}
          />
        ) : (
          <Loader />
        )}
      </TabsSettings>
    </div>
  );
};
