import React, { useEffect, useState } from 'react';
import { ReviewsWindowHeader } from './components/ReviewsWindowHeader';
import { ReviewsWindowContent } from './components/ReviewsWindowContent/ReviewsWindowContent';
import './ReviewsWindow.scss';
import { reviewsApi } from '../../../../api/reviewsApi';

export const ReviewsWindow = ({
  selectedDate,
  platformsOptions,
  startDate,
  endDate,
  account_id,
  npsTypeOptions,
  setSelectedDate,
  setCustomDate,
  setStartDate,
  setEndDate,
}) => {
  const [reviews, setReviews] = useState([]);
  const [rating, setRating] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [count, setCount] = useState([]);
  const [pagination, setPagination] = useState('');
  const [selectedOpthionsReviews, setSelectedOpthionsReviews] = useState({
    ...npsTypeOptions.find((item) => item.type === 'Общий'),
  });

  const selectedOrder = selectedOptions.length > 0 ? selectedOptions[0].value : '0';
  const selectedPlatform =
    platformsOptions.length > 0
      ? platformsOptions.map((p) => p.value).join('&provider_type=')
      : '1';

  const handleSelectedOptionsReviewsChange = (newSelectedOptions, order) => {
    reviewsApi
      .getSortedAllReviews(
        null,
        account_id,
        selectedDate,
        selectedPlatform,
        order,
        newSelectedOptions.id,
        startDate,
        endDate
      )
      .then((res) => {
        setReviews(res.data.results);
        setRating(res.data.average_rating);
        setCount(res.data.count);
        setPagination(res.data.next);
      })
      .catch((err) => {
        console.log(err, 'catch');
      });
    setSelectedOpthionsReviews({ ...newSelectedOptions });
  };

  useEffect(() => {
    if (endDate !== '' && startDate !== '') {
      setSelectedDate('');
    }

    if (selectedDate !== '') {
      setStartDate('');
      setEndDate('');
    }

    reviewsApi
      .getSortedAllReviews(
        null,
        account_id,
        selectedDate,
        selectedPlatform,
        selectedOrder,
        0,
        startDate,
        endDate,
        setSelectedDate,
        setStartDate,
        setEndDate
      )
      .then((res) => {
        setReviews(res.data.results);
        setRating(res.data.average_rating);
        setCount(res.data.count);
        setPagination(res.data.next);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedPlatform, startDate, endDate]);

  return (
    <div className="reviews-window__container">
      <ReviewsWindowHeader reviews={reviews} rating={rating} count={count} />
      <ReviewsWindowContent
        reviews={reviews}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        pagination={pagination}
        setReviews={setReviews}
        setPagination={setPagination}
        platformsOptions={platformsOptions}
        handleSelectedOptionsReviewsChange={handleSelectedOptionsReviewsChange}
        selectedOpthionsReviews={selectedOpthionsReviews}
        npsTypeOptions={npsTypeOptions}
      />
    </div>
  );
};
