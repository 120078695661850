import { DATA } from "./const";

export const sortServerData = (data) => {
  if (!Array.isArray(data)) {
    return []; 
  }

  const updatedArray = data.map((item) => {
    return {
      period: item.period,
      tonalities: {
        ...item.tonalities,
        name: item.period,
      },
    };
  });

  const final = updatedArray.map((obj) => {
    const newTonalities = {};
    for (const key in obj.tonalities) {
      if (key !== 'name') {
        const newKey = DATA[key][key];
        newTonalities[newKey] = obj.tonalities[key];
      } else {
        newTonalities['name'] = obj.tonalities['name'];
      }
    }
    return newTonalities;
  });

  return final;
};
