import React, { useState } from 'react';
import './Hover.scss';

export const Hover = ({
  children,
  containerClassName,
  renderTitle,
  title,
  hoverContainerClassName,
  isArray,
  index,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const hover = isHovered || hoveredIndex === index;
  const handleMouseEnterIndex = (hoverIndex) => {
    setHoveredIndex(hoverIndex);
    setIsHovered((prev) => !prev);
  };

  const handleMouseLeaveIndex = () => {
    setHoveredIndex(null);
    setIsHovered((prev) => !prev);
  };
  const handleHover = () => {
    setIsHovered((prev) => !prev);
  };

  return (
    <div
      onMouseEnter={isArray ? () => handleMouseEnterIndex(index ?? 0) : handleHover}
      onMouseLeave={isArray ? handleMouseLeaveIndex : handleHover}
      className={`hover-container ${containerClassName}`}
    >
      {title && <p>{title}</p>}
      {renderTitle && renderTitle}
      {hover && <div className={`common classes ${hoverContainerClassName}`}>{children}</div>}
    </div>
  );
};
