import { useEffect, useState } from 'react';
import { reviewsApi } from '../../../../api/reviewsApi';
import { ErrorWidget } from '../../../../components/common/ErrorWidget/ErrorWidget';
import { ERROR_TEXT_FOR_SELECT } from '../../../../const/commonConst';
import { Chart } from './Chart/Chart';
import { sortServerData } from './helper';
import { TonalityInfo } from './TonalityInfo/TonalityInfo';
import './TonalityWidget.scss';

export const TonalityWidget = ({ npsTypeOptions, selectedDate, selectedPlatform, account_id }) => {
  const [data, setData] = useState([]);

  const [selectedOptionTonality, setSelectedOptionTonality] = useState({
    ...npsTypeOptions.find((item) => item.type === 'Общий'),
  });

  const handleSelectedOptionsTonalityChange = (newSelectedOptions) => {
    reviewsApi
      .getFilteredReviewsByTonality(
        account_id,
        selectedDate,
        newSelectedOptions.id,
        selectedPlatform
      )
      .then((res) => {
        setData(sortServerData(res.data));
      })
      .catch((err) => {
        console.log(err, 'catch');
      });
    setSelectedOptionTonality({ ...newSelectedOptions });
  };

  useEffect(() => {
    reviewsApi
      .getFilteredReviewsByTonality(account_id, selectedDate, 0, selectedPlatform)
      .then((res) => {
        setData(sortServerData(res.data));
      })
      .catch((err) => {
        console.log(err, 'catch');
      });
  }, [account_id, selectedDate, selectedPlatform]);

  return (
    <>
      <TonalityInfo
        npsTypeOptions={npsTypeOptions}
        selectedOptions={selectedOptionTonality}
        handleSelectedOptionsChange={handleSelectedOptionsTonalityChange}
        data={data}
      />
      {data.length === 0 ? <ErrorWidget text={ERROR_TEXT_FOR_SELECT} /> : <Chart final={data} />}
    </>
  );
};
