import React from "react";

export const TripadvisorIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#00AF87"
    >
      <path
        d="M6.494 14.6514C6.87913 14.6515 7.25563 14.5425 7.57587 14.3383C7.89612 14.1341 8.14573 13.8439 8.29314 13.5042C8.44055 13.1646 8.47913 12.7909 8.40401 12.4304C8.32889 12.0698 8.14344 11.7386 7.87111 11.4787C7.59878 11.2187 7.2518 11.0417 6.87407 10.97C6.49633 10.8983 6.10481 10.9351 5.749 11.0759C5.39319 11.2166 5.08909 11.4548 4.87516 11.7605C4.66122 12.0662 4.54707 12.4255 4.54712 12.7931C4.54719 13.286 4.75233 13.7586 5.11743 14.1071C5.48252 14.4556 5.97767 14.6514 6.494 14.6514Z"
        fill="#1E1E1E"
      />
      <path
        d="M17.4706 14.6517C18.546 14.6517 19.4177 13.8196 19.4177 12.7931C19.4177 11.7667 18.546 10.9346 17.4706 10.9346C16.3952 10.9346 15.5234 11.7667 15.5234 12.7931C15.5234 13.8196 16.3952 14.6517 17.4706 14.6517Z"
        fill="#1E1E1E"
      />
      <path
        d="M1.00568 12.7936C1.00568 15.6871 3.46276 18.0321 6.49391 18.0321C7.87757 18.0339 9.21049 17.5349 10.2239 16.6357L11.9832 18.463L13.7425 16.6368C14.7559 17.5352 16.0883 18.0338 17.4714 18.0318C20.5029 18.0318 22.9621 15.6863 22.9621 12.7933C22.9629 12.0644 22.8039 11.3435 22.4955 10.6767C22.187 10.0099 21.7358 9.41215 21.1709 8.92172L22.9667 7.05671H18.9855C16.9223 5.71662 14.4845 5 11.9889 5C9.49333 5 7.05547 5.71662 4.99227 7.05671H1L2.79585 8.92172C2.23108 9.41226 1.78006 10.0101 1.47176 10.6769C1.16346 11.3437 1.0047 12.0647 1.00568 12.7936ZM21.1849 12.7936C21.1849 13.4948 20.9671 14.1802 20.559 14.7632C20.1508 15.3463 19.5707 15.8007 18.892 16.069C18.2133 16.3374 17.4665 16.4076 16.746 16.2708C16.0255 16.134 15.3637 15.7963 14.8442 15.3005C14.3248 14.8047 13.971 14.173 13.8277 13.4852C13.6844 12.7975 13.7579 12.0847 14.0391 11.4368C14.3202 10.789 14.7963 10.2353 15.4071 9.84576C16.0179 9.4562 16.736 9.24827 17.4706 9.24827C18.4557 9.24827 19.4005 9.62179 20.097 10.2867C20.7936 10.9515 21.1849 11.8533 21.1849 12.7936ZM11.9824 6.71388C13.398 6.7122 14.7998 6.97971 16.106 7.50081C13.7617 8.35697 11.9843 10.3587 11.9843 12.6913C11.9843 10.3584 10.2063 8.35646 7.86184 7.50055C9.16704 6.97986 10.5678 6.71244 11.9824 6.71388ZM6.49391 9.24827C7.22853 9.24827 7.94665 9.4562 8.55747 9.84576C9.16828 10.2353 9.64435 10.789 9.92548 11.4368C10.2066 12.0847 10.2802 12.7975 10.1368 13.4852C9.99353 14.173 9.63977 14.8047 9.12032 15.3005C8.60086 15.7963 7.93904 16.134 7.21853 16.2708C6.49803 16.4076 5.75121 16.3374 5.07251 16.069C4.39381 15.8007 3.81371 15.3463 3.40558 14.7632C2.99745 14.1802 2.77961 13.4948 2.77961 12.7936C2.77961 11.8533 3.17093 10.9515 3.8675 10.2867C4.56407 9.62179 5.50882 9.24827 6.49391 9.24827Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};
