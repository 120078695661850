import { Switchers } from './Switchers/Switchers';
import { Telegram } from './Telegram/Telegram';
import './Notifications.scss';
import { PageHeader } from '../../components/common/PageHeader/PageHeader';
import { TabsSettings } from '../../components/settings/tabs/tabs';

export const Notifications = () => {
  return (
    <div className="settings-container">
      <PageHeader title="Настройки" />
      <TabsSettings tabIndex={5}>
        <Telegram />
      </TabsSettings>
    </div>
  );
};
