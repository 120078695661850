import React, { useState } from 'react';
import ProgressBar from './ProgressBar/ProgressBar';
import './Banner.scss';
import { SliderBannerStatistics } from './SliderBannerStatistics/SliderBannerStatistics';
import { HoverPercent } from '../../../dayResult/components/ThreeCardsTop/HoverPercent/HoverPercent';
import { MiniArrowUp } from '../../../../../icons/reports/mini-arrow-up';
import { HoverSettings } from '../../../../../components/common/HoverSettings/HoverSettings';
import { HoverDescription } from '../../../../../components/common/HoverSettings/HoverDescription/HoverDescription';
import { Hover } from '../../../../../components/common/Hover/Hover';

const PROGRESS_BAR_HEIGHT = 17;
const PROGRESS_PERCENT = '- 2,45 %';

export const Banner = ({
  banner,
  bannerList,
  dataHoverPercent,
  dataHoverPercentLeftSidePercent,
  revenue,
}) => {
  return (
    <div className="banner-sales__conatiner">
      <div className="banner-sales__left-side">
        <div className="banner-sales__left-side_top">
          <div className="banner-sales__left-side-title">{banner.title}</div>
          <HoverSettings>
            <HoverDescription />
          </HoverSettings>
        </div>
        <div className="banner-sales__left-side-percent">
          <p className="banner-sales__left-side-percent-left">{banner.percent}</p>
          <Hover
            containerClassName={'banner-sales__left-side-percent-right'}
            hoverContainerClassName={'banner-sales__left-side-percent-right_hover'}
            title={PROGRESS_PERCENT}
          >
            <HoverPercent
              hoveredIndex={0}
              dataHoverPercent={dataHoverPercentLeftSidePercent}
              revenue={revenue}
            />
          </Hover>
        </div>
        <p className="banner-sales__left-side-sum">
          Сумма <span>{banner.sum}</span>
          <ProgressBar completed={banner.percentProgressBar} height={PROGRESS_BAR_HEIGHT} />
        </p>
      </div>
      <div className="banner-sales__right-side">
        <SliderBannerStatistics bannerList={bannerList} dataHoverPercent={dataHoverPercent} />
      </div>
    </div>
  );
};
