import { COLORS } from '../../../const/nps';
import './Statistics.scss';

export const Statistics = ({ data }) => {
  const filteredData = data.filter((item) => item.percent !== 0);

  return (
    <ul className={`statistic-write-off-diagram`}>
      {filteredData.map((item, index) => (
        <li key={index}>
          <span
            className="color"
            style={{
              backgroundColor: COLORS[index % COLORS.length],
            }}
          ></span>
          <span className="statistic-write-off-diagram-percent">{item.percent}%</span>
          <span className="statistic-write-off-diagram-text">{item.reason_name} </span>
        </li>
      ))}
    </ul>
  );
};
