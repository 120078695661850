import { FirstWidget } from './FirstWidget/FirstWidget';
import { SecondWidget } from './SecondWidget/SecondWidget';
import './Info.scss';

export const Info = () => {
  return (
    <div className='settings-subsciption-info'>
      <FirstWidget />
      <SecondWidget />
    </div>
  );
};
