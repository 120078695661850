export const DATA = [
  {
    name: 'Московская область',
    sum: { num: 18467715.5, isColor: true },
    revenue: { num: 17654848.54, isColor: true },
    paid: { num: 16884486.06, isColor: true },
    foodcost: { num: 16884486.06, isColor: true },
    writeoff: { num: 16884486.06, isColor: true },
    orders: { num: 11135, isColor: true },
    averageReceipt: { num: 8204.5, isColor: true },
    averageStringReceipt: { num: 0.0, isColor: false },
    guests: { num: 14681, isColor: true },
    guestReceipt: { num: 2119.0, isColor: false },
    averageGuestInCheque: { num: 2.7, isColor: false },
    visitLenght: { num: '1:34:18', isColor: false },
    isOpen: false,
    company: [
      {
        name: 'Caviar, Москва, ',
        address: 'Дзержинского 16',
        sum: { num: 11996828.0, isColor: true },
        revenue: { num: 11636923.16, isColor: true },
        paid: { num: 25919865.44, isColor: true },
        foodcost: { num: 11287815.47, isColor: true },
        writeoff: { num: 11287815.47, isColor: true },
        orders: { num: 760, isColor: false },
        averageReceipt: { num: 15785.3, isColor: true },
        averageStringReceipt: { num: 0.0, isColor: false },
        guests: { num: 3268, isColor: false },
        guestReceipt: { num: 3671.0, isColor: true },
        averageGuestInCheque: { num: 4.3, isColor: true },
        visitLenght: { num: '3:02:34', isColor: true },
      },
      {
        name: 'Caviar Sus, Москва',
        address: 'Пролетарская 74',
        sum: { num: 0.0, isColor: false },
        revenue: { num: 0.0, isColor: false },
        paid: { num: 0.0, isColor: false },
        foodcost: { num: 0.0, isColor: false },
        writeoff: { num: 0.0, isColor: false },
        orders: { num: 0.0, isColor: false },
        averageReceipt: { num: 0.0, isColor: false },
        averageStringReceipt: { num: 0.0, isColor: false },
        guests: { num: 0.0, isColor: false },
        guestReceipt: { num: 0.0, isColor: false },
        averageGuestInCheque: { num: 0.0, isColor: false },
        visitLenght: { num: 0.0, isColor: false },
      },
      {
        name: 'Dolce Cafe, Химки',
        address: 'Малининина 9',
        sum: { num: 6470887.5, isColor: false },
        revenue: { num: 6017925.38, isColor: false },
        paid: { num: 5596670.6, isColor: false },
        foodcost: { num: 5596670.6, isColor: false },
        writeoff: { num: 5596670.6, isColor: false },
        orders: { num: 10375, isColor: true },
        averageReceipt: { num: 623.7, isColor: false },
        averageStringReceipt: { num: 0.0, isColor: false },
        guests: { num: 11413, isColor: true },
        guestReceipt: { num: 567.0, isColor: false },
        averageGuestInCheque: { num: 1.1, isColor: false },
        visitLenght: { num: '0:06:02', isColor: true },
      },
    ],
  },
  {
    name: 'Ленинградская область',
    sum: { num: 10007235.0, isColor: false },
    revenue: { num: 9506792.67, isColor: false },
    paid: { num: 9035379.38, isColor: false },
    foodcost: { num: 9035379.38, isColor: false },
    writeoff: { num: 9035379.38, isColor: false },
    orders: { num: 1692, isColor: false },
    averageReceipt: { num: 7743.1, isColor: false },
    averageStringReceipt: { num: 0.0, isColor: false },
    guests: { num: 4240, isColor: false },
    guestReceipt: { num: 2721.0, isColor: true },
    averageGuestInCheque: { num: 2.7, isColor: false },
    visitLenght: { num: '2:08:59', isColor: true },
    isOpen: false,
    company: [
      {
        name: 'Caviar, Санкт-Петербург, ',
        address: 'Пушкина 20',
        sum: { num: 5005632.0, isColor: true },
        revenue: { num: 4655237.76, isColor: false },
        paid: { num: 4329371.12, isColor: false },
        foodcost: { num: 4329371.12, isColor: false },
        writeoff: { num: 4329371.12, isColor: false },
        orders: { num: 435, isColor: false },
        averageReceipt: { num: 11507.2, isColor: true },
        averageStringReceipt: { num: 0.0, isColor: false },
        guests: { num: 1349, isColor: false },
        guestReceipt: { num: 3712.0, isColor: true },
        averageGuestInCheque: { num: 3.1, isColor: false },
        visitLenght: { num: '3:45:20', isColor: false },
      },
      {
        name: 'Caviar Sushi',
        address: 'Пушкина 4',
        sum: { num: 5001603.0, isColor: false },
        revenue: { num: 4851554.91, isColor: true },
        paid: { num: 4706008.26, isColor: true },
        foodcost: { num: 4706008.26, isColor: true },
        writeoff: { num: 4706008.26, isColor: true },
        orders: { num: 1257, isColor: true },
        averageReceipt: { num: 3979.0, isColor: false },
        averageStringReceipt: { num: 0.0, isColor: false },
        guests: { num: 2891, isColor: true },
        guestReceipt: { num: 1730.0, isColor: false },
        averageGuestInCheque: { num: 2.3, isColor: false },
        visitLenght: { num: '0:32:38', isColor: false },
      },
    ],
  },
];

export const TWO_COMPANY = [
  {
    name: 'Caviar, Москва, Дзержинского 16',
    sum: { num: 6115953.6, isColor: true },
    revenue: { num: 6054670.00 , isColor: true },
    paid: { num: 6010430.00, isColor: true },
    foodcost: { num: "29.31%", isColor: false },
    writeoff: { num: "3.72%", isColor: false },
    orders: { num: 492, isColor: true },
    averageReceipt: { num: 12430.80, isColor: true },
    averageStringReceipt: { num: 9.37, isColor: false },
    guests: { num: 1771, isColor: true },
    guestReceipt: { num: 3453.00, isColor: true },
    averageGuestInCheque: { num: 3.6, isColor: false },
    visitLenght: { num: '3:12:40', isColor: false },
    isOpen: false,
  },
  {
    name: 'Caviar, Санкт-Петербург, Пролетарская 74',
    sum: { num: 4835259.00, isColor: false },
    revenue: { num: 4821320.00, isColor: false },
    paid: { num: 4811940.00, isColor: false },
    foodcost: { num: "30.17%", isColor: false },
    writeoff: { num: "3.43%", isColor: false },
    orders: { num: 429, isColor: false },
    averageReceipt: { num: 11271.00, isColor: false },
    averageStringReceipt: { num: 10.11, isColor: true },
    guests: { num: 1673, isColor: false },
    guestReceipt: { num: 2890.00, isColor: false },
    averageGuestInCheque: { num: 3.9, isColor: true },
    visitLenght: { num: '2:53:12', isColor: false },
    isOpen: false,
  },
];
