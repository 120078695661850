import React from 'react';

export const YandexIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48.000000 48.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
        fill="#ff0000"
        stroke="none"
      >
        <path
          d="M178 466 c-205 -56 -235 -337 -45 -435 133 -68 291 2 334 148 49 171
-115 335 -289 287z m142 -226 l0 -150 -25 0 -25 0 0 125 c0 120 -1 125 -21
125 -67 0 -81 -61 -24 -107 l35 -29 -38 -55 c-27 -39 -45 -55 -65 -57 -15 -2
-27 0 -27 3 0 4 14 27 31 52 l31 45 -25 26 c-14 15 -28 41 -31 57 -8 40 24 92
61 101 15 3 33 7 38 9 6 2 27 4 48 4 l37 1 0 -150z"
        />
      </g>
    </svg>
  );
};
