import React, { useState } from 'react';

import './HoverTitleGroup.scss';

export default function HoverTitleGroup({ children, isGroup }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="hover-title__container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="hover-title__title">{children}</div>
      {isHovered && (
        <div className="hover-title__text">
          {isGroup ? 'Лучший показатель из выбранных групп' : 'Лучший показатель из заведений группы'}
        </div>
      )}
    </div>
  );
}
