import React from 'react';

export const SemiBlankStarIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.18994 2.15077C8.55159 1.5299 9.44859 1.5299 9.81017 2.15077L11.9064 5.74982L15.9771 6.63131C16.6793 6.78337 16.9565 7.63642 16.4778 8.17222L13.7027 11.278L14.1222 15.4219C14.1946 16.1367 13.469 16.6639 12.8114 16.3742L9.00009 14.6947L5.18871 16.3742C4.53121 16.6639 3.80555 16.1367 3.87794 15.4219L4.29749 11.278L1.52238 8.17222C1.04364 7.63642 1.32082 6.78337 2.02305 6.63131L6.09372 5.74982L8.18994 2.15077Z"
        fill="#FFC700"
      />
      <path
        d="M11.9063 5.74983L9.81006 2.15079V15.0517L12.8113 16.3742C13.4689 16.664 14.1945 16.1367 14.1221 15.4219L13.7026 11.2781L16.4777 8.17224C16.9564 7.63644 16.6792 6.78339 15.977 6.63132L11.9063 5.74983Z"
        fill="white"
      />
    </svg>
  );
};
