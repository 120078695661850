import React, { useState } from 'react';
import { options } from './const';
import './ReviewsContentHeader.scss';
import { CommentsSelect } from './CommentsSelect/CommentsSelect';
import { Select } from '../../../../../../../components/common/Select/Select';
import { ThreeDotsIcon } from '../../../../../../../icons/three-dots-icon';
import NpsPopover from './NpsPopover/NpsPopover';

export const ReviewsContentHeader = ({
  selectedOptions,
  setSelectedOptions,
  handleSelectedOptionsReviewsChange,
  selectedOpthionsReviews,
  npsTypeOptions,
  setIsChecked,
  isChecked,
}) => {
  const [npsForOrdering, setNpsForOrdering] = useState([]);

  return (
    <div className="reviews-content-header">
      <div className="reviews-content-header__filter">
        <Select
          options={npsTypeOptions}
          setNpsForOrdering={setNpsForOrdering}
          selectedOptions={selectedOpthionsReviews}
          onChange={handleSelectedOptionsReviewsChange}
          secondBg
          isReviews
        />
        <CommentsSelect
          npsForOrdering={npsForOrdering}
          options={options}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          onChange={handleSelectedOptionsReviewsChange}
        />
        <NpsPopover setIsChecked={setIsChecked} isChecked={isChecked}>
          <ThreeDotsIcon />
        </NpsPopover>
      </div>
    </div>
  );
};
