import { TofuErrorIcon } from '../../../../icons/tofu-error';
import logo from '../../../../images/demo/logo.JPG';
import cn from 'classnames';
import './Company.scss';

export const Company = ({ collapsed }) => {
  console.log(collapsed, 'collapsed');
  return (
    <div className="company__line">
      <div className={cn('company__container', collapsed)}>
        <div className={cn('company__logo', collapsed)}>
          <img src={logo} width={56} height={56} />
        </div>

        <div className={cn('company__info', collapsed)}>
          <div className="company__name">Caviar</div>
          <div className="company__format">Гастробар</div>
        </div>
      </div>
    </div>
  );
};
