import React, { useState } from 'react';
import { SettingsIconGear } from '../../../icons/settings/setting-icon-gear';
import './HoverSettings.scss';

export const HoverSettings = ({ children, height, width, dayResult }) => {
  const [isHoveredSettings, setIsHoveredSettings] = useState(false);
  const handleMouseHoverSettings = () => {
    setIsHoveredSettings((prev) => !prev);
  };
  return (
    <div
      onMouseEnter={handleMouseHoverSettings}
      onMouseLeave={handleMouseHoverSettings}
      className={dayResult ? 'hover-settings__gear__day-result' : 'hover-settings__gear'}
    >
      <div className="hover-settings__info-container">
        <div className="hover-settings__info-icon">
          <SettingsIconGear
            fill={isHoveredSettings ? '#67A8F4' : '#7D9EC5'}
            height={height}
            width={width}
          />
        </div>
        {isHoveredSettings && <>{children}</>}
      </div>
    </div>
  );
};
