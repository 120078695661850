import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import {rootSaga} from './rootSaga';
import {rootReducer} from './rootReducer';
import {initAuthorization} from "../api/authApi";

const sagaMiddleware = createSagaMiddleware();

export const store = createStore (
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

store.runSaga = (saga) => {
  return sagaMiddleware.run(saga);
};

store.subscribe(() => {
  const {isAuthorized} = store.getState().auth;
  if (isAuthorized) {
    initAuthorization();
  }
})

sagaMiddleware.run(rootSaga);
