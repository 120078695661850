import './TwoCards.scss';
import { TwoCardsItem } from './TwoCardsItem/TwoCardsItem';

export const TwoCards = ({ data }) => {
  return (
    <div className="two-cards__container">
      {data.map((item, index) => {
        return <TwoCardsItem item={item} index={index}/>;
      })}
    </div>
  );
};
