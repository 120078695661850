import {
  AUTH,
  LOGOUT,
  LOGOUT_SUCCESS,
  REG,
  REG_FAILURE,
  REG_SUCCESS,
  SET_AUTH_DATA,
  SET_USER_ID,
  IS_EMAIL_REGISTRATION_IN_PROGRESS,
  HIDE_RESEND_EMAIL_CONFIRMATION,
  SHOW_RESEND_EMAIL_CONFIRMATION,
} from './authConstants';

export const authActions = {
  auth(email, password, franchise_code, history, location, supportEmail) {
    return {
      type: AUTH,
      payload: {
        email,
        password,
        franchise_code,
        history,
        location,
        supportEmail,
      },
    };
  },

  reg(last_name, first_name, phone, password, email, invite_code) {
    return {
      type: REG,
      payload: {
        last_name,
        first_name,
        phone,
        email,
        password,
        invite_code,
      },
    };
  },

  showResendEmailConfirmation() {
    return {
      type: SHOW_RESEND_EMAIL_CONFIRMATION,
    };
  },

  hideResendEmailConfirmation() {
    return {
      type: HIDE_RESEND_EMAIL_CONFIRMATION,
    };
  },

  setAuthData(token, userId, is_franchise_owner, is_email_in_progress, userType, fullName) {
    return {
      type: SET_AUTH_DATA,
      payload: {
        token,
        userId,
        is_franchise_owner,
        is_email_in_progress,
        userType,
        fullName,
      },
    };
  },

  logOut() {
    return {
      type: LOGOUT,
    };
  },

  logOutSuccess() {
    return {
      type: LOGOUT_SUCCESS,
    };
  },

  setUserId(userId) {
    return {
      type: SET_USER_ID,
      payload: userId,
    };
  },

  isEmailRegistrationInProgress(data) {
    return {
      type: IS_EMAIL_REGISTRATION_IN_PROGRESS,
      payload: data,
    };
  },

  regSuccess() {
    return {
      type: REG_SUCCESS,
    };
  },

  regFailure() {
    return {
      type: REG_FAILURE,
    };
  },
};
