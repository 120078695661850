import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import './TofuField.scss';

export const TofuField = ({
  label,
  value,
  onChange,
  name,
  type,
  helperText,
  placeholder,
  Icon,
  disabled,
  options,
  inputIcon,
  onClickIcon,
  onBlur,
  required,
  formError,
  ...props
}) => {
  const [showClearingFieldIcon, setShowClearingFieldIcon] = useState(false);
  const htmlElRef = useRef(null);

  useEffect(() => {
    if (value && value.length > 0 && document.activeElement === htmlElRef.current)
      setShowClearingFieldIcon(true);
    if (value && (value.length === 0 || value === '')) setShowClearingFieldIcon(false);
    if (!value) setShowClearingFieldIcon(false);
  }, [value, setShowClearingFieldIcon, htmlElRef]);

  // const handleBlur = (e) => {
  //   setShowClearingFieldIcon(false);
  //   props.onBlur && props.onBlur(e);
  // };

  return (
    <div className="fields">
      {label && <div className="fields__title">{label}</div>}
      <span className="input-span">
        <div className="fields__input-wrapper">
          <label htmlFor="copy-button">
            <input
              key={type}
              className={classNames('fields__input', {
                'fields__filled-input': value && !helperText,
                'fields__error-input': helperText && value,
                formError: formError
               
              })}
              name={name}
              type={type}
              ref={htmlElRef}
              onChange={(e) => onChange(e, name)}
              value={value}
              onBlur={onBlur}
              onMouseDown={() => value && value.length > 0 && setShowClearingFieldIcon(true)}
              placeholder={placeholder}
              disabled={disabled}
              required={required}
              {...props}
            />
            {showClearingFieldIcon && (
              <span
                className="fields__input-button"
                onMouseDown={(e) => {
                  onChange(e, name);
                }}
              >
                {Icon}
              </span>
            )}
            <div className="fields__input-icon-wrapper">
              {' '}
              {inputIcon && (
                <div className="fields__input-icon" onClick={onClickIcon}>
                  {inputIcon}
                </div>
              )}
            </div>
          </label>
        </div>
      </span>
      {helperText && <div className="fields__error-value">{helperText}</div>}
    </div>
  );
};
