import { PathRoutes } from '../../../const/routes';
import { ReportsIcon, ReviewsIcon, SettingsIcon } from '../../../icons/navigation';
import { DotsIcon } from '../../../icons/navigation/dots-icon';

export const routes = [
  {
    path: PathRoutes.ReviewsRoute,
    name: 'Отзывы',
    icon: <ReviewsIcon />,
    generateIcon: (isActive) => (
      <ReviewsIcon color={isActive ? '#67A8F4' : 'rgba(196, 196, 194, 1)'} />
    ),
  },
  {
    path: PathRoutes.Reports,
    name: 'Отчеты',
    icon: <ReportsIcon />,
    generateIcon: (isActive) => (
      <ReportsIcon color={isActive ? '#67A8F4' : 'rgba(196, 196, 194, 1)'} />
    ),
    subRoutes: [
      {
        path: PathRoutes.Foodcost,
        name: 'Фудкост ',
        icon: <DotsIcon />,
        generateIcon: (isActive) => (
          <DotsIcon color={isActive ? '#67A8F4' : 'rgba(196, 196, 194, 1)'} />
        ),
      },
      {
        path: PathRoutes.WriteOff,
        name: 'Списания',
        icon: <DotsIcon />,
        generateIcon: (isActive) => (
          <DotsIcon color={isActive ? '#67A8F4' : 'rgba(196, 196, 194, 1)'} />
        ),
      },
      {
        path: PathRoutes.SalesRoute,
        name: 'Продажи',
        icon: <DotsIcon />,
        generateIcon: (isActive) => (
          <DotsIcon color={isActive ? '#67A8F4' : 'rgba(196, 196, 194, 1)'} />
        ),
      },
      {
        path: PathRoutes.DayResult,
        name: 'Итоги дня',
        icon: <DotsIcon />,
        generateIcon: (isActive) => (
          <DotsIcon color={isActive ? '#67A8F4' : 'rgba(196, 196, 194, 1)'} />
        ),
      },
      {
        path: PathRoutes.LikeForLike,
        name: 'Like for Like',
        icon: <DotsIcon />,
        generateIcon: (isActive) => (
          <DotsIcon color={isActive ? '#67A8F4' : 'rgba(196, 196, 194, 1)'} />
        ),
      },
    ],
  },
  // {
  //   path: PathRoutes.CompanyInfo,
  //   name: 'Настройки',
  //   icon: <SettingsIcon />,
  //   generateIcon: (isActive) => (
  //     <SettingsIcon color={isActive ? '#67A8F4' : 'rgba(196, 196, 194, 1)'} />
  //   ),
  // },
];
