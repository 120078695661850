import './ABCdescription.scss';

export const ABCdescription = () => {
  const DATA = [
    <>
      Позиции, на которые стоит повысить цены,
      <br />
      не потеряв спрос у гостей.
      <br /> Это видно по количеству продаж
    </>,
    <>
      Позиции, для которых надо поработать
      <br /> с себестоимостью
    </>,
    'Позиции, продажи которых надо стимулировать',
  ];
  return (
    <div className="abc-desc__container">
      <h3 className="abc-desc__title">АВС-анализ</h3>
      <p className="abc-desc__group-a">
        <span>Группа A</span> — блюда-лидеры, которые приносят больше всего денег
      </p>
      <p className="abc-desc__group-b">
        <span>Группа В</span> — средние товары, на которые спрос
        <br /> есть периодически
      </p>
      <p className="abc-desc__group-c">
        <span>Группа С</span> — позиции, которые имеют низкий спрос и дают меньше всего выручки
      </p>
      <p>АВС-анализ показывает:</p>
      <ol className="abc-desc__list">
        {DATA.map((item) => (
          <li className="abc-desc__item">{item}</li>
        ))}
      </ol>
    </div>
  );
};
