import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './ChartSecond.scss';
import { BarChart, Tooltip, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: '16:00',
    uv: 99600,
  },
  {
    name: '17:00',
    uv: 149300,
  },
  {
    name: '18:00',
    uv: 174300,
  },
  {
    name: '19:00',
    uv: 424300,
  },
  {
    name: '20:00',
    uv: 572690,
  },
  {
    name: '21:00',
    uv: 622500,
  },
  {
    name: '22:00',
    uv: 398393,
  },
  {
    name: '23:00',
    uv: 48800,
  },
];

export default function ChartSecond() {
  return (
    <ResponsiveContainer width="99.5%" height={195}>
      <BarChart
        width={500}
        height={300}
        data={data}
        barGap={0}
        margin={{
          top: 13,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="name"
          //   minTickGap={40}
          tickLine={true}
          //   tickFormatter={(unixTime) => {
          //     return moment.utc(unixTime).local().format(' MM.YY');
          //   }}
          tick={{
            fill: '#9C9C9C',
            fontSize: '12px',
            lineHeight: '15px',
          }}
          stroke="#F6F6F6"
          dx={2}
        />
        <YAxis
          tickFormatter={(value) => value / 1000 + 'k'}
          tick={{
            fill: '#9C9C9C',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '15px',
          }}
          stroke="#F6F6F6"
          allowDecimals={false}
          width={45}
          dy={-8}
          tickMargin={2}
        />
        <Tooltip
          wrapperStyle={{ outline: 'none' }}
          content={CustomToolTip}
          cursor={{ fill: 'white', strokeWidth: 0, radius: 0 }}
          strokeDasharray={[1, 1]}
        />
        <Bar dataKey="uv" fill="#67A8F4" barSize={15} radius={[3, 3, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
}

function CustomToolTip({ active, payload, label }) {
  const [convertedStringUV, setConvertedStringUV] = useState('');
  function convertNumberToStringUV(payload) {
    if (payload[0].payload.uv.toString().length <= 5) {
      const numberStringUV = payload[0].payload.uv.toString();
      const firstPart = numberStringUV.slice(0, 2);
      const secondPart = numberStringUV.slice(2, 5);
      return firstPart + ' ' + secondPart  + " ₽";
    } else {
      const numberStringUV = payload[0].payload.uv.toString();
      const firstPart = numberStringUV.slice(0, 3);
      const secondPart = numberStringUV.slice(3, 6);
      return firstPart + ' ' + secondPart  + " ₽";
    }
  }
  useEffect(() => {
    if (payload && payload.length > 0) {
      const newConvertedStringUV = convertNumberToStringUV(payload);
      setConvertedStringUV(newConvertedStringUV);
    }
  }, [payload]);

  if (active) {
    return (
      <div className="tooltip-second-container" style={{ border: 'none' }}>
        <ul className="tooltip-second-list">
          {payload ? (
            <>
              <p className="tooltip-second-text">
                Средняя выручка: <span>{convertedStringUV}</span>
              </p>
            </>
          ) : null}
        </ul>
      </div>
    );
  }
}
