import React, { useState } from 'react';
import moment from 'moment';

import { CommentPopover } from './Popover/Popover';
import { GrayStarIcon } from '../../../../../../../icons/star/gray-star-icon';
import { FillStarIcon } from '../../../../../../../icons/star/fill-star-icon';
import { ThreeDotsIcon } from '../../../../../../../icons/three-dots-icon';
import { ProviderTypes } from '../../../../../../../const/providerTypes';
import './Review.scss';
import { NpsCategory } from './NpsCategory/NpsCategory';
import { NPS_TYPES } from '../../../../../../../const/nps';

export const Review = ({
  date,
  rating,
  author,
  text,
  providerType,
  contacts,
  url,
  npsCategory,
  isChecked,
}) => {
  const dateFormat = 'DD.MM.YYYY';
  const stars = Array(5)
    .fill()
    .map((_, index) =>
      index < rating ? <FillStarIcon key={index} /> : <GrayStarIcon key={index} />
    );

  const updatedNpsCategory = npsCategory.map((item) => {
    const npsType = NPS_TYPES.find((type) => type.id === item.nps_type);
    return { ...item, nps_type: npsType ? npsType.type : null };
  });

  const [showAllNpsCategories, setShowAllNpsCategories] = useState(false);

  const visibleNpsCategories = showAllNpsCategories
    ? updatedNpsCategory
    : updatedNpsCategory.slice(0, 3);

  const handleShowMoreClick = () => {
    setShowAllNpsCategories(true);
  };

  return (
    <div className="review-item__container">
      <div className="review-item__header">
        <div className="review-item__header-left-side">
          <div className="review-item__header-logo">{ProviderTypes[providerType].icon}</div>
          <div className="review-item__stars">{stars}</div>
        </div>
        <div className="review-item__header-right-side">
          <CommentPopover providerType={providerType} author={author} contacts={contacts} url={url}>
            <ThreeDotsIcon />
          </CommentPopover>
        </div>
      </div>
      <div className="review-item__content">
        <div className="review-item__content-info">
          <div className="review-item__content-name">{author}</div>
          <div className="review-item__content-date">
            {moment.utc(date).local().format(dateFormat)}
          </div>
        </div>
        {isChecked && (
          <div className="review-item__header-nps-category">
            {visibleNpsCategories.map((item) => (
              <NpsCategory npsCategory={item} key={item.nps_type} />
            ))}
            {updatedNpsCategory.length > 3 && !showAllNpsCategories && (
              <button className="review-item__header-btn" onClick={handleShowMoreClick}>
                Еще...
              </button>
            )}
          </div>
        )}
        {showAllNpsCategories && (
          <div className="review-item__header-nps-category">
            {updatedNpsCategory.slice(9).map((item) => (
              <NpsCategory npsCategory={item} key={item.nps_type} />
            ))}
          </div>
        )}
        <div className="review-item__content-text">{text}</div>
      </div>
    </div>
  );
};
