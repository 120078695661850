import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { StaffRatingSlider } from '../../../components/reports/StaffRatingSlider/StaffRatingSlider';
import { Checque } from '../../../components/reports/Cheque/Checque';
import { useHover } from '../../../hooks/useHover';
import { ThreeDotsIcon } from '../../../icons/three-dots-icon';
import { Sales } from '../../../components/reports/Sales/Sales';
import { Banner } from './Banner/Banner';
import { TopTenWidget } from './TopTenWidget/TopTenWidget';
import { Revenue } from './Revenue/Revenue';
import './SalesReport.scss';
import { PageHeader } from '../../../components/common/PageHeader/PageHeader';
import useSetBodyScroll from '../../../hooks/useSetBodyScroll';
import { TABLE__DATA } from '../foodcost/const';
import {
  FOODCOST_COLUMN_TITLE,
  info,
  SALES_COLUMN_TITLE,
  subinfo,
} from '../../../components/reports/Table/const';
import { AverageChecque } from '../../../icons/day-results/checque/average-checque';
import { NumberChecque } from '../../../icons/day-results/checque/number-checque';
import { DepthChecque } from '../../../icons/day-results/checque/depth-checque';
import { NewTable } from '../../../components/common/NewTable/NewTable';
import { TableItem } from '../../../components/common/NewTable/TableItem/TableItem';

export const SalesReport = ({ isMenuOpen, setIsMenuOpen }) => {
  const data = {
    title: 'Выручка',
    total_percent: '9 523 088,92',
    change_percent: '+ 2,45 %',
    total_sum: '7 620 000,00',
    list: [
      { title: 'Чеки', percent: 2.3, percent_up: 2.45, sum: 954.82 },
      { title: 'Оплата', percent: 2.3, percent_up: 2.45, sum: 954.82 },
    ],
  };

  const [hoverRefSales, isHoveredSales] = useHover();
  const [hoverRefCheque, isHoveredCheque] = useHover();

  const setBodyScroll = useSetBodyScroll();
  useEffect(() => {
    setBodyScroll(true);
    return () => {
      setBodyScroll(false);
    };
  }, [setBodyScroll]);

  const checkData = [
    {
      sum: '2 830,60 ₽',
      title: 'Средний чек',
      per: '-102 ₽ -36,35%',
      color: 'red',
      img: <AverageChecque />,
      hoverText: 'Средний чек',
      hoverNum: '2 728,60 ₽',
    },
    {
      sum: '98',
      title: 'Кол-во чеков',
      per: '+3 чека',
      color: 'green',
      img: <NumberChecque />,
      hoverText: 'Кол-во чеков',
      hoverNum: '95',
    },
    {
      sum: '9 пц.',
      title: 'Глубина чека',
      per: '0 порций',
      color: 'gray',
      img: <DepthChecque />,
      hoverText: 'Глубина чека',
      hoverNum: '9 пц.',
    },
  ];

  const TableSectionInner = [
    'price',
    'sales_number',
    'sales_cost_price',
    'revenue',
    'gross_profit',
    'food_cost',
  ];

  return (
    <>
      <PageHeader title="Продажи" donwnload />
      <div className="sales-report__container">
        <div className="sales-report__header">
          <Banner banner={data} revenue />
        </div>
        <div className="sales-report__middle">
          <div className="sales-report__middle-left-side" ref={hoverRefSales}>
            <div className="sales-report__wrapper-sales">
              <Sales isHoveredSales={isHoveredSales} />
            </div>
          </div>
          <div className="sales-report__middle-left-side">
            <div className="sales-report__wrapper-revenue">
              {/* <Sales isHoveredSales={isHoveredSales} /> */}
              <Revenue />
            </div>
          </div>
        </div>
        <div className="sales-report__bottom-container">
          <div className="sales-report__bottom-wrapper">
            <div className="sales-report__bottom-left-side" ref={hoverRefCheque}>
              <div className="sales-report__wrapper">
                <Checque isHoveredCheque={isHoveredCheque} data={checkData} />
              </div>
            </div>
            <div>
              <div className="sales-report__wrapper-bottom-right">
                <div className="sales-report__top-text">
                  <div>Рейтинг официантов</div>
                  {/* <ThreeDotsIcon /> */}
                </div>
                <StaffRatingSlider isMenuOpen={isMenuOpen} />
              </div>
            </div>
          </div>
          <div className="sales-report__bottom-wrapper">
            <div>
              <div className="sales-report__wrapper">
                <TopTenWidget />
              </div>
            </div>
          </div>
        </div>
        <div className="sales-report__table">
          <div className="sales-report__table-wrapper">
            <NewTable
              isFoodcost={true}
              gridColumns={'22.77% 12.87% 12.87% 12.87% 12.87% 12.87% 12.87%'}
              columnsName={[
                'Название',
                'Цена',
                'Кол-во проданного',
                'Сумма себестоимости',
                'Выручка',
                'Валовая прибыль',
                'Фудкост, %',
              ]}
              Component={TableItem}
              TableSectionInner={TableSectionInner}
              tableData={TABLE__DATA}
            />
          </div>
        </div>
      </div>
    </>
  );
};
