import React, { useEffect, useState } from 'react';
import './AddCircle.scss';

export const AddCircle = ({ size = 77, strokeWidth = 7, percentage, nps_name }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(percentage);
  }, [percentage]);

  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;

  return (
        <svg width={size} height={size} viewBox={viewBox}>
          <circle
            fill="none"
            stroke="#67A8F4"
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`2px`}
            strokeDasharray="5"
          />
          <circle
            fill="#F9F9F9"
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
            transform={`rotate(-90 ${size / 2} ${size / 2})`}
            strokeDasharray={[dash, circumference - dash]}
            strokeLinecap="round"
            style={{ transition: 'all 0.5s' }}
          />
          <g>
             <text fill="#67A8F4" fontSize="20px" x="50%" y="15%" dy="20px" textAnchor="middle">
            {`+`}
          </text>
          <text fill="#67A8F4" fontSize="10px" x="50%" y="30%" dy="20px" textAnchor="middle">
            Добавить
          </text>
          <text fill="#67A8F4" fontSize="10px" x="50%" y="45%" dy="20px" textAnchor="middle">
            {`Раздел`}
            </text>
            </g>
        </svg>

  );
};
