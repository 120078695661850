import React, { useState } from 'react';
import {
  infoWriteOff,
  infoWriteOffSecond,
  subinfoWriteOff,
  subinfoWriteOffSecond,
  WRITE_OFF_COLUMNS_TITLE,
  WRITE_OFF_COLUMNS_TITLE_SECOND,
  WRITE_OFF_COLUMNS_TITLE_PAYMENT,
} from '../../../../../components/reports/Table/const';
import { Table } from '../../../../../components/reports/Table/Table';
import './TabsTable.scss';
import {
  TABLE_DATA,
  CANCELLATIONS_TABLE_DATA,
  PAYMENTS_TABLE_DATA,
} from '../../../write-off/const';
import { NewTable } from '../../../../../components/common/NewTable/NewTable';
import { TableItem } from '../../../../../components/common/NewTable/TableItem/TableItem';
import { TableSelect } from '../../../../../components/common/NewTable/TableSelect/TableSelect';

export const TabsTable = ({ cancellationsTable }) => {
  const [activeTab, setActiveTab] = useState('tab1');
  const TableSectionInner = ['quantity', 'const_sum', 'sum', 'percent'];
  const TableSectionInnerReasons = ['quantity', 'sum', 'percent'];
  const TableSectionInnerServicePayments = ['quantity', 'const_sum', 'sum', 'percent'];

  const handleTab1 = () => {
    setActiveTab('tab1');
  };
  const handleTab2 = () => {
    setActiveTab('tab2');
  };
  const handleTab3 = () => {
    setActiveTab('tab3');
  };
  return (
    <div className="tabs-table">
      <div className="tabs-table__head">
        <div className="tabs-table__head-left">
          <ul className="tabs-table__nav">
            <li className={activeTab === 'tab1' ? 'active' : ''} onClick={handleTab1}>
              Отмена блюд
            </li>
            <li className={activeTab === 'tab2' ? 'active' : ''} onClick={handleTab2}>
              Списания по статьям
            </li>
            <li className={activeTab === 'tab3' ? 'active' : ''} onClick={handleTab3}>
              Служебные оплаты
            </li>
          </ul>
        </div>
        <TableSelect />
      </div>
      <div></div>
      <div className="outlet">
        {activeTab === 'tab1' && (
          <NewTable
            gridColumns={'43.15% 14.27% 14.27% 14.27% 14.27%'}
            columnsName={[
              'Название',
              'Сумма себестоимости, ₽',
              'Сумма, ₽',
              'Количество, пц',
              '% от всех отмен',
            ]}
            isWriteOff={true}
            marginLeft={0}
            Component={TableItem}
            TableSectionInner={TableSectionInner}
            tableData={CANCELLATIONS_TABLE_DATA}
          />
        )}
        {activeTab === 'tab2' && (
          <NewTable
            isWriteOff={true}
            gridColumns={'43% 19% 19% 19%'}
            columnsName={[
              'Статья отмены',
              'Сумма себестоимости, ₽',
              'Количество',
              '% от своей статьи',
            ]}
            marginLeft={0}
            Component={TableItem}
            TableSectionInner={TableSectionInnerReasons}
            tableData={TABLE_DATA}
          />
        )}
        {activeTab === 'tab3' && (
          <NewTable
            gridColumns={'43.15% 14.27% 14.27% 14.27% 14.27%'}
            columnsName={[
              'Название',
              'Сумма себестоимости, ₽',
              'Сумма, ₽',
              'Количество, пц',
              '% от всех отмен',
            ]}
            isWriteOff={true}
            marginLeft={0}
            Component={TableItem}
            TableSectionInner={TableSectionInnerServicePayments}
            tableData={PAYMENTS_TABLE_DATA}
          />
        )}
      </div>
    </div>
  );
};
