import { GoogleWhiteIcon } from '../icons/registration/google-white';
import { TofuWhiteIcon } from '../icons/registration/tofu-white';
import { TripWhiteIcon } from '../icons/registration/trip-white';
import { YandexWhiteIcon } from '../icons/registration/yandex-white';
import { TofuIcon, GoogleIcon, YandexIcon, TripadvisorIcon } from '../icons/reviewsPlatforms';

export const ProviderTypes = [
  { id: 0, name: 'GOOGLE', icon: <GoogleIcon /> },
  { id: 1, name: 'YANDEX', icon: <YandexIcon /> },
  { id: 2, name: 'TWO_GIS', icon: <></> },
  { id: 3, name: 'TRIPADVISOR', icon: <TripadvisorIcon /> },
  { id: 4, name: 'TOFU', icon: <TofuIcon /> },
];

export const ProviderTypesWhite = [
  { id: 0, name: 'GOOGLE', icon: <GoogleWhiteIcon /> },
  { id: 1, name: 'YANDEX', icon: <YandexWhiteIcon /> },
  { id: 2, name: 'TWO_GIS', icon: <></> },
  { id: 3, name: 'TRIPADVISOR', icon: <TripWhiteIcon /> },
  { id: 4, name: 'TOFU', icon: <TofuWhiteIcon /> },
];

