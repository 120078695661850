import React, { useEffect, useState } from 'react';
import { foodcostApi } from '../../../../../api/foodcost';
import { Loader } from '../../../../../components/common/Loader/Loader';
import { TopTenProductsInfo } from '../../../../../components/reports/TopTenProductsInfo/TopTenProductsInfo';
import { Header } from './Header/Header';
import './TopTenWidget.scss';

const TOP_TEN_PRODUCTS_DATA = {
  banner_text: 'расхода 10 продуктов от общей',
  banner_text_bold: 'себестоимости',
  left_column_title: 'Название продукта',
  middle_column_title: 'Расход',
  right_column_title: 'Сумма, ₽',
};

export const TopTenWidget = ({ bannerData }) => {
  const [data, setData] = useState({
    tabTitle: 'По смайлику',
    percent: '32%',
    banner_text: '10 портящихся продуктов от общего',
    banner_text_bold: 'числа продуктов',
    completed: '32',
    progress_bar_current_sum: '186 250,12',
    progress_bar_total_sum: '576 020,34',
    list: [
      { id: 1, product_name: 'Филе форели (замороженное)', coast: '22,486 кг', sum: '29 872,79' },
      { id: 2, product_name: 'Говядина вырезка', coast: '28,349 кг', sum: '27 546,05' },
      { id: 3, product_name: 'Креветка с/м б/г', coast: '28,14 кг', sum: '26 487,82' },
      { id: 4, product_name: 'Филе форели (охлажденное)', coast: '10,527 кг', sum: '20 526,12' },
      { id: 5, product_name: 'Уголь', coast: '2263,00 шт', sum: '18 267,94' },
      { id: 6, product_name: 'Сыр творожный', coast: '40,356 кг', sum: '17 339,99' },
      { id: 7, product_name: 'Сябры', coast: '23,859 л', sum: '13 210,77' },
      { id: 8, product_name: 'Ребра свиные', coast: '30,219 кг', sum: '12 430,81' },
      { id: 9, product_name: 'Aperol Aperitivo', coast: '5,28 л', sum: '10 369,77' },
      { id: 10, product_name: 'Jim Beam', coast: '4,915 л', sum: '10 198,06' },
    ],
  });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Склад');
  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    switch (selectedOption) {
      case 'Все':
        setData({
          tabTitle: 'По смайлику',
          percent: '32%',
          banner_text: '10 портящихся продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '32',
          progress_bar_current_sum: '186 250,12',
          progress_bar_total_sum: '576 020,34',
          list: [
            {
              id: 1,
              product_name: 'Филе форели (замороженное)',
              coast: '22,486 кг',
              sum: '29 872,79',
            },
            { id: 2, product_name: 'Говядина вырезка', coast: '28,349 кг', sum: '27 546,05' },
            { id: 3, product_name: 'Креветка с/м б/г', coast: '28,14 кг', sum: '26 487,82' },
            {
              id: 4,
              product_name: 'Филе форели (охлажденное)',
              coast: '10,527 кг',
              sum: '20 526,12',
            },
            { id: 5, product_name: 'Уголь', coast: '2263,00 шт', sum: '18 267,94' },
            { id: 6, product_name: 'Сыр творожный', coast: '40,356 кг', sum: '17 339,99' },
            { id: 7, product_name: 'Сябры', coast: '23,859 л', sum: '13 210,77' },
            { id: 8, product_name: 'Ребра свиные', coast: '30,219 кг', sum: '12 430,81' },
            { id: 9, product_name: 'Aperol Aperitivo', coast: '5,28 л', sum: '10 369,77' },
            { id: 10, product_name: 'Jim Beam', coast: '4,915 л', sum: '10 198,06' },
          ],
        });
        break;
      case 'Кухня':
        setData({
          tabTitle: 'По смайлику',
          percent: '58%',
          banner_text: '10 портящихся продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '58',
          progress_bar_current_sum: '161 375,07',
          progress_bar_total_sum: '278 458,93',
          list: [
            {
              id: 1,
              product_name: 'Филе форели (замороженное)',
              coast: '22,486 кг',
              sum: '29 872,79',
            },
            { id: 2, product_name: 'Говядина вырезка', coast: '28,349 кг', sum: '27 546,05' },
            { id: 3, product_name: 'Креветка с/м б/г', coast: '28,14 кг', sum: '26 487,82' },
            {
              id: 4,
              product_name: 'Филе форели (охлажденное)',
              coast: '10,527 кг',
              sum: '20 526,12',
            },
            { id: 5, product_name: 'Сыр творожный', coast: '40,356 кг', sum: '17 339,99' },
            { id: 6, product_name: 'Ребра свиные', coast: '30,219 кг', sum: '12 430,81' },
            { id: 7, product_name: 'Сливки 20%', coast: '21,16 л', sum: '8 113,02' },
            { id: 8, product_name: 'Рис', coast: '70,644 кг', sum: '7 321,92' },
            { id: 9, product_name: 'Грудинка к/в', coast: '12,89 кг', sum: '6 210,83' },
            { id: 10, product_name: 'Хлеб чиабатта', coast: '21,041 кг', sum: '5 525,72' },
          ],
        });
        break;
      case 'Бар':
        setData({
          tabTitle: 'По смайлику',
          percent: '35%',
          banner_text: '10 портящихся продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '35',
          progress_bar_current_sum: '83 071,56',
          progress_bar_total_sum: '235 717,07',
          list: [
            { id: 1, product_name: 'Сябры', coast: '23,859 л', sum: '13 210,77' },
            { id: 2, product_name: 'Aperol Aperitivo', coast: '5,28 л', sum: '10 369,77' },
            { id: 3, product_name: 'Jim Beam', coast: '4,915 л', sum: '10 198,06' },
            { id: 4, product_name: 'Amaretto, Marie Brizard', coast: '3,846 л', sum: '7 996,17' },
            { id: 5, product_name: 'Coca-Cola', coast: '229 шт', sum: '7 883,32' },
            {
              id: 6,
              product_name: 'I Castelli Romeo e Giulietta Prosecco',
              coast: '9 л',
              sum: '7 549,34',
            },
            { id: 7, product_name: 'Ballantine’s', coast: '3,6 л', sum: '6 918,51' },
            { id: 8, product_name: 'Bonaqua газ.', coast: '184,2 шт', sum: '6 574,81' },
            {
              id: 9,
              product_name: 'Сок Rich коробка апельсин',
              coast: '66,295 л',
              sum: '6 431,96',
            },
            { id: 10, product_name: 'Аливария Белое Золото', coast: '89 шт', sum: '5 938,85' },
          ],
        });
        break;
      case 'Кальян':
        setData({
          tabTitle: 'По смайлику',
          percent: '63%',
          banner_text: '10 портящихся продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '63',
          progress_bar_current_sum: '39 184,90',
          progress_bar_total_sum: '61 844,05',
          list: [
            { id: 1, product_name: 'Уголь', coast: '2263 шт', sum: '18 267,94' },
            { id: 2, product_name: 'WTO табак', coast: '0,202 кг', sum: '5 561,44' },
            { id: 3, product_name: 'Bonche табак', coast: '0,202 кг', sum: '2 907,00' },
            { id: 4, product_name: 'Kraken табак', coast: '0,202 кг', sum: '2 730,15' },
            { id: 5, product_name: 'Satyr brilliant табак', coast: '0,202 кг', sum: '2 022,18' },
            { id: 6, product_name: 'Tangiers табак', coast: '0,202 кг', sum: '1 941,33' },
            { id: 7, product_name: 'Adam Eva табак', coast: '0,202 кг', sum: '1 459,64' },
            { id: 8, product_name: 'Smoke Angels табак', coast: '0,202 кг', sum: '1 455,93' },
            { id: 9, product_name: 'НАШ табак', coast: '0,202 кг', sum: '1 452,50' },
            { id: 10, product_name: 'Chabacco табак', coast: '0,202 кг', sum: '1 386,79' },
          ],
        });
        break;
    }
  }, [selectedOption]);
  const handleMainOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [topTenBytExpenseSelect, setTopTenBYExpenseSelect] = useState([]);
  const [totalTopTenByExpenses, setTotalTopTenByExpenses] = useState();

  const progressBarCompleted =
    (totalTopTenByExpenses / bannerData?.sales_cost_price_including_write_offs) * 100;

  const newOptions = topTenBytExpenseSelect.map((item) => {
    return {
      id: item.id,
      title: item.name,
    };
  });
  return (
    <>
      <div>
        <Header
          newOptions={newOptions}
          onMainOptionChange={handleMainOptionChange}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          selectOption={selectOption}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        <TopTenProductsInfo
          selectedOption={selectedOption}
          data={data}
          topTenProductsData={TOP_TEN_PRODUCTS_DATA}
          progressBarCompleted={progressBarCompleted.toFixed(0)}
        />
      </div>
    </>
  );
};
