import React from "react";

export const LogoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.7895 2.63158C15.7895 1.75955 15.0826 1.05263 14.2105 1.05263L4.21054 1.05263C3.33851 1.05263 2.63159 1.75955 2.63159 2.63158L2.63159 3.15789C2.63159 4.02992 3.33851 4.73684 4.21054 4.73684L7.36843 4.73684V15.7895C7.36843 16.6615 8.07535 17.3684 8.94738 17.3684H9.4737C10.3457 17.3684 11.0526 16.6615 11.0526 15.7895V4.73684L14.2105 4.73684C15.0826 4.73684 15.7895 4.02992 15.7895 3.1579V2.63158Z"
        fill="#67A8F4"
      />
      <path
        d="M37.3685 9.21053C37.3685 13.716 33.7161 17.3684 29.2106 17.3684C24.7051 17.3684 21.0527 13.716 21.0527 9.21053C21.0527 4.70505 24.7051 1.05264 29.2106 1.05264C33.7161 1.05264 37.3685 4.70505 37.3685 9.21053ZM24.7238 9.21053C24.7238 11.6885 26.7326 13.6974 29.2106 13.6974C31.6886 13.6974 33.6975 11.6885 33.6975 9.21053C33.6975 6.73251 31.6886 4.72369 29.2106 4.72369C26.7326 4.72369 24.7238 6.73251 24.7238 9.21053Z"
        fill="#67A8F4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2105 22.6316C15.0826 22.6316 15.7895 23.3385 15.7895 24.2105V24.7368C15.7895 25.6089 15.0826 26.3158 14.2105 26.3158L6.31582 26.3158V28.9474L14.2105 28.9474C15.0826 28.9474 15.7895 29.6543 15.7895 30.5263V31.0526C15.7895 31.9247 15.0826 32.6316 14.2105 32.6316L6.31582 32.6316V37.3684C6.31582 38.2404 5.6089 38.9474 4.73687 38.9474H4.21055C3.33852 38.9474 2.63161 38.2404 2.63161 37.3684V31.0592C2.6316 31.057 2.63159 31.0548 2.63159 31.0526L2.63159 30.5263C2.63159 30.5241 2.6316 30.5219 2.63161 30.5197V24.7434L2.63159 24.7368L2.63159 24.2105C2.63159 23.3385 3.33851 22.6316 4.21054 22.6316H4.21055H4.73687H14.2105Z"
        fill="#D5D5D5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.8948 26.3158C23.9122 26.3158 24.7369 25.491 24.7369 24.4737C24.7369 23.4563 23.9122 22.6316 22.8948 22.6316C21.8775 22.6316 21.0527 23.4563 21.0527 24.4737C21.0527 25.491 21.8775 26.3158 22.8948 26.3158ZM37.3685 24.4737C37.3685 25.491 36.5438 26.3158 35.5264 26.3158C34.5091 26.3158 33.6843 25.491 33.6843 24.4737C33.6843 23.4563 34.5091 22.6316 35.5264 22.6316C36.5438 22.6316 37.3685 23.4563 37.3685 24.4737ZM35.8444 30.7895C36.6494 30.7895 37.3152 31.4472 37.1722 32.2394C37.0689 32.8113 36.9045 33.3719 36.6811 33.9114C36.2711 34.9011 35.6702 35.8004 34.9127 36.558C34.1551 37.3155 33.2558 37.9164 32.266 38.3264C31.2763 38.7364 30.2155 38.9474 29.1441 38.9474C28.0728 38.9474 27.012 38.7364 26.0223 38.3264C25.0325 37.9164 24.1332 37.3155 23.3756 36.558C22.6181 35.8004 22.0172 34.9011 21.6072 33.9114C21.3838 33.3719 21.2194 32.8113 21.1161 32.2394C20.9731 31.4472 21.6389 30.7895 22.4439 30.7895H23.3628C24.1678 30.7895 24.7976 31.46 25.0639 32.2197C25.0903 32.2952 25.1189 32.37 25.1496 32.4441C25.3669 32.9686 25.6854 33.4453 26.0868 33.8468C26.4883 34.2483 26.965 34.5667 27.4895 34.784C28.0141 35.0013 28.5764 35.1132 29.1441 35.1132C29.7119 35.1132 30.2742 35.0013 30.7988 34.784C31.3233 34.5667 31.8 34.2483 32.2015 33.8468C32.6029 33.4453 32.9214 32.9686 33.1387 32.4441C33.1694 32.37 33.198 32.2952 33.2244 32.2197C33.4907 31.46 34.1205 30.7895 34.9255 30.7895H35.8444Z"
        fill="#D5D5D5"
      />
    </svg>
  );
};
