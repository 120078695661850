// FirstTab.js

import React from 'react';
import ChartFirst from '../../ChartFirst/ChartFirst';
import './FirstTab.scss';
const FirstTab = () => {
  return (
    <div className="FirstTab">
      <ChartFirst />
    </div>
  );
};
export default FirstTab;
