import React, { useState } from 'react';
import { HoverPercent } from '../../../../../dayResult/components/ThreeCardsTop/HoverPercent/HoverPercent';
import { Hover } from '../../../../../../../components/common/Hover/Hover';
import './ProgressPercent.scss';

export const ProgressPercent = ({
  index,
  dataHoverPercent,
  progressPercentColor,
  progressPercent,
}) => {
  return (
    <div className="banner-statistics__percent-right">
      <Hover
        containerClassName={`banner-statistics__percent-right_text ${progressPercentColor}`}
        hoverContainerClassName={'banner-statistics__percent-right_hover'}
        title={progressPercent}
        isArray
        index={index}
      >
        <HoverPercent hoveredIndex={index} dataHoverPercent={dataHoverPercent} />
      </Hover>
    </div>
  );
};
