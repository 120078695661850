import { DateArrowIcon } from '../../../../icons/date-arrow-icon';
import './NavButton.scss';
export const NavButton = ({ direction, onClick }) => {
  return (
    <button type="button" onClick={onClick} className="arrow-btn">
      {direction === 'left' ? (
        <div className="left">
          <DateArrowIcon direction="left" />
        </div>
      ) : (
        <div className="right">
          <DateArrowIcon />
        </div>
      )}
    </button>
  );
};
