import React from 'react';
import moment from 'moment';
import './AreaChart.scss';
import { XAxis, YAxis, Tooltip, Area, AreaChart, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: '01.07 - 02.07',
    uv: 56550.84,
    amt: 61800.08,
    percent: 20,
    foodcostWithDiscout: 25.43,
    foodcost: 23.27,
  },
  {
    name: '03.07 - 09.07',
    uv: 122435.36,
    amt: 125635.61,
    percent: 22,
    foodcostWithDiscout: 24.34,
    foodcost: 23.72,
  },
  {
    name: '10.07 - 16.07',
    uv: 126255.02,
    amt: 134771.81,
    percent: 24,
    foodcostWithDiscout: 26.11,
    foodcost: 24.46,
  },
  {
    name: '17.07 - 23.07',
    uv: 126634.91,
    amt: 142038.79,
    percent: 26,
    foodcostWithDiscout: 24.62,
    foodcost: 21.95,
  },
  {
    name: '24.07 - 31.07',
    uv: 139715.58,
    amt: 162161.9,
    percent: 28,
    foodcostWithDiscout: 25.43,
    foodcost: 21.92,
  },
];

const graphHeigth = 125;

export const ChartArea = ({ numAdditionStore, numAdditionReason }) => {
  const multiplyGraph = numAdditionStore * numAdditionReason;
  const newData = data.map((item) => ({
    name: item.name,
    uv: item.uv * multiplyGraph,
    amt: item.amt * multiplyGraph,
    percent: item.percent,
    foodcostWithDiscout: Math.round(item.foodcostWithDiscout * multiplyGraph),
    foodcost: Math.round(item.foodcost * multiplyGraph),
  }));
  return (
    <ResponsiveContainer width="100%" height={graphHeigth}>
      <AreaChart data={newData} margin={{ top: 10, right: 20, left: 0, bottom: 0 }}>
        <defs>
          {/* <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
        </linearGradient> */}
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="70%" stopColor="#67A8F4" stopOpacity={0.05} />
            <stop offset="90%" stopColor="#FFFF" stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="name"
          tickLine={true}
          tick={{
            fill: '#9C9C9C',
            fontWeight: '400',
            fontSize: '10px',
            lineHeight: '15px',
          }}
          padding={{ left: 0, right: 20 }}
          stroke="#F6F6F6"
          interval={0}
        />
        <YAxis
          tickFormatter={(value) => value.toFixed(0) + '%'}
          tick={{
            fill: '#9C9C9C',
            fontWeight: '400',
            fontSize: '10px',
            lineHeight: '15px',
          }}
          stroke="#F6F6F6"
          padding={{ bottom: 10 }}
          width={37}
          orientation="left"
          domain={[20, 28]}
          dataKey="percent"
        />
        <Tooltip
          wrapperStyle={{ outline: 'none' }}
          content={CustomToolTip}
          cursor={{ fill: 'black', strokeWidth: 0, radius: 0 }}
          strokeDasharray={[5, 5]}
        />
        <Area
          type="monotone"
          dataKey="foodcostWithDiscout"
          stroke="#67A8F4"
          fillOpacity={1}
          fill="url(#colorPv)"
          strokeWidth={2}
        />
        <Area
          type="monotone"
          dataKey="foodcost"
          stroke="#0A3160"
          fillOpacity={1}
          fill="url(#colorUv)"
          strokeWidth={2}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

function CustomToolTip({ active, payload, label }) {
  if (active) {
    return (
      <div className="tooltip-foodcost" style={{ border: 'none', width: '200px' }}>
        {payload ? (
          <div>
            <div>
              <p className="tooltip-foodcost__date">{payload[0].payload.name}</p>
            </div>
            <div>
              <div className="tooltip-foodcost__column">
                <div className="tooltip-foodcost__circle"></div>
                <div>
                  <p className="tooltip-foodcost__label">Сумма:</p>
                  <p className="tooltip-foodcost__label">Процент:</p>
                </div>
                <div>
                  <p className="tooltip-foodcost__label">
                    <span>{Math.floor(payload[0].payload.uv)} ₽</span>
                  </p>
                  <p className="tooltip-foodcost__label">
                    <span>{Math.floor(payload[0].payload.foodcostWithDiscout)} %</span>
                  </p>
                </div>
              </div>
              <div className="tooltip-foodcost__column">
                <div className="tooltip-foodcost__circle-second"></div>
                <div>
                  <p className="tooltip-foodcost__label">Сумма:</p>
                  <p className="tooltip-foodcost__label">Процент:</p>
                </div>
                <div>
                  <p className="tooltip-foodcost__label">
                    <span>{Math.floor(payload[0].payload.amt)} ₽</span>
                  </p>
                  <p className="tooltip-foodcost__label">
                    <span>{Math.floor(payload[0].payload.foodcost)} %</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  return null; // Return null if not active
}
