import React, { useState } from 'react';
import ProgressBar from './ProgressBar/ProgressBar';
import './Revenue.scss';
import { HoverPercent } from '../ThreeCardsTop/HoverPercent/HoverPercent';
import './Revenue.scss';
import { HoverSettings } from '../../../../../components/common/HoverSettings/HoverSettings';
import { HoverDescription } from '../../../../../components/common/HoverSettings/HoverDescription/HoverDescription';
import { Hover } from '../../../../../components/common/Hover/Hover';

const PROGRESS_BAR_PERCENT = 88;
const PROGRESS_PERCENT = '- 13 %';
const CURRENT_REVENUE = '278 960,50';
const PLANNED_REVENUE = '317 000';

export const Revenue = ({ dataHoverPercentLeftSide, revenue }) => {
  return (
    <div className="revenue-banner-container">
      <div className="revenue-banner-top">
        <div className="revenue-banner-title">Выручка</div>
        <HoverSettings dayResult>
          <HoverDescription />
        </HoverSettings>
      </div>
      <div className="revenue-banner-data">
        <p className="revenue-banner-data__sum">{CURRENT_REVENUE} ₽</p>
        <Hover
          containerClassName={'revenue-banner-data__percent'}
          hoverContainerClassName={'revenue-banner-data__percent_hover'}
          title={PROGRESS_PERCENT}
        >
          <HoverPercent
            hoveredIndex={0}
            dataHoverPercent={dataHoverPercentLeftSide}
            revenue={revenue}
          />
        </Hover>
      </div>
      <p className="revenue-banner-plan">План {PLANNED_REVENUE} ₽</p>
      <ProgressBar completed={PROGRESS_BAR_PERCENT} />
    </div>
  );
};
