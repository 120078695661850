import { combineReducers } from "redux";
import { authReducer } from "./auth/authReducer";

const appReducer = combineReducers({
  auth: authReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};
