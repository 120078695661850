import { useState } from 'react';
import { Button } from '../../../../components/common/Button/Button';
import { TofuField } from '../../../../components/common/fields/TofuField/TofuField';
import { Modal } from '../../../../components/common/ModalWindow/ModalWindow';
import { UploadPhotoCopmonent } from '../../../../components/common/UploadPhotoCopmonent/UploadPhotoCopmonent';
import './AddStaffModal.scss';
import { EMPLOYEE_ROLE } from './const';
import { TofuSelect } from '../../../../components/common/fields/TofuSelect/TofuSelect';
import { authApi } from '../../../../api/authApi';

export const AddStaffModal = ({ isOpen, onClose, data, user_avatar, setAvatar, avatar }) => {
  const [email, setEmail] = useState(data.email);
  const [first_name, setFirst_name] = useState(data.first_name);
  const [last_name, setLast_name] = useState(data.last_name);
  const [date, setDate] = useState('');
  const [restaurant_name, setRestaurant_name] = useState('');
  const [phone, setPhone] = useState(data.phone);
  const newNumber = '+' + phone;
  const [employee_role, setEmployee_role] = useState('');

  const handleChange = (e, name) => {
    if (name === 'phone') {
      setPhone(e);
    }
  };
  const sendDataToServer = () => {
    const token = localStorage.getItem('authToken');
    const formData = new FormData();
    formData.append('first_name', first_name);
    formData.append('last_name', last_name);
    formData.append('phone', newNumber);
    formData.append('email', email);
    if (avatar instanceof File) {
      formData.append('user_avatar', avatar);
    }
    authApi
      .addUserName(formData, token)
      .then((response) => {
        window.location.reload();
        onClose();
      })
      .catch((error) => {
        console.error('not suc');
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} width={796}>
      <div className="staff-settings__modal-upload">
        <UploadPhotoCopmonent isUser avatar={user_avatar} setPhoto={setAvatar} />
      </div>
      <div className="staff-settings__modal-fields">
        <TofuField
          name="first_name"
          value={first_name}
          placeholder="Фамилия"
          onChange={(e) => setFirst_name(e.target.value)}
          label="Фамилия *"
        />
        {/* <TofuField
          name="email"
          value={email}
          placeholder="Дата рождения"
          onChange={(e) => setEmail(e.target.value)}
          label="Дата рождения"
          inputIcon={<CalendarIcon />}
        /> */}
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Выберите дату"
            className="qwef"
            slotProps={{ textField: { variant: 'standard' } }}
          />
        </LocalizationProvider> */}

        <TofuField
          name="last_name"
          value={last_name}
          placeholder="Имя"
          onChange={(e) => setLast_name(e.target.value)}
          label="Имя *"
        />
        <TofuField
          name="email"
          value={email}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
          label="email *"
        />
        {/* <TofuField
          name="restaurant_name"
          value={restaurant_name}
          placeholder="Отчество"
          onChange={(e) => setRestaurant_name(e.target.value)}
          label="Отчество"
        /> */}
        {/* <TofuField
          name="email"
          value={email}
          placeholder="Отчество"
          onChange={(e) => setEmail(e.target.value)}
          label="Отчество *"
        /> */}
        {/* <PhoneNumberInput phone={phone} handleChange={handleChange} /> */}
        {/* <TofuField
          name="email"
          value={email}
          placeholder="Должность"
          onChange={(e) => setEmail(e.target.value)}
          label="Должность *"
        /> */}
        {/* <TofuSelect
          onChange={(value) => setEmail(value.label)}
          name="email"
          value={email}
          type="select"
          options={EMPLOYEE_ROLE}
          label="Должность *"
          placeholder="Должность"
          inputIcon={<ArrowDownIcon />}
        /> */}
      </div>
      <div className="staff-settings__modal">
        <Button styleType="primary" onClick={sendDataToServer}>
          Сохранить
        </Button>
      </div>
    </Modal>
  );
};
