import React, { useCallback, useState } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { COLORS } from '../../../const/colors';
import { calculate } from '../../../helpers/calculate';
import './Circle.scss';

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
  return (
    <g>
      {payload.nps_type ? (
        <>
          <text x={cx} y={cy - 20} dy={8} textAnchor="middle" fill={'#2B2B2B'} fontSize="14">
            {payload.nps_type === 'Время ожидания' ? (
              <>
                <tspan x={cx} dy={-1} dominantBaseline="middle" className="time">
                  Время
                </tspan>
                <tspan x={cx} dy={13} dominantBaseline="middle" className="time">
                  ожидания
                </tspan>
              </>
            ) : (
              payload.nps_type
            )}
          </text>
          <text x={cx} y={cy} dy={8} textAnchor="middle" fill={'#9C9C9C'} fontSize="12">
            {payload.value} упоминаний
          </text>
          {payload.positive.toString().length === 1 ? (
            <text x={cx - 5} y={cy + 20} dy={8} textAnchor="middle" fill={'#84D9BF'} fontSize="12">
              {payload.positive}
            </text>
          ) : payload.positive.toString().length === 2 ? (
            <text x={cx - 9} y={cy + 20} dy={8} textAnchor="middle" fill={'#84D9BF'} fontSize="12">
              {payload.positive}
            </text>
          ) : (
            <text x={cx - 13} y={cy + 20} dy={8} textAnchor="middle" fill={'#84D9BF'} fontSize="12">
              {payload.positive}
            </text>
          )}
          <text x={cx + 3} y={cy + 20} dy={8} textAnchor="middle" fill={'#9C9C9C'} fontSize="12">
            /
          </text>
          {payload.negative.toString().length === 1 ? (
            <text x={cx + 11} y={cy + 20} dy={8} textAnchor="middle" fill={'#F56C7B'} fontSize="12">
              {payload.negative}
            </text>
          ) : payload.negative.toString().length === 2 ? (
            <text x={cx + 14} y={cy + 20} dy={8} textAnchor="middle" fill={'#F56C7B'} fontSize="12">
              {payload.negative}
            </text>
          ) : (
            <text x={cx + 18} y={cy + 20} dy={8} textAnchor="middle" fill={'#F56C7B'} fontSize="12">
              {payload.negative}
            </text>
          )}
        </>
      ) : (
        <>
          <text x={cx} y={cy - 15} dy={8} textAnchor="middle" fill={'#2B2B2B'} fontSize="14">
            {payload.rating}{' '}
            {payload.rating === 4
              ? 'звезды'
              : payload.rating === 2 || payload.rating === 3
              ? 'звезды'
              : payload.rating === 1
              ? 'звезда'
              : 'звезд'}
          </text>
          <text x={cx} y={cy + 10} dy={8} textAnchor="middle" fill={'#9C9C9C'} fontSize="12">
            {payload.value} отзывов
          </text>
        </>
      )}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 1}
        outerRadius={outerRadius + 4}
        fill={fill}
      />
    </g>
  );
};

export default function Circle({ sortedData }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const sortedChartData = calculate(sortedData);
  const finalSortedChartData = sortedChartData.sort(
    (a, b) => b.sectionPercentage - a.sectionPercentage
  );

  return (
    <ResponsiveContainer width="100%" height={130}>
      <PieChart>
        <Pie
          stroke="none"
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          isAnimationActive={false}
          data={finalSortedChartData}
          cx="50%"
          cy="50%"
          innerRadius={50}
          outerRadius={60}
          dataKey="value"
          onMouseEnter={onPieEnter}
        >
          {COLORS.map((entry, index) => (
            <Cell style={{ outline: 'none' }} key={`cell-${index}`} fill={entry} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
