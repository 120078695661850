import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { PathRoutes } from '../../../const/routes';
import { TabPanel } from '../../common/TabPanel/TabPanel';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const TabsSettings = ({ children, tabIndex }) => {
  const [value, setValue] = useState(tabIndex);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ '& .MuiTab-root': { textTransform: 'none' } }}
        >
          <Tab
            label="Информация о компании"
            component={Link}
            to={PathRoutes.CompanyInfo}
            {...a11yProps(tabIndex)}
          />
          <Tab
            label="Настройки сотрудников"
            component={Link}
            to={PathRoutes.UserInfo}
            {...a11yProps(tabIndex)}
          />
          <Tab
            label="Платформы с отзывами"
            component={Link}
            to={PathRoutes.PlatformsInfo}
            {...a11yProps(tabIndex)}
          />
          <Tab
            label="Настройка плана отчётов"
            component={Link}
            to={PathRoutes.Reports}
            {...a11yProps(tabIndex)}
          />
          <Tab
            label="Интеграция POS"
            component={Link}
            to={PathRoutes.PosIntegrations}
            {...a11yProps(tabIndex)}
          />
          <Tab
            label="Уведомления"
            component={Link}
            to={PathRoutes.Notification}
            {...a11yProps(tabIndex)}
          />
          <Tab
            label="Подписка"
            component={Link}
            to={PathRoutes.SettingsSubscription}
            {...a11yProps(tabIndex)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={tabIndex}>
        {children}
      </TabPanel>
    </Box>
  );
};
