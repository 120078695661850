import React, { useEffect, useState } from 'react';
import { WriteOffDynamic } from './components/WriteOffDynamic/WriteOffDynamic';
import { TopTenWidget } from './components/TopTenWidget/TopTenWidget';
import { WriteOff } from './components/WriteOff/WriteOff';
import { ThreeDotsIcon } from '../../../icons/three-dots-icon';
import './WriteOff.scss';
import { PageHeader } from '../../../components/common/PageHeader/PageHeader';
import useSetBodyScroll from '../../../hooks/useSetBodyScroll';
import {
  info,
  infoWriteOff,
  subinfo,
  subinfoWriteOff,
  WRITE_OFF_COLUMNS_TITLE,
} from '../../../components/reports/Table/const';
import { TabsTable } from './components/TabsTable/TabsTable';
import { writeOffApi } from '../../../api/writeOff';
import { foodcostApi } from '../../../api/foodcost';
import { Loader } from '../../../components/common/Loader/Loader';
import { Banner } from '../foodcost/components/Banner/Banner';
import { TwoCards } from '../../../components/reports/TwoCards/TwoCards';

const data = {
  title: 'Фудкост со скидками и списаниями',
  total_percent: '26,1 %',
  change_percent: '2,6%',
  total_sum: '10 809,74',
  list: [
    { title: 'Фудкост', percent: 23.5, percent_up: 1.37, sum: 9751.58 },
    { title: 'Скидки', percent: 2.3, percent_up: 2.45, sum: 954.82 },
    { title: 'Списания', percent: 2.3, percent_up: 2.45, sum: 954.82 },
  ],
};

export const WriteOffPage = ({ account_id }) => {
  const token = localStorage.getItem('authToken');

  const [cancellationsTable, setCancellationsTable] = useState([]);

  useEffect(() => {
    writeOffApi.getCancellationsTable(account_id, token).then((res) => {
      setCancellationsTable(res.data);
    });
  }, [account_id, token]);

  const setBodyScroll = useSetBodyScroll();
  useEffect(() => {
    setBodyScroll(true);
    return () => {
      setBodyScroll(false);
    };
  }, [setBodyScroll]);

  const bannerData = {
    title: 'Все списания',
    sum: '12 674,06 ₽',
    percent: '4,6 %',
    percentProgressBar: 85,
  };

  const bannerList = [
    {
      title: 'Бракераж',
      sum: '278,42',
      percent: '1,9',
      progressPercent: '+1,37%',
      progressPercentColor: 'red',
    },
    {
      title: 'Порча',
      sum: '2 205,15',
      percent: '1,4',
      progressPercent: '-2,45%',
      progressPercentColor: 'green',
    },
    {
      title: 'Питание персонала',
      sum: '1 205,15',
      percent: '1,1',
      progressPercent: '+0,37%',
      progressPercentColor: 'red',
    },
    {
      title: 'Бракераж продукции не находящейся на учете',
      sum: '278,42',
      percent: '1,9',
      progressPercent: '+0,44%',
      progressPercentColor: 'red',
    },
    {
      title: 'Порча продукции не находящейся на учете',
      sum: '2 205,15',
      percent: '1,4',
      progressPercent: '+0,67%',
      progressPercentColor: 'red',
    },
    {
      title: 'Питание продукции не находящейся на учете',
      sum: '1 205,15',
      percent: '1,1',
      progressPercent: '+0,22%',
      progressPercentColor: 'red',
    },
  ];
  const TwoCardsData = [
    {
      title: 'Удаление блюд со списанием',
      percent: '4,6%',
      percentDiff: '-2,45%',
      color: 'green',
      sum: '12 674,06',
    },
    {
      title: 'Служебные оплаты',
      percent: '4,6%',
      percentDiff: '+1,37%',
      color: 'red',
      sum: '12 674,06',
    },
  ];

  const dataHoverPercent = [
    {
      percent: '0,53%',
      sum: '270,60',
    },
    {
      percent: '3,85%',
      sum: '2 254,38',
    },
    {
      percent: '0,73%',
      sum: '1 180,77',
    },
    {
      percent: '1,46%',
      sum: '270,32',
    },
    {
      percent: '0,73%',
      sum: '2 180,77',
    },
    {
      percent: '0,88%',
      sum: '1 180,77',
    },
  ];
  const dataHoverPercentBanner = [
    {
      sum: '12 984,57',
    },
  ];

  return (
    <>
      <PageHeader title="Списания" donwnload isWriteOff />
      <div className="write-off__container">
        <div className="write-off__right-side">
          <div>
            <Banner
              banner={bannerData}
              bannerList={bannerList}
              dataHoverPercent={dataHoverPercent}
              dataHoverPercentLeftSidePercent={dataHoverPercentBanner}
              revenue
            />
          </div>
          <div className="write-off__right-side-up">
            <div className="write-off__first-column">
              <div className="write-off__dynamic">
                <div className="write-off__chart-container">
                  <WriteOffDynamic account_id={account_id} token={token} />
                </div>
              </div>
              <TwoCards data={TwoCardsData} />
              <div className="write-off__write-off">
                <div className="write-off__cloud-container-write-off">
                  <div className="write-off__cloud-container-header">
                    <div className="write-off__cloud-container-title-write-off">
                      Отмена по сотрудникам{' '}
                    </div>
                    {/* <ThreeDotsIcon /> */}
                  </div>
                  <WriteOff />
                </div>
              </div>
            </div>
            <div className="write-off__second-column">
              <div className="write-off__top-ten-widget">
                <div className="write-off__cloud-container">
                  <TopTenWidget />
                </div>
              </div>
            </div>
          </div>
          <div className="write-off__table">
            <div className="write-off__table-wrapper">
              <TabsTable cancellationsTable={cancellationsTable} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
