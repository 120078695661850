import { Button } from '../../../../components/common/Button/Button';
import './SecondWidget.scss';

export const SecondWidget = () => {
  return (
    <div className="second-widget__container">
      <div className="second-widget__top">
        <div className="second-widget__top-title">Информация о выставлении счетов</div>
        <div className="second-widget__top-desc">
          Ваша подписка действительна до <span>20 июня 2023 года.</span> В этот день вам необходимо
          будет выставить счёт на сумму <span>$9,99.</span>
        </div>
      </div>
      <div className="second-widget__btns">
        <Button styleType="primary">Выставить счет</Button>
        <Button styleType="secondary">Получить акт выполненных работ</Button>
      </div>
    </div>
  );
};
