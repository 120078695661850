import React from 'react';

export const TofuWhiteIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.55225 3.06018C4.55225 2.54207 4.97225 2.12207 5.49035 2.12207H5.64215C6.16026 2.12207 6.58026 2.54208 6.58026 3.06018V10.1174C6.58026 10.6355 6.16026 11.0555 5.64216 11.0555H5.49035C4.97225 11.0555 4.55225 10.6355 4.55225 10.1174V3.06018Z"
        fill="white"
      />
      <path
        d="M8.20887 2.12207C8.72697 2.12207 9.14697 2.54208 9.14697 3.06018L9.14697 3.1198C9.14697 3.6379 8.72697 4.0579 8.20887 4.0579L2.95049 4.0579C2.43239 4.0579 2.01238 3.6379 2.01238 3.1198L2.01238 3.06018C2.01238 2.54207 2.43239 2.12207 2.95049 2.12207L8.20887 2.12207Z"
        fill="white"
      />
      <path
        d="M21.6818 6.51694C21.6818 9.01158 19.5329 11.0339 16.8822 11.0339C14.2314 11.0339 12.0825 9.01158 12.0825 6.51694C12.0825 4.0223 14.2314 2 16.8822 2C19.5329 2 21.6818 4.0223 21.6818 6.51694ZM13.9396 6.51694C13.9396 8.04635 15.257 9.28617 16.8822 9.28617C18.5073 9.28617 19.8247 8.04635 19.8247 6.51694C19.8247 4.98754 18.5073 3.74771 16.8822 3.74771C15.257 3.74771 13.9396 4.98754 13.9396 6.51694Z"
        fill="white"
      />
      <path
        d="M2 14.1461C2 13.628 2.42 13.208 2.93811 13.208H3.08991C3.60801 13.208 4.02802 13.628 4.02802 14.1461V21.0003C4.02802 21.5184 3.60801 21.9384 3.08991 21.9384H2.93811C2.42001 21.9384 2 21.5184 2 21.0003V14.1461Z"
        fill="white"
      />
      <path
        d="M8.08142 13.208C8.59953 13.208 9.01953 13.628 9.01953 14.1461L9.01953 14.2057C9.01953 14.7238 8.59953 15.1438 8.08142 15.1438L2.9852 15.1438C2.4671 15.1438 2.04709 14.7238 2.04709 14.2057L2.04709 14.1461C2.04709 13.628 2.4671 13.208 2.9852 13.208L8.08142 13.208Z"
        fill="white"
      />
      <path
        d="M8.08142 16.376C8.59953 16.376 9.01953 16.796 9.01953 17.3141L9.01953 17.3737C9.01953 17.8918 8.59953 18.3118 8.08142 18.3118L2.9852 18.3118C2.4671 18.3118 2.04709 17.8918 2.04709 17.3737L2.04709 17.3141C2.04709 16.796 2.4671 16.376 2.9852 16.376L8.08142 16.376Z"
        fill="white"
      />
      <path
        d="M21.0288 17.2232C21.5099 17.2232 21.9083 17.6167 21.8165 18.0889C21.7533 18.414 21.6546 18.7327 21.5214 19.0396C21.2715 19.6155 20.9052 20.1388 20.4434 20.5795C19.9817 21.0203 19.4335 21.3699 18.8302 21.6085C18.2269 21.847 17.5803 21.9698 16.9272 21.9698C16.2742 21.9698 15.6276 21.847 15.0243 21.6085C14.421 21.3699 13.8728 21.0203 13.411 20.5795C12.9493 20.1388 12.583 19.6155 12.3331 19.0396C12.1999 18.7327 12.1012 18.414 12.038 18.0889C11.9462 17.6167 12.3446 17.2232 12.8257 17.2232L13.2475 17.2232C13.7286 17.2232 14.1053 17.6239 14.2651 18.0777C14.2855 18.1355 14.3079 18.1926 14.3324 18.2491C14.4736 18.5744 14.6804 18.8699 14.9413 19.1189C15.2021 19.3678 15.5117 19.5653 15.8524 19.7001C16.1932 19.8348 16.5584 19.9041 16.9272 19.9041C17.2961 19.9041 17.6613 19.8348 18.002 19.7001C18.3428 19.5653 18.6524 19.3678 18.9132 19.1189C19.174 18.8699 19.3809 18.5744 19.5221 18.2491C19.5466 18.1926 19.569 18.1355 19.5894 18.0777C19.7492 17.6239 20.1259 17.2232 20.6069 17.2232H21.0288Z"
        fill="white"
      />
      <ellipse cx="20.9328" cy="14.2251" rx="1.06557" ry="1.01713" fill="white" />
      <ellipse cx="13.0202" cy="14.2251" rx="1.06557" ry="1.01713" fill="white" />
    </svg>
  );
};
