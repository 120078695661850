import ProgressBar from '../ProgressBar/ProgressBar';
import './TopBanner.scss';

export const TopBanner = ({ banner, bannerText }) => {
  return (
    <>
      {banner.list ? (
        <div className="top-banner__conatiner">
          <div className="top-banner__info">
            <div className="top-banner__info-percent">{banner.percent}</div>
            <div className="top-banner__info-title">
              <span>Доля</span> {bannerText.banner_text}
              <span> {bannerText.banner_text_bold}</span>
            </div>
          </div>
          <ProgressBar
            completed={banner.completed}
            currentSum={banner.progress_bar_current_sum}
            totalSum={banner.progress_bar_total_sum}
          />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};
