import React from 'react';

export const ArrowDownIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7072 15.7067C12.3167 16.0972 11.6835 16.0972 11.293 15.7067L5.63616 10.0498C5.24563 9.65935 5.24563 9.02619 5.63616 8.63566C6.02668 8.24514 6.65985 8.24514 7.05037 8.63566L12.0001 13.5854L16.9498 8.63566C17.3404 8.24514 17.9735 8.24514 18.3641 8.63566C18.7546 9.02619 18.7546 9.65935 18.3641 10.0498L12.7072 15.7067Z"
        fill="#67A8F4"
      />
    </svg>
  );
};
