import React from 'react';

export const EditIcon = ({isClicked}) => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1315 1.16091C15.9599 -0.0106684 14.0604 -0.0106684 12.8889 1.16091L12.1818 1.86801L17.1315 6.81776L17.8386 6.11065C19.0102 4.93908 19.0102 3.03959 17.8386 1.86801L17.1315 1.16091ZM15.7173 8.232L10.7675 3.28223L1.67657 12.3732C1.47778 12.572 1.33886 12.8227 1.27565 13.0966L0.247592 17.5515C0.0812322 18.2724 0.727332 18.9183 1.44804 18.7519L5.90295 17.7239C6.17688 17.6607 6.42753 17.5218 6.62632 17.323L15.7173 8.232Z"
        fill={isClicked ? "#67A8F4" : "#E3E3E3"}
      />
    </svg>
  );
};
