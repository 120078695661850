import React, { useState, useEffect } from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { CheckBox } from '../../../../../components/common/CheckBox/CheckBox';
import { EditIcon } from '../../../../../icons/edit-icon';
import { reviewsApi } from '../../../../../api/reviewsApi';
import { authApi } from '../../../../../api/authApi';
import { CloseIcon } from '../../../../../icons/close-icon';
import './Popover.scss';

export default function PopoverInfo({
  setServerData,
  children,
  dataWithSelected,
  setDataWithSelected,
  serverData,
  setCheckedItems,
  checkedItems,
  account_id,
  vertical = 27,
  horizontal = -320,
  popoverTitle,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsClicked(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authApi.getAccountId();
        const accountId = response.data[0].id;
        // setAccount_id(accountId);

        const res = await reviewsApi.getAll(accountId);
        setDataWithSelected((prevData) => {
          return prevData.map((item) => {
            const dataItem = res.data.find((d) => d.nps_type === item.id);
            return dataItem ? { ...item, selected: dataItem.selected } : item;
          });
        });
      } catch (error) {
        console.log(error);
      }
    };

    if (account_id !== '') {
      fetchData();
    }
  }, [account_id, setDataWithSelected]);

  const handleClose = () => {
    setAnchorEl(null);
    setIsClicked(false);
  };

  const handleCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;

    let newDataWithSelected = dataWithSelected.map((dataItem) => {
      if (dataItem.id === item.id) {
        return { ...dataItem, selected: isChecked };
      }
      return dataItem;
    });

    const selectedItems = newDataWithSelected.filter((dataItem) => dataItem.selected);
    if (selectedItems.length <= 5) {
      setDataWithSelected(newDataWithSelected);
      setCheckedItems(selectedItems.map((dataItem) => ({ nps_type: dataItem.id })));
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleCancelClick = () => {
    setCheckedItems(serverData);
    handleClose();
    const fetchData = async () => {
      try {
        const response = await authApi.getAccountId();
        const accountId = response.data[0].id;
        const res = await reviewsApi.getAll(accountId);
        setDataWithSelected((prevData) => {
          return prevData.map((item) => {
            const dataItem = res.data.find((d) => d.nps_type === item.id);
            return dataItem ? { ...item, selected: dataItem.selected } : item;
          });
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  };

  const handleSaveClick = () => {
    reviewsApi
      .sendNps(account_id, checkedItems, 0)
      .then((res) => {
        handleClose();
        setServerData(res.data);
      })
      .catch((err) => {
        console.log(err, 'catch');
      });
  };

  return (
    <div className="popover">
      <Button
        disableRipple
        onClick={handleClick}
        sx={{
          '&:hover': { backgroundColor: '#FFFF' },
          minHeight: 0,
          minWidth: 0,
          padding: 0,
          textTransform: 'capitalize',
        }}
      >
        {children ? children : <EditIcon isClicked={isClicked} />}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: vertical,
          horizontal: horizontal,
        }}
        PaperProps={{
          style: {
            boxShadow: '2px 6px 20px 0px rgba(0, 0, 0, 0.09)',
            borderRadius: '15px',
          },
        }}
      >
        <div className="popover-header">
          <div>
            {checkedItems.length === 0 ? (
              <p className="popover-title">
                {popoverTitle} {serverData.length}/5
              </p>
            ) : (
              <p className="popover-title">
                {popoverTitle} {checkedItems.length}/5
              </p>
            )}
          </div>
          <div className="popover-cancel" onClick={handleClose}>
            <CloseIcon />
          </div>
        </div>
        <ul className="popover-items">
          {dataWithSelected.map((item) => (
            <li key={item.id}>
              <CheckBox
                checked={item.selected}
                onChange={(event) => handleCheckboxChange(event, item)}
                disabled={item.disabled}
              />
              <p className="popover-item">{item.type}</p>
            </li>
          ))}
        </ul>
        <div className="popover-btn-wrapper">
          <button className="popover-cancel-btn" onClick={handleCancelClick}>
            Отмена
          </button>
          <button onClick={handleSaveClick} className="popover-save-btn">
            Сохранить
          </button>
        </div>
      </Popover>
    </div>
  );
}
