import axios from 'axios';
import { store } from '../redux/store';
import { authActions } from '../redux/auth/authActions';

export const API_URL_V1 = 'https://prod-backend.tofu.systems/api/v1/';
// export const API_URL_V1 = 'https://staging-backend.tofu.systems/api/v1/';

const api = axios.create({
  baseURL: API_URL_V1,
});

export default api;

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    let code = '';
    console.log('error', error);
    if (isProperty(error.response.data, 'code')) {
      code = error.response.data.code;
    }
    if (error.response.status === 401 && !originalRequest._retry && code === 'token_not_valid') {
      originalRequest._retry = true;
      const refresh = window.localStorage.getItem('refreshToken');
      if (!refresh) {
        store.dispatch(authActions.logOut());
      }
      return axios
        .post(API_URL_V1 + '/auth/jwt/refresh/', { refresh: refresh })
        .then(({ data }) => {
          localStorage.setItem('authToken', data.access);
          api.defaults.headers.common['Authorization'] = 'Bearer ' + data.access;
          originalRequest.headers['Authorization'] = 'Bearer ' + data.access;
          return axios(originalRequest);
        })
        .catch((error) => {
          let code = isProperty(error.response.data, 'code') ? error.response.data.code : '';
          if (error.response.status === 401 && code === 'token_not_valid') {
            store.dispatch(authActions.logOut());
          }
        });
    }
    return Promise.reject(error);
  }
);

const isProperty = (data, property) => {
  return data.hasOwnProperty(property);
};
