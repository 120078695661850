export const PathRoutes = {
  LandingReviewRoute: '/landing-review', 
  LikeForLike: '/reports/like-for-like',
  DashboardRoute: '/dashboard',
  ReviewsRoute: '/reviews',
  ReportsRoute: '/report',
  Foodcost: '/report/foodcost',
  WriteOff: '/report/write-off',
  DayResult: '/report/day-result',
  SalesRoute: '/report/sales',
  AssistantRoute: '/assistant',
  SettingsRoute: '/settings',
  LoginRoute: '/login',
  RegistrationRoute: '/registration',
  Verification: '/verification',
  Subscription: '/subscription',
  CompanyCreation: '/company-creation',
  UserCreation: '/user-creation',
  Settings: '/settings',
  CompanyInfo: '/settings/company-info',
  UserInfo: '/settings/user-info',
  PlatformsInfo: '/settings/platforms-info',
  PosIntegrations: '/settings/pos-integration',
  Notification: '/settings/notification',
  Reports: '/settings/reports',
  SettingsSubscription: '/settings/subscription',
  ResetPassword: '/reset-password',
  NewPassword: '/new-password/:mta/:token',
  Download : '/download'
};
