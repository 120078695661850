import React from 'react';

export const Message = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.4999 2.32996C4.77654 2.32996 2.0199 4.81919 2.0199 7.59496C2.0199 9.22598 2.9444 10.7314 4.4836 11.723C5.08203 12.1086 5.24417 12.8589 5.27149 13.5229C5.88092 13.2719 6.29 12.5835 7.0304 12.7236C7.50174 12.8127 7.99357 12.86 8.4999 12.86C12.2232 12.86 14.9799 10.3707 14.9799 7.59496C14.9799 4.81919 12.2232 2.32996 8.4999 2.32996ZM0.399902 7.59496C0.399902 3.66047 4.17095 0.709961 8.4999 0.709961C12.8289 0.709961 16.5999 3.66047 16.5999 7.59496C16.5999 11.5295 12.8289 14.48 8.4999 14.48C7.97113 14.48 7.45338 14.4369 6.95167 14.3546C6.87664 14.4135 6.74683 14.5205 6.58709 14.6331C6.12765 14.9568 5.46173 15.29 4.4499 15.29C4.00256 15.29 3.6399 14.9273 3.6399 14.48C3.6399 14.0344 3.75599 13.4807 3.56396 13.0574C1.67728 11.8252 0.399902 9.86118 0.399902 7.59496Z" fill="#1E1E1E"/>
    </svg>
    
  );
};
