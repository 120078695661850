import './Loader.scss';


export const Loader = () => (
    <div className='loader'>
        <div className="lds-roller">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
        </div>
    </div>
);
