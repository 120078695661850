import React from 'react';
import { COLORS, COLORS_SECOND } from '../../../../../const/colors';
import { calculate } from '../../../../../helpers/calculate';
import './StarsStatistic.scss';

export const StarsStatistics = ({ sortedData }) => {
  const statisticData = calculate(sortedData);

  const finalStatisticData = statisticData.sort(
    (a, b) => b.sectionPercentage - a.sectionPercentage
  );

  let firstColumnElements = [];
  let secondColumnElements = [];

  if (finalStatisticData.length === 5) {
    firstColumnElements = finalStatisticData.slice(0, 3);
    secondColumnElements = finalStatisticData.slice(3);
  } else if (finalStatisticData.length === 4) {
    firstColumnElements = finalStatisticData.slice(0, 2);
    secondColumnElements = finalStatisticData.slice(2);
  } else if (finalStatisticData.length === 3) {
    firstColumnElements = finalStatisticData.slice(0, 2);
    secondColumnElements = finalStatisticData.slice(2);
  } else if (finalStatisticData.length === 2) {
    firstColumnElements = finalStatisticData.slice(0, 1);
    secondColumnElements = finalStatisticData.slice(1);
  } else if (finalStatisticData.length === 1) {
    firstColumnElements = finalStatisticData;
  }
  return (
    <div className="stars-statisctic__container">
      <ul className="stars-statisctic__list">
        {firstColumnElements.map((item, index) => (
          <li key={index}>
            <div className="stars-statisctic__text-star">
              <span
                className="stars-statisctic__color"
                style={{
                  backgroundColor: COLORS[index % COLORS.length],
                }}
              ></span>
              <span className="stars-statisctic__percent">{item.sectionPercentage}% </span>
              <span
                className={`stars-statisctic__stars ${
                  item.sectionPercentage >= 1 && item.sectionPercentage <= 9
                    ? 'low-rating'
                    : 'high-rating'
                } ${item.rating === 1 ? 'additional-class' : ''}`}
              >
                {item.rating}
                {'  '}
                {item.rating === 4
                  ? 'звезды'
                  : item.rating === 2 || item.rating === 3
                  ? 'звезды'
                  : item.rating === 1
                  ? 'звезда'
                  : 'звезд'}
              </span>
            </div>
          </li>
        ))}
      </ul>

      <ul className="stars-statisctic__list-second">
        {secondColumnElements.map((item, index) => (
          <li key={index + firstColumnElements.length}>
            <div className="stars-statisctic__text-star">
              <span
                className="stars-statisctic__color"
                style={{
                  backgroundColor: COLORS_SECOND[index % COLORS_SECOND.length],
                }}
              ></span>
              <span className="stars-statisctic__percent">{item.sectionPercentage}% </span>
              <span
                className={`stars-statisctic__stars ${
                  item.sectionPercentage >= 1 && item.sectionPercentage <= 9
                    ? 'low-rating'
                    : 'high-rating'
                } ${item.rating === 1 ? 'additional-class' : ''}`}
              >
                {item.rating}
                {'  '}
                {item.rating === 4
                  ? 'звезды'
                  : item.rating === 2 || item.rating === 3
                  ? 'звезды'
                  : item.rating === 1
                  ? 'звезда'
                  : 'звезд'}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
