import React from 'react';

export const ArrowRightIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3571 7.64303C10.6174 7.90336 10.6174 8.3255 10.3571 8.58583L6.58579 12.3571C6.32547 12.6174 5.90337 12.6174 5.64301 12.3571C5.38267 12.0967 5.38267 11.6746 5.64301 11.4143L8.94286 8.11443L5.64301 4.81463C5.38267 4.55423 5.38267 4.13216 5.64301 3.87176C5.90337 3.61143 6.32547 3.61143 6.58579 3.87176L10.3571 7.64303Z"
        fill="#67A8F4"
      />
    </svg>
  );
};
