import React from 'react';

export const UserDefaultIcon = () => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="80" height="80" rx="40" fill="#F9F9F9" />
      <path
        d="M40 41.3333C43.194 41.3333 46.1004 42.2583 48.2369 43.562C49.3048 44.2136 50.2161 44.9817 50.8747 45.8153C51.5227 46.6356 52 47.6173 52 48.6667C52 49.7932 51.4519 50.6815 50.6631 51.3145C49.9157 51.9145 48.9309 52.3115 47.8837 52.5888C45.7804 53.1457 42.972 53.3333 40 53.3333C37.028 53.3333 34.2195 53.1457 32.1162 52.5888C31.0691 52.3115 30.0843 51.9145 29.3369 51.3145C28.5482 50.6815 28 49.7932 28 48.6667C28 47.6173 28.4774 46.6356 29.1253 45.8153C29.7838 44.9817 30.6952 44.2136 31.763 43.562C33.8996 42.2583 36.806 41.3333 40 41.3333ZM40 26.6667C43.6819 26.6667 46.6667 29.6514 46.6667 33.3333C46.6667 37.0152 43.6819 40 40 40C36.3181 40 33.3333 37.0152 33.3333 33.3333C33.3333 29.6514 36.3181 26.6667 40 26.6667Z"
        fill="#67A8F4"
      />
    </svg>
  );
};
