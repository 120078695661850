import React from "react";

export const TofuErrorIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7897 2.63168C15.7897 1.75965 15.0828 1.05273 14.2108 1.05273L4.21078 1.05273C3.33875 1.05273 2.63184 1.75965 2.63184 2.63168L2.63184 3.158C2.63184 4.03003 3.33875 4.73694 4.21078 4.73694L7.36868 4.73694V15.7896C7.36868 16.6616 8.0756 17.3685 8.94762 17.3685H9.47394C10.346 17.3685 11.0529 16.6616 11.0529 15.7896V4.73694L14.2108 4.73695C15.0828 4.73695 15.7897 4.03003 15.7897 3.158V2.63168Z" fill="#67A8F4"/>
    <path d="M37.3685 9.21063C37.3685 13.7161 33.7161 17.3685 29.2106 17.3685C24.7051 17.3685 21.0527 13.7161 21.0527 9.21063C21.0527 4.70515 24.7051 1.05273 29.2106 1.05273C33.7161 1.05273 37.3685 4.70515 37.3685 9.21063ZM24.7238 9.21063C24.7238 11.6886 26.7326 13.6975 29.2106 13.6975C31.6886 13.6975 33.6975 11.6886 33.6975 9.21063C33.6975 6.73261 31.6886 4.72379 29.2106 4.72379C26.7326 4.72379 24.7238 6.73261 24.7238 9.21063Z" fill="#67A8F4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2108 22.6316C15.0828 22.6316 15.7897 23.3385 15.7897 24.2105V24.7369C15.7897 25.6089 15.0828 26.3158 14.2108 26.3158L6.31606 26.3158V28.9474L14.2108 28.9474C15.0828 28.9474 15.7897 29.6543 15.7897 30.5263V31.0526C15.7897 31.9247 15.0828 32.6316 14.2108 32.6316L6.31606 32.6316V37.3684C6.31606 38.2405 5.60914 38.9474 4.73711 38.9474H4.2108C3.33877 38.9474 2.63185 38.2405 2.63185 37.3684V31.0592C2.63184 31.057 2.63184 31.0548 2.63184 31.0526L2.63184 30.5263C2.63184 30.5241 2.63184 30.5219 2.63185 30.5197V24.7434L2.63184 24.7369L2.63184 24.2105C2.63184 23.3385 3.33875 22.6316 4.21078 22.6316H4.2108H4.73711H14.2108Z" fill="#D5D5D5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8948 26.3158C23.9122 26.3158 24.7369 25.4911 24.7369 24.4737C24.7369 23.4563 23.9122 22.6316 22.8948 22.6316C21.8775 22.6316 21.0527 23.4563 21.0527 24.4737C21.0527 25.4911 21.8775 26.3158 22.8948 26.3158ZM37.3685 24.4737C37.3685 25.4911 36.5438 26.3158 35.5264 26.3158C34.5091 26.3158 33.6843 25.4911 33.6843 24.4737C33.6843 23.4563 34.5091 22.6316 35.5264 22.6316C36.5438 22.6316 37.3685 23.4563 37.3685 24.4737ZM22.4578 38.2632C21.6528 38.2632 20.9869 37.6055 21.13 36.8132C21.2333 36.2414 21.3977 35.6808 21.6211 35.1413C22.0311 34.1515 22.632 33.2522 23.3895 32.4947C24.1471 31.7372 25.0464 31.1362 26.0361 30.7263C27.0259 30.3163 28.0867 30.1053 29.158 30.1053C30.2293 30.1053 31.2902 30.3163 32.2799 30.7263C33.2697 31.1362 34.169 31.7372 34.9265 32.4947C35.6841 33.2522 36.285 34.1515 36.6949 35.1413C36.9184 35.6808 37.0827 36.2414 37.186 36.8132C37.3291 37.6055 36.6633 38.2632 35.8582 38.2632H34.9394C34.1343 38.2632 33.5046 37.5927 33.2383 36.833C33.2118 36.7575 33.1833 36.6827 33.1526 36.6086C32.9353 36.084 32.6168 35.6074 32.2153 35.2059C31.8138 34.8044 31.3372 34.4859 30.8126 34.2686C30.2881 34.0513 29.7258 33.9395 29.158 33.9395C28.5902 33.9395 28.028 34.0513 27.5034 34.2686C26.9788 34.4859 26.5022 34.8044 26.1007 35.2059C25.6992 35.6074 25.3807 36.084 25.1635 36.6086C25.1328 36.6827 25.1042 36.7575 25.0777 36.833C24.8114 37.5927 24.1817 38.2632 23.3767 38.2632H22.4578Z" fill="#D5D5D5"/>
    </svg>
    
  );
};
