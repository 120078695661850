import './ProductsInfo.scss';
import { SearchError } from '../../../../pages/reportsPages/write-off/components/SearchError/SearchError';

export const ProductsInfo = ({ products, columns, topTenByExpenseList, totalTopTenByExpenses }) => {
  return (
    <>
      {products.list ? (
        <div>
          <div className="products__container">
            <div className="products__name">{columns.left_column_title}</div>
            <div className="products__coast">{columns.middle_column_title}</div>
            <div className="products__sum">{columns.right_column_title}</div>
          </div>
          <div className="products__wrapper">
            {products.list.map((item) => (
              <div className="products__info">
                <div className="products__info-name">{item.product_name}</div>
                <div className="products__info-coast">{item.coast}</div>
                <div className="products__info-sum">{item.sum}</div>
              </div>
            ))}
          </div>
          <div className="products__result">
            <div className="products__result-text">Итого</div>
            <div className="products__result-text">{products.progress_bar_current_sum} ₽</div>
          </div>
        </div>
      ) : (
        <SearchError />
      )}
    </>
  );
};
