import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import './CheckBox.scss';
export const CheckBox = ({ checked, onChange, customClass, disabled }) => {
  return (
    <input
      type="checkbox"
      className={cn('common-custom-checkbox', customClass)}
      checked={checked}
      onChange={onChange}
      onClick={(event) => event.stopPropagation()}
      disabled={disabled}
    />
  );
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
};

CheckBox.defaultProps = {
  disabled: false,
};
