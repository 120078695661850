export const MONTH_NAMES = [
  {
    name: 'Январь',
    number: 0,
  },
  {
    name: 'Февраль',
    number: 1,
  },
  {
    name: 'Март',
    number: 2,
  },
  {
    name: 'Апрель',
    number: 3,
  },
  {
    name: 'Май',
    number: 4,
  },
  {
    name: 'Июнь',
    number: 5,
  },
  {
    name: 'Июль',
    number: 6,
  },
  {
    name: 'Август',
    number: 7,
  },
  {
    name: 'Сентябрь',
    number: 8,
  },
  {
    name: 'Октябрь',
    number: 9,
  },
  {
    name: 'Ноябрь',
    number: 10,
  },
  {
    name: 'Декабрь',
    number: 11,
  },
];

export const DAYS_NAME = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];
