import './ModalChequeInfo.scss';

export const ModalChequeInfo = ({ item }) => {
  return (
    <div className="modal-cheque__info-item">
      <div className="modal-cheque__info-item__num">{item.sum}</div>
      <div className="modal-cheque__info-item__title">{item.title}</div>
    </div>
  );
};
