import React, { useState, useEffect } from 'react';
import { formatNumbers } from '../../../../../../helpers/formatNumbers';
import { ArrowIcon } from '../../../../../../icons/like-for-like/arrow-icon';
import { ThreeDotsIcon } from '../../../../../../icons/three-dots-icon';
import './TwoCompanyTable.scss';
import { DATA, TWO_COMPANY } from '../../const';
import { ThreeDotsPopover } from '../threeDotsPopover/ThreeDotsPopover';
import HoverTitleGroup from '../hoverTitleGroup/HoverTitleGroup';

export const TwoCompanyTable = () => {
  const TableSectionInner = [
    'sum',
    'revenue',
    'paid',
    'foodcost',
    'writeoff',
    'orders',
    'averageReceipt',
    'averageStringReceipt',
    'guests',
    'guestReceipt',
    'averageGuestInCheque',
    'visitLenght',
  ];

  const [data, setData] = useState(TWO_COMPANY);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleArrowClick = (itemIndex) => {
    const updatedData = [...data];
    updatedData[itemIndex].isOpen = !updatedData[itemIndex].isOpen;
    updatedData[itemIndex].company.forEach((companyItem) => {
      companyItem.isOpen = !companyItem.isOpen;
    });
    setData(updatedData);
    setSelectedItemIndex(null);
  };

  const openPopover = (itemIndex) => {
    setSelectedItemIndex(itemIndex);
    setIsPopoverOpen(true);
  };

  const closePopover = () => {
    setSelectedItemIndex(null);
    setIsPopoverOpen(false);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        closePopover();
      }
    };

    const handleOutsideClick = (event) => {
      if (!event.target.closest('.restaurants-table__header-btns')) {
        closePopover();
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="restaurants-table__container">
      <div className="restaurants-table__header">
        {data.map((item, itemIndex) => (
          <div key={itemIndex} className="restaurants-table__header-list">
            <div key={itemIndex} className="restaurants-table__header-item-wrapper">
              <div className="restaurants-table__header-item">
                <p className="restaurants-table__header-item-top" style={{fontWeight:'400', width:'150px'}}>{item.name}</p>
                {!item.isOpen ? (
                  <div className="restaurants-table__header-btns">
                  </div>
                ) : null}
              </div>
            </div>
            {item.isOpen &&
              item.company.map((companyItem, index) => (
                <div className="restaurants-table__header-item" key={index}>
                  <div>
                    <p>{companyItem.name}</p>
                    <p>{companyItem.address}</p>
                  </div>
                  {item.isOpen &&
                  (companyItem.name === 'Dolce Cafe, Химки' ||
                    companyItem.name === 'Caviar Sushi') ? (
                    <div className="restaurants-table__header-btns">
                      <div
                        onClick={() => handleArrowClick(itemIndex)}
                        className={`rotate-${item.isOpen ? 'down' : 'right'}`}
                      >
                        <ArrowIcon />
                      </div>
                      <div>
                        <div
                          onClick={
                            isPopoverOpen
                              ? () => setIsPopoverOpen(false)
                              : () => openPopover(itemIndex)
                          }
                          className="restaurants-table__header-btns-three-dots"
                        >
                          <ThreeDotsIcon />
                        </div>
                        {isPopoverOpen && selectedItemIndex === itemIndex && <ThreeDotsPopover />}
                      </div>{' '}
                    </div>
                  ) : null}
                </div>
              ))}
          </div>
        ))}
      </div>

      <div className="restaurants-table__data">
        {data.map((item, itemIndex) => (
          <div key={itemIndex} className="restaurants-table__data-list">
            {TableSectionInner.map((columnName, columnIndex) => (
              <div key={columnIndex} className="restaurants-table__list">
                <div key={columnIndex} className="restaurants-table__item">
                  <div
                    style={{
                      color: item.isOpen ? '#1e1e1e' : item[columnName].isColor ? '#67A8F4' : '',
                      fontWeight: 400,
                    }}
                  >
                    {item[columnName].isColor === true && !item.isOpen ? (
                      <HoverTitleGroup isGroup>
                        {formatNumbers(item[columnName].num)}
                      </HoverTitleGroup>
                    ) : (
                      formatNumbers(item[columnName].num)
                    )}
                  </div>
                </div>
                {item.isOpen &&
                  item.company.map((companyItem, index) => (
                    <div key={index} className="restaurants-table__item">
                      <div
                        key={index}
                        style={{ color: companyItem[columnName].isColor === true ? '#67A8F4' : '' }}
                      >
                        {companyItem[columnName].isColor === true ? (
                          <HoverTitleGroup>
                            {formatNumbers(companyItem[columnName].num)}
                          </HoverTitleGroup>
                        ) : (
                          formatNumbers(companyItem[columnName].num)
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
