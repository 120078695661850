export const NPS_TYPES = [
  {
    id: 0,
    type: 'Общий',
    disabled: true,
  },
  {
    id: 1,
    type: 'Напитки',
  },
  {
    id: 2,
    type: 'Сервис',
  },
  {
    id: 3,
    type: 'Кальян',
  },
  {
    id: 4,
    type: 'Кухня',
  },
  {
    id: 5,
    type: 'Интерьер',
  },
  {
    id: 6,
    type: 'Атмосфера',
  },
  {
    id: 7,
    type: 'Время ожидания',
  },
  {
    id: 8,
    type: 'Музыка',
  },
  {
    id: 9,
    type: 'Локация',
  },
];

export const COLORS = ['#0A3160', '#1969C8', '#67A8F4', '#B5D7FF', '#E3F0FF'];
