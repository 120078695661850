import { ChartArea } from './AreaChart/AreaChart';
import { Header } from './Header/Header';
import './FoodcostDynamic.scss';
import { useEffect, useState } from 'react';
import { foodcostApi } from '../../../../../api/foodcost';

export const FoodcostDynamic = ({
  account_id,
  token,
  selectGroups,
  startDate,
  endDate,
  selectedDate,
}) => {
  const initialReasons = [
    { id: 1, title: 'Горячие блюда русское застол' },
    { id: 2, title: 'горячие блюда вкус юга' },
  ];

  const initialStores = [
    { id: 1, title: 'Прочие расходы' },
    { id: 2, title: 'Амортизация' },
  ];

  const [selectedReason, setSelectedReason] = useState(initialReasons);
  const [selectedStore, setSelectedStore] = useState(initialStores);
  const [numAdditionStore, setNumAdditionStore] = useState(false);
  const [numAdditionReason, setNumAdditionReason] = useState(false);

  const handleSelectedOptionsDynamicForReasons = (newSelectedOptions) => {
    setSelectedReason([...newSelectedOptions]);
  };
  const handleSelectedOptionsDynamicForStore = (newSelectedOptions) => {
    setSelectedStore([...newSelectedOptions]);
  };
  useEffect(() => {
    if (selectedReason.length === 2) {
      setNumAdditionReason(1);
    } else if (selectedReason.length === 1) {
      setNumAdditionReason(1.08);
    } else setNumAdditionReason(0);

    if (selectedStore.length === 2) {
      setNumAdditionStore(1);
    } else if (selectedStore.length === 1) {
      setNumAdditionStore(1.05);
    } else setNumAdditionStore(0);
  }, [selectedReason, selectedStore]);

  return (
    <>
      <div>
        <Header
          reasons={initialReasons}
          store={initialStores}
          setSelectedReason={setSelectedReason}
          handleSelectedOptionsDynamicForReasons={handleSelectedOptionsDynamicForReasons}
          handleSelectedOptionsDynamicForStore={handleSelectedOptionsDynamicForStore}
        />
        <ChartArea numAdditionReason={numAdditionReason} numAdditionStore={numAdditionStore} />
      </div>
    </>
  );
};
