import { useState } from 'react';
import './StaffCard.scss';

export const StaffCard = ({ data, index, isMenuOpen }) => {
  const { name, revenue, avgCheck, deepCheck, icon } = data;

  return (
    <div
      className={
        isMenuOpen === 'open'
          ? 'staff-card__container is-open-side-bar'
          : 'staff-card__container is-close-side-bar'
      }
    >
      <div className="staff-card__top">
        <div className="staff-card__top-icon">
          <img src={icon} width={40} height={40} alt="logo" />
        </div>
        <div className="staff-card__top-rating"> {index > 2 ? ' ' : `Топ ${index + 1}`}</div>
      </div>
      <div className={index > 2 ? 'staff-card__name without-top' : 'staff-card__name'}>{name}</div>
      <div className="staff-card__info">
        <div>
          <div className="staff-card__info-title">Выручка</div>
          <div className="staff-card__info-desc">{revenue} ₽</div>
        </div>
        <div>
          <div className="staff-card__info-title">Средний чек</div>
          <div className="staff-card__info-desc">{avgCheck} ₽</div>
        </div>
        <div>
          <div className="staff-card__info-title">Глубина чека</div>
          <div className="staff-card__info-desc">{deepCheck} пц.</div>
        </div>
      </div>
    </div>
  );
};
