import { useState } from 'react';
import { useDatepicker, START_DATE } from '@datepicker-react/hooks';
import Month from '../../../../../components/common/DatePicker/Month/Month';
import DatepickerContext from '../../../../../components/common/DatePicker/datepickerContext';

export const DatePickerSecond = ({
    setCalendarDate,
    calendarDate,
    setSelectedDate,
    setIsApplyButtonClick,
}) => {
  const [state, setState] = useState({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE,
  });

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonthsByOneMonth,
    goToNextMonthsByOneMonth,
  } = useDatepicker({
    startDate: state.startDate,
    endDate: state.endDate,
    focusedInput: state.focusedInput,
    onDatesChange: handleDateChange,
  });

  function handleDateChange(data) {
    if (!data.focusedInput) {
      setState({ ...data, focusedInput: START_DATE });
    } else {
      setState(data);
    }
  }

  const month = activeMonths[0];

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
      }}
    >
      <div className="date-wrapper-second">
        <div className="date-title-header">Отчетный период</div>
        <Month
          key={`${month.year}-${month.month}`}
          year={month.year}
          month={month.month}
          firstDayOfWeek={firstDayOfWeek}
          goToPreviousMonthsByOneMonth={goToPreviousMonthsByOneMonth}
          goToNextMonthsByOneMonth={goToNextMonthsByOneMonth}
          setCalendarDate={setCalendarDate}
          state={state}
          setIsApplyButtonClick={setIsApplyButtonClick}
          setSelectedDate={setSelectedDate}
        />
      </div>
    </DatepickerContext.Provider>
  );
};
