import { Select } from '../../../../../components/common/Select/Select';
import { TONALITY_COLORS } from '../../../consts';
import './TonalityInfo.scss';

export const TonalityInfo = ({
  npsTypeOptions,
  selectedOptions,
  handleSelectedOptionsChange,
  data,
}) => {
  return (
    <div className="info">
      <div className="info-wrapper">
        <p className="reviews__cloud-container-title">Тональность</p>
        <div>
          <Select
            options={npsTypeOptions}
            selectedOptions={selectedOptions}
            onChange={handleSelectedOptionsChange}
            secondBg
          />
        </div>
      </div>
      {data.length === 0 ? null : (
        <div className="info-colors">
          <ul className="reviews__cloud-container-list">
            {TONALITY_COLORS.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
