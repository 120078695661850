/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useRef, useContext } from 'react';
import { useDay } from '@datepicker-react/hooks';
import './Day.scss';

import datepickerContext from '../datepickerContext';
import getColor from '../utils/getColor';

function Day({ dayLabel, date }) {
  const dayRef = useRef(null);
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useContext(datepickerContext);
  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });

  if (!dayLabel) {
    return <div />;
  }

  const getColorFn = getColor(isSelected, isSelectedStartOrEnd, isWithinHoverRange, disabledDate);
  return (
    <button
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={tabIndex}
      className="day-btn"
      type="button"
      ref={dayRef}
      css={{
        padding: '8px',
        cursor: 'pointer',
        border: 0,
        color: getColorFn({
          selectedFirstOrLastColor: '#FFFFFF',
          normalColor: '#001217',
          selectedColor: '#2B2B2B',
          rangeHoverColor: '#2B2B2B',
          disabledColor: '#808285',
        }),
        background: getColorFn({
          selectedFirstOrLastColor: '#67A8F4',
          normalColor: '#FFFFFF',
          selectedColor: 'rgba(103, 168, 244, 0.20)',
          rangeHoverColor: 'rgba(103, 168, 244, 0.20)',
          disabledColor: '#FFFFFF',
        }),
      }}
    >
      {dayLabel}
    </button>
  );
}

export default Day;
