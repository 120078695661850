import React, { useState } from 'react';
import './ProgressBar.scss';
import { HoverPlan } from '../../../../../../components/common/HoverPlan/HoverPlan';
import { Hover } from '../../../../../../components/common/Hover/Hover';

const ProgressBar = ({ completed, height }) => {
  const fillerStyle = {
    width: completed >= 100 ? `100%` : `${completed}%`,
    background:
      completed >= 100
        ? 'linear-gradient(90deg, #F56C7B 0%, #FFC1C8 100%)'
        : 'linear-gradient(90deg, #CCFFEF 0%, #44D6A9 100%)',
  };

  return (
    <div className="sales-progress-bar__container" style={{ height: height }}>
      <div
        className={
          completed >= 100 ? 'sales-progress-bar__filler-completed' : 'sales-progress-bar__filler'
        }
        style={fillerStyle}
      >
        <p
          className={
            completed >= 100 ? 'sales-progress-bar__label-completed' : 'sales-progress-bar__label'
          }
        >{`${completed}%`}</p>
        <div className={completed >= 100 ? 'sales-progress-bar__line' : ''}></div>
      </div>
      <div className="sales-progress-bar__info">
        <div className="sales-progress-bar__info-title">Факт</div>
        <Hover
          containerClassName={
            completed >= 100 ? 'sales-progress-bar__info-sum_max' : 'sales-progress-bar__info-sum'
          }
          hoverContainerClassName={'sales-progress-bar__info_hover'}
          title={'План'}
        >
          <HoverPlan />
        </Hover>
      </div>
    </div>
  );
};

export default ProgressBar;
