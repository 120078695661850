import React from 'react';

export const DragIcon = ( ) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icons">
        <path
          id="settings"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.75 2.83333C12.75 2.44213 12.4329 2.125 12.0417 2.125C11.6505 2.125 11.3333 2.44213 11.3333 2.83333V3.54167H2.83333C2.44213 3.54167 2.125 3.8588 2.125 4.25C2.125 4.6412 2.44213 4.95833 2.83333 4.95833H11.3333V5.66667C11.3333 6.05786 11.6505 6.375 12.0417 6.375C12.4329 6.375 12.75 6.05786 12.75 5.66667V4.95833H14.1667C14.5579 4.95833 14.875 4.6412 14.875 4.25C14.875 3.8588 14.5579 3.54167 14.1667 3.54167H12.75V2.83333ZM2.83333 7.79167C2.44213 7.79167 2.125 8.10879 2.125 8.5C2.125 8.89121 2.44213 9.20833 2.83333 9.20833H4.25V9.91667C4.25 10.3079 4.56713 10.625 4.95833 10.625C5.34953 10.625 5.66667 10.3079 5.66667 9.91667V9.20833H14.1667C14.5579 9.20833 14.875 8.89121 14.875 8.5C14.875 8.10879 14.5579 7.79167 14.1667 7.79167H5.66667V7.08333C5.66667 6.69213 5.34953 6.375 4.95833 6.375C4.56713 6.375 4.25 6.69213 4.25 7.08333V7.79167H2.83333ZM2.125 12.75C2.125 12.3588 2.44213 12.0417 2.83333 12.0417H11.3333V11.3333C11.3333 10.9421 11.6505 10.625 12.0417 10.625C12.4329 10.625 12.75 10.9421 12.75 11.3333V12.0417H14.1667C14.5579 12.0417 14.875 12.3588 14.875 12.75C14.875 13.1412 14.5579 13.4583 14.1667 13.4583H12.75V14.1667C12.75 14.5579 12.4329 14.875 12.0417 14.875C11.6505 14.875 11.3333 14.5579 11.3333 14.1667V13.4583H2.83333C2.44213 13.4583 2.125 13.1412 2.125 12.75Z"
          fill="#C4C4C2"
        />
      </g>
    </svg>
  );
};
