import React from 'react';

export const ArrowLeftIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icons">
        <path
          id="full arrow left"
          d="M3.2929 12.364C2.90237 11.9735 2.90237 11.3403 3.29289 10.9498L8.94975 5.29289C9.34027 4.90237 9.97344 4.90237 10.3639 5.29289C10.7544 5.68342 10.7544 6.31658 10.3639 6.70711L6.41421 10.6569L19.6568 10.6569C20.2091 10.6569 20.6568 11.1046 20.6568 11.6569C20.6568 12.2092 20.2091 12.6569 19.6568 12.6569L6.41422 12.6569L10.3639 16.6066C10.7544 16.9972 10.7544 17.6303 10.3639 18.0209C9.97344 18.4114 9.34027 18.4114 8.94975 18.0209L3.2929 12.364Z"
          fill="#67A8F4"
        />
      </g>
    </svg>
  );
};
