import {
  AUTH,
  LOGOUT_SUCCESS,
  REG_FAILURE,
  REG_SUCCESS,
  SET_AUTH_DATA,
  SET_USER_ID,
  IS_EMAIL_REGISTRATION_IN_PROGRESS,
  HIDE_RESEND_EMAIL_CONFIRMATION,
  SHOW_RESEND_EMAIL_CONFIRMATION,
} from './authConstants';

const initialState = {
  userId: null,
  token: null,
  signUpData: null,
  fullName: JSON.parse(localStorage.getItem('fullName')),
  isAuthorized: !!localStorage.getItem('authToken'),
  userCreatedSuccess: false,
  isResendEmailConfirmation: false,
  isFranchiseOwner: JSON.parse(localStorage.getItem('isFranchiseOwner')),
  isEmailRegistrationInProgress: JSON.parse(localStorage.getItem('isEmailRegistrationInProgress')),
  userType: JSON.parse(localStorage.getItem('userType')),
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH:
      return {
        ...state,
      };
    case SHOW_RESEND_EMAIL_CONFIRMATION:
      return {
        ...state,
        isResendEmailConfirmation: true,
      };
    case HIDE_RESEND_EMAIL_CONFIRMATION:
      return {
        ...state,
        isResendEmailConfirmation: false,
      };
    case SET_AUTH_DATA:
      const { token, userId, is_franchise_owner, is_email_in_progress, userType, fullName } =
        action.payload;
      return {
        ...state,
        token,
        userId,
        fullName: fullName,
        isAuthorized: !!token,
        isFranchiseOwner: is_franchise_owner,
        isEmailRegistrationInProgress: is_email_in_progress,
        userType: userType,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        token: null,
        userId: null,
        isAuthorized: null,
        isEmailRegistrationInProgress: null,
        isFranchiseOwner: null,
      };
    case SET_USER_ID:
      return { ...state, userId: action.payload };
    case REG_SUCCESS:
      return { ...state, userCreatedSuccess: true };
    case REG_FAILURE:
      return { ...state, userCreatedSuccess: false };
    case IS_EMAIL_REGISTRATION_IN_PROGRESS:
      return { ...state, isEmailRegistrationInProgress: action.payload };
    default:
      return state;
  }
};
