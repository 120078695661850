import React from 'react';

export const OneToOne = () => {
  return (
    <svg width="63" height="73" viewBox="0 0 63 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 89" clip-path="url(#clip0_6513_56704)">
        <rect id="Rectangle 4301" width="63" height="73" rx="10" fill="#F6F6F6" />
        <rect
          id="Rectangle 4302"
          x="15.5"
          y="20.5"
          width="33"
          height="33"
          rx="4.5"
          stroke="#C4C4C2"
          stroke-dasharray="2 2"
        />
      </g>
      <defs>
        <clipPath id="clip0_6513_56704">
          <rect width="63" height="73" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
