import './WeeksReport.scss';

const test = [
  { date: '01.06-07.06', price: '10 456,00' },
  { date: '01.06-07.06', price: '10 456,00' },
  { date: '01.06-07.06', price: '10 456,00' },
  { date: '01.06-07.06', price: '10 456,00' },
  { date: '01.06-07.06', price: '10 456,00' },
];

const testSecond = { date: 'июнь', price: '10 456,00' };

export const WeeksReport = () => {
  return (
    <div className="week-report__container">
      <div className="week-report__top">
        <div className="week-report__top-title">Рассчет по неделям</div>
        <div className="week-report__top-weeks">
          {test.map((item) => (
            <div>
              <div className="week-report__top-weeks-title">Выручка за неделю {item.date}</div>
              <div className="week-report__top-weeks-price">
                {item.price} <span>р.</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="week-report__bottom">
        <div className="week-report__bottom-title">Рассчет за месяц</div>
        <div className="week-report__bottom-date">Планируемая выручка за {testSecond.date} </div>
        <div className="week-report__bottom-price">
          {testSecond.price} <span>р.</span>
        </div>
      </div>
    </div>
  );
};
