import axios from 'axios';

import api from './baseApi';

export const authApi = {
  registration: (email, password) => {
    return api.post(`users/`, {
      email,
      password,
    });
  },
  

  tryAgain: (email, retry_type) => {
    return api.post(`account/try_again/`, {
      email,
      retry_type,
    });
  },

  getUser: (token) => {
    return api.get(`users/me/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },

  getCompany: (account_id, token) => {
    return api.get(`/account/${account_id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },

  addUserName: (formData, token) => {
    return api.patch(`users/me/`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  emailVerification: (activation_code) => {
    return api.post(`/account/activate/`, {
      activation_code,
    });
  },

  auth: (email, password) => {
    return api.post(`/auth/jwt/create/`, {
      email,
      password,
    });
  },

  resetPassword: (email) => {
    return api.post(`/password/reset/`, {
      email,
    });
  },

  newPassword: (uid, token, new_password) => {
    return api.post(`/password/reset/confirm/`, {
      uid,
      token,
      new_password,
    });
  },

  getAccountId: () => {
    return api.get('/accounts/');
  },

  sendCompany: (formData, token, account_id) => {
    return api.patch(
      `/account/${account_id}/`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },


  connectIiko: (domain, login, password, account_id, token) => {
    return api.post(
      `/accounts/${account_id}/iiko/`,
      {
        domain,
        login,
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  createPoint: (account_id, point_name, point_address, token) => {
    return api.post(
      'reviews/initial_points/',
      {
        account_id,
        point_name,
        point_address,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
  },
  // registrationRequest: (userRegData) => {
  //   return api.post(`/users/`, userRegData);
  // },
  // resendActivation: (emailData) => {
  //   return api.post(`/users/resend_activation/`, { email: emailData });
  // },
};

export const initAuthorization = () => {
  const token = localStorage.getItem('authToken');
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

export const resetAuthorization = () => {
  delete api.defaults.headers.common['Authorization'];
  delete axios.defaults.headers.common.Authorization;
};

