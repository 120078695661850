import { PageHeader } from '../../components/common/PageHeader/PageHeader';
import { TabsSettings } from '../../components/settings/tabs/tabs';
import { Iiko } from './Iiko/Iiko';
import { QuickResto } from './QuickResto/QuickResto';
import { RKepper } from './RKepper/RKepper';

export const PosIntegration = () => {
  return (
    <div className="settings-container">
      <PageHeader title="Настройки" />
      <TabsSettings tabIndex={4}>
        <QuickResto />
        <Iiko />
        <RKepper />
      </TabsSettings>
    </div>
  );
};
