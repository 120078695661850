import React, { useState } from 'react';
import './SliderBannerStatistics.scss';
import { SliderBannerCard } from './SliderBannerCard/SliderBannerCard';

export const SliderBannerStatistics = ({ data }) => {
  return (
    <div className="sales_slider-banner-container">
      <div className="sales_slider-banner-list">
        {data.map((item) => <SliderBannerCard item={item} />)}
      </div>
    </div>
  );
};
