import React, { useEffect, useState } from 'react';
import { Revenue } from './components/Revenue/Revenue';
import { ThreeCardsTop } from './components/ThreeCardsTop/ThreeCardsTop';
import { ArrowRightIcon } from '../../../icons/reports/day-result/arrow-right-icon';
import { CSSTransition } from 'react-transition-group';
import { StaffRatingSlider } from '../../../components/reports/StaffRatingSlider/StaffRatingSlider';
import { Checque } from '../../../components/reports/Cheque/Checque';
import { DangerousOperations } from '../../../components/reports/DangerousOperations/DangerousOperations';
import { useHover } from '../../../hooks/useHover';
import { Button } from '../../../components/common/Button/Button';
import { ThreeDotsIcon } from '../../../icons/three-dots-icon';
import './DayResult.scss';
import { Sales } from '../../../components/reports/Sales/Sales';
import { PageHeader } from '../../../components/common/PageHeader/PageHeader';
import useSetBodyScroll from '../../../hooks/useSetBodyScroll';
import { Table } from '../../../components/reports/Table/Table';
import { info, subinfo, FOODCOST_COLUMN_TITLE } from '../../../components/reports/Table/const';
import FoodcosGraph from '../../../images/day-result/foodcostGraph.svg';
import DeleteGraph from '../../../images/day-result/deleteGraph.svg';
import ServicesGraph from '../../../images/day-result/servicesGraph.svg';
import { AverageChecque } from '../../../icons/day-results/checque/average-checque';
import { NumberChecque } from '../../../icons/day-results/checque/number-checque';
import { DepthChecque } from '../../../icons/day-results/checque/depth-checque';

export const DayResults = ({ isMenuOpen }) => {
  const data = [
    {
      title: 'Фудкост',
      main_percent: '23,5%',
      change_percent: '+1,37',
      sum: '65 555,72',
      btn_text: 'Перейти к отчету',
      color: 'red',
      img: FoodcosGraph,
    },
    {
      title: 'Cлужебные оплаты',
      main_percent: '2,5%',
      change_percent: '-2,45',
      sum: '6 974,02',
      btn_text: 'Подробнее',
      color: 'green',
      img: ServicesGraph,
    },
    {
      title: 'Удаление со списанием',
      main_percent: '3,1%',
      change_percent: '0',
      sum: '8 647,77',
      btn_text: 'Подробнее',
      color: 'gray',
      img: DeleteGraph,
    },
  ];

  const [hoverRefSales, isHoveredSales] = useHover();
  const [hoverRefCheque, isHoveredCheque] = useHover();
  const [hoverRefOperations, isHoveredOperations] = useHover();

  const setBodyScroll = useSetBodyScroll();
  useEffect(() => {
    setBodyScroll(true);
    return () => {
      setBodyScroll(false);
    };
  }, [setBodyScroll]);

  const checkData = [
    {
      sum: '2 830,60 ₽',
      title: 'Средний чек',
      per: '-102 ₽ -36,35%',
      color: 'red',
      img: <AverageChecque />,
      hoverText: "Средний чек",
      hoverNum: "2 728,60 ₽"
    },
    {
      sum: '98',
      title: 'Кол-во чеков',
      per: '+3 чека',
      color: 'green',
      img: <NumberChecque />,
      hoverText: "Кол-во чеков",
      hoverNum: "95"
    },
    {
      sum: '9 пц.',
      title: 'Глубина чека',
      per: '0 порций',
      color: 'gray',
      img: <DepthChecque />,
      hoverText: "Глубина чека",
      hoverNum: "9 пц."
    },
  ];
  const dataHoverPercentRevenue = [
    {
      sum: '315 224,80',
    },
  ];
  return (
    <>
      <PageHeader title="Итоги дня" donwnload isWriteOff />
      <div className="day-result__container">
        <div className="day-result__right-side">
          <div className="day-result__header">
            <div className="day-result__header-left-side">
              <Revenue dataHoverPercentLeftSide={dataHoverPercentRevenue} revenue />
            </div>
            <div className="day-result__header-right-side">
              <ThreeCardsTop data={data} />
            </div>
          </div>
          <div className="day-result__middle">
            <div className="day-result__middle-left-side" ref={hoverRefSales}>
              <div className="day-result__wrapper">
                <Sales isHoveredSales={isHoveredSales}/>
              </div>
            </div>
            <div className="day-result__middle-right-side" ref={hoverRefCheque}>
              <div className="day-result__wrapper">
                <Checque isHoveredCheque={isHoveredCheque} data={checkData} />
              </div>
            </div>
          </div>
          <div className="day-result__bottom">
            <div className="day-result__bottom-left-side" ref={hoverRefOperations}>
              <div className="day-result__wrapper-operation">
                <DangerousOperations />
                {/* <div
                  className={
                    isHoveredOperations ? 'day-result__about-btn-hovered' : 'day-result__about-btn'
                  }
                >
                  <Button styleType="about" height={15} icon={<ArrowRightIcon />}>
                    Подробнее
                  </Button>
                </div> */}
              </div>
            </div>
            <div className="day-result__bottom-right-side">
              <div className="day-result__wrapper-bottom-right">
                <div className="day-result__top-text">
                  <div>Рейтинг официантов</div>
                  {/* <ThreeDotsIcon /> */}
                </div>
                <StaffRatingSlider isMenuOpen={isMenuOpen} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
