import React from 'react';

export const BlankStarIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.18994 2.1512C8.55159 1.53033 9.44859 1.53033 9.81017 2.1512L11.9064 5.75025L15.9771 6.63174C16.6793 6.7838 16.9565 7.63685 16.4778 8.17265L13.7027 11.2785L14.1222 15.4223C14.1946 16.1371 13.469 16.6644 12.8114 16.3747L9.00009 14.6951L5.18871 16.3747C4.53121 16.6644 3.80555 16.1371 3.87794 15.4223L4.29749 11.2785L1.52238 8.17265C1.04364 7.63685 1.32082 6.7838 2.02305 6.63174L6.09372 5.75025L8.18994 2.1512Z"
        fill="white"
      />
    </svg>
  );
};
