import React from 'react';

export const EyeIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99998 3.33301C10.4524 3.33301 13.4414 4.94409 14.4861 7.26727C14.5843 7.48567 14.6666 7.74094 14.6666 7.99967C14.6666 8.25841 14.5843 8.51367 14.4861 8.73207C13.4414 11.0553 10.4524 12.6663 7.99998 12.6663C5.54757 12.6663 2.55857 11.0553 1.51386 8.73207C1.41563 8.51367 1.33331 8.25841 1.33331 7.99967C1.33331 7.74094 1.41563 7.48567 1.51386 7.26727C2.55857 4.94409 5.54757 3.33301 7.99998 3.33301ZM7.99998 5.33301C6.52722 5.33301 5.33331 6.52691 5.33331 7.99967C5.33331 9.47241 6.52722 10.6663 7.99998 10.6663C9.47271 10.6663 10.6666 9.47241 10.6666 7.99967C10.6666 6.52691 9.47271 5.33301 7.99998 5.33301ZM7.99998 6.66634C8.73638 6.66634 9.33331 7.26327 9.33331 7.99967C9.33331 8.73607 8.73638 9.33301 7.99998 9.33301C7.26358 9.33301 6.66665 8.73607 6.66665 7.99967C6.66665 7.26327 7.26358 6.66634 7.99998 6.66634Z"
        fill="#67A8F4"
      />
    </svg>
  );
};
