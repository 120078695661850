import React from 'react';
import cn from 'classnames';
import blueBg from '../../../../../images/settings/reviewsPlatform/nine-to-sixteen-blue.jpg';
import orangeBg from '../../../../../images/settings/reviewsPlatform/nine-to-sixteen-orange.jpg';
import './TofuQr.scss';

export const TofuQr = ({ companyData, selectedItemSide, selectedItemBg }) => {
  const { avatar, qr_code } = companyData;

  let qrCodeHeight, qrCodeWidth, btnFirstWidth, btnSecondWidth;

  if (selectedItemSide === 'threeToFour') {
    qrCodeHeight = 148;
    qrCodeWidth = 148;
    btnFirstWidth = 112;
    btnSecondWidth = 151;
  } else if (selectedItemSide === 'oneToOne') {
    qrCodeHeight = 111;
    qrCodeWidth = 111;
    btnFirstWidth = 94;
    btnSecondWidth = 124;
  } else if (selectedItemSide === 'nineToSixteen') {
    qrCodeHeight = 219;
    qrCodeWidth = 219;
  } else {
    qrCodeHeight = 220;
    qrCodeWidth = 220;
  }

  let firstBtnBg, thirdBtnBg;

  if (selectedItemBg === 'orange') {
    firstBtnBg = '#FFC700';
    thirdBtnBg = '#FF6B00';
  } else {
    firstBtnBg = '#67A8F4';
    thirdBtnBg = '#0075FF';
  }

  const backgroundImage = selectedItemBg === 'orange' ? `url(${orangeBg})` : `url(${blueBg})`;

  return (
    <div
      className={cn('tofu-qr__container', {
        threeToFour: selectedItemSide === 'threeToFour',
        oneToOne: selectedItemSide === 'oneToOne',
      })}
      style={{ backgroundImage }}
    >
      <div
        className={cn('tofu-qr__logo', {
          threeToFourLogo: selectedItemSide === 'threeToFour',
          oneToOneLogo: selectedItemSide === 'oneToOne',
        })}
      >
        <img
          src={avatar ? `${avatar}?not-from-cache-please}` : ''}
          height={53}
          width={53}
          alt="Logo"
          crossOrigin="anonymous"
          id="chosen-image"
        />
      </div>
      <div
        className={cn('tofu-text', {
          threeToFourText: selectedItemSide === 'threeToFour',
          oneToOneText: selectedItemSide === 'oneToOne',
        })}
      >
        {selectedItemSide === 'oneToOne' ? (
          <div className="tofu-qr__wrapper">
            <div
              className={cn(
                'tofu-qr__wrapper-first',
                { threeToFourWrapperFirst: selectedItemSide === 'threeToFour' },
                { oneToOneWrapperFirst: selectedItemSide === 'oneToOne' }
              )}
            >
              <div
                className={cn(
                  'tofu-qr__text-first',
                  { threeToFourTextFirst: selectedItemSide === 'threeToFour' },
                  { oneToOneTextFirst: selectedItemSide === 'oneToOne' }
                )}
                style={{ width: btnFirstWidth, backgroundColor: firstBtnBg }}
              >
                Благодаря Вам
              </div>
            </div>
            <div
              className={cn(
                'tofu-qr__wrapper-second',
                { threeToFourWrapperSecond: selectedItemSide === 'threeToFour' },
                { oneToOneWrapperSecond: selectedItemSide === 'oneToOne' }
              )}
            >
              <div
                className={cn(
                  'tofu-qr__text-second',
                  { threeToFourTextSecond: selectedItemSide === 'threeToFour' },
                  { oneToOneTextSecond: selectedItemSide === 'oneToOne' }
                )}
                style={{ width: btnSecondWidth }}
              >
                мы становимся лучше
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div
              className={cn(
                'tofu-qr__wrapper-first',
                { threeToFourWrapperFirst: selectedItemSide === 'threeToFour' },
                { oneToOneWrapperFirst: selectedItemSide === 'oneToOne' }
              )}
            >
              <div
                className={cn(
                  'tofu-qr__text-first',
                  { threeToFourTextFirst: selectedItemSide === 'threeToFour' },
                  { oneToOneTextFirst: selectedItemSide === 'oneToOne' }
                )}
                style={{ width: btnFirstWidth, backgroundColor: firstBtnBg }}
              >
                Благодаря Вам
              </div>
            </div>
            <div
              className={cn(
                'tofu-qr__wrapper-second',
                { threeToFourWrapperSecond: selectedItemSide === 'threeToFour' },
                { oneToOneWrapperSecond: selectedItemSide === 'oneToOne' }
              )}
            >
              <div
                className={cn(
                  'tofu-qr__text-second',
                  { threeToFourTextSecond: selectedItemSide === 'threeToFour' },
                  { oneToOneTextSecond: selectedItemSide === 'oneToOne' }
                )}
                style={{ width: btnSecondWidth }}
              >
                мы становимся лучше
              </div>
            </div>
          </div>
        )}

        <div
          className={cn('tofu-qr__wrapper-third', {
            threeToFourWrapperThird: selectedItemSide === 'threeToFour',
            oneToOneWrapperThird: selectedItemSide === 'oneToOne',
          })}
        >
          <div
            className={cn(
              'tofu-qr__text-third',
              { threeToFourTextThird: selectedItemSide === 'threeToFour' },
              { oneToOneTextThird: selectedItemSide === 'oneToOne' }
            )}
            style={{ backgroundColor: thirdBtnBg }}
          >
            поделитесь впечатлениями
          </div>
        </div>
      </div>
      <div
        className={cn(
          'tofu-qr__code',
          { threeToFourCode: selectedItemSide === 'threeToFour' },
          { oneToOneCode: selectedItemSide === 'oneToOne' }
        )}
      >
        <p>оставить отзыв </p>
        <img
          src={qr_code}
          height={qrCodeHeight}
          width={qrCodeWidth}
          alt="QR Code"
          crossOrigin="anonymous"
        />
      </div>
    </div>
  );
};
