import React, { useEffect, useState } from 'react';
import { Card } from './Card/Card';
import { STAFF_INFO } from './const';
import './Cards.scss';
import { authApi } from '../../../../api/authApi';
import { getBottomNavigationActionUtilityClass } from '@mui/material';

export const Cards = () => {
  const [staffInfo, setStaffInfo] = useState([]);
  const [avatar, setAvatar] = useState();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    authApi
      .getUser(token)
      .then((res) => {
        setStaffInfo(res.data);
        setAvatar(res.data.user_avatar);
      })
      .catch((error) => {
        console.error('Error fetching staff info:', error);
      });
  }, []);

  return (
    <div>
      <div className="staff-settings__cards-department"></div>
      <div className="staff-settings__cards-section">
        {/* {staffInfo.map((item, index) => ( */}
        <Card data={staffInfo} avatar={avatar} setAvatar={setAvatar} />
        {/* ))} */}
      </div>
    </div>
  );
};

{
  /* <div className='staff-settings__cards-deparment'>Менеджмент</div>
      <div className="staff-settings__cards-section">
        {staffInfo.managers.map((item, index) => (
          <Card key={index} data={item} />
        ))}
      </div>
      <div className='staff-settings__cards-deparment'>Администрация</div>
      <div className="staff-settings__cards-section">
        {staffInfo.admins.map((item, index) => (
          <Card key={index} data={item} />
        ))}
      </div>
      <div className='staff-settings__cards-deparment'>Персонал</div>
      <div className="staff-settings__cards-section">
        {staffInfo.staff.map((item, index) => (
          <Card key={index} data={item} />
        ))}
      </div> */
}
