import React from 'react';

export const NumberChecque = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="144"
      height="119"
      viewBox="0 0 144 119"
      fill="none"
    >
      <g clip-path="url(#clip0_12386_76029)">
        <path
          d="M75 45C50.6432 61.7791 16.976 67 1 67V119H143.5V12.5C106 17.5 99.3568 28.2209 75 45Z"
          fill="url(#paint0_linear_12386_76029)"
        />
        <path
          d="M1 67C16.976 67 50.6432 61.7791 75 45C99.3568 28.2209 105.5 17 143 12"
          stroke="#2B2B2B"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_12386_76029"
          x1="73"
          y1="-58.5"
          x2="72.5"
          y2="119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-opacity="0.13" />
          <stop offset="1" stop-opacity="0" />
        </linearGradient>
        <clipPath id="clip0_12386_76029">
          <rect width="144" height="119" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
