import React, { useEffect, useState } from 'react';
import { authApi } from '../../../api/authApi';
import { DefaultUserWhiteBg } from '../../../icons/default-user-white-bg';
import cn from 'classnames';
import './AppHeader.scss';
import { ProfilePopover } from './ProfilePopover/ProfilePopover';
import kk from '../../../images/demo/avatar.JPG';

export const AppHeader = ({ collapsed }) => {
  return (
    <>
      <div className="app-header__contai1er">
        <div className={cn('app-header__right-side', { collapsed })}>
          <ProfilePopover>
            <img
              src={kk}
              width={36}
              height={36}
              className="app-header__right-side__img"
              alt="user profile"
            />
          </ProfilePopover>
        </div>
      </div>
    </>
  );
};
