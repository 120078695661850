import React from 'react';

export const OrangeTofuBgIcon = () => {
  return (
    <svg width="63" height="73" viewBox="0 0 63 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 89" clip-path="url(#clip0_6513_56744)">
        <rect id="Rectangle 4301" width="63" height="73" rx="10" fill="#F6F6F6" />
        <rect id="Rectangle 4303" x="14" y="39" width="36" height="28" rx="5" fill="white" />
        <rect id="Rectangle 4304" x="14" y="26" width="36" height="6" rx="3" fill="#FF6B00" />
        <rect id="Rectangle 4305" x="26" y="17" width="24" height="6" rx="3" fill="white" />
        <rect id="Rectangle 4306" x="14" y="8" width="24" height="6" rx="3" fill="#FFC700" />
      </g>
      <defs>
        <clipPath id="clip0_6513_56744">
          <rect width="63" height="73" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
