import React from 'react';

export const SettingsIconGear = ({ fill }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="menu">
        <path
          id="&#229;&#189;&#162;&#231;&#138;&#182;"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.96478 2.80881C9.59589 2.54328 9.07558 2.4191 8.56387 2.60633C7.4666 3.0078 6.46004 3.59559 5.5823 4.3308C5.16522 4.68015 5.01268 5.19204 5.05794 5.64391C5.13333 6.39671 5.00046 7.12356 4.63874 7.75007C4.27685 8.37689 3.71344 8.85555 3.02334 9.16664C2.60859 9.35361 2.24074 9.74273 2.14751 10.2801C2.05047 10.8396 2 11.4143 2 12.0001C2 12.5858 2.05047 13.1606 2.14752 13.72C2.24075 14.2574 2.6086 14.6465 3.02335 14.8335C3.71344 15.1446 4.27685 15.6233 4.63874 16.2501C5.00045 16.8766 5.13332 17.6034 5.05794 18.3562C5.01269 18.8081 5.16523 19.32 5.5823 19.6693C6.46002 20.4045 7.46655 20.9923 8.56378 21.3937C9.07552 21.581 9.59585 21.4568 9.96474 21.1912C10.5794 20.7488 11.2759 20.5 12 20.5C12.7241 20.5 13.4206 20.7488 14.0353 21.1912C14.4042 21.4568 14.9245 21.581 15.4362 21.3937C16.5334 20.9923 17.5399 20.4045 18.4176 19.6694C18.8347 19.32 18.9872 18.8081 18.942 18.3562C18.8666 17.6034 18.9994 16.8766 19.3611 16.2501C19.723 15.6233 20.2865 15.1446 20.9766 14.8335C21.3914 14.6465 21.7593 14.2574 21.8525 13.72C21.9495 13.1606 22 12.5858 22 12.0001C22 11.4144 21.9495 10.8397 21.8525 10.2803C21.7593 9.74288 21.3914 9.35374 20.9766 9.16678C20.2865 8.85569 19.723 8.37702 19.3611 7.7502C18.9994 7.12366 18.8666 6.39677 18.942 5.64392C18.9873 5.19202 18.8347 4.6801 18.4176 4.33073C17.5399 3.59556 16.5334 3.00779 15.4361 2.60633C14.9244 2.41911 14.4041 2.54328 14.0352 2.80881C13.4206 3.25123 12.7241 3.50003 12 3.50003C11.2759 3.50003 10.5794 3.25123 9.96478 2.80881ZM9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
