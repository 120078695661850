import React from 'react';
import './HoverPercent.scss';

export const HoverPercent = ({ hoveredIndex, dataHoverPercent, revenue }) => {
  return (
    <div className={revenue ? `hover-percent__container revenue` : 'hover-percent__container'}>
      <div className="hover-percent__top">
        <p>
          <span className="hover-percent__bold">Воскресенье</span>{' '}
          <span className="hover-percent__top-day-info">(16.07)</span>
        </p>
      </div>
      {revenue ? (
        <div className="hover-percent__bold">
          {' '}
          <p>
            <span className="hover-percent__text">Выручка </span>
            {dataHoverPercent[hoveredIndex].sum} ₽
          </p>
        </div>
      ) : (
        <>
          <div className="hover-percent__bold">{dataHoverPercent[hoveredIndex].percent}</div>
          <div className="hover-percent__bottom">Сумма {dataHoverPercent[hoveredIndex].sum} ₽</div>
        </>
      )}
    </div>
  );
};
