import { useEffect, useRef, useState } from 'react';
import { CheckBox } from '../../CheckBox/CheckBox';
import { BlackArrowForSelect } from '../../../../icons/black-arrow-for-select';
import './TableSelect.scss';
import ArrowSelect from '../../../../icons/arrow-select.svg';

export const TableSelect = ({}) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const selectRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setExpanded(false);
      }
    };

    if (expanded) {
      document.addEventListener('click', handleOutsideClick);
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [expanded]);

  return (
    <div className="table-select__container" ref={selectRef}>
      <div className="table-select__header" onClick={handleToggleExpand}>
        <p>Действия</p>{' '}
        <img
          className={`select-arrow ${expanded ? 'expanded' : 'expanded-close'}`}
          src={ArrowSelect}
        />
      </div>
      {expanded && (
        <div className="table-select__list">
          <p className="table-select__item">
            <CheckBox />
            Скрыть позиции с количеством 0
          </p>
          <p className="table-select__item">
            <CheckBox />
            Показать <span>удаленные позиции</span>
          </p>
        </div>
      )}
    </div>
  );
};
