import api from './baseApi';
import { trimUrl } from './helper';

export const reviewsApi = {
  getAllPoints: (account_id) => {
    return api.get(`/reviews/all_points/${account_id}/`);
  },
  getAllReviews: (account_id, platform, ordering) => {
    return api.get(
      `/reviews/all_reviews/${account_id}/?provider_type=${platform}&ordering=${ordering}`
    );
  },

  getSortedAllReviews: (
    preparedUrl,
    account_id,
    selectedDate,
    platform,
    ordering = 0,
    nps_type = 0,
    startDate,
    endDate,
    setSelectedDate,
    setStartDate,
    setEndDate
  ) => {
    let url;
    if (selectedDate === '') {
      url = `/reviews/all_reviews/${account_id}/?start_time_date=${startDate}&end_time_date=${endDate}&&provider_type=${platform}&ordering=${ordering}&nps_type=${nps_type}`;
    } else {
      url =
        selectedDate === 'all_time'
          ? `/reviews/all_reviews/${account_id}/?&provider_type=${platform}&ordering=${ordering}&nps_type=${nps_type}`
          : `/reviews/all_reviews/${account_id}/?time_period=${selectedDate}&provider_type=${platform}&ordering=${ordering}&nps_type=${nps_type}`;
    }
    return api.get(preparedUrl ? trimUrl(preparedUrl) : url);
  },

  getAverageRating: (account_id) => {
    return api.get(`/reviews/average_rating/${account_id}/`);
  },

  getReviewByStars: (account_id, nps_type) => {
    return api.get(`/reviews/stars/${account_id}/?nps_type=${nps_type}`);
  },
  getFilteredReviewsByStars: (account_id, selectedDate, nps_type, selectedPlatform) => {
    return api.get(
      selectedDate === 'all_time'
        ? `/reviews/stars/${account_id}/?nps_type=${nps_type}&provider_type=${selectedPlatform}`
        : `/reviews/stars/${account_id}/?time_period=${selectedDate}&nps_type=${nps_type}&provider_type=${selectedPlatform}`
    );
  },

  getReviesByMention: (account_id) => {
    return api.get(`/reviews/mentions/${account_id}/`);
  },

  getFilteredReviewsByMention: (account_id, selectedDate, selectedPlatform) => {
    return api.get(
      selectedDate === 'all_time'
        ? `/reviews/mentions/${account_id}/?provider_type=${selectedPlatform}`
        : `/reviews/mentions/${account_id}/?time_period=${selectedDate}&provider_type=${selectedPlatform}`
    );
  },

  getSelected: (account_id, platform) => {
    return api.get(`/reviews/nps/get_selected/${account_id}/?provider_type=${platform}`);
  },

  getFilteredSelected: (account_id, selectedDate, platform) => {
    return api.get(
      selectedDate === 'all_time'
        ? `/reviews/nps/get_selected/${account_id}/?provider_type=${platform}`
        : `/reviews/nps/get_selected/${account_id}/?time_period=${selectedDate}&provider_type=${platform}`
    );
  },

  getAllPages: (account_id, page) => {
    return api.get(`/reviews/nps/get_selected/${account_id}/?page=${page}`);
  },

  getAll: (account_id) => {
    return api.get(`/reviews/nps/all/${account_id}/`);
  },

  sendNps: (account_id, nps) => {
    return api.post('reviews/nps/select_nps_ids/', {
      nps: nps,
      account_id: account_id,
    });
  },

  getReviewByAverageRatingAll: (account_id, nps_type) => {
    return api.get(`/reviews/average_rating/${account_id}/?nps_type=${nps_type}`);
  },

  getReviewByAverageRating: (account_id, nps_type) => {
    return api.get(`/reviews/average_rating/${account_id}/?nps_type=${nps_type}`);
  },

  getFilteredReviewsByAverageRating: (account_id, selectedDate, nps_type, selectedPlatform) => {
    return api.get(
      selectedDate === 'all_time'
        ? `/reviews/average_rating/${account_id}/?nps_type=${nps_type}&provider_type=${selectedPlatform}`
        : `/reviews/average_rating/${account_id}/?time_period=${selectedDate}&nps_type=${nps_type}&provider_type=${selectedPlatform}`
    );
  },

  getReviewsByTonality: (account_id, nps_type) => {
    return api.get(`/reviews/tonality/${account_id}/?nps_type=${nps_type}`);
  },
  getFilteredReviewsByTonality: (account_id, selectedDate, nps_type, selectedPlatform) => {
    return api.get(
      selectedDate === 'all_time'
        ? `/reviews/tonality/${account_id}/?nps_type=${nps_type}&provider_type=${selectedPlatform}`
        : `/reviews/tonality/${account_id}/?time_period=${selectedDate}&nps_type=${nps_type}&provider_type=${selectedPlatform}`
    );
  },
};
