import { useEffect, useState } from 'react';
import { reviewsApi } from '../../../../api/reviewsApi';
import Circle from '../../../../components/common/Circle/Circle';
import { sortServerData } from './helper';
import { ERROR_TEXT } from '../../../../const/commonConst';
import { ErrorWidget } from '../../../../components/common/ErrorWidget/ErrorWidget';
import './MenthionWidget.scss';
import { MenthionStatisctis } from './MenthionStatistics/MenthionStatistics';

export const MenthionWidget = ({ selectedDate, selectedPlatform, account_id  }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    reviewsApi
      .getFilteredReviewsByMention(account_id, selectedDate, selectedPlatform)
      .then((res) => {
        setData(sortServerData(res.data));
      });
  }, [account_id, selectedDate, selectedPlatform]);

  if (Array.isArray(data) && data.length === 0) {
    return <ErrorWidget text={ERROR_TEXT} withMargin />;
  }

  return (
    <div className="menthion-widget">
      <div>
        <Circle sortedData={data} tooltip />
      </div>
      <div >
        {/* <Statistics sortedData={data} /> */}
        <MenthionStatisctis sortedData={data} />
      </div>
    </div>
  );
};
