import { all, takeLatest, put } from "redux-saga/effects";
import jwtDecode from "jwt-decode";
import { authApi, resetAuthorization } from "../../api/authApi";
import { authActions } from "./authActions";
import { AUTH, LOGOUT } from "./authConstants";
import { REG } from "./authConstants";

function* authSaga({
  payload: { email, password, franchise_code, history, location, supportEmail },
}) {
  try {
    const response = yield authApi.auth(email, password, franchise_code);
    const { data } = response;
    const token = data.access;
    const refresh = data.refresh;
    const isFranchiseOwner = data.is_franchise_owner;
    const userType = data.user_type;
    const isEmailRegistrationInProgress =
      data.is_email_registration_in_processing;
    if (response.status === 200) {
      localStorage.setItem("authToken", token);
      localStorage.setItem("refreshToken", refresh);
      localStorage.setItem(
        "isFranchiseOwner",
        JSON.stringify(isFranchiseOwner)
      );
      localStorage.setItem("userType", JSON.stringify(userType));
      localStorage.setItem(
        "isEmailRegistrationInProgress",
        JSON.stringify(isEmailRegistrationInProgress)
      );
      const { user_id, first_name, last_name } = jwtDecode(token);
      const fullName = first_name + " " + last_name;
      localStorage.setItem("fullName", JSON.stringify(fullName));
      yield put(
        authActions.setAuthData(
          token,
          user_id,
          isFranchiseOwner,
          isEmailRegistrationInProgress,
          userType,
          fullName
        )
      );
      const { from } = location.state || { from: { pathname: "/" } };
      history.replace(from);
    }
  } catch (err) {}
}

function* regSaga(action) {
  try {
    const payload = action.payload;
    const response = yield authApi.registrationRequest(payload);
    if (response.status === 201) {
      yield put(authActions.regSuccess());
    }
  } catch (err) {
    let text = "";
    Object.entries(err.response.data).map((value) => {
      if (value[0] !== "status_code") {
        text += " " + value[1][0];
      }
    });
    yield put(authActions.regFailure());
  }
}

function* logOutSaga() {
  try {
    resetAuthorization();
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("selectedCompany");
    localStorage.removeItem("isFranchiseOwner");
    localStorage.removeItem("isEmailRegistrationInProgress");
    localStorage.removeItem("userType");
    localStorage.removeItem("fullName");
    yield put(authActions.logOutSuccess());
  } catch (err) {
    console.log("err", err);
  }
}

function* sagas() {
  yield all([
    takeLatest(AUTH, authSaga),
    takeLatest(REG, regSaga),
    takeLatest(LOGOUT, logOutSaga),
  ]);
}

export const authSagas = sagas;
