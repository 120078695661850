import React from 'react';

export const BlueTofuBgIcon = () => {
  return (
    <svg width="63" height="73" viewBox="0 0 63 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6513_56722)">
        <rect width="63" height="73" rx="10" fill="#F0F6FE" />
        <rect x="14" y="39" width="36" height="28" rx="5" fill="white" />
        <rect x="14" y="26" width="36" height="6" rx="3" fill="#0075FF" />
        <rect x="26" y="17" width="24" height="6" rx="3" fill="white" />
        <rect x="14" y="8" width="24" height="6" rx="3" fill="#67A8F4" />
      </g>
      <defs>
        <clipPath id="clip0_6513_56722">
          <rect width="63" height="73" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
