import React from 'react';

export const TableIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icons">
        <path
          id="table"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.4348 12.4349C18.832 12.1642 23 10.3871 23 8.23529C23 5.89621 18.0751 4 12 4C5.92487 4 1 5.89621 1 8.23529C1 10.3872 5.16803 12.1642 10.5652 12.4349V15.3571C7.82316 15.6022 5.78259 16.5344 5.78259 17.6471C5.78259 18.9466 8.56621 20 12 20C15.4338 20 18.2174 18.9466 18.2174 17.6471C18.2174 16.5345 16.1769 15.6022 13.4348 15.3571V12.4349Z"
          fill="#F56C7B"
        />
      </g>
    </svg>
  );
};
