import { useState } from 'react';
import { Tabs } from '../../../../../components/common/Tabs/Tabs';
import Chart from '../../../../../components/reports/Circle/Circle';
import { Statistics } from '../../../../../components/reports/Statistics/Statistics';
import './WriteOff.scss';

const chartData = {
  slides: [
    {
      id: 0,
      tabTitle: 'По количеству',
      percent: "2,32%",
      tittle: "Отмена блюд со списанием",
      discription: "Отменено блюд",
      sum: "172 пц.",
      data: [
        { reason_name: 'Копылович Иван', sum_of_cost: '55', percent: 32 },
         { reason_name: 'Седнеров Алексей', sum_of_cost: '48', percent: 28 },
         { reason_name: 'Новик Кирилл', sum_of_cost: '33', percent: 19 },
         { reason_name: 'Шилко Денис', sum_of_cost: '19', percent: 11 },
         { reason_name: 'Другие', sum_of_cost: '17', percent: 10 },
      ],
    },
    {
      id: 1,
      tabTitle: 'По сумме',
      percent: "2,19%",
      tittle: "От выручки",
      discription: "Сумма отмен",
      sum: "54 470 ₽",
      data: [
        { reason_name: 'Копылович Иван', sum_of_cost: '17 430', percent: 32 },
         { reason_name: 'Седнеров Алексей', sum_of_cost: '15 250', percent: 28 },
         { reason_name: 'Новик Кирилл', sum_of_cost: '10 350', percent: 19 },
         { reason_name: 'Шилко Денис', sum_of_cost: '5 990', percent: 11 },
         { reason_name: 'Другие', sum_of_cost: '5 450', percent: 10 },
      ],
    },
    {
      id: 2,
      tabTitle: 'По себестоимости',
      percent: "2,01%",
      tittle: "От себестоимости",
      discription: "Сумма отмен",
      sum: "12 293,96 ₽",
      data: [
        { reason_name: 'Копылович Иван', sum_of_cost: '3 934,07', percent: 32 },
         { reason_name: 'Седнеров Алексей', sum_of_cost: '3 442,31', percent: 28 },
         { reason_name: 'Новик Кирилл', sum_of_cost: '2 335,85', percent: 19 },
         { reason_name: 'Шилко Денис', sum_of_cost: '1 352,34', percent: 11 },
         { reason_name: 'Другие', sum_of_cost: '1 229,40', percent: 10 },
      ],
    },
  ],
};


// const writeOffDiagramDataTest = [
//   { reason_name: 'Питание персонала', sum_of_cost: '51 299,44', percent: 0.73 },

//   { reason_name: 'Удаление блюд со списанием', sum_of_cost: '12 293,96', percent: 0.49 },

//   { reason_name: 'Порча', sum_of_cost: '11 743,70', percent: 0.47 },

//   { reason_name: 'Бракераж', sum_of_cost: '6 055,70', percent: 0.24 },

//   { reason_name: 'Служебные оплаты', sum_of_cost: '2 986,99', percent: 0.12 },
// ];

// writeOffDiagramDataTest ВОТ ТАКИЕ ДОЛЖНЫ БЫТЬ ПРОПСЫ

export const WriteOff = () => {
  const [currentTab, setCurrentTab] = useState('0');
  return (
    <Tabs tabs={chartData.slides} currentTab={currentTab} setCurrentTab={setCurrentTab}>
      <div className="write-off">
        <Chart data={chartData.slides[currentTab].data} currentTab={currentTab} info={chartData.slides[currentTab]}/>
        <Statistics data={chartData.slides[currentTab]?.data} />
      </div>
    </Tabs>
  );
};
