export const DATA = [
  {
    name: 'Сумма',
    compare_period: '14 189 938.20 ₽',
    report_period: '28 474 950.50 ₽',
    network_growth: '14 285 012.30 ₽',
    network_dynamic: 100.67,
    comparable_growth: '6051247.40 ₽',
    comparable_dynamic: 55.26,

    child_objects: [
      {
        name: 'Московская область',
        compare_period: '9 354 679.20 ₽',
        report_period: '18 467 715.50 ₽',
        network_growth: '9 113 036.30 ₽',
        network_dynamic: 97.42,
        comparable_growth: '5 880 874.40 ₽',
        comparable_dynamic: 96.16,
        items: [
          {
            name: 'Caviar, Москва, Дзержинского 16',
            compare_period: '6 115 953.60 ₽',
            report_period: '11 996 828.00 ₽',
            network_growth: '5 880 874.40 ₽',
            network_dynamic: 96.16,
            comparable_growth: '5 880 874.40 ₽',
            comparable_dynamic: 96.16,
          },
          {
            name: 'Caviar Sushi, Москва, Дзержинского 16',
            compare_period: '3 238 725.60 ₽',
            report_period: '0.00 ₽',
            network_growth: '-3 238 725.60 ₽',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
          {
            name: 'Dolce Cafe, Химки, Малинина 9',
            compare_period: '0.00 ₽',
            report_period: '6 470 887.50 ₽',
            network_growth: '6 470 887.50 ₽',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
      {
        name: 'Ленинградская область',
        compare_period: '4 835 259.00 ₽',
        report_period: '10 007 235.00 ₽',
        network_growth: '5 171 976.00 ₽',
        network_dynamic: 106.96,
        comparable_growth: '170 373.00 ₽',
        comparable_dynamic: 3.52,
        items: [
          {
            name: 'Caviar, Санкт-Петербург, Пролетарская 74',
            compare_period: '4 835 259.00 ₽',
            report_period: '5 005 632.00 ₽',
            network_growth: '170 373.00 ₽',
            network_dynamic: 3.52,
            comparable_growth: '170 373.00',
            comparable_dynamic: 3.52,
          },
          {
            name: 'Caviar Sushi, Санкт-Петербург, Пролетарская 74',
            compare_period: '0.00 ₽',
            report_period: '5 001 603.00 ₽',
            network_growth: '5 001 603.00 ₽',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
    ],
  },
  {
    name: 'Выручка',
    compare_period: '14 080 420.00 ₽',
    report_period: '27 161 641.21 ₽',
    network_growth: '13 081 221.21 ₽',
    network_dynamic: 92.9,
    comparable_growth: '5 416 170.92 ₽',
    comparable_dynamic: 49.8,
    child_objects: [
      {
        name: 'Московская область',
        compare_period: '9 259 100.00 ₽',
        report_period: '17 654 848.54 ₽',
        network_growth: '8 395 748.54 ₽',
        network_dynamic: 90.68,
        comparable_growth: '5 582 253.16',
        comparable_dynamic: 92.2,
        items: [
          {
            name: 'Caviar, Москва, Дзержинского 16',
            compare_period: '6 054 670.00 ₽',
            report_period: '11 636 923.16 ₽',
            network_growth: '5 582 253.16 ₽',
            network_dynamic: 92.2,
            comparable_growth: '5 582 253.16 ₽',
            comparable_dynamic: 92.2,
          },
          {
            name: 'Caviar Sushi, Москва, Дзержинского 16',
            compare_period: '3 204 430.00 ₽',
            report_period: '0.00 ₽',
            network_growth: '-3 204 430.00 ₽',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
          {
            name: 'Dolce Cafe, Химки, Малинина 9',
            compare_period: '0.00 ₽',
            report_period: '6 017 925.38 ₽',
            network_growth: '6 017 925.38 ₽',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
      {
        name: 'Ленинградская область',
        compare_period: '4 821 320.00 ₽',
        report_period: '9 506 792.67 ₽',
        network_growth: '4 685 472.67 ₽',
        network_dynamic: 97.18,
        comparable_growth: '-166 082.24 ₽',
        comparable_dynamic: -3.44,
        items: [
          {
            name: 'Caviar, Санкт-Петербург, Пролетарская 74',
            compare_period: '4 821 320.00 ₽',
            report_period: '4 655 237.76 ₽',
            network_growth: '-166 082.24 ₽',
            network_dynamic: -3.44,
            comparable_growth: '-166 082.24 ₽',
            comparable_dynamic: -3.44,
          },
          {
            name: 'Caviar Sushi, Санкт-Петербург, Пролетарская 74',
            compare_period: '0.00 ₽',
            report_period: '4 851 554.91 ₽',
            network_growth: '4 851 554.91 ₽',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
    ],
  },
  {
    name: 'Итого оплачено',
    compare_period: '13 820 880.00 ₽',
    report_period: '25 919 865.44 ₽',
    network_growth: '12 098 985.44 ₽',
    network_dynamic: 87.54,
    comparable_growth: '4 794 816.58 ₽',
    comparable_dynamic: 44.3,
    child_objects: [
      {
        name: 'Московская область',
        compare_period: '9 008 940.00 ₽',
        report_period: '16 884 486.06 ₽',
        network_growth: '7 875 546.06 ₽',
        network_dynamic: 87.42,
        comparable_growth: '5 277 385.47 ₽',
        comparable_dynamic: 87.8,
        items: [
          {
            name: 'Caviar, Москва, Дзержинского 16',
            compare_period: '6 010 430.00 ₽',
            report_period: '11 287 815.47 ₽',
            network_growth: '5 277 385.47 ₽',
            network_dynamic: 87.8,
            comparable_growth: '5 277 385.47 ₽',
            comparable_dynamic: 87.8,
          },
          {
            name: 'Caviar Sushi, Москва, Дзержинского 16',
            compare_period: '2 998 510.00 ₽',
            report_period: '0.00 ₽',
            network_growth: '-2 998 510.00 ₽',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
          {
            name: 'Dolce Cafe, Химки, Малинина 9',
            compare_period: '0.00 ₽',
            report_period: '5 596 670.60 ₽',
            network_growth: '5 596 670.60 ₽',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
      {
        name: 'Ленинградская область',
        compare_period: '4 811 940.00 ₽',
        report_period: '9 035 379.38 ₽',
        network_growth: '4 223 439.38 ₽',
        network_dynamic: 87.77,
        comparable_growth: '-4 825 68.88 ₽',
        comparable_dynamic: -10.03,
        items: [
          {
            name: 'Caviar, Санкт-Петербург, Пролетарская 74',
            compare_period: '4 811 940.00 ₽',
            report_period: '4 329 371.12 ₽',
            network_growth: '-4 825 68.88 ₽',
            network_dynamic: -10.03,
            comparable_growth: '-4 825 68.88 ₽',
            comparable_dynamic: -10.03,
          },
          {
            name: 'Caviar Sushi, Санкт-Петербург, Пролетарская 74',
            compare_period: '0.00 ₽',
            report_period: '4 706 008.26 ₽',
            network_growth: '4 706 008.26 ₽',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
    ],
  },
  {
    name: 'Фудкост',
    compare_period: '31.54%',
    report_period: '27.97%',
    network_growth: '-3.56%',
    network_dynamic: 11.30,
    comparable_growth: '0.45%',
    comparable_dynamic: -1.53,
    child_objects: [
      {
        name: 'Московская область',
        compare_period: '32.90%',
        report_period: '24.34%',
        network_growth: '-8.57%',
        network_dynamic: 26.03,
        comparable_growth: '2.15%',
        comparable_dynamic: -7.34,
        items: [
          {
            name: 'Caviar, Москва, Дзержинского 16',
            compare_period: '29.31%',
            report_period: '31.46%',
            network_growth: '2.15%',
            network_dynamic: -7.34,
            comparable_growth: '2.15%',
            comparable_dynamic: -7.34,
          },
          {
            name: 'Caviar Sushi, Москва, Дзержинского 16',
            compare_period: '36.49%',
            report_period: '0.00%',
            network_growth: '-36.49%',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
          {
            name: 'Dolce Cafe, Химки, Малинина 9',
            compare_period: '0.00%',
            report_period: '17.21%',
            network_growth: '17.21%',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
      {
        name: 'Ленинградская область',
        compare_period: '30.17%',
        report_period: '31.61%',
        network_growth: '1.44%',
        network_dynamic: -4.77,
        comparable_growth: '-1.24%',
        comparable_dynamic: 4.11,
        items: [
          {
            name: 'Caviar, Санкт-Петербург, Пролетарская 74',
            compare_period: '30.17%',
            report_period: '28.93%',
            network_growth: '-1.24%',
            network_dynamic: 4.11,
            comparable_growth: '-1.24%',
            comparable_dynamic: 4.11,
          },
          {
            name: 'Caviar Sushi, Санкт-Петербург, Пролетарская 74',
            compare_period: '0.00%',
            report_period: '34.29%',
            network_growth: '34.29%',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
    ],
  },
  {
    name: 'Списания',
    compare_period: '3.67%',
    report_period: '2.72%',
    network_growth: '-0.96%',
    network_dynamic: 26.00,
    comparable_growth: '-0.20%',
    comparable_dynamic: 5.59,
    child_objects: [
      {
        name: 'Московская область',
        compare_period: '3.92%',
        report_period: '1.64%',
        network_growth: '-2.28%',
        network_dynamic: 58.24,
        comparable_growth: '-0.81%',
        comparable_dynamic: 21.77,
        items: [
          {
            name: 'Caviar, Москва, Дзержинского 16',
            compare_period: '3.72%',
            report_period: '2.91%',
            network_growth: '-0.81%',
            network_dynamic: 21.77,
            comparable_growth: '-0.81%',
            comparable_dynamic: 21.77,
          },
          {
            name: 'Caviar Sushi, Москва, Дзержинского 16',
            compare_period: '4.11%',
            report_period: '0.00%',
            network_growth: '-4.11%',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
          {
            name: 'Dolce Cafe, Химки, Малинина 9',
            compare_period: '0.00%',
            report_period: '0.36%',
            network_growth: '0.36%',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
      {
        name: 'Ленинградская область',
        compare_period: '3.43%',
        report_period: '3.80%',
        network_growth: '0.37%',
        network_dynamic: -10.79,
        comparable_growth: '0.41%',
        comparable_dynamic: -11.95,
        items: [
          {
            name: 'Caviar, Санкт-Петербург, Пролетарская 74',
            compare_period: '3.43%',
            report_period: '3.84%',
            network_growth: '0.41%',
            network_dynamic: -11.95,
            comparable_growth: '0.41%',
            comparable_dynamic: -11.95,
          },
          {
            name: 'Caviar Sushi, Санкт-Петербург, Пролетарская 74',
            compare_period: '0.00%',
            report_period: '3.76%',
            network_growth: '3.76%',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
    ],
  },
  {
    name: 'Количество заказов',
    compare_period: '1 555',
    report_period: '12 827',
    network_growth: '11 272',
    network_dynamic: 724.89,
    comparable_growth: 274,
    comparable_dynamic: 29.75,
    child_objects: [
      {
        name: 'Московская область',
        compare_period: '1 126',
        report_period: '11 135',
        network_growth: '10 009',
        network_dynamic: 888.9,
        comparable_growth: 268,
        comparable_dynamic: 54.47,
        items: [
          {
            name: 'Caviar, Москва, Дзержинского 16',
            compare_period: 492,
            report_period: 760,
            network_growth: 268,
            network_dynamic: 54.47,
            comparable_growth: 268,
            comparable_dynamic: 54.47,
          },
          {
            name: 'Caviar Sushi, Москва, Дзержинского 16',
            compare_period: 634,
            report_period: '0.00 ₽',
            network_growth: -634,
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
          {
            name: 'Dolce Cafe, Химки, Малинина 9',
            compare_period: '0.00 ₽',
            report_period: '10 375',
            network_growth: '10 375',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
      {
        name: 'Ленинградская область',
        compare_period: 429,
        report_period: '1 692',
        network_growth: '',
        network_dynamic: 294.41,
        comparable_growth: 6,
        comparable_dynamic: 1.4,
        items: [
          {
            name: 'Caviar, Санкт-Петербург, Пролетарская 74',
            compare_period: 429,
            report_period: 435,
            network_growth: 6,
            network_dynamic: 1.40,
            comparable_growth: 6,
            comparable_dynamic: 1.40,
          },
          {
            name: 'Caviar Sushi, Санкт-Петербург, Пролетарская 74',
            compare_period: '0.00 ₽',
            report_period: '1 257',
            network_growth: '1 257',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
    ],
  },
  {
    name: 'Средний чек за заказ',
    compare_period: '10 020.30 ₽',
    report_period: '7 973.80 ₽',
    network_growth: '-2 046.50 ₽',
    network_dynamic: -20.42,
    comparable_growth: '1 795.35 ₽',
    comparable_dynamic: 15.15,
    child_objects: [
      {
        name: 'Московская область',
        compare_period: '8 769.60 ₽',
        report_period: '8 204.50 ₽',
        network_growth: '-565.10 ₽',
        network_dynamic: -6.44,
        comparable_growth: '3 354.50 ₽',
        comparable_dynamic: 26.99,
        items: [
          {
            name: 'Caviar, Москва, Дзержинского 16',
            compare_period: '12 430.80 ₽',
            report_period: '15 785.30 ₽',
            network_growth: '3 354.50 ₽',
            network_dynamic: 26.99,
            comparable_growth: '3 354.50 ₽',
            comparable_dynamic: 26.99,
          },
          {
            name: 'Caviar Sushi, Москва, Дзержинского 16',
            compare_period: '5 108.40 ₽',
            report_period: '0.00 ₽',
            network_growth: '-5 108.40 ₽',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
          {
            name: 'Dolce Cafe, Химки, Малинина 9',
            compare_period: '0.00 ₽',
            report_period: '623.70 ₽',
            network_growth: '623.70 ₽',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
      {
        name: 'Ленинградская область',
        compare_period: '11 271.00 ₽',
        report_period: '7 743.10 ₽',
        network_growth: '-3 527.90 ₽',
        network_dynamic: -31.3,
        comparable_growth: '236.20 ₽',
        comparable_dynamic: 2.1,
        items: [
          {
            name: 'Caviar, Санкт-Петербург, Пролетарская 74',
            compare_period: '11 271.00 ₽',
            report_period: '11 507.20 ₽',
            network_growth: '236.20 ₽',
            network_dynamic: 2.1,
            comparable_growth: '236.20 ₽',
            comparable_dynamic: 2.1,
          },
          {
            name: 'Caviar Sushi, Санкт-Петербург, Пролетарская 74',
            compare_period: '0.00 ₽',
            report_period: '3 979.00 ₽',
            network_growth: '3 979.00 ₽',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
    ],
  },
  {
    name: 'Среднее количество строк в чеке',
    compare_period: '9.53',
    report_period: '7.99',
    network_growth: '-1.54',
    network_dynamic: -16.14,
    comparable_growth: '0.63',
    comparable_dynamic: 6.47,
    child_objects: [
      {
        name: 'Московская область',
        compare_period: '8.9',
        report_period: '7.4',
        network_growth: '-1.56',
        network_dynamic: -17.38,
        comparable_growth: '1.54',
        comparable_dynamic: 16.44,
        items: [
          {
            name: 'Caviar, Москва, Дзержинского 16',
            compare_period: '9.37',
            report_period: '10.91',
            network_growth: '1.54',
            network_dynamic: 16.44,
            comparable_growth: '1.54',
            comparable_dynamic: 16.44,
          },
          {
            name: 'Caviar Sushi, Москва, Дзержинского 16',
            compare_period: '8.52',
            report_period: '0.00',
            network_growth: '-8.52',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
          {
            name: 'Dolce Cafe, Химки, Малинина 9',
            compare_period: '0.00',
            report_period: '3.87',
            network_growth: '3.87',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
      {
        name: 'Ленинградская область',
        compare_period: '10.1',
        report_period: '8.6',
        network_growth: '-1.52',
        network_dynamic: 15.03,
        comparable_growth: '-0.28',
        comparable_dynamic: -2.77,
        items: [
          {
            name: 'Caviar, Санкт-Петербург, Пролетарская 74',
            compare_period: '10.11',
            report_period: '9.83',
            network_growth: '-0.28',
            network_dynamic: -2.77,
            comparable_growth: '-0.28',
            comparable_dynamic: -2.77,
          },
          {
            name: 'Caviar Sushi, Санкт-Петербург, Пролетарская 74',
            compare_period: '0.00',
            report_period: '7.35',
            network_growth: '7.35',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
    ],
  },
  {
    name: 'Количество гостей',
    compare_period: 5156,
    report_period: 18920,
    network_growth: 13764,
    network_dynamic: 266.95,
    comparable_growth: 1172.2,
    comparable_dynamic: 34.03,
    child_objects: [
      {
        name: 'Московская область',
        compare_period: 3483,
        report_period: 14681,
        network_growth: 11198,
        network_dynamic: 321.49,
        comparable_growth: 1496.8,
        comparable_dynamic: 84.51,
        items: [
          {
            name: 'Caviar, Москва, Дзержинского 16',
            compare_period: 1771,
            report_period: 3268,
            network_growth: 1497,
            network_dynamic: 84.51,
            comparable_growth: 1496.8,
            comparable_dynamic: 84.51,
          },
          {
            name: 'Caviar Sushi, Москва, Дзержинского 16',
            compare_period: 1712,
            report_period: '0',
            network_growth: -1712,
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
          {
            name: 'Dolce Cafe, Химки, Малинина 9',
            compare_period: '0',
            report_period: 11413,
            network_growth: 11413,
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
      {
        name: 'Ленинградская область',
        compare_period: 1673,
        report_period: 4240,
        network_growth: '',
        network_dynamic: 153.4,
        comparable_growth: -324.6,
        comparable_dynamic: -19.4,
        items: [
          {
            name: 'Caviar, Санкт-Петербург, Пролетарская 74',
            compare_period: 1673,
            report_period: 1349,
            network_growth: -325,
            network_dynamic: -19.40,
            comparable_growth: -324.6,
            comparable_dynamic: -19.4,
          },
          {
            name: 'Caviar Sushi, Санкт-Петербург, Пролетарская 74',
            compare_period: '0',
            report_period: 2891,
            network_growth: 2891,
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
    ],
  },
  {
    name: 'Средний чек на гостя',
    compare_period: '2 781.25 ₽',
    report_period: '2 420.00 ₽',
    network_growth: '-361.25 ₽',
    network_dynamic: -12.99,
    comparable_growth: '520.00 ₽',
    comparable_dynamic: 16.4,
    child_objects: [
      {
        name: 'Московская область',
        compare_period: '2 672.50 ₽',
        report_period: '2 119.00 ₽',
        network_growth: '-553.50 ₽',
        network_dynamic: -20.71,
        comparable_growth: '218.00 ₽',
        comparable_dynamic: 6.31,
        items: [
          {
            name: 'Caviar, Москва, Дзержинского 16',
            compare_period: '3 453.00 ₽',
            report_period: '3 671.00 ₽',
            network_growth: '218.00 ₽',
            network_dynamic: 6.31,
            comparable_growth: '218.00 ₽',
            comparable_dynamic: 6.31,
          },
          {
            name: 'Caviar Sushi, Москва, Дзержинского 16',
            compare_period: '1 892.00 ₽',
            report_period: '0.00 ₽',
            network_growth: '-1 892.00 ₽',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
          {
            name: 'Dolce Cafe, Химки, Малинина 9',
            compare_period: '0.00 ₽',
            report_period: '567.00 ₽',
            network_growth: '567.00 ₽',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
      {
        name: 'Ленинградская область',
        compare_period: '2 890.00 ₽',
        report_period: '2 721.00 ₽',
        network_growth: '-169.00 ₽',
        network_dynamic: -5.85,
        comparable_growth: '822.00 ₽',
        comparable_dynamic: 28.44,
        items: [
          {
            name: 'Caviar, Санкт-Петербург, Пролетарская 74',
            compare_period: '2 890.00 ₽',
            report_period: '3 712.00 ₽',
            network_growth: '822.00 ₽',
            network_dynamic: 28.44,
            comparable_growth: '822.00 ₽',
            comparable_dynamic: 28.44,
          },
          {
            name: 'Caviar Sushi, Санкт-Петербург, Пролетарская 74',
            compare_period: '0.00 ₽',
            report_period: '1 730.00 ₽',
            network_growth: '1 730.00 ₽',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
    ],
  },
  {
    name: 'Среднее количество гостей в заказе',
    compare_period: 3.53,
    report_period: 2.7,
    network_growth: -0.83,
    network_dynamic: -23.4,
    comparable_growth: -0.05,
    comparable_dynamic: -1.33,
    child_objects: [
      {
        name: 'Московская область',
        compare_period: 3.2,
        report_period: 2.7,
        network_growth: -0.45,
        network_dynamic: -14.29,
        comparable_growth: 0.7,
        comparable_dynamic: 19.44,
        items: [
          {
            name: 'Caviar, Москва, Дзержинского 16',
            compare_period: 3.6,
            report_period: 4.3,
            network_growth: 0.7,
            network_dynamic: 19.44,
            comparable_growth: 0.7,
            comparable_dynamic: 19.44,
          },
          {
            name: 'Caviar Sushi, Москва, Дзержинского 16',
            compare_period: 2.7,
            report_period: '0',
            network_growth: -2.7,
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
          {
            name: 'Dolce Cafe, Химки, Малинина 9',
            compare_period: '0',
            report_period: 1.1,
            network_growth: 1.1,
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
      {
        name: 'Ленинградская область',
        compare_period: 3.9,
        report_period: 2.7,
        network_growth: -1.2,
        network_dynamic: -30.77,
        comparable_growth: -0.8,
        comparable_dynamic: -20.51,
        items: [
          {
            name: 'Caviar, Санкт-Петербург, Пролетарская 74',
            compare_period: 3.9,
            report_period: 3.1,
            network_growth: -0.8,
            network_dynamic: -20.51,
            comparable_growth: -0.8,
            comparable_dynamic: -20.51,
          },
          {
            name: 'Caviar Sushi, Санкт-Петербург, Пролетарская 74',
            compare_period: '0',
            report_period: 2.3,
            network_growth: 2.3,
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
    ],
  },
  {
    name: 'Длительность визита',
    compare_period: '2:25:37',
    report_period: '1:51:39',
    network_growth: '-0:33:58',
    network_dynamic: -23.33,
    comparable_growth: '0:21:01',
    comparable_dynamic: 11.49,
    child_objects: [
      {
        name: 'Московская область',
        compare_period: '1:58:02',
        report_period: '1:34:18',
        network_growth: '-0:23:44',
        network_dynamic: -20.1,
        comparable_growth: '-0:10:06',
        comparable_dynamic: -5.24,
        items: [
          {
            name: 'Caviar, Москва, Дзержинского 16',
            compare_period: '3:12:40',
            report_period: '3:02:34',
            network_growth: '-0:10:06',
            network_dynamic: -5.24,
            comparable_growth: '-0:10:06',
            comparable_dynamic: -5.24,
          },
          {
            name: 'Caviar Sushi, Москва, Дзержинского 16',
            compare_period: '0:43:23',
            report_period: '0:00:00',
            network_growth: '-0:43:23',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
          {
            name: 'Dolce Cafe, Химки, Малинина 9',
            compare_period: '0:00:00',
            report_period: '0:06:02',
            network_growth: '0:06:02',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
      {
        name: 'Ленинградская область',
        compare_period: '2:53:12',
        report_period: '2:08:59',
        network_growth: '-0:44:13',
        network_dynamic: -25.53,
        comparable_growth: '0:52:08',
        comparable_dynamic: 30.1,
        items: [
          {
            name: 'Caviar, Санкт-Петербург, Пролетарская 74',
            compare_period: '2:53:12',
            report_period: '3:45:20',
            network_growth: '0:52:08',
            network_dynamic: 30.1,
            comparable_growth: '0:52:08',
            comparable_dynamic: 30.1,
          },
          {
            name: 'Caviar Sushi, Санкт-Петербург, Пролетарская 74',
            compare_period: '0:00:00',
            report_period: '0:32:38',
            network_growth: '0:32:38',
            network_dynamic: '',
            comparable_growth: '',
            comparable_dynamic: '',
          },
        ],
      },
    ],
  },
];
