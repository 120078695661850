import React, { useEffect, useState } from 'react';
import { ThreeDotsIcon } from '../../../icons/three-dots-icon';
import { FoodcostDynamic } from './components/FoodcostDynamic/FoodcostDynamic';
import { TopTenWidget } from './components/TopTenWidget/TopTenWidget';
import { WriteOff } from './components/WriteOff/WriteOff';
import { PageHeader } from '../../../components/common/PageHeader/PageHeader';
import useSetBodyScroll from '../../../hooks/useSetBodyScroll';
import { foodcostApi } from '../../../api/foodcost';
import { Loader } from '../../../components/common/Loader/Loader';
import './Foodcost.scss';
import { TABLE__DATA } from './const';
import { Banner } from './components/Banner/Banner';
import { NewTable } from '../../../components/common/NewTable/NewTable';
import { TableItem } from '../../../components/common/NewTable/TableItem/TableItem';
import { TwoCards } from '../../../components/reports/TwoCards/TwoCards';

export const Foodcost = ({ account_id }) => {
  const [tableData, setTableData] = useState([]);
  const token = localStorage.getItem('authToken');

  const foodcostDynamicSelects = tableData.map((item) => {
    return {
      id: item.id,
      type: item.name,
    };
  });

  const setBodyScroll = useSetBodyScroll();
  useEffect(() => {
    setBodyScroll(true);
    return () => {
      setBodyScroll(false);
    };
  }, [setBodyScroll]);

  const bannerData = {
    title: 'Фудкост со списаниями',
    sum: '12 674,06 ₽',
    percent: '26,1 %',
    percentProgressBar: 125,
  };

  const bannerList = [
    {
      title: 'Фудкост',
      sum: '278,42',
      percent: '23,5',
      progressPercent: '+1,37%',
      progressPercentColor: 'red',
    },
    {
      title: 'Порча',
      sum: '2 205,15',
      percent: '1,4',
      progressPercent: '-2,45%',
      progressPercentColor: 'green',
    },
    {
      title: 'Питание персонала',
      sum: '1 205,15',
      percent: '1,1',
      progressPercent: '+0,37%',
      progressPercentColor: 'red',
    },
    {
      title: 'Проработка',
      sum: '1 205,15',
      percent: '1,1',
      progressPercent: '+0,37%',
      progressPercentColor: 'red',
    },
  ];

  const dataHoverPercent = [
    {
      percent: '22,13%',
      sum: '274,60',
    },
    {
      percent: '3,85%',
      sum: '2 259,17',
    },
    {
      percent: '0,73%',
      sum: '1 200,50',
    },
    {
      percent: '0,73%',
      sum: '1 200,50',
    },
  ];
  const dataHoverPercentBanner = [
    {
      sum: '12 984,57',
    },
  ];

  const TableSectionInner = [
    'cost_price',
    'sales_number',
    'sales_cost_price',
    'revenue',
    'gross_profit',
    'food_cost',
  ];

  const TwoCardsData = [
    {
      title: 'Удаление блюд со списанием',
      percent: '4,6%',
      percentDiff: '-2,45%',
      color: 'green',
      sum: '12 674,06',
    },
    {
      title: 'Служебные оплаты',
      percent: '4,6%',
      percentDiff: '+1,37%',
      color: 'red',
      sum: '12 674,06',
    },
  ];

  return (
    <>
      <PageHeader title="Фудкост" donwnload isFoodcost />
      <div className="foodcost__container">
        <div className="foodcost__right-side">
          <div>
            <Banner
              banner={bannerData}
              bannerList={bannerList}
              dataHoverPercent={dataHoverPercent}
              dataHoverPercentLeftSidePercent={dataHoverPercentBanner}
              revenue
            />
          </div>
          <div className="foodcost__right-side-up">
            <div className="foodcost__first-column">
              <div className="foodcost__dynamic">
                <div className="foodcost__dynamic-cloud-container">
                  <FoodcostDynamic
                    foodcostDynamicSelects={foodcostDynamicSelects}
                    account_id={account_id}
                    token={token}
                    selectGroups={tableData}
                  />
                </div>
              </div>
              <TwoCards data={TwoCardsData} />
              <div className="foodcost__write-off">
                <div className="foodcost__cloud-container-write-off">
                  <div className="foodcost__cloud-container-header">
                    <div className="foodcost__cloud-container-title-write-off">Списания</div>
                  </div>
                  <WriteOff account_id={account_id} token={token} />
                </div>
              </div>
            </div>
            <div className="foodcost__second-column">
              <div className="foodcost__top-ten-widget">
                <div className="foodcost__cloud-container">
                  <TopTenWidget
                    totalProgressBar={bannerData?.sales_cost_price_including_write_offs}
                    account_id={account_id}
                    token={token}
                    bannerData={bannerData}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="foodcost__table">
            <div className="foodcost__table-wrapper">
              <NewTable
                isFoodcost={true}
                marginLeft={0}
                gridColumns={'22.77% 12.87% 12.87% 12.87% 12.87% 12.87% 12.87%'}
                columnsName={[
                  'Название',
                  'Себестоимость',
                  'Кол-во проданного',
                  'Сумма себестоимости',
                  'Выручка',
                  'Валовая прибыль',
                  'Фудкост, %',
                ]}
                tableData={TABLE__DATA}
                Component={TableItem}
                TableSectionInner={TableSectionInner}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
