import React, { useCallback, useState } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { COLORS } from '../../../../const/nps';
import './Circle.scss';

const renderActiveShape = (props) => {

  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, commonSum } =
    props;
  return (
    <g>
      <text
        x={cx}
        y={cy - 20}
        dy={20}
        textAnchor="middle"
        fill={'#2B2B2B'}
        fontSize="20"
        fontWeight="800"
      >
        {commonSum}
      </text>
      <text x={cx} y={cy} dy={20} textAnchor="middle" fill={'#2B2B2B'} fontSize="14">
        Выручка
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 1}
        outerRadius={outerRadius + 4}
        fill={'#fff'}
      />
    </g>
  );
};

export default function Chart({ data, inner = 85, outer = 103, width, commonSum}) {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <ResponsiveContainer width={width ? width : 216} height={216}>
      <PieChart>
        <Pie
          stroke="none"
          activeIndex={activeIndex}
          activeShape={(props) => renderActiveShape({ commonSum ,...props, })}
          isAnimationActive={false}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={inner}
          outerRadius={outer}
          dataKey="value"
        >
          {COLORS.map((entry, index) => (
            <Cell style={{ outline: 'none' }} key={`cell-${index}`} fill={entry} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
