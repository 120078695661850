import React, { useState } from 'react';
import './HoverInfoTable.scss';
import { InfoIcon } from '../../../../../../icons/info-icon';

export const HoverInfoTable = ({ right, isPopap, bottom }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="hover-info-table__container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="hover-info-table__icon">
        <InfoIcon width={14} height={14} className={isHovered ? 'hovered' : ''} />
      </div>
      {isHovered && (
        <div className="hover-info-table__title" style={{ right, bottom }}>
          {isPopap ? (
            <div>При выборе 2 заведений появится<br/> таблица отклонения показателей между ними</div>
          ) : (
            <div>
              {' '}
              Сумма чеков по всем закрытым заказам
              <br /> до вычета скидок, подарков и списанных бонусов
              <br /> за выбранный период
            </div>
          )}
        </div>
      )}
    </div>
  );
};
