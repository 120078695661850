import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Portal } from './Portal/Portal';
import './ModalWindow.scss';

export const Modal = ({ children, isOpen, onClose, lazy, width, height, padding }) => {
  const [isClosing, setIsCloasing] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const timerRef = useRef();

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    }
  }, [isOpen]);

  const closeHandler = useCallback(() => {
    if (onClose) {
      setIsCloasing(true);
      timerRef.current = setTimeout(() => {
        onClose();
        setIsCloasing(false);
      }, 300);
    }
  }, [onClose]);

  const stopPropogetion = (e) => {
    e.stopPropagation();
  };

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', onKeyDown);
    }
    return () => {
      clearTimeout(timerRef.current);
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [isOpen, onKeyDown]);

  const modalClasses = cn('modal', {
    opened: isOpen,
    isClosing: isClosing,
  });

  if (lazy && !isMounted) {
    return null;
  }

  return (
    <Portal>
      <div className={modalClasses}>
        <div className="overlay" onClick={closeHandler}>
          <div
            className="content"
            style={{ width: width, height: height, padding: padding }}
            onClick={stopPropogetion}
          >
            {children}
          </div>
        </div>
      </div>
    </Portal>
  );
};
