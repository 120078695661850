import * as React from 'react';

export const SmallEditIcon = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4209 1.10727C10.6398 0.326221 9.37349 0.326221 8.59249 1.10727L8.12109 1.57867L11.4209 4.87851L11.8923 4.4071C12.6734 3.62605 12.6734 2.35973 11.8923 1.57867L11.4209 1.10727ZM10.4781 5.82133L7.17823 2.52149L1.11759 8.58213C0.985066 8.71467 0.892453 8.8818 0.850313 9.0644L0.164939 12.0343C0.0540327 12.5149 0.484766 12.9455 0.965239 12.8346L3.93518 12.1493C4.1178 12.1071 4.2849 12.0145 4.41743 11.882L10.4781 5.82133Z"
        fill="#67A8F4"
      />
    </svg>
  );
};
