import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import './TimePicker.scss';
import { CustomSelectInput } from './customOption/customSelectInput';
import { CustomOption } from './customOption/customOption';

const colors = {
  blue: '#1187F4',
  gray: '#D9DFE3',
  white: '#FFF',
  lightGray: '#EFF2F3',
  black: '#29292F',
  red: '#F54E19',
};

const styles = {
  control: (baseStyles, state) => {
    const {
      selectProps: { isError },
    } = state;

    return {
      ...baseStyles,
      height: '52px',
      borderRadius: '10px',
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '20px',
      background: '#F9F9F9',
      boxShadow: 'none',
      border: 'none',
      '&:hover': {
        borderColor: `rgba(${isError ? colors.red : '17, 135, 244'}, ${
          state.isFocused ? '1' : '.5'
        })`,
      },
    };
  },
  input: (baseStyles) => ({
    ...baseStyles,
    input: {
      //   width: '40px !important',
      opacity: '1 !important',
    },
  }),
  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    color: '#67A8F4',
    transition: 'all 0.2s ease-out',
    transform: `rotate(${state.selectProps.menuIsOpen ? 180 : 0}deg)`,
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    zIndex: '100',
    minWidth: '155px',
    borderRadius: '6px',
    boxShadow: '2px 6px 20px rgba(85, 85, 85, 0.09)',
    background: '#FFFFFF',
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    display: 'flex',
    height: '277px',
    overflow: 'hidden',
    padding: '0',
  }),
  group: (baseStyles) => ({
    ...baseStyles,
    width: '50%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      background: 'transparent',
    },
    '&:first-of-type': {
      borderRight: '1px solid ' + colors.gray,
    },
  }),
  groupHeading: () => ({
    display: 'none',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '19px',
    background: 'transparent',
    color: state.isSelected ? colors.blue : colors.black,
    '&:hover': {
      background: 'rgba(17, 135, 244, 0.1)',
    },
  }),
  singleValue: () => ({
    display: 'none',
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontSize: '15px',
  }),
};

const hours = Array.from({ length: 24 }, (v, i) => i++).map((hour) => ({
  label: hour.toString().length === 1 ? `0${hour}` : hour,
  value: hour,
  group: 'hour',
}));

const minutes = Array.from({ length: 60 }, (v, i) => i++).map((min) => ({
  label: min.toString().length === 1 ? `0${min}` : min,
  value: min,
  group: 'min',
}));

const timeReg = new RegExp('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$');

export const TimePicker = ({
  disabled,
  onChange,
  value,
  error,
  label,
  setTime,
  time,
  onBlur,
  ...rest
}) => {
  // const [time, setTime] = useState('');
  const [err, setErr] = useState({ isActive: false, textMessage: '' });

  useEffect(() => {
    error && setErr(error);
  }, [error]);

  const handleInputChange = (value, actionType) => {
    const { action } = actionType;

    if (action === 'input-change') {
      setTime(value);
    }
  };

  const handleChange = (value) => {
    const isHour = value.group === 'hour';

    if (!time) {
      setTime(isHour ? value.label + ':00' : '00:' + value.label);
    } else {
      const splitTime = time.split(':');
      splitTime[isHour ? 0 : 1] = value.label;
      setTime(splitTime.join(':'));
    }
  };

  const handleCloseMenu = () => {
    if (timeReg.test(time)) {
      setErr({ ...error, isActive: false });
      onChange(time);
    } else {
      setErr({ ...error, isActive: true });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      handleCloseMenu();
    }
  };

  return (
    <>
      <div>
        <div className="time-picker-label">{label}</div>
        <Select
          menuPosition="fixed"
          menuPlacement="auto"
          isError={err.isActive}
          disabled={disabled}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          captureMenuScroll={false}
          onMenuClose={handleCloseMenu}
          onInputChange={(value, a) => handleInputChange(value, a)}
          inputValue={time}
          onKeyDown={handleKeyDown}
          onChange={(value) => handleChange(value)}
          onBlur={onBlur}
          components={{
            Input: CustomSelectInput,
            IndicatorSeparator: null,
            Option: CustomOption,
          }}
          options={[
            { label: 'hour', options: hours },
            { label: 'minute', options: minutes },
          ]}
          placeholder="hh:mm"
          styles={styles}
          filterOption={() => {
            return true;
          }}
          {...rest}
        />
      </div>
    </>
  );
};

TimePicker.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.shape({
    isActive: PropTypes.bool,
    textMessage: PropTypes.string,
  }),
};
