export const TEXTSFORCOMMENTS = [
    {
      text: (
        <>
          Место, где вы можете получать эстетическое
          <br /> удовольствие от всего: еды, музыки,
          <br /> интерьера. Официанты вежливые, всегда
          <br /> готовы помочь в выборе
        </>
      ),
    },
    {
      text: (
        <>
          Официанты приятные) <br /> Блюда очень вкусные. <br /> НО МЫ ЖДАЛИ ЗАКАЗ 2 ЧАСА!!!
        </>
      ),
    },
    {
      text: (
        <>
          Заведение без недостатков. <br />
          Отличное обслуживание прямо со входа
        </>
      ),
    },
    {
      text: (
        <>
          Неплохое для города заведение, но заявленный уровень <br />
          кухни, обслуживания и атмосферы, к сожалению, <br />
          не дотягивает до поставленной самими же владельцами <br />
          планки. Но очень понравился бар. Отличные авторские <br />
          коктейли. Сели за бар и прекрасно провели вечер с <br />
          барменом. Человек знает свое дело. Профессионально <br />
          собирает коктейли и хороший собеседник
        </>
      ),
    },
    {
      text: <>Как по мне лучшая кофейня в городе</>,
    },
  ];
  