import React from 'react';
import './ProgressBar.scss';

const ProgressBar = ({ completed, totalSum, currentSum }) => {
  const fillerWidth = `${completed}%`;
  const labelPosition = {
    left: `calc(${fillerWidth} - 70px)`,
  };

  return (
    <div className="progress-bar__container">
      <div className="progress-bar__filler" style={{ width: `${completed}%` }}>
        <span className="progress-bar__label">{`${completed}%`}</span>
      </div>
      <div className="progress-bar__info">
        <div className={completed>85 ? "progress-bar__info-title-max" : "progress-bar__info-title"} style={{ left: labelPosition.left }}>
          {currentSum}  <span>{completed<85 ? "₽" : "₽" + " /" }</span>
        </div>
        <div className="progress-bar__info-sum">
          {totalSum} <span> ₽</span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
