import { useEffect, useState } from 'react';
import { foodcostApi } from '../../../../../api/foodcost';
import { writeOffApi } from '../../../../../api/writeOff';
import { Chart } from './Chart/Chart';
import { Header } from './Header/Header';
import './WriteOffDynamic.scss';

export const WriteOffDynamic = ({ account_id, token, startDate, endDate, selectedDate }) => {
  const initialReasons = [
    { id: 1, title: 'Прочие расходы' },
    { id: 2, title: 'Артистам' },
  ];

  const initialStores = [
    { id: 1, title: 'Склад Кухня' },
    { id: 2, title: 'Склад Бар' },
  ];

  const [selectedReason, setSelectedReason] = useState(initialReasons);
  const [selectedStore, setSelectedStore] = useState(initialStores);
  const [numAdditionStore, setNumAdditionStore] = useState(false);
  const [numAdditionReason, setNumAdditionReason] = useState(false);

  const handleSelectedOptionsDynamicForReasons = (newSelectedOptions) => {
    setSelectedReason([...newSelectedOptions]);
  };
  const handleSelectedOptionsDynamicForStore = (newSelectedOptions) => {
    setSelectedStore([...newSelectedOptions]);
  };
  useEffect(() => {
    if (selectedReason.length === 2) {
      setNumAdditionReason(1);
    } else if (selectedReason.length === 1) {
      setNumAdditionReason(1.7);
    } else setNumAdditionReason(0);

    if (selectedStore.length === 2) {
      setNumAdditionStore(1);
    } else if (selectedStore.length === 1) {
      setNumAdditionStore(1.6);
    } else setNumAdditionStore(0);
  }, [selectedReason, selectedStore]);

  return (
    <>
      <div>
        <Header
          reasons={initialReasons}
          store={initialStores}
          setSelectedReason={setSelectedReason}
          handleSelectedOptionsDynamicForReasons={handleSelectedOptionsDynamicForReasons}
          handleSelectedOptionsDynamicForStore={handleSelectedOptionsDynamicForStore}
        />
        <Chart numAdditionReason={numAdditionReason} numAdditionStore={numAdditionStore} />
      </div>
    </>
  );
};
