import 'keen-slider/keen-slider.min.css';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@mui/material/Slider';

import { sliderStyles } from '../const';
import { marks } from '../const';

const CustomSlider = withStyles({
  rail: {
    backgroundImage: '#FFFFFF',
    background: '#FFFFFF',
    opacity: 1,
  },
  track: {
    backgroundImage: 'linear-gradient(90deg, #67A8F4 36.52%, #43C3AC 100%)',
  },
})(Slider);

const RangeSlider = ({ handleChange, value }) => {
  return (
    <CustomSlider
      marks={marks}
      onChange={handleChange}
      value={value}
      valueLabelDisplay="on"
      valueLabelFormat={(value) => {
        if (value === 1 || value === 10) {
          return '';
        }
        return value;
      }}
      step={1}
      min={1}
      max={10}
      sx={sliderStyles}
    />
  );
};

export default RangeSlider;
