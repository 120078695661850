import { Button } from '../../../../components/common/Button/Button';
import { CheckBox } from '../../../../components/common/CheckBox/CheckBox';
import { Modal } from '../../../../components/common/ModalWindow/ModalWindow';
import { CloseIcon } from '../../../../icons/close-icon';
import { REPORTS } from '../const';
import './ModalAccessReports.scss';

export const ModalAccessReports = ({ isOpen, onClose, name, role, avatar }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} width={420}>
      <div className="modal-access-reports__container">
        <div className="modal-access-reports__header">
          <div className="modal-access-reports__header-title">Раздел “Отчёты”</div>
          <div onClick={onClose} className='modal-access-reports__header-icon'>
            <CloseIcon />
          </div>
        </div>
        <div className="modal-access-reports__user">
          <div>
            <img
              src={avatar}
              alt="123"
              width={60}
              height={60}
              className="modal-access-reports__user-avatar"
            />
          </div>
          <div className="modal-access-reports__user-info">
            <div className="modal-access-reports__user-info-name">{name}</div>
            <div className="modal-access-reports__user-info-role">{role}</div>
          </div>
        </div>
        <div className="modal-access-reports__reports">
          <div className="modal-access-reports__reports-title">Настройка раздела “Отчёты”</div>
          <div className="modal-access-reports__reports-list">
            {REPORTS.map((item) => (
              <div className="modal-access-reports__reports-list-container">
                <CheckBox />
                <div className="modal-access-reports__reports-list-item">{item.title}</div>
              </div>
            ))}
          </div>
        </div>
        <Button styleType="primary">Сохранить</Button>
      </div>
    </Modal>
  );
};
