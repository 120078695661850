export const COMPARISON_DATA = [
    {
      num: 1280694.60 ,
      percent: 26.49,
    },
    {
      num: 1233350.00,
      percent: 25.58,
    },
    {
      num: 1198490.00,
      percent: 24.91,
    },
    {
      num: "-0.86%",
      percent: 2.85,
    },
    {
      num: "0.29%" ,
      percent: -8.45,
    },
    {
      num: 63,
      percent: 14.69,
    },
    {
      num: 1159.80,
      percent: 10.29,
    },
    {
      num: -0.74 ,
      percent: -7.32,
    },
    {
      num: 98,
      percent: 5.86,
    },
    {
      num: 563.00,
      percent: 19.48,
    },
    {
      num: -0.3,
      percent:  -7.69,
    },
    {
      num: "0:19:28",
      percent: 11.24,
    },
  ];