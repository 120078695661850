import React from "react";

export const ReviewsIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color}
    >
      <path
        d="M10.9198 2.8677C11.402 2.03987 12.598 2.03987 13.0801 2.8677L15.8751 7.66643L21.3027 8.84175C22.239 9.0445 22.6086 10.1819 21.9703 10.8963L18.2701 15.0374L18.8295 20.5625C18.926 21.5156 17.9585 22.2186 17.0818 21.8323L12 19.5929L6.91816 21.8323C6.04149 22.2186 5.07395 21.5156 5.17046 20.5625L5.72987 15.0374L2.02972 10.8963C1.3914 10.1819 1.76097 9.0445 2.69728 8.84175L8.12484 7.66643L10.9198 2.8677Z"
        fill={color}
      />
    </svg>
  );
};
