import { CompaniesSelect } from '../companiesSelect/companiesSelect';
import { PeriodSelect } from '../periodSelect/PeriodSelect';
import { ParamentrsTable } from './paramentrsTable/ParamentrsTable';
import { RestaurantsTable } from './restaurantsTable/RestaurantsTable';
import './RestaurantComparison.scss';
import { CommentsSelect } from '../../../reviews/components/ReviewsWindow/components/ReviewsWindowContent/ReviewsContentHeader/CommentsSelect/CommentsSelect';
import { DeviationTable } from './deviationTable/DeviationTable';
import { useState } from 'react';
import { GROUPS_DATA, RESTAURANTS_DATA } from '../const';
import { Switcher } from '../switcher/Switcher';
import { TwoCompanyTable } from './restaurantsTable/TwoCompanyTable/TwoCompanyTable';
const options = [
  { value: '0', label: 'Органические' },
  { value: '1', label: 'Сопоставимые' },
];

export const RestaurantComparison = () => {
  const [selectedOptions, setSelectedOptions] = useState(GROUPS_DATA);
  return (
    <div className="restaurant-comparison__container">
      <div className="restaurant-comparison__selects">
        <div className="restaurant-comparison__selects-left">
          <CompaniesSelect
            options={selectedOptions}
            setOptions={setSelectedOptions}
            isSecondTable={true}
          />
          <CommentsSelect options={options} isWhite />
          <PeriodSelect />
        </div>
        <div>
          <Switcher />
        </div>
      </div>
      <div className="restaurant-comparison__table">
        <div>
          <ParamentrsTable />
        </div>
        <div>
          {(selectedOptions[0] === 'Московская область' ||
            selectedOptions[0] === 'Ленинградская область') && (
            <RestaurantsTable selectedOptions={selectedOptions} />
          )}
          {selectedOptions[0].isChecked === false && <TwoCompanyTable selectedOptions={setSelectedOptions} />}
        </div>
        {selectedOptions[0].isChecked === false && <DeviationTable />}
      </div>
    </div>
  );
};
