import React from 'react';

export const ArrowIcon = ({ fill }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icons">
        <path
          id="small arrow right"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.9873 12.946C15.2933 13.252 15.2933 13.7482 14.9873 14.0542L10.5545 18.487C10.2485 18.793 9.75233 18.793 9.44631 18.487C9.14029 18.181 9.14029 17.6848 9.44631 17.3788L13.325 13.5001L9.44631 9.62146C9.14029 9.31538 9.14029 8.81927 9.44631 8.51319C9.75233 8.20719 10.2485 8.20719 10.5545 8.51319L14.9873 12.946Z"
          fill="#67A8F4"
        />
      </g>
    </svg>
  );
};
