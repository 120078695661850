import { useState } from 'react';
import { authApi } from '../../../../api/authApi';
import { Button } from '../../../../components/common/Button/Button';
import { TofuField } from '../../../../components/common/fields/TofuField/TofuField';
import { Modal } from '../../../../components/common/ModalWindow/ModalWindow';
import { UploadPhotoCopmonent } from '../../../../components/common/UploadPhotoCopmonent/UploadPhotoCopmonent';
import './ModalWindow.scss';

export const ModalWindow = ({
  isOpen,
  onClose,
  avatar,
  address,
  name,
  setAddress,
  setName,
  setPhoto,
  account_id,
}) => {
  const onSaveInfo = () => {
    const token = localStorage.getItem('authToken');
    const formData = new FormData();
    formData.append('account_id', account_id);
    formData.append('restaurant_name_for_settings', name);
    formData.append('restaurant_address_for_settings', address);

    if (avatar instanceof File) {
      formData.append('avatar', avatar);
    }

    authApi
      .sendCompany(formData, token, account_id)
      .then(() => {
        onClose();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };



  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <UploadPhotoCopmonent avatar={avatar} setPhoto={setPhoto} />
      <div className="comapny-info__modal-fields">
        <TofuField
          name="name"
          value={name}
          placeholder="Название заведения"
          onChange={(e) => setName(e.target.value)}
          label="Название заведения"
        />
        <TofuField
          name="address"
          value={address}
          placeholder="Адрес заведения"
          onChange={(e) => setAddress(e.target.value)}
          label="Адрес заведения"
        />
      </div>
      <div className="comapny-info__modal">
        <Button styleType="primary" onClick={onSaveInfo}>
          Сохранить
        </Button>
      </div>
    </Modal>
  );
};
