import './NpsCategory.scss';

export const NpsCategory = ({ npsCategory }) => {
  const backgroundColorClass =
    npsCategory.rate >= 1 && npsCategory.rate <= 2
      ? 'negative-background'
      : npsCategory.rate >= 3 && npsCategory.rate <= 4
      ? 'neatural-background'
      : npsCategory.rate === 5
      ? 'positive-background'
      : '';
  return (
    <div className="nps-category__container">
      <div className="nps-category__text">{npsCategory.nps_type}</div>
      <div className={`nps-category__number ${backgroundColorClass}`}>{npsCategory.rate}</div>
    </div>
  );
};
