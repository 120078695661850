import React, { useState } from 'react';
import './ProgressBar.scss';
import { HoverPlan } from '../../../../../../components/common/HoverPlan/HoverPlan';
import { Hover } from '../../../../../../components/common/Hover/Hover';

const ProgressBar = ({ completed }) => {
  const fillerWidth = `${completed}%`;
  const labelPosition = {
    left: `calc(${fillerWidth} - 70px)`,
  };

  return (
    <div className="revenue-banner-progress-bar__container">
      <div className="revenue-banner-progress-bar__filler" style={{ width: `${completed}%` }}>
        <span className="revenue-banner-progress-bar__label">{`${completed}%`}</span>
      </div>
      <div className="revenue-banner-progress-bar__info">
        <div className="revenue-banner-progress-bar__info-title">Факт</div>
        <Hover
          containerClassName={'revenue-banner-progress-bar__info-sum'}
          hoverContainerClassName={'revenue-banner-progress-bar__info-sum__hover'}
          title={'План'}
        >
          <HoverPlan />
        </Hover>
      </div>
    </div>
  );
};

export default ProgressBar;
