import './DeviationTable.scss';
import { COMPARISON_DATA } from './const';
import { InfoDeviation } from '../infoDeviation/InfoDeviation';
import {formatNumbers} from "../../../../../helpers/formatNumbers"

export const DeviationTable = () => {
  return (
    <div className="deviation-table__container">
      <div className="deviation-table__header">
        <div v className="deviation-table__header-title">
          <p>Отклонение</p>
          <InfoDeviation/>
        </div>
        <div v className="deviation-table__header-text">
          <div>Динамика (абс. знч.)</div>
          <div>Динамика, %</div>
        </div>
      </div>
      <div className="deviation-table__wrapper">
        {COMPARISON_DATA.map((item) => (
          <div className="deviation-table__item">
            <div className="deviation-table__item-num">{formatNumbers(item.num)}</div>
            <div
              className={
                item.percent < 0
                  ? 'like-for-like-table-row-negative'
                  : 'like-for-like-table-row-positive'
              }
            >
              {item.percent}%
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
