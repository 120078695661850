import React, { useState } from 'react';
import './SliderBannerStatistics.scss';
import { BorderCard } from '../../../../../../icons/borderCard';
import { ProgressPercent } from './ProgressPercent/ProgressPercent';

export const SliderBannerStatistics = ({ bannerList, dataHoverPercent }) => {
  return (
    <div className="slider-banner-container">
      <div className="slider-banner-list">
        {bannerList.map((item, index) => {
          return (
            <>
              <div className="slider-banner-list_card">
                <h1 className="slider-banner-list_card_text_title">{item.title}</h1>
                <div className="slider-banner-list_card_percent-box">
                  <p className="slider-banner-list_card_percent_text">{item.percent}%</p>
                  {item.progressPercent && (
                    <ProgressPercent
                      index={index}
                      dataHoverPercent={dataHoverPercent}
                      progressPercent={item.progressPercent}
                      progressPercentColor={item.progressPercentColor}
                    />
                  )}
                </div>
                <p className="slider-banner-list_card_sum_text">
                  Сумма <span className="slider-banner-list_card_sum_number">{item.sum}</span> ₽
                </p>
              </div>
              <BorderCard />
            </>
          );
        })}
      </div>
    </div>
  );
};
