import { useEffect, useRef, useState } from 'react';
import { CheckBox } from '../CheckBox/CheckBox';
import cn from 'classnames';
import './CustomSelect.scss';

export const CustomSelect = ({ options, selectedOptions, onChange, secondBg }) => {
  const [expanded, setExpanded] = useState(false);
  const selectRef = useRef(null);
  const [clearSelected, setClearSelected] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleChangeNps = (option) => {
    onChange(option);
  };

  const handleOptionChange = (option) => {
    const selectedIndex = selectedOptions.findIndex(
      (selectedOption) => selectedOption.value === option.value
    );
    let newSelectedOptions = [];

    if (selectedIndex >= 0) {
      newSelectedOptions = [...selectedOptions];
      newSelectedOptions.splice(selectedIndex, 1);
    } else {
      if (options[0].label !== 'TOFU') {
        newSelectedOptions = [option];
      } else {
        newSelectedOptions = [...selectedOptions, option];
      }
    }

    onChange(newSelectedOptions);
  };

  const handleSelectAll = () => {
    if (selectedOptions.length === options.length) {
      setClearSelected(true);
    } else {
      onChange(options);
    }
  };

  useEffect(() => {
    if (clearSelected) {
      onChange([]);
      setClearSelected(false);
    }
  }, [clearSelected, onChange]);

  const isAllSelected = selectedOptions.length === options.length;

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setExpanded(false);
      }
    };

    if (expanded) {
      document.addEventListener('click', handleOutsideClick);
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [expanded]);

  return (
    <div className="custom-select-options" ref={selectRef}>
      <div
        className={cn('custom-select-header', { secondBg: secondBg })}
        onClick={handleToggleExpand}
      >
        <div className="custom-select-header-label">
          {options[0].label === 'TOFU' ? (
            selectedOptions.length === 0 ? (
              <p className="custom-select-header-label-text">Плошадки</p>
            ) : isAllSelected ? (
              <div className="test">Все</div>
            ) : (
              <div className="custom-select-header-label-text-wrapper">
                {selectedOptions.map((item, index) => (
                  <p className="custom-select-header-label-hover-text">
                    {selectedOptions.length === 1 || index === selectedOptions.length - 1
                      ? item.label
                      : item.label + ', '}
                  </p>
                ))}
              </div>
            )
          ) : null}
        </div>
        <div className={`custom-select-arrow ${expanded ? 'expanded' : ''}`}></div>
      </div>
      {expanded && (
        <div className="custom-select-options-list">
          {options[0].label === 'TOFU' ? (
            <label key="select-all" className="custom-select-option">
              <CheckBox checked={isAllSelected} onChange={() => handleSelectAll()} />
              Все
            </label>
          ) : null}
          {options.map((option) => (
            <label key={option.value} className="custom-select-option">
              <CheckBox
                checked={
                  selectedOptions.findIndex(
                    (selectedOption) => selectedOption.value === option.value
                  ) >= 0
                }
                onChange={() =>
                  options[0].label === 'TOFU' ? handleOptionChange(option) : handleChangeNps(option)
                }
              />
              {option.label}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};
