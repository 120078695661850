import React from 'react';
import './Tooltip.scss';
import { TOOLTIP__VARIABLE_TEXT } from './const';

export const Tooltip = ({ tooltipText }) => {
  return (
    <div className="toolTip_container">
      <p className="toolTipText">{TOOLTIP__VARIABLE_TEXT[tooltipText].title}</p>
      <p className="toolTipBoldText">{TOOLTIP__VARIABLE_TEXT[tooltipText].subtitle}</p>
      {TOOLTIP__VARIABLE_TEXT[tooltipText].description && (
        <p className="toolTipText">{TOOLTIP__VARIABLE_TEXT[tooltipText].description}</p>
      )}
    </div>
  );
};
