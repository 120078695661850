import React from 'react';

export const Check = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.7839 7.22068C19.072 7.51492 19.072 7.99198 18.7839 8.28622L10.4867 16.7573C10.1697 17.0809 9.65566 17.0809 9.33864 16.7573L5.21615 12.5484C4.92795 12.2541 4.92795 11.7771 5.21615 11.4828C5.50435 11.1886 5.97162 11.1886 6.25982 11.4828L9.91266 15.2122L17.7402 7.22068C18.0284 6.92644 18.4957 6.92644 18.7839 7.22068Z"
        fill="#67A8F4"
      />
    </svg>
  );
};
