export const options = [
  // { value: '5', label: 'Все' },
  { value: '4', label: 'TOFU' },
  { value: '1', label: 'Яндекс Карты' },
  { value: '0', label: 'Google Maps' },
  { value: '3', label: 'TripAdvisor' },
];

export const dateOptions = [
  { value: 'week', type: 'Неделя' },
  { value: 'month', type: 'Месяц' },
  { value: 'year', type: 'Год' },
  { value: 'custom', type: 'Календарь' },
];


export const defaultoptions = [
  { value: 'month', type: 'Месяц' },
];

