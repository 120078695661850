import React, { useRef, useState } from 'react';
import cn from 'classnames';
import './SubRow.scss';
import { Tooltip } from './Tooltip/Tooltip';
import { formatNumbers } from '../../../../helpers/formatNumbers';
import { HoverRevenue } from './HoverRevenue/HoverRevenue';

export const SubRow = ({ item, TableSectionInner, style, marginLeft, isShowABC }) => {
  const textRef = useRef();
  const textRevenueRef = useRef();
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipText, setTooltipText] = useState('');

  const [hoveredRevenue, setHoveredRevenue] = useState(false);
  const [hoverTextRevenue, setHoverTextRevenue] = useState('');

  const handleMouseOver = () => {
    setTooltipText(textRef.current.textContent);
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const handleMouseEnterRevenue = () => {
    setHoverTextRevenue(textRevenueRef.current.textContent);
    setHoveredRevenue(true);
  };

  const handleMouseLeaveRevenue = () => {
    setHoveredRevenue(false);
  };
  return (
    <div className="sub-row__container">
      <div className={'sub-row__wrapper'} style={{ ...style }}>
        <div
          className="sub-row__main-title"
          style={{
            color: '#2B2B2B',
            marginLeft: marginLeft,
          }}
        >
          <div
            className={cn('sub-row__abc', {
              'show-abc': isShowABC,
            })}
            ref={textRef}
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseOut}
          >
            {TableSectionInner.map((elem, index) => {
              return (
                <div
                  key={index}
                  style={{
                    color:
                      item[elem].abc_category === 'A'
                        ? '#84D9BF'
                        : item[elem].abc_category === 'B'
                        ? '#FFC700'
                        : item[elem].abc_category === 'C'
                        ? '#F56C7B'
                        : 'inherit',
                  }}
                >
                  {isShowABC && typeof item[elem] === 'object' ? item[elem].abc_category : ''}
                  {isHovered && <Tooltip tooltipText={tooltipText} />}
                </div>
              );
            })}
          </div>
          {item.name}
        </div>
        <>
          {TableSectionInner.map((elem, index) => (
            <div
              key={index}
              className={cn('sub-row__title', {
                'category-A': item[elem].abc_category === 'A' && isShowABC,
                'category-B': item[elem].abc_category === 'B' && isShowABC,
                'category-C': item[elem].abc_category === 'C' && isShowABC,
              })}
              style={{
                color: '#2B2B2B',
              }}
            >
              {typeof item[elem] === 'object' ? (
                <div key={index} className={cn('sub-row__title-abc', {})}>
                  {elem === 'revenue' ? (
                    <div
                      ref={textRevenueRef}
                      onMouseEnter={handleMouseEnterRevenue}
                      onMouseLeave={handleMouseLeaveRevenue}
                      className="revenue"
                    >
                      {hoveredRevenue && <HoverRevenue text={hoverTextRevenue} />}
                      {formatNumbers(item[elem].value)}
                    </div>
                  ) : (
                    <div>{formatNumbers(item[elem].value)}</div>
                  )}
                  <div
                    style={{
                      color:
                        item[elem].abc_category === 'A'
                          ? '#84D9BF'
                          : item[elem].abc_category === 'B'
                          ? '#FFC700'
                          : item[elem].abc_category === 'C'
                          ? '#F56C7B'
                          : 'inherit', // Default color
                    }}
                  >
                    {isShowABC && typeof item[elem] === 'object' ? item[elem].abc_category : ''}
                  </div>{' '}
                </div>
              ) : elem === 'food_cost' || elem === 'percent' ? (
                item[elem]
              ) : (
                item[elem]
              )}
            </div>
          ))}
        </>
      </div>
    </div>
  );
};
