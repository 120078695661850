import { useState } from 'react';
import Switcher from './Switcher/Switcher';
import './Switchers.scss';

export const Switchers = () => {
  const [switchersData, setSwitchersData] = useState([
    {
      title: 'Фудкост',
      desc: 'Краткое описание того, что включает в себя это уведомление',
      isChecked: true,
    },
    {
      title: 'Опасные операции',
      desc: 'Краткое описание того, что включает в себя это уведомление',
      isChecked: true,
    },
    {
      title: 'Списания',
      desc: 'Краткое описание того, что включает в себя это уведомление',
      isChecked: true,
    },
    {
      title: 'Отчет по столам',
      desc: 'Краткое описание того, что включает в себя это уведомление',
      isChecked: true,
    },
    {
      title: 'Продажи',
      desc: 'Краткое описание того, что включает в себя это уведомление',
      isChecked: true,
    },
    {
      title: 'Отчет по сотрудникам',
      desc: 'Краткое описание того, что включает в себя это уведомление',
      isChecked: true,
    },
    {
      title: 'Итоги дня',
      desc: 'Краткое описание того, что включает в себя это уведомление',
      isChecked: true,
    },
    {
      title: 'Отчет по скидкам',
      desc: 'Краткое описание того, что включает в себя это уведомление',
      isChecked: true,
    },
  ]);

  const handleSwitcherChange = (index, isChecked) => {
    setSwitchersData((prevData) => {
      const newData = [...prevData];
      newData[index].isChecked = isChecked;
      return newData;
    });
  };
  return (
    <div className="switchers-container">
      <div className="switchers-title">Настройка отображаемых отчётов в баре</div>
      <div className="switchers-list">
        {switchersData.map((item, index) => (
          <Switcher
            key={index}
            title={item.title}
            desc={item.desc}
            isChecked={item.isChecked}
            setIsChecked={(isChecked) => handleSwitcherChange(index, isChecked)}
          />
        ))}
      </div>
    </div>
  );
};
