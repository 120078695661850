import React from 'react';

export const GoogleWhiteIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3126 10.7014V14.2487H17.4002C17.0963 15.3529 16.4286 16.3222 15.5054 16.9998C14.5821 17.6774 13.4572 18.0237 12.3126 17.9827C11.4034 17.9796 10.5084 17.7571 9.70358 17.334C8.89878 16.9109 8.20804 16.2997 7.69005 15.5525C7.17207 14.8052 6.84216 13.9439 6.72836 13.0419C6.61457 12.1398 6.72025 11.2236 7.03643 10.3711C7.35261 9.51858 7.86994 8.75504 8.54445 8.14533C9.21895 7.53562 10.0307 7.09778 10.9107 6.86901C11.7907 6.64025 12.7129 6.62733 13.5989 6.83135C14.485 7.03538 15.3087 7.45031 16 8.04088L18.6138 5.42708C17.4924 4.40782 16.1397 3.67689 14.6726 3.29742C13.2055 2.91795 11.6681 2.90135 10.1931 3.24904C8.71818 3.59674 7.35005 4.29829 6.20691 5.29308C5.06377 6.28788 4.18 7.54601 3.63193 8.95882C3.08386 10.3716 2.88797 11.8966 3.06119 13.4021C3.2344 14.9075 3.77151 16.3482 4.62613 17.5996C5.48074 18.851 6.62716 19.8755 7.96635 20.5847C9.30555 21.2939 10.7973 21.6664 12.3126 21.67C20.1541 21.67 21.881 14.342 21.1342 10.7014H12.3126Z"
        fill="white"
      />
    </svg>
  );
};
