import cn from 'classnames';
import { ArrowDownIcon } from '../../../icons/arrow-down';
import { Check } from '../../../icons/reviewsPlatforms/check';
import './MultiSelect.scss';
import { useState, useRef, useEffect } from 'react';
import { BlackArrowForSelect } from '../../../icons/black-arrow-for-select';

export const MultiSelectDropdown = ({ options, onChange, title }) => {
  const [isOptionsOpen, setOptionsOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState(options);

  const dropdownRef = useRef(null);

  const toggleOptions = () => {
    setOptionsOpen(!isOptionsOpen);
  };

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);

    if (selectAll) {
      setSelectedOptions([]);
      onChange([]);
    } else {
      setSelectedOptions(options);
      onChange(options);
    }
  };

  const toggleSingleOption = (option, isSelected) => {
    const updatedSelectedOptions = isSelected
      ? selectedOptions.filter((selectedOption) => selectedOption.id !== option.id)
      : [...selectedOptions, option];

    setSelectedOptions(updatedSelectedOptions);
    onChange(updatedSelectedOptions);
  };
  useEffect(() => {
    const handleEscapeKeyPress = (event) => {
      if (event.key === 'Escape') {
        setOptionsOpen(false);
      }
    };

    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOptionsOpen(false);
      }
    };

    window.addEventListener('keydown', handleEscapeKeyPress);
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('keydown', handleEscapeKeyPress);
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);
  const isAllSelected = selectedOptions.length === options.length;
  return (
    <div className="multi-select-dropdown" ref={dropdownRef}>
      <div className="multi-select-dropdown__selected" onClick={toggleOptions}>
        <div>{title}</div>
        <div className={isOptionsOpen ? 'rotate-arrow' : 'rotate-arrow-down'}>
          <BlackArrowForSelect />
        </div>
      </div>
      <ul
        className={cn('multi-select-dropdown__options', {
          openOptions: isOptionsOpen,
          isScrollOptions: options.length > 5,
        })}
      >
        <p className="multi-select-dropdown__option" onClick={toggleSelectAll}>
          <span
            className={
              !isAllSelected
                ? 'multi-select-dropdown__option-title'
                : 'multi-select-dropdown__option-selected'
            }
          >
            Все
          </span>
          {isAllSelected && <Check />}
        </p>
        {options.map((option) => {
          const isSelected = selectedOptions.some(
            (selectedOption) => selectedOption.id === option.id
          );
          return (
            <li
              className="multi-select-dropdown__option"
              onClick={() => toggleSingleOption(option, isSelected)}
              key={option.id}
            >
              <span
                className={
                  isSelected
                    ? 'multi-select-dropdown__option-selected'
                    : 'multi-select-dropdown__option-title'
                }
              >
                {option.title}
              </span>
              {isSelected && <Check />}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
