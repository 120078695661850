import { Modal } from '../../common/ModalWindow/ModalWindow';
import './WarningModal.scss';

export const WarningModal = ({ isOpen }) => {
  return (
    <Modal isOpen={isOpen} width={300}>
      <div className="modal-sales__container">
        <div style={{ textAlign: 'center' }}>
          📱 Мы делаем сайт удобным для смартфонов, но это займет немного времени. Пока
          наслаждайтесь полной версией на ноутбуке или компьютере. <br />
          Спасибо за ваше терпение и понимание!
        </div>
      </div>
    </Modal>
  );
};
