import { useEffect, useRef, useState } from 'react';
import { Check } from '../../../icons/reviewsPlatforms/check';
import cn from 'classnames';
import './Select.scss';

export const Select = ({
  options,
  onChange,
  secondBg,
  staticWidth,
  isDateSelect,
  isReviews,
  setNpsForOrdering,
  isOneRow,
  isDefault,
  topTenBytExpenseSelect,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const selectRef = useRef(null);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    if (setNpsForOrdering) {
      setNpsForOrdering(option);
    }
    onChange(option);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setExpanded(false);
      }
    };

    if (expanded) {
      document.addEventListener('click', handleOutsideClick);
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [expanded]);

  return (
    <div className="initial-select-options" ref={selectRef}>
      <div
        className={cn('initial-select-header', {
          secondBg: secondBg,
          staticWidth: staticWidth,
          isDefault: isDefault,
        })}
        onClick={handleToggleExpand}
      >
        <div className={cn('initial-select-header-label', { secondLabel: secondBg })}>
          {selectedOption
            ? selectedOption.type.length > 13
              ? selectedOption.type.substring(0, 12) + '...'
              : selectedOption.type
            : options[0].type}
        </div>
        <div className={`initial-select-arrow ${expanded ? 'expanded' : ''}`}></div>
      </div>
      {expanded && (
        <div
          className={cn('initial-select-options-list', {
            isDateSelect: isDateSelect,
            isReviews: isReviews,
            isOneRow: isOneRow,
            scrollable: isOneRow && options.length > 4,
          })}
        >
          {options.map((option) => (
            <div
              className={cn('initial-select-options-list-item', {
                isDateSelectItem: isDateSelect,
                isOneRowItem: isOneRow,
              })}
              key={option.type && option.type}
            >
              <label
                className={`initial-select-optionn ${
                  selectedOption &&
                  selectedOption.type === option.type &&
                  selectedOption.label === option.label
                    ? 'selected'
                    : ''
                }`}
                onClick={() => handleOptionChange(option)}
                style={{
                  color:
                    selectedOption &&
                    selectedOption.type === option.type &&
                    selectedOption.label === option.label
                      ? '#007AFF'
                      : '#1E1E1E',
                }}
                onMouseEnter={(e) => (e.target.style.color = '#67A8F4')}
                onMouseLeave={(e) =>
                  (e.target.style.color =
                    selectedOption &&
                    selectedOption.type === option.type &&
                    selectedOption.label === option.label
                      ? '#67A8F4'
                      : '#1E1E1E')
                }
              >
                <div onClick={handleToggleExpand}>{option.label ? option.label : option.type}</div>
              </label>
              <div className="initial-select-icon">
                {selectedOption &&
                selectedOption.type === option.type &&
                selectedOption.label === option.label ? (
                  <Check />
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
