import api from './baseApi';

export const writeOffApi = {
  getCancellationsTable: (account_id, token) => {
    return api.get(`/accounts/${account_id}/writeoff/cancellations/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },

  //   &date_from=${startDate}&date_to=${endDate}
  getDynamicsWriteOff: (account_id, token, reason, store, startDate, endDate, selectedDate) => {
    return api.get(
      `/accounts/${account_id}/writeoff/reasons/dynamic/?reason_id=${reason}&store_id=${store}&time_period=${selectedDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
  },

  getReasonsForDynamics: (account_id, token) => {
    return api.get(`/accounts/${account_id}/writeoff/reasons/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },
};
