import { useState } from 'react';
import { ArrowRightIcon } from '../../../../../icons/reports/day-result/arrow-right-icon';
import './ItemCheque.scss';
import { ModalAbout } from '../ModalAbout/ModalAbout';

export const ItemInfo = ({ item, index, onCloseFirstModal }) => {
  const [isSalesModal, setIsSalesModal] = useState(false);

  const onCloseModal = () => {
    setIsSalesModal(false);
  };

  const onOpenModal = () => {
    setIsSalesModal(true);
  };

  return (
    <div className="item-info__wrapper">
      <div className="item-info-num">{index + 1}</div>
      <div className="item-info-table">{item.coast}</div>
      <div className="item-info-time">{item.time}</div>
      <div className="item-info-sum">{item.sum}</div>
      <div className="item-info-arrow" onClick={onOpenModal}>
        <ArrowRightIcon />
      </div>
      {isSalesModal && (
        <ModalAbout
          isOpen={isSalesModal}
          onClose={onCloseModal}
          onCloseFirstModal={onCloseFirstModal}
          item={item}
          cheque={index + 1}
        />
      )}
    </div>
  );
};
