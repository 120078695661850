import React, { useState } from 'react';
import { CheckBox } from '../../CheckBox/CheckBox';
import HoverInfo from '../../HoverInfo/HoverInfo';
import {ABCdescription} from "./ABCdescription/ABCdescription"
import './ABCanalysis.scss';

export const ABCanalysis = ({ setIsShowABC, isShowABC }) => {
  const handleCheckBoxChange = () => {
    setIsShowABC(!isShowABC);
  };

  return (
    <div className="abc-analysis__container">
      <CheckBox checked={isShowABC} onChange={handleCheckBoxChange} />
      <div className="abc-analysis__title">Показать ABC-анализ</div>
      <div className="abc-analysis__info">
        <HoverInfo height={16} width={16}>
          <ABCdescription />
        </HoverInfo>
      </div>
    </div>
  );
};
