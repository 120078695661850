import { Google } from './Google/Google';
import { Trip } from './Trip/Trip';
import { Yandex } from './Yandex/Yandex';
import './ReviewsPlatform.scss';
import { Tofu } from './Tofu/Tofu';

export const ReviewsPlatform = ({ yandexPoint, googlePoint, tripPoint, companyData }) => {
  return (
    <div className="reviews-platforms__container">
      <Tofu googlePoint={googlePoint} companyData={companyData} />
      <Google googlePoint={googlePoint} />
      <Yandex yandexPoint={yandexPoint} />
      <Trip tripPoint={tripPoint} />
    </div>
  );
};
