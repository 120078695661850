import cn from 'classnames';
import { useEffect } from 'react';
import './Tabs.scss';

export const Tabs = ({ tabs, children, setCurrentTab, currentTab, width, isDark, marginLeft }) => {
  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
  };

  const containerStyle = {
    width: width,
    marginLeft: marginLeft + '%',
  };

  return (
    <div>
      <div className={cn('tabs-container', { isDarkContainer: isDark })} style={containerStyle}>
        {tabs.map((tab, index) => (
          <div className="tabs-wrapper" key={tab.id}>
            <button
              id={tab.id}
              disabled={currentTab === `${tab.id}`}
              onClick={handleTabClick}
              className={cn('tabs-button', { isDarkButton: isDark })}
            >
              {tab.tabTitle}
            </button>
            {/* {index !== tabs.length - 1 ? <div className="tabs-stick">|</div> : null} */}
          </div>
        ))}
      </div>
      {children}
    </div>
  );
};
