import { useState } from 'react';
import { settingsApi } from '../../../../api/settingsApi';
import { Button } from '../../../../components/common/Button/Button';
import { TofuField } from '../../../../components/common/fields/TofuField/TofuField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Yandex.scss';
import { YandexIcon } from '../../../../icons/yandex-icon';

export const Yandex = ({ yandexPoint }) => {
  const [restaurant_name_on_map, setRestaurant_name_on_map] = useState(
    yandexPoint?.restaurant_name_on_map
  );
  const [point_address, setPoint_address] = useState(yandexPoint?.point_address);
  const changeData = () => {
    settingsApi
      .changePoint(yandexPoint.id, restaurant_name_on_map, point_address, yandexPoint.account)
      .then((res) => {
        toast.success('Данные успешно сохранены!');
      });
  };
  return (
    <div className="google-container">
      <div>
        <div>
          <YandexIcon width={48} height={48} />
        </div>
        <div className="google-title">Яндекс</div>
      </div>
      <div>
        <div className="google-title">Отзывы Яндекс</div>
        <div className="google-descripthion">
          Заполните поля ниже, чтобы Вы могли отслеживать отзывы с платформы
        </div>
        <div className="google-fields">
          <TofuField
            name="restaurant_name"
            value={restaurant_name_on_map}
            placeholder="Введите название заведения"
            onChange={(e) => setRestaurant_name_on_map(e.target.value)}
            label="название заведения на карте *"
          />
          <TofuField
            name="address"
            value={point_address}
            placeholder="Укажите адрес заведения"
            onChange={(e) => setPoint_address(e.target.value)}
            label="адрес заведения *"
          />
        </div>
        <div className="google-info">
          Не знаете, где взять информацию? Посмотрите инструкцию{' '}
          <a href="/123" target="_blank">
            здесь
          </a>
        </div>
        <div>
          <Button styleType="primary" width={300} height={50} onClick={changeData}>
            Подключить
          </Button>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};
