import React from "react";

export const GoogleIcon = ({width, height}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
    >
      <g clip-path="url(#clip0_107_83)">
        <path
          d="M24.6029 16C24.6041 17.8238 23.9617 19.5895 22.7887 20.986C21.6158 22.3826 19.9876 23.3204 18.191 23.6342C16.3944 23.9481 14.5447 23.6178 12.9678 22.7016C11.3908 21.7854 10.1878 20.3421 9.57062 18.6259L5.13782 22.1721C6.51617 24.7923 8.73269 26.875 11.4335 28.0878C14.1342 29.3007 17.1635 29.5736 20.0375 28.863C22.9116 28.1525 25.4647 26.4994 27.2892 24.1678C29.1136 21.8363 30.1043 18.9606 30.1029 16"
          fill="#00AC47"
        />
        <path
          d="M24.6029 16C24.6019 17.233 24.3063 18.4479 23.7407 19.5435C23.1751 20.6392 22.3559 21.5838 21.3513 22.2987L25.7337 25.8046C27.1079 24.5674 28.2069 23.0553 28.9596 21.3663C29.7122 19.6773 30.1018 17.8491 30.1029 16"
          fill="#4285F4"
        />
        <path
          d="M9.1029 16C9.10482 15.1041 9.2631 14.2155 9.5706 13.3741L5.1378 9.82788C4.12989 11.7291 3.60291 13.8482 3.60291 16C3.60291 18.1518 4.12989 20.2709 5.1378 22.1721L9.5706 18.6259C9.2631 17.7845 9.10482 16.8958 9.1029 16Z"
          fill="#FFBA00"
        />
        <path
          d="M16.8529 8.25001C18.4928 8.25047 20.0897 8.77451 21.4109 9.74581L25.4709 5.95651C23.9638 4.65707 22.1827 3.71477 20.2604 3.19993C18.3382 2.6851 16.3246 2.61101 14.3697 2.98322C12.4148 3.35542 10.5693 4.16429 8.97076 5.34948C7.37222 6.53466 6.06198 8.06555 5.13782 9.82791L9.57062 13.3741C10.1112 11.8755 11.1002 10.5797 12.4032 9.66289C13.7061 8.7461 15.2598 8.25278 16.8529 8.25001Z"
          fill="#EA4435"
        />
        <path
          d="M30.1029 15V16L27.8529 19.5H17.3529V14H29.1029C29.3681 14 29.6225 14.1054 29.81 14.2929C29.9975 14.4804 30.1029 14.7348 30.1029 15Z"
          fill="#4285F4"
        />
      </g>
      <defs>
        <clipPath id="clip0_107_83">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.852905)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

GoogleIcon.defaultProps = {
  width: 32,
  height: 32
};