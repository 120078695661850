import api from './baseApi';

export const settingsApi = {
  getCompany: (account_id) => {
    return api.get(`/account/${account_id}/`);
  },

  changeCompany: (
    account_id,
    bank,
    bic,
    company_email,
    count_of_workers,
    instagram_username,
    // integrated_pos_system,
    legal_entity_name,
    place_opening,
    place_closing,

    // plan_type,
    restaurant_type,
    timezone,
    unp,
    user,
    checking_account,
    legal_address
  ) => {
    return api.put(`/account/${account_id}/`, {
      account_id,
      bank,
      bic,
      company_email,
      count_of_workers,
      instagram_username,
      //   integrated_pos_system: integrated_pos_system,
      legal_entity_name,
      place_opening,
      place_closing,
      //   plan_type: plan_type,
      restaurant_type,
      timezone,
      unp,
      user,
      checking_account,
      legal_address,
    });
  },

  changePoint: (account_id, restaurant_name_on_map, point_address, account) => {
    return api.put(`reviews/point/${account_id}/`, {
      restaurant_name_on_map,
      point_address,
      account,
    });
  },

  getAllPoints: (account_id) => {
    return api.get(`/reviews/all_points/${account_id}/`);
  },

  changePos: (
    account_id,
    integrated_pos_system
  ) => {
    return api.patch(`/account/${account_id}/`, {
      account_id,
      integrated_pos_system,
    });
  },
};

