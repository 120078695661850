import React from 'react';
import { ArrowDownIcon } from '../../../../icons/arrow-down';
import './ExpandedButton.scss';

export const ExpendableButton = ({ isOpen, toggle, children }) => {
  return (
    <button onClick={toggle} className="tabs-btn">
      {children}
      <div
        style={{
          transform: `rotate(${isOpen ? 180 : 0}deg)`,
          transition: 'all 0.25s',
        }}
      >
        <ArrowDownIcon />
      </div>
    </button>
  );
};
