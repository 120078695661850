import React, {useState} from 'react';
import './InfoDeviation.scss';
import { InfoIcon } from '../../../../../icons/info-icon';

export const InfoDeviation = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered((prev) => !prev);
  };

  return (
    <div
      className="hover-info-deviation__container"
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      <div className="hover-info-deviation__icon">
        <InfoIcon width={14} height={14} className={isHovered && 'hovered'} />
      </div>
      {isHovered && (
        <div className="hover-info-deviation__hover__wrapper">
          <p className="hover-info-deviation__hover__text">Отклонение относительно первого заведения </p>
        </div>
      )}
    </div>
  );
};
