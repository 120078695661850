import React from "react";

export const ReportsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 2V8.5C12 9.32843 12.6716 10 13.5 10H20V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4C4 2.89543 4.89543 2 6 2H12ZM15 15H9C8.44772 15 8 15.4477 8 16C8 16.5523 8.44772 17 9 17H15C15.5523 17 16 16.5523 16 16C16 15.4477 15.5523 15 15 15ZM10 11H9C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H10C10.5523 13 11 12.5523 11 12C11 11.4477 10.5523 11 10 11ZM14 2.04336C14.3759 2.12295 14.7241 2.30991 15 2.58579L19.4142 7C19.6901 7.27588 19.8771 7.62406 19.9566 8H14V2.04336Z"
        fill="#C4C4C2"
      />
    </svg>
  );
};
