export const TIME_ZONES = 
[
    {
      "label": "Africa/Abidjan",
      "value": "+00:00"
    },
    {
      "label": "Africa/Accra",
      "value": "+00:00"
    },
    {
      "label": "Africa/Addis_Ababa",
      "value": "+03:00"
    },
    {
      "label": "Africa/Algiers",
      "value": "+01:00"
    },
    {
      "label": "Africa/Asmara",
      "value": "+03:00"
    },
    {
      "label": "Africa/Asmera",
      "value": "+03:00"
    },
    {
      "label": "Africa/Bamako",
      "value": "+00:00"
    },
    {
      "label": "Africa/Bangui",
      "value": "+01:00"
    },
    {
      "label": "Africa/Banjul",
      "value": "+00:00"
    },
    {
      "label": "Africa/Bissau",
      "value": "+00:00"
    },
    {
      "label": "Africa/Blantyre",
      "value": "+02:00"
    },
    {
      "label": "Africa/Brazzaville",
      "value": "+01:00"
    },
    {
      "label": "Africa/Bujumbura",
      "value": "+02:00"
    },
    {
      "label": "Africa/Cairo",
      "value": "+02:00"
    },
    {
      "label": "Africa/Casablanca",
      "value": "+01:00"
    },
    {
      "label": "Africa/Ceuta",
      "value": "+01:00"
    },
    {
      "label": "Africa/Conakry",
      "value": "+00:00"
    },
    {
      "label": "Africa/Dakar",
      "value": "+00:00"
    },
    {
      "label": "Africa/Dar_es_Salaam",
      "value": "+03:00"
    },
    {
      "label": "Africa/Djibouti",
      "value": "+03:00"
    },
    {
      "label": "Africa/Douala",
      "value": "+01:00"
    },
    {
      "label": "Africa/El_Aaiun",
      "value": "+01:00"
    },
    {
      "label": "Africa/Freetown",
      "value": "+00:00"
    },
    {
      "label": "Africa/Gaborone",
      "value": "+02:00"
    },
    {
      "label": "Africa/Harare",
      "value": "+02:00"
    },
    {
      "label": "Africa/Johannesburg",
      "value": "+02:00"
    },
    {
      "label": "Africa/Juba",
      "value": "+02:00"
    },
    {
      "label": "Africa/Kampala",
      "value": "+03:00"
    },
    {
      "label": "Africa/Khartoum",
      "value": "+02:00"
    },
    {
      "label": "Africa/Kigali",
      "value": "+02:00"
    },
    {
      "label": "Africa/Kinshasa",
      "value": "+01:00"
    },
    {
      "label": "Africa/Lagos",
      "value": "+01:00"
    },
    {
      "label": "Africa/Libreville",
      "value": "+01:00"
    },
    {
      "label": "Africa/Lome",
      "value": "+00:00"
    },
    {
      "label": "Africa/Luanda",
      "value": "+01:00"
    },
    {
      "label": "Africa/Lubumbashi",
      "value": "+02:00"
    },
    {
      "label": "Africa/Lusaka",
      "value": "+02:00"
    },
    {
      "label": "Africa/Malabo",
      "value": "+01:00"
    },
    {
      "label": "Africa/Maputo",
      "value": "+02:00"
    },
    {
      "label": "Africa/Maseru",
      "value": "+02:00"
    },
    {
      "label": "Africa/Mbabane",
      "value": "+02:00"
    },
    {
      "label": "Africa/Mogadishu",
      "value": "+03:00"
    },
    {
      "label": "Africa/Monrovia",
      "value": "+00:00"
    },
    {
      "label": "Africa/Nairobi",
      "value": "+03:00"
    },
    {
      "label": "Africa/Ndjamena",
      "value": "+01:00"
    },
    {
      "label": "Africa/Niamey",
      "value": "+01:00"
    },
    {
      "label": "Africa/Nouakchott",
      "value": "+00:00"
    },
    {
      "label": "Africa/Ouagadougou",
      "value": "+00:00"
    },
    {
      "label": "Africa/Porto-Novo",
      "value": "+01:00"
    },
    {
      "label": "Africa/Sao_Tome",
      "value": "+00:00"
    },
    {
      "label": "Africa/Timbuktu",
      "value": "+00:00"
    },
    {
      "label": "Africa/Tripoli",
      "value": "+02:00"
    },
    {
      "label": "Africa/Tunis",
      "value": "+01:00"
    },
    {
      "label": "Africa/Windhoek",
      "value": "+02:00"
    },
    {
      "label": "America/Adak",
      "value": "-10:00"
    },
    {
      "label": "America/Anchorage",
      "value": "-09:00"
    },
    {
      "label": "America/Anguilla",
      "value": "-04:00"
    },
    {
      "label": "America/Antigua",
      "value": "-04:00"
    },
    {
      "label": "America/Araguaina",
      "value": "-03:00"
    },
    {
      "label": "America/Argentina/Buenos_Aires",
      "value": "-03:00"
    },
    {
      "label": "America/Argentina/Catamarca",
      "value": "-03:00"
    },
    {
      "label": "America/Argentina/ComodRivadavia",
      "value": "-03:00"
    },
    {
      "label": "America/Argentina/Cordoba",
      "value": "-03:00"
    },
    {
      "label": "America/Argentina/Jujuy",
      "value": "-03:00"
    },
    {
      "label": "America/Argentina/La_Rioja",
      "value": "-03:00"
    },
    {
      "label": "America/Argentina/Mendoza",
      "value": "-03:00"
    },
    {
      "label": "America/Argentina/Rio_Gallegos",
      "value": "-03:00"
    },
    {
      "label": "America/Argentina/Salta",
      "value": "-03:00"
    },
    {
      "label": "America/Argentina/San_Juan",
      "value": "-03:00"
    },
    {
      "label": "America/Argentina/San_Luis",
      "value": "-03:00"
    },
    {
      "label": "America/Argentina/Tucuman",
      "value": "-03:00"
    },
    {
      "label": "America/Argentina/Ushuaia",
      "value": "-03:00"
    },
    {
      "label": "America/Aruba",
      "value": "-04:00"
    },
    {
      "label": "America/Asuncion",
      "value": "-03:00"
    },
    {
      "label": "America/Atikokan",
      "value": "-05:00"
    },
    {
      "label": "America/Atka",
      "value": "-10:00"
    },
    {
      "label": "America/Bahia",
      "value": "-03:00"
    },
    {
      "label": "America/Bahia_Banderas",
      "value": "-06:00"
    },
    {
      "label": "America/Barbados",
      "value": "-04:00"
    },
    {
      "label": "America/Belem",
      "value": "-03:00"
    },
    {
      "label": "America/Belize",
      "value": "-06:00"
    },
    {
      "label": "America/Blanc-Sablon",
      "value": "-04:00"
    },
    {
      "label": "America/Boa_Vista",
      "value": "-04:00"
    },
    {
      "label": "America/Bogota",
      "value": "-05:00"
    },
    {
      "label": "America/Boise",
      "value": "-07:00"
    },
    {
      "label": "America/Buenos_Aires",
      "value": "-03:00"
    },
    {
      "label": "America/Cambridge_Bay",
      "value": "-07:00"
    },
    {
      "label": "America/Campo_Grande",
      "value": "-04:00"
    },
    {
      "label": "America/Cancun",
      "value": "-05:00"
    },
    {
      "label": "America/Caracas",
      "value": "-04:00"
    },
    {
      "label": "America/Catamarca",
      "value": "-03:00"
    },
    {
      "label": "America/Cayenne",
      "value": "-03:00"
    },
    {
      "label": "America/Cayman",
      "value": "-05:00"
    },
    {
      "label": "America/Chicago",
      "value": "-06:00"
    },
    {
      "label": "America/Chihuahua",
      "value": "-07:00"
    },
    {
      "label": "America/Coral_Harbour",
      "value": "-05:00"
    },
    {
      "label": "America/Cordoba",
      "value": "-03:00"
    },
    {
      "label": "America/Costa_Rica",
      "value": "-06:00"
    },
    {
      "label": "America/Creston",
      "value": "-07:00"
    },
    {
      "label": "America/Cuiaba",
      "value": "-04:00"
    },
    {
      "label": "America/Curacao",
      "value": "-04:00"
    },
    {
      "label": "America/Danmarkshavn",
      "value": "+00:00"
    },
    {
      "label": "America/Dawson",
      "value": "-07:00"
    },
    {
      "label": "America/Dawson_Creek",
      "value": "-07:00"
    },
    {
      "label": "America/Denver",
      "value": "-07:00"
    },
    {
      "label": "America/Detroit",
      "value": "-05:00"
    },
    {
      "label": "America/Dominica",
      "value": "-04:00"
    },
    {
      "label": "America/Edmonton",
      "value": "-07:00"
    },
    {
      "label": "America/Eirunepe",
      "value": "-05:00"
    },
    {
      "label": "America/El_Salvador",
      "value": "-06:00"
    },
    {
      "label": "America/Ensenada",
      "value": "-08:00"
    },
    {
      "label": "America/Fort_Nelson",
      "value": "-07:00"
    },
    {
      "label": "America/Fort_Wayne",
      "value": "-05:00"
    },
    {
      "label": "America/Fortaleza",
      "value": "-03:00"
    },
    {
      "label": "America/Glace_Bay",
      "value": "-04:00"
    },
    {
      "label": "America/Godthab",
      "value": "-03:00"
    },
    {
      "label": "America/Goose_Bay",
      "value": "-04:00"
    },
    {
      "label": "America/Grand_Turk",
      "value": "-05:00"
    },
    {
      "label": "America/Grenada",
      "value": "-04:00"
    },
    {
      "label": "America/Guadeloupe",
      "value": "-04:00"
    },
    {
      "label": "America/Guatemala",
      "value": "-06:00"
    },
    {
      "label": "America/Guayaquil",
      "value": "-05:00"
    },
    {
      "label": "America/Guyana",
      "value": "-04:00"
    },
    {
      "label": "America/Halifax",
      "value": "-04:00"
    },
    {
      "label": "America/Havana",
      "value": "-05:00"
    },
    {
      "label": "America/Hermosillo",
      "value": "-07:00"
    },
    {
      "label": "America/Indiana/Indianapolis",
      "value": "-05:00"
    },
    {
      "label": "America/Indiana/Knox",
      "value": "-06:00"
    },
    {
      "label": "America/Indiana/Marengo",
      "value": "-05:00"
    },
    {
      "label": "America/Indiana/Petersburg",
      "value": "-05:00"
    },
    {
      "label": "America/Indiana/Tell_City",
      "value": "-06:00"
    },
    {
      "label": "America/Indiana/Vevay",
      "value": "-05:00"
    },
    {
      "label": "America/Indiana/Vincennes",
      "value": "-05:00"
    },
    {
      "label": "America/Indiana/Winamac",
      "value": "-05:00"
    },
    {
      "label": "America/Indianapolis",
      "value": "-05:00"
    },
    {
      "label": "America/Inuvik",
      "value": "-07:00"
    },
    {
      "label": "America/Iqaluit",
      "value": "-05:00"
    },
    {
      "label": "America/Jamaica",
      "value": "-05:00"
    },
    {
      "label": "America/Jujuy",
      "value": "-03:00"
    },
    {
      "label": "America/Juneau",
      "value": "-09:00"
    },
    {
      "label": "America/Kentucky/Louisville",
      "value": "-05:00"
    },
    {
      "label": "America/Kentucky/Monticello",
      "value": "-05:00"
    },
    {
      "label": "America/Knox_IN",
      "value": "-06:00"
    },
    {
      "label": "America/Kralendijk",
      "value": "-04:00"
    },
    {
      "label": "America/La_Paz",
      "value": "-04:00"
    },
    {
      "label": "America/Lima",
      "value": "-05:00"
    },
    {
      "label": "America/Los_Angeles",
      "value": "-08:00"
    },
    {
      "label": "America/Louisville",
      "value": "-05:00"
    },
    {
      "label": "America/Lower_Princes",
      "value": "-04:00"
    },
    {
      "label": "America/Maceio",
      "value": "-03:00"
    },
    {
      "label": "America/Managua",
      "value": "-06:00"
    },
    {
      "label": "America/Manaus",
      "value": "-04:00"
    },
    {
      "label": "America/Marigot",
      "value": "-04:00"
    },
    {
      "label": "America/Martinique",
      "value": "-04:00"
    },
    {
      "label": "America/Matamoros",
      "value": "-06:00"
    },
    {
      "label": "America/Mazatlan",
      "value": "-07:00"
    },
    {
      "label": "America/Mendoza",
      "value": "-03:00"
    },
    {
      "label": "America/Menominee",
      "value": "-06:00"
    },
    {
      "label": "America/Merida",
      "value": "-06:00"
    },
    {
      "label": "America/Metlakatla",
      "value": "-09:00"
    },
    {
      "label": "America/Mexico_City",
      "value": "-06:00"
    },
    {
      "label": "America/Miquelon",
      "value": "-03:00"
    },
    {
      "label": "America/Moncton",
      "value": "-04:00"
    },
    {
      "label": "America/Monterrey",
      "value": "-06:00"
    },
    {
      "label": "America/Montevideo",
      "value": "-03:00"
    },
    {
      "label": "America/Montreal",
      "value": "-05:00"
    },
    {
      "label": "America/Montserrat",
      "value": "-04:00"
    },
    {
      "label": "America/Nassau",
      "value": "-05:00"
    },
    {
      "label": "America/New_York",
      "value": "-05:00"
    },
    {
      "label": "America/Nipigon",
      "value": "-05:00"
    },
    {
      "label": "America/Nome",
      "value": "-09:00"
    },
    {
      "label": "America/Noronha",
      "value": "-02:00"
    },
    {
      "label": "America/North_Dakota/Beulah",
      "value": "-06:00"
    },
    {
      "label": "America/North_Dakota/Center",
      "value": "-06:00"
    },
    {
      "label": "America/North_Dakota/New_Salem",
      "value": "-06:00"
    },
    {
      "label": "America/Nuuk",
      "value": "-03:00"
    },
    {
      "label": "America/Ojinaga",
      "value": "-07:00"
    },
    {
      "label": "America/Panama",
      "value": "-05:00"
    },
    {
      "label": "America/Pangnirtung",
      "value": "-05:00"
    },
    {
      "label": "America/Paramaribo",
      "value": "-03:00"
    },
    {
      "label": "America/Phoenix",
      "value": "-07:00"
    },
    {
      "label": "America/Port-au-Prince",
      "value": "-05:00"
    },
    {
      "label": "America/Port_of_Spain",
      "value": "-04:00"
    },
    {
      "label": "America/Porto_Acre",
      "value": "-05:00"
    },
    {
      "label": "America/Porto_Velho",
      "value": "-04:00"
    },
    {
      "label": "America/Puerto_Rico",
      "value": "-04:00"
    },
    {
      "label": "America/Punta_Arenas",
      "value": "-03:00"
    },
    {
      "label": "America/Rainy_River",
      "value": "-06:00"
    },
    {
      "label": "America/Rankin_Inlet",
      "value": "-06:00"
    },
    {
      "label": "America/Recife",
      "value": "-03:00"
    },
    {
      "label": "America/Regina",
      "value": "-06:00"
    },
    {
      "label": "America/Resolute",
      "value": "-06:00"
    },
    {
      "label": "America/Rio_Branco",
      "value": "-05:00"
    },
    {
      "label": "America/Rosario",
      "value": "-03:00"
    },
    {
      "label": "America/Santa_Isabel",
      "value": "-08:00"
    },
    {
      "label": "America/Santarem",
      "value": "-03:00"
    },
    {
      "label": "America/Santiago",
      "value": "-03:00"
    },
    {
      "label": "America/Santo_Domingo",
      "value": "-04:00"
    },
    {
      "label": "America/Sao_Paulo",
      "value": "-03:00"
    },
    {
      "label": "America/Scoresbysund",
      "value": "-01:00"
    },
    {
      "label": "America/Shiprock",
      "value": "-07:00"
    },
    {
      "label": "America/Sitka",
      "value": "-09:00"
    },
    {
      "label": "America/St_Barthelemy",
      "value": "-04:00"
    },
    {
      "label": "America/St_Johns",
      "value": "-03:30"
    },
    {
      "label": "America/St_Kitts",
      "value": "-04:00"
    },
    {
      "label": "America/St_Lucia",
      "value": "-04:00"
    },
    {
      "label": "America/St_Thomas",
      "value": "-04:00"
    },
    {
      "label": "America/St_Vincent",
      "value": "-04:00"
    },
    {
      "label": "America/Swift_Current",
      "value": "-06:00"
    },
    {
      "label": "America/Tegucigalpa",
      "value": "-06:00"
    },
    {
      "label": "America/Thule",
      "value": "-04:00"
    },
    {
      "label": "America/Thunder_Bay",
      "value": "-05:00"
    },
    {
      "label": "America/Tijuana",
      "value": "-08:00"
    },
    {
      "label": "America/Toronto",
      "value": "-05:00"
    },
    {
      "label": "America/Tortola",
      "value": "-04:00"
    },
    {
      "label": "America/Vancouver",
      "value": "-08:00"
    },
    {
      "label": "America/Virgin",
      "value": "-04:00"
    },
    {
      "label": "America/Whitehorse",
      "value": "-07:00"
    },
    {
      "label": "America/Winnipeg",
      "value": "-06:00"
    },
    {
      "label": "America/Yakutat",
      "value": "-09:00"
    },
    {
      "label": "America/Yellowknife",
      "value": "-07:00"
    },
    {
      "label": "Antarctica/Casey",
      "value": "+11:00"
    },
    {
      "label": "Antarctica/Davis",
      "value": "+07:00"
    },
    {
      "label": "Antarctica/DumontDUrville",
      "value": "+10:00"
    },
    {
      "label": "Antarctica/Macquarie",
      "value": "+11:00"
    },
    {
      "label": "Antarctica/Mawson",
      "value": "+05:00"
    },
    {
      "label": "Antarctica/McMurdo",
      "value": "+13:00"
    },
    {
      "label": "Antarctica/Palmer",
      "value": "-03:00"
    },
    {
      "label": "Antarctica/Rothera",
      "value": "-03:00"
    },
    {
      "label": "Antarctica/South_Pole",
      "value": "+13:00"
    },
    {
      "label": "Antarctica/Syowa",
      "value": "+03:00"
    },
    {
      "label": "Antarctica/Troll",
      "value": "+00:00"
    },
    {
      "label": "Antarctica/Vostok",
      "value": "+06:00"
    },
    {
      "label": "Arctic/Longyearbyen",
      "value": "+01:00"
    },
    {
      "label": "Asia/Aden",
      "value": "+03:00"
    },
    {
      "label": "Asia/Almaty",
      "value": "+06:00"
    },
    {
      "label": "Asia/Amman",
      "value": "+02:00"
    },
    {
      "label": "Asia/Anadyr",
      "value": "+12:00"
    },
    {
      "label": "Asia/Aqtau",
      "value": "+05:00"
    },
    {
      "label": "Asia/Aqtobe",
      "value": "+05:00"
    },
    {
      "label": "Asia/Ashgabat",
      "value": "+05:00"
    },
    {
      "label": "Asia/Ashkhabad",
      "value": "+05:00"
    },
    {
      "label": "Asia/Atyrau",
      "value": "+05:00"
    },
    {
      "label": "Asia/Baghdad",
      "value": "+03:00"
    },
    {
      "label": "Asia/Bahrain",
      "value": "+03:00"
    },
    {
      "label": "Asia/Baku",
      "value": "+04:00"
    },
    {
      "label": "Asia/Bangkok",
      "value": "+07:00"
    },
    {
      "label": "Asia/Barnaul",
      "value": "+07:00"
    },
    {
      "label": "Asia/Beirut",
      "value": "+02:00"
    },
    {
      "label": "Asia/Bishkek",
      "value": "+06:00"
    },
    {
      "label": "Asia/Brunei",
      "value": "+08:00"
    },
    {
      "label": "Asia/Calcutta",
      "value": "+05:30"
    },
    {
      "label": "Asia/Chita",
      "value": "+09:00"
    },
    {
      "label": "Asia/Choibalsan",
      "value": "+08:00"
    },
    {
      "label": "Asia/Chongqing",
      "value": "+08:00"
    },
    {
      "label": "Asia/Chungking",
      "value": "+08:00"
    },
    {
      "label": "Asia/Colombo",
      "value": "+05:30"
    },
    {
      "label": "Asia/Dacca",
      "value": "+06:00"
    },
    {
      "label": "Asia/Damascus",
      "value": "+02:00"
    },
    {
      "label": "Asia/Dhaka",
      "value": "+06:00"
    },
    {
      "label": "Asia/Dili",
      "value": "+09:00"
    },
    {
      "label": "Asia/Dubai",
      "value": "+04:00"
    },
    {
      "label": "Asia/Dushanbe",
      "value": "+05:00"
    },
    {
      "label": "Asia/Famagusta",
      "value": "+02:00"
    },
    {
      "label": "Asia/Gaza",
      "value": "+02:00"
    },
    {
      "label": "Asia/Harbin",
      "value": "+08:00"
    },
    {
      "label": "Asia/Hebron",
      "value": "+02:00"
    },
    {
      "label": "Asia/Ho_Chi_Minh",
      "value": "+07:00"
    },
    {
      "label": "Asia/Hong_Kong",
      "value": "+08:00"
    },
    {
      "label": "Asia/Hovd",
      "value": "+07:00"
    },
    {
      "label": "Asia/Irkutsk",
      "value": "+08:00"
    },
    {
      "label": "Asia/Istanbul",
      "value": "+03:00"
    },
    {
      "label": "Asia/Jakarta",
      "value": "+07:00"
    },
    {
      "label": "Asia/Jayapura",
      "value": "+09:00"
    },
    {
      "label": "Asia/Jerusalem",
      "value": "+02:00"
    },
    {
      "label": "Asia/Kabul",
      "value": "+04:30"
    },
    {
      "label": "Asia/Kamchatka",
      "value": "+12:00"
    },
    {
      "label": "Asia/Karachi",
      "value": "+05:00"
    },
    {
      "label": "Asia/Kashgar",
      "value": "+06:00"
    },
    {
      "label": "Asia/Kathmandu",
      "value": "+05:45"
    },
    {
      "label": "Asia/Katmandu",
      "value": "+05:45"
    },
    {
      "label": "Asia/Khandyga",
      "value": "+09:00"
    },
    {
      "label": "Asia/Kolkata",
      "value": "+05:30"
    },
    {
      "label": "Asia/Krasnoyarsk",
      "value": "+07:00"
    },
    {
      "label": "Asia/Kuala_Lumpur",
      "value": "+08:00"
    },
    {
      "label": "Asia/Kuching",
      "value": "+08:00"
    },
    {
      "label": "Asia/Kuwait",
      "value": "+03:00"
    },
    {
      "label": "Asia/Macao",
      "value": "+08:00"
    },
    {
      "label": "Asia/Macau",
      "value": "+08:00"
    },
    {
      "label": "Asia/Magadan",
      "value": "+11:00"
    },
    {
      "label": "Asia/Makassar",
      "value": "+08:00"
    },
    {
      "label": "Asia/Manila",
      "value": "+08:00"
    },
    {
      "label": "Asia/Muscat",
      "value": "+04:00"
    },
    {
      "label": "Asia/Nicosia",
      "value": "+02:00"
    },
    {
      "label": "Asia/Novokuznetsk",
      "value": "+07:00"
    },
    {
      "label": "Asia/Novosibirsk",
      "value": "+07:00"
    },
    {
      "label": "Asia/Omsk",
      "value": "+06:00"
    },
    {
      "label": "Asia/Oral",
      "value": "+05:00"
    },
    {
      "label": "Asia/Phnom_Penh",
      "value": "+07:00"
    },
    {
      "label": "Asia/Pontianak",
      "value": "+07:00"
    },
    {
      "label": "Asia/Pyongyang",
      "value": "+09:00"
    },
    {
      "label": "Asia/Qatar",
      "value": "+03:00"
    },
    {
      "label": "Asia/Qostanay",
      "value": "+06:00"
    },
    {
      "label": "Asia/Qyzylorda",
      "value": "+05:00"
    },
    {
      "label": "Asia/Rangoon",
      "value": "+06:30"
    },
    {
      "label": "Asia/Riyadh",
      "value": "+03:00"
    },
    {
      "label": "Asia/Saigon",
      "value": "+07:00"
    },
    {
      "label": "Asia/Sakhalin",
      "value": "+11:00"
    },
    {
      "label": "Asia/Samarkand",
      "value": "+05:00"
    },
    {
      "label": "Asia/Seoul",
      "value": "+09:00"
    },
    {
      "label": "Asia/Shanghai",
      "value": "+08:00"
    },
    {
      "label": "Asia/Singapore",
      "value": "+08:00"
    },
    {
      "label": "Asia/Srednekolymsk",
      "value": "+11:00"
    },
    {
      "label": "Asia/Taipei",
      "value": "+08:00"
    },
    {
      "label": "Asia/Tashkent",
      "value": "+05:00"
    },
    {
      "label": "Asia/Tbilisi",
      "value": "+04:00"
    },
    {
      "label": "Asia/Tehran",
      "value": "+03:30"
    },
    {
      "label": "Asia/Tel_Aviv",
      "value": "+02:00"
    },
    {
      "label": "Asia/Thimbu",
      "value": "+06:00"
    },
    {
      "label": "Asia/Thimphu",
      "value": "+06:00"
    },
    {
      "label": "Asia/Tokyo",
      "value": "+09:00"
    },
    {
      "label": "Asia/Tomsk",
      "value": "+07:00"
    },
    {
      "label": "Asia/Ujung_Pandang",
      "value": "+08:00"
    },
    {
      "label": "Asia/Ulaanbaatar",
      "value": "+08:00"
    },
    {
      "label": "Asia/Ulan_Bator",
      "value": "+08:00"
    },
    {
      "label": "Asia/Urumqi",
      "value": "+06:00"
    },
    {
      "label": "Asia/Ust-Nera",
      "value": "+10:00"
    },
    {
      "label": "Asia/Vientiane",
      "value": "+07:00"
    },
    {
      "label": "Asia/Vladivostok",
      "value": "+10:00"
    },
    {
      "label": "Asia/Yakutsk",
      "value": "+09:00"
    },
    {
      "label": "Asia/Yangon",
      "value": "+06:30"
    },
    {
      "label": "Asia/Yekaterinburg",
      "value": "+05:00"
    },
    {
      "label": "Asia/Yerevan",
      "value": "+04:00"
    },
    {
      "label": "Atlantic/Azores",
      "value": "-01:00"
    },
    {
      "label": "Atlantic/Bermuda",
      "value": "-04:00"
    },
    {
      "label": "Atlantic/Canary",
      "value": "+00:00"
    },
    {
      "label": "Atlantic/Cape_Verde",
      "value": "-01:00"
    },
    {
      "label": "Atlantic/Faeroe",
      "value": "+00:00"
    },
    {
      "label": "Atlantic/Faroe",
      "value": "+00:00"
    },
    {
      "label": "Atlantic/Jan_Mayen",
      "value": "+01:00"
    },
    {
      "label": "Atlantic/Madeira",
      "value": "+00:00"
    },
    {
      "label": "Atlantic/Reykjavik",
      "value": "+00:00"
    },
    {
      "label": "Atlantic/South_Georgia",
      "value": "-02:00"
    },
    {
      "label": "Atlantic/St_Helena",
      "value": "+00:00"
    },
    {
      "label": "Atlantic/Stanley",
      "value": "-03:00"
    },
    {
      "label": "Australia/ACT",
      "value": "+11:00"
    },
    {
      "label": "Australia/Adelaide",
      "value": "+10:30"
    },
    {
      "label": "Australia/Brisbane",
      "value": "+10:00"
    },
    {
      "label": "Australia/Broken_Hill",
      "value": "+10:30"
    },
    {
      "label": "Australia/Canberra",
      "value": "+11:00"
    },
    {
      "label": "Australia/Currie",
      "value": "+11:00"
    },
    {
      "label": "Australia/Darwin",
      "value": "+09:30"
    },
    {
      "label": "Australia/Eucla",
      "value": "+08:45"
    },
    {
      "label": "Australia/Hobart",
      "value": "+11:00"
    },
    {
      "label": "Australia/LHI",
      "value": "+11:00"
    },
    {
      "label": "Australia/Lindeman",
      "value": "+10:00"
    },
    {
      "label": "Australia/Lord_Howe",
      "value": "+11:00"
    },
    {
      "label": "Australia/Melbourne",
      "value": "+11:00"
    },
    {
      "label": "Australia/NSW",
      "value": "+11:00"
    },
    {
      "label": "Australia/North",
      "value": "+09:30"
    },
    {
      "label": "Australia/Perth",
      "value": "+08:00"
    },
    {
      "label": "Australia/Queensland",
      "value": "+10:00"
    },
    {
      "label": "Australia/South",
      "value": "+10:30"
    },
    {
      "label": "Australia/Sydney",
      "value": "+11:00"
    },
    {
      "label": "Australia/Tasmania",
      "value": "+11:00"
    },
    {
      "label": "Australia/Victoria",
      "value": "+11:00"
    },
    {
      "label": "Australia/West",
      "value": "+08:00"
    },
    {
      "label": "Australia/Yancowinna",
      "value": "+10:30"
    },
    {
      "label": "Brazil/Acre",
      "value": "-05:00"
    },
    {
      "label": "Brazil/DeNoronha",
      "value": "-02:00"
    },
    {
      "label": "Brazil/East",
      "value": "-03:00"
    },
    {
      "label": "Brazil/West",
      "value": "-04:00"
    },
    {
      "label": "CET",
      "value": "+01:00"
    },
    {
      "label": "CST6CDT",
      "value": "-06:00"
    },
    {
      "label": "Canada/Atlantic",
      "value": "-04:00"
    },
    {
      "label": "Canada/Central",
      "value": "-06:00"
    },
    {
      "label": "Canada/Eastern",
      "value": "-05:00"
    },
    {
      "label": "Canada/Mountain",
      "value": "-07:00"
    },
    {
      "label": "Canada/Newfoundland",
      "value": "-03:30"
    },
    {
      "label": "Canada/Pacific",
      "value": "-08:00"
    },
    {
      "label": "Canada/Saskatchewan",
      "value": "-06:00"
    },
    {
      "label": "Canada/Yukon",
      "value": "-07:00"
    },
    {
      "label": "Chile/Continental",
      "value": "-03:00"
    },
    {
      "label": "Chile/EasterIsland",
      "value": "-05:00"
    },
    {
      "label": "Cuba",
      "value": "-05:00"
    },
    {
      "label": "EET",
      "value": "+02:00"
    },
    {
      "label": "EST",
      "value": "-05:00"
    },
    {
      "label": "EST5EDT",
      "value": "-05:00"
    },
    {
      "label": "Egypt",
      "value": "+02:00"
    },
    {
      "label": "Eire",
      "value": "+00:00"
    },
    {
      "label": "Etc/GMT",
      "value": "+00:00"
    },
    {
      "label": "Etc/GMT+0",
      "value": "+00:00"
    },
    {
      "label": "Etc/GMT+1",
      "value": "-01:00"
    },
    {
      "label": "Etc/GMT+10",
      "value": "-10:00"
    },
    {
      "label": "Etc/GMT+11",
      "value": "-11:00"
    },
    {
      "label": "Etc/GMT+12",
      "value": "-12:00"
    },
    {
      "label": "Etc/GMT+2",
      "value": "-02:00"
    },
    {
      "label": "Etc/GMT+3",
      "value": "-03:00"
    },
    {
      "label": "Etc/GMT+4",
      "value": "-04:00"
    },
    {
      "label": "Etc/GMT+5",
      "value": "-05:00"
    },
    {
      "label": "Etc/GMT+6",
      "value": "-06:00"
    },
    {
      "label": "Etc/GMT+7",
      "value": "-07:00"
    },
    {
      "label": "Etc/GMT+8",
      "value": "-08:00"
    },
    {
      "label": "Etc/GMT+9",
      "value": "-09:00"
    },
    {
      "label": "Etc/GMT-0",
      "value": "+00:00"
    },
    {
      "label": "Etc/GMT-1",
      "value": "+01:00"
    },
    {
      "label": "Etc/GMT-10",
      "value": "+10:00"
    },
    {
      "label": "Etc/GMT-11",
      "value": "+11:00"
    },
    {
      "label": "Etc/GMT-12",
      "value": "+12:00"
    },
    {
      "label": "Etc/GMT-13",
      "value": "+13:00"
    },
    {
      "label": "Etc/GMT-14",
      "value": "+14:00"
    },
    {
      "label": "Etc/GMT-2",
      "value": "+02:00"
    },
    {
      "label": "Etc/GMT-3",
      "value": "+03:00"
    },
    {
      "label": "Etc/GMT-4",
      "value": "+04:00"
    },
    {
      "label": "Etc/GMT-5",
      "value": "+05:00"
    },
    {
      "label": "Etc/GMT-6",
      "value": "+06:00"
    },
    {
      "label": "Etc/GMT-7",
      "value": "+07:00"
    },
    {
      "label": "Etc/GMT-8",
      "value": "+08:00"
    },
    {
      "label": "Etc/GMT-9",
      "value": "+09:00"
    },
    {
      "label": "Etc/GMT0",
      "value": "+00:00"
    },
    {
      "label": "Etc/Greenwich",
      "value": "+00:00"
    },
    {
      "label": "Etc/UCT",
      "value": "+00:00"
    },
    {
      "label": "Etc/UTC",
      "value": "+00:00"
    },
    {
      "label": "Etc/Universal",
      "value": "+00:00"
    },
    {
      "label": "Etc/Zulu",
      "value": "+00:00"
    },
    {
      "label": "Europe/Amsterdam",
      "value": "+01:00"
    },
    {
      "label": "Europe/Andorra",
      "value": "+01:00"
    },
    {
      "label": "Europe/Astrakhan",
      "value": "+04:00"
    },
    {
      "label": "Europe/Athens",
      "value": "+02:00"
    },
    {
      "label": "Europe/Belfast",
      "value": "+00:00"
    },
    {
      "label": "Europe/Belgrade",
      "value": "+01:00"
    },
    {
      "label": "Europe/Berlin",
      "value": "+01:00"
    },
    {
      "label": "Europe/Bratislava",
      "value": "+01:00"
    },
    {
      "label": "Europe/Brussels",
      "value": "+01:00"
    },
    {
      "label": "Europe/Bucharest",
      "value": "+02:00"
    },
    {
      "label": "Europe/Budapest",
      "value": "+01:00"
    },
    {
      "label": "Europe/Busingen",
      "value": "+01:00"
    },
    {
      "label": "Europe/Chisinau",
      "value": "+02:00"
    },
    {
      "label": "Europe/Copenhagen",
      "value": "+01:00"
    },
    {
      "label": "Europe/Dublin",
      "value": "+00:00"
    },
    {
      "label": "Europe/Gibraltar",
      "value": "+01:00"
    },
    {
      "label": "Europe/Guernsey",
      "value": "+00:00"
    },
    {
      "label": "Europe/Helsinki",
      "value": "+02:00"
    },
    {
      "label": "Europe/Isle_of_Man",
      "value": "+00:00"
    },
    {
      "label": "Europe/Istanbul",
      "value": "+03:00"
    },
    {
      "label": "Europe/Jersey",
      "value": "+00:00"
    },
    {
      "label": "Europe/Kaliningrad",
      "value": "+02:00"
    },
    {
      "label": "Europe/Kiev",
      "value": "+02:00"
    },
    {
      "label": "Europe/Kirov",
      "value": "+03:00"
    },
    {
      "label": "Europe/Lisbon",
      "value": "+00:00"
    },
    {
      "label": "Europe/Ljubljana",
      "value": "+01:00"
    },
    {
      "label": "Europe/London",
      "value": "+00:00"
    },
    {
      "label": "Europe/Luxembourg",
      "value": "+01:00"
    },
    {
      "label": "Europe/Madrid",
      "value": "+01:00"
    },
    {
      "label": "Europe/Malta",
      "value": "+01:00"
    },
    {
      "label": "Europe/Mariehamn",
      "value": "+02:00"
    },
    {
      "label": "Europe/Minsk",
      "value": "+03:00"
    },
    {
      "label": "Europe/Monaco",
      "value": "+01:00"
    },
    {
      "label": "Europe/Moscow",
      "value": "+03:00"
    },
    {
      "label": "Europe/Nicosia",
      "value": "+02:00"
    },
    {
      "label": "Europe/Oslo",
      "value": "+01:00"
    },
    {
      "label": "Europe/Paris",
      "value": "+01:00"
    },
    {
      "label": "Europe/Podgorica",
      "value": "+01:00"
    },
    {
      "label": "Europe/Prague",
      "value": "+01:00"
    },
    {
      "label": "Europe/Riga",
      "value": "+02:00"
    },
    {
      "label": "Europe/Rome",
      "value": "+01:00"
    },
    {
      "label": "Europe/Samara",
      "value": "+04:00"
    },
    {
      "label": "Europe/San_Marino",
      "value": "+01:00"
    },
    {
      "label": "Europe/Sarajevo",
      "value": "+01:00"
    },
    {
      "label": "Europe/Saratov",
      "value": "+04:00"
    },
    {
      "label": "Europe/Simferopol",
      "value": "+03:00"
    },
    {
      "label": "Europe/Skopje",
      "value": "+01:00"
    },
    {
      "label": "Europe/Sofia",
      "value": "+02:00"
    },
    {
      "label": "Europe/Stockholm",
      "value": "+01:00"
    },
    {
      "label": "Europe/Tallinn",
      "value": "+02:00"
    },
    {
      "label": "Europe/Tirane",
      "value": "+01:00"
    },
    {
      "label": "Europe/Tiraspol",
      "value": "+02:00"
    },
    {
      "label": "Europe/Ulyanovsk",
      "value": "+04:00"
    },
    {
      "label": "Europe/Uzhgorod",
      "value": "+02:00"
    },
    {
      "label": "Europe/Vaduz",
      "value": "+01:00"
    },
    {
      "label": "Europe/Vatican",
      "value": "+01:00"
    },
    {
      "label": "Europe/Vienna",
      "value": "+01:00"
    },
    {
      "label": "Europe/Vilnius",
      "value": "+02:00"
    },
    {
      "label": "Europe/Volgograd",
      "value": "+03:00"
    },
    {
      "label": "Europe/Warsaw",
      "value": "+01:00"
    },
    {
      "label": "Europe/Zagreb",
      "value": "+01:00"
    },
    {
      "label": "Europe/Zaporozhye",
      "value": "+02:00"
    },
    {
      "label": "Europe/Zurich",
      "value": "+01:00"
    },
    {
      "label": "GB",
      "value": "+00:00"
    },
    {
      "label": "GB-Eire",
      "value": "+00:00"
    },
    {
      "label": "GMT",
      "value": "+00:00"
    },
    {
      "label": "GMT+0",
      "value": "+00:00"
    },
    {
      "label": "GMT-0",
      "value": "+00:00"
    },
    {
      "label": "GMT0",
      "value": "+00:00"
    },
    {
      "label": "Greenwich",
      "value": "+00:00"
    },
    {
      "label": "HST",
      "value": "-10:00"
    },
    {
      "label": "Hongkong",
      "value": "+08:00"
    },
    {
      "label": "Iceland",
      "value": "+00:00"
    },
    {
      "label": "Indian/Antananarivo",
      "value": "+03:00"
    },
    {
      "label": "Indian/Chagos",
      "value": "+06:00"
    },
    {
      "label": "Indian/Christmas",
      "value": "+07:00"
    },
    {
      "label": "Indian/Cocos",
      "value": "+06:30"
    },
    {
      "label": "Indian/Comoro",
      "value": "+03:00"
    },
    {
      "label": "Indian/Kerguelen",
      "value": "+05:00"
    },
    {
      "label": "Indian/Mahe",
      "value": "+04:00"
    },
    {
      "label": "Indian/Maldives",
      "value": "+05:00"
    },
    {
      "label": "Indian/Mauritius",
      "value": "+04:00"
    },
    {
      "label": "Indian/Mayotte",
      "value": "+03:00"
    },
    {
      "label": "Indian/Reunion",
      "value": "+04:00"
    },
    {
      "label": "Iran",
      "value": "+03:30"
    },
    {
      "label": "Israel",
      "value": "+02:00"
    },
    {
      "label": "Jamaica",
      "value": "-05:00"
    },
    {
      "label": "Japan",
      "value": "+09:00"
    },
    {
      "label": "Kwajalein",
      "value": "+12:00"
    },
    {
      "label": "Libya",
      "value": "+02:00"
    },
    {
      "label": "MET",
      "value": "+01:00"
    },
    {
      "label": "MST",
      "value": "-07:00"
    },
    {
      "label": "MST7MDT",
      "value": "-07:00"
    },
    {
      "label": "Mexico/BajaNorte",
      "value": "-08:00"
    },
    {
      "label": "Mexico/BajaSur",
      "value": "-07:00"
    },
    {
      "label": "Mexico/General",
      "value": "-06:00"
    },
    {
      "label": "NZ",
      "value": "+13:00"
    },
    {
      "label": "NZ-CHAT",
      "value": "+13:45"
    },
    {
      "label": "Navajo",
      "value": "-07:00"
    },
    {
      "label": "PRC",
      "value": "+08:00"
    },
    {
      "label": "PST8PDT",
      "value": "-08:00"
    },
    {
      "label": "Pacific/Apia",
      "value": "+13:00"
    },
    {
      "label": "Pacific/Auckland",
      "value": "+13:00"
    },
    {
      "label": "Pacific/Bougainville",
      "value": "+11:00"
    },
    {
      "label": "Pacific/Chatham",
      "value": "+13:45"
    },
    {
      "label": "Pacific/Chuuk",
      "value": "+10:00"
    },
    {
      "label": "Pacific/Easter",
      "value": "-05:00"
    },
    {
      "label": "Pacific/Efate",
      "value": "+11:00"
    },
    {
      "label": "Pacific/Enderbury",
      "value": "+13:00"
    },
    {
      "label": "Pacific/Fakaofo",
      "value": "+13:00"
    },
    {
      "label": "Pacific/Fiji",
      "value": "+13:00"
    },
    {
      "label": "Pacific/Funafuti",
      "value": "+12:00"
    },
    {
      "label": "Pacific/Galapagos",
      "value": "-06:00"
    },
    {
      "label": "Pacific/Gambier",
      "value": "-09:00"
    },
    {
      "label": "Pacific/Guadalcanal",
      "value": "+11:00"
    },
    {
      "label": "Pacific/Guam",
      "value": "+10:00"
    },
    {
      "label": "Pacific/Honolulu",
      "value": "-10:00"
    },
    {
      "label": "Pacific/Johnston",
      "value": "-10:00"
    },
    {
      "label": "Pacific/Kanton",
      "value": "+13:00"
    },
    {
      "label": "Pacific/Kiritimati",
      "value": "+14:00"
    },
    {
      "label": "Pacific/Kosrae",
      "value": "+11:00"
    },
    {
      "label": "Pacific/Kwajalein",
      "value": "+12:00"
    },
    {
      "label": "Pacific/Majuro",
      "value": "+12:00"
    },
    {
      "label": "Pacific/Marquesas",
      "value": "-09:30"
    },
    {
      "label": "Pacific/Midway",
      "value": "-11:00"
    },
    {
      "label": "Pacific/Nauru",
      "value": "+12:00"
    },
    {
      "label": "Pacific/Niue",
      "value": "-11:00"
    },
    {
      "label": "Pacific/Norfolk",
      "value": "+12:00"
    },
    {
      "label": "Pacific/Noumea",
      "value": "+11:00"
    },
    {
      "label": "Pacific/Pago_Pago",
      "value": "-11:00"
    },
    {
      "label": "Pacific/Palau",
      "value": "+09:00"
    },
    {
      "label": "Pacific/Pitcairn",
      "value": "-08:00"
    },
    {
      "label": "Pacific/Pohnpei",
      "value": "+11:00"
    },
    {
      "label": "Pacific/Ponape",
      "value": "+11:00"
    },
    {
      "label": "Pacific/Port_Moresby",
      "value": "+10:00"
    },
    {
      "label": "Pacific/Rarotonga",
      "value": "-10:00"
    },
    {
      "label": "Pacific/Saipan",
      "value": "+10:00"
    },
    {
      "label": "Pacific/Samoa",
      "value": "-11:00"
    },
    {
      "label": "Pacific/Tahiti",
      "value": "-10:00"
    },
    {
      "label": "Pacific/Tarawa",
      "value": "+12:00"
    },
    {
      "label": "Pacific/Tongatapu",
      "value": "+13:00"
    },
    {
      "label": "Pacific/Truk",
      "value": "+10:00"
    },
    {
      "label": "Pacific/Wake",
      "value": "+12:00"
    },
    {
      "label": "Pacific/Wallis",
      "value": "+12:00"
    },
    {
      "label": "Pacific/Yap",
      "value": "+10:00"
    },
    {
      "label": "Poland",
      "value": "+01:00"
    },
    {
      "label": "Portugal",
      "value": "+00:00"
    },
    {
      "label": "ROC",
      "value": "+08:00"
    },
    {
      "label": "ROK",
      "value": "+09:00"
    },
    {
      "label": "Singapore",
      "value": "+08:00"
    },
    {
      "label": "Turkey",
      "value": "+03:00"
    },
    {
      "label": "UCT",
      "value": "+00:00"
    },
    {
      "label": "US/Alaska",
      "value": "-09:00"
    },
    {
      "label": "US/Aleutian",
      "value": "-10:00"
    },
    {
      "label": "US/Arizona",
      "value": "-07:00"
    },
    {
      "label": "US/Central",
      "value": "-06:00"
    },
    {
      "label": "US/East-Indiana",
      "value": "-05:00"
    },
    {
      "label": "US/Eastern",
      "value": "-05:00"
    },
    {
      "label": "US/Hawaii",
      "value": "-10:00"
    },
    {
      "label": "US/Indiana-Starke",
      "value": "-06:00"
    },
    {
      "label": "US/Michigan",
      "value": "-05:00"
    },
    {
      "label": "US/Mountain",
      "value": "-07:00"
    },
    {
      "label": "US/Pacific",
      "value": "-08:00"
    },
    {
      "label": "US/Samoa",
      "value": "-11:00"
    },
    {
      "label": "UTC",
      "value": "+00:00"
    },
    {
      "label": "Universal",
      "value": "+00:00"
    },
    {
      "label": "W-SU",
      "value": "+03:00"
    },
    {
      "label": "WET",
      "value": "+00:00"
    },
    {
      "label": "Zulu",
      "value": "+00:00"
    }
  ]