import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import useOpenController from '../../../../hooks/useOpenController';
import { ExpendableButton } from '../../../reports/Table/ExpandedButton/ExpandedButton';
import { SubRow } from '../../../reports/Table/SubRow/SubRow';
import './TableItem.scss';

export const TableItem = ({
  item,
  row,
  style,
  marginLeft,
  isFoodcost,
  TableSectionInner,
  isShowABC,
}) => {
  const { isOpen, toggle } = useOpenController(false);
  return (
    <>
      {item['revenue'] !== 0 || item['sales_number'] ? (
        <div
          className={cn(`${row ? 'table-row' : 'table-section'}__container`)}
          style={{ ...style }}
        >
          <div className={`${row ? 'table-row' : 'table-section'}__wrapper`} style={{ ...style }}>
            <div
              className={`${row ? 'table-row' : 'table-section'}__main-title`}
              style={{ marginLeft: marginLeft }}
            >
              <div className="button-div">
                <ExpendableButton isOpen={isOpen} toggle={toggle}>
                  <div
                    className={`${row ? 'table-row' : 'table-section'}__title`}
                    style={{ color: '#2B2B2B' }}
                  >
                    {item['name']}
                  </div>
                </ExpendableButton>
              </div>
            </div>
            <>
              {TableSectionInner.map((elem, index) => {
                if (isFoodcost && elem === 'cost_price') {
                  return (
                    <div
                      key={index}
                      className={`${row ? 'table-row' : 'table-section'}__title`}
                      style={{ ...style }}
                    >
                      {' '}
                    </div>
                  );
                }

                const value = item[elem];

                return (
                  <div
                    className={`${row ? 'table-row' : 'table-section'}__title`}
                    style={{ ...style }}
                  >
                    {value}
                  </div>
                );
              })}
            </>
          </div>
          <div className={isOpen ? 'table-section__open' : 'table-section__close'}>
            {item.items &&
              item.items.map((i) => (
                <SubRow
                  item={i}
                  isShowABC={isShowABC}
                  TableSectionInner={TableSectionInner}
                  style={style}
                  marginLeft={marginLeft + 15}
                />
              ))}
            {item.child_objects &&
              item.child_objects.map((i) => (
                <TableItem
                  row
                  item={i}
                  TableSectionInner={TableSectionInner}
                  style={style}
                  marginLeft={marginLeft + 15}
                  isFoodcost={isFoodcost}
                  isShowABC={isShowABC}
                />
              ))}
            <div></div>
          </div>
        </div>
      ) : null}
    </>
  );
};
