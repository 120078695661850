import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ReviewsWindow } from './components/ReviewsWindow/ReviewsWindow';
import { StarsWidget } from './components/StarsWidget/StarsWidget';
import { MenthionWidget } from './components/MenthionWidget/MenthionWidget';
import { AverageRating } from './components/AverageRating/AverageRating';
import Nps from './components/Nps/Nps';
import { reviewsApi } from '../../api/reviewsApi';
import { Loader } from '../../components/common/Loader/Loader';
import Popover from './components/Nps/Popover/Popover';
import { TonalityWidget } from './components/TonalityWidget/TonalityWidget';
import { NPS_TYPES } from '../../const/nps';
import { dateOptions, options } from '../../const/selectReviews';
import { ERROR_TEXT } from '../../const/commonConst';
import { ErrorWidget } from '../../components/common/ErrorWidget/ErrorWidget';
import HoverInfo from '../../components/common/HoverInfo/HoverInfo';
import { NpsDescription } from './components/Nps/NpsDesc/NpsDesc';
import { MenthionDescription } from './components/MenthionWidget/MenthionDesc/MenthionDesc';
import useSetBodyScroll from '../../hooks/useSetBodyScroll';
import { authApi } from '../../api/authApi';
import { PageHeader } from '../../components/common/PageHeader/PageHeader';
import './Reviews.scss';

export const Reviews = ({ email, password }) => {
  const [account_id, setAccount_id] = useState('');
  const [serverData, setServerData] = useState([]);
  const [platformsOptions, setPlatformsOptions] = useState(options);
  const [selectedDate, setSelectedDate] = useState(dateOptions[2].value);
  const [customDate, setCustomDate] = useState('');
  // const startDate = moment(customDate.startDate).format('DD.MM.YYYY');
  // const endDate = moment(customDate.endDate).format('DD.MM.YYYY');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [checkedItems, setCheckedItems] = useState([]);

  const [dataWithSelected, setDataWithSelected] = useState(
    NPS_TYPES.map((item) => ({ ...item, selected: false }))
  );

  const handlePlatformsSelectedChange = (newSelectedOptions) => {
    setPlatformsOptions(newSelectedOptions);
  };

  const handleSelectedDateChange = (newSelectedOption) => {
    setSelectedDate(newSelectedOption.value);
  };
  const selectedPlatform =
    platformsOptions.length > 0
      ? platformsOptions.map((platform) => platform.value).join('&provider_type=')
      : '1';
  console.log(selectedDate, 'selectedDate')
  useEffect(() => {
    authApi
      .getAccountId()
      .then((response) => {
        setAccount_id(response.data[0].id);

        return reviewsApi.getFilteredSelected(
          response.data[0].id,
          selectedDate,
          selectedPlatform,
          startDate,
          endDate
        );
      })
      .then((res) => {
        setServerData(res.data);
      })
      .catch((err) => {
        console.log(err, 'catch');
      });
  }, [selectedDate, selectedPlatform, startDate, endDate]);

  const calculatedPercentInNps = serverData.reduce((a, b) => {
    return a + b.percentage;
  }, 0);

  const setBodyScroll = useSetBodyScroll();
  useEffect(() => {
    setBodyScroll(false);
    return () => {
      setBodyScroll(true);
    };
  }, [setBodyScroll]);
  return (
    <>
      <PageHeader
        title="Отзывы"
        handleSelectedDateChange={handleSelectedDateChange}
        selectedDate={selectedDate}
        handlePlatformsSelectedChange={handlePlatformsSelectedChange}
        platformsOptions={platformsOptions}
        setCustomDate={setCustomDate}
        account_id={account_id}
        reviewsPage
        dateSelect
        email={email}
        password={password}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
      {serverData.length >= 1 ? (
        <div className="reviews__container">
          <div className="reviews__left-side">
            <ReviewsWindow
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              npsTypeOptions={NPS_TYPES}
              selectedPlatform={selectedPlatform}
              platformsOptions={platformsOptions}
              handleSelectedDateChange={handleSelectedDateChange}
              account_id={account_id}
              startDate={startDate}
              endDate={endDate}
              setCustomDate={setCustomDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>
          <div className="reviews__right-side">
            <div className="reviews__right-side-up">
              <div className="reviews__first-column">
                <div className="reviews__nps">
                  <div className="reviews__cloud-container">
                    <div className="reviews__cloud-container-info-wrapper">
                      <div className="reviews__cloud-container-info-top">
                        <div className="reviews__cloud-container-title">NPS</div>
                        <div className="reviews__cloud-container-hover">
                          <HoverInfo>
                            <NpsDescription />
                          </HoverInfo>
                        </div>
                      </div>
                      {calculatedPercentInNps === 0 ? null : (
                        <div className="reviews__cloud-container-popap">
                          <Popover
                            setCheckedItems={setCheckedItems}
                            checkedItems={checkedItems}
                            setServerData={setServerData}
                            serverData={serverData}
                            dataWithSelected={dataWithSelected}
                            setDataWithSelected={setDataWithSelected}
                            account_id={account_id}
                            popoverTitle="Редактировать раздел"
                          />
                        </div>
                      )}
                    </div>
                    {calculatedPercentInNps === 0 ? (
                      <ErrorWidget text={ERROR_TEXT} />
                    ) : (
                      <Nps
                        setCheckedItems={setCheckedItems}
                        checkedItems={checkedItems}
                        serverData={serverData}
                        setServerData={setServerData}
                        dataWithSelected={dataWithSelected}
                        setDataWithSelected={setDataWithSelected}
                        account_id={account_id}
                      />
                    )}
                  </div>
                </div>
                <div className="reviews__tonality">
                  <div className="reviews__cloud-container-tonality">
                    <div className="reviews__cloud-content">
                      <TonalityWidget
                        selectedDate={selectedDate}
                        npsTypeOptions={NPS_TYPES}
                        selectedPlatform={selectedPlatform}
                        account_id={account_id}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="reviews__second-column">
                <div className="reviews__mentions">
                  <div className="reviews__cloud-container">
                    <div className="reviews__mentions-info">
                      <div className="reviews__cloud-container-title">Упоминания</div>
                      <div className="reviews__mentions-info-hover">
                        <HoverInfo>
                          <MenthionDescription />
                        </HoverInfo>
                      </div>
                    </div>
                    <MenthionWidget
                      selectedDate={selectedDate}
                      selectedPlatform={selectedPlatform}
                      account_id={account_id}
                    />
                  </div>
                </div>
                <div className="reviews__by-stars">
                  <div className="reviews__cloud-container">
                    <div className="reviews__cloud-container-wrapper"></div>
                    <StarsWidget
                      selectedDate={selectedDate}
                      npsTypeOptions={NPS_TYPES}
                      selectedPlatform={selectedPlatform}
                      account_id={account_id}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="reviews__right-side-down">
              <div className="reviews__average-rating">
                <div className="reviews__cloud-container">
                  <div className="reviews__cloud-container-wrapper"></div>
                  <AverageRating
                    selectedDate={selectedDate}
                    npsTypeOptions={NPS_TYPES}
                    selectedPlatform={selectedPlatform}
                    account_id={account_id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};
