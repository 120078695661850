export const RESTAURANTS_DATA = [
  {
    title: 'Caviar, Москва,',
    address: 'Дзержинского 16',
    isChecked: false,
  },
  {
    title: 'Caviar, Москва,',
    address: 'Пролетарская 74',
    isChecked: false,
  },
  {
    title: 'Caviar, Химки,',
    address: 'Малинина 9',
    isChecked: false,
  },
  {
    title: 'Caviar, Санкт-Петербург,',
    address: 'Пушкина 20',
    isChecked: false,
  },
  {
    title: 'Caviar, Санкт-Петербург,',
    address: 'Пушкина 4',
    isChecked: false,
  },
  {
    title: 'Caviar, Санкт-Петербург,',
    address: 'Красная 45А',
    isChecked: false,
  },
];

export const GROUPS_DATA = [
  {
    title: 'Московская область',
    isChecked: false,
  },
  {
    title: 'Ленинградская область',
    isChecked: false,
  },
];

export const GROUPS_DATA_FIRST = [
  {
    title: 'Московская область',
    isChecked: true,
  },
  {
    title: 'Ленинградская область',
    isChecked: true,
  },
];

export const PARAMETRS_DATA = [
  {
    id: 1,
    order: 1,
    text: 'Сумма',
    isChecked: true,
  },
  {
    id: 2,
    order: 2,
    text: 'Выручка',
    isChecked: true,
  },
  {
    id: 3,
    order: 3,
    text: 'Итого оплачено',
    isChecked: true,
  },
  {
    id: 4,
    order: 4,
    text: 'Фудкост',
    isChecked: true,
  },
  {
    id: 5,
    order: 5,
    text: 'Списания',
    isChecked: true,
  },
  {
    id: 6,
    order: 6,
    text: 'Количество заказов',
    isChecked: true,
  },
  {
    id: 7,
    order: 7,
    text: 'Средний чек за заказ',
    isChecked: true,
  },
  {
    id: 8,
    order: 8,
    text: 'Среднее количество строк в чеке',
    isChecked: true,
  },
  {
    id: 9,
    order: 9,
    text: 'Количество гостей',
    isChecked: true,
  },
  {
    id: 10,
    order: 10,
    text: 'Средний чек на гостя',
    isChecked: true,
  },
  {
    id: 11,
    order: 11,
    text: 'Среднее количество гостей в заказе',
    isChecked: true,
  },
  {
    id: 12,
    order: 12,
    text: 'Длительность визита',
    isChecked: true,
  },
];
