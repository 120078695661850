import * as React from 'react';
import Popover from '@mui/material/Popover';

// import Button from '@mui/material/Button';
import './PopoverRevenue.scss';
import { TofuField } from '../../../../components/common/fields/TofuField/TofuField';
import { Button } from '../../../../components/common/Button/Button';

export const PopoverRevenue = ({ children }) => {
  const [price, setPrice] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button height={0} onClick={handleClick}>
        {children}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 30,
          horizontal: -163,
        }}
        anchorPosition={{ left: 20 }}
        PaperProps={{
          style: {
            boxShadow: '2px 6px 20px 0px rgba(0, 0, 0, 0.09)',
            borderRadius: '15px',
          },
        }}
      >
        <div className="popover-revenue">
          <TofuField
            name="price"
            value={price}
            placeholder="Введите выручку"
            onChange={(e) => setPrice(e.target.value)}
          />
          <Button styleType="primary">Сохранить</Button>
        </div>
      </Popover>
    </div>
  );
};
