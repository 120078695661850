import api from './baseApi';

export const foodcostApi = {
  getNomenclaturesList: (account_id, token) => {
    return api.get(`accounts/${account_id}/sales/nomenclatures/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },

  getGroupNomenclaturesList: (account_id, token) => {
    return api.get(`accounts/${account_id}/sales/nomenclatures/groups/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },
  // список складов
  listOfWarehouses: (account_id, token) => {
    return api.get(`accounts/${account_id}/sales/stores/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },

  writeOffDiagram: (account_id, token, selectedDate) => {
    return api.get(`accounts/${account_id}/sales/writeoffs/diagram/ `, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },

  topTenByExpense: (account_id, token, selectedTopTenBytExpenseSelect, selectedDate) => {
    return api.get(
      `accounts/${account_id}/sales/stores/${selectedTopTenBytExpenseSelect}/outgoing/top10/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
  },

  // accounts/<int:account_id>/sales/stores/<int:store_id>/outgoing/top10/ - топ 10 по расходу

  dynamicFoodcost: (account_id, token, selectedReasons, selectedDate) => {
    return api.get(
      `/accounts/${account_id}/sales/dynamics?top_nomenclature_group_id=${selectedReasons}&time_period=${selectedDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
  },
};
