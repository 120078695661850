import { useState } from 'react';
import Switcher from './Switcher/Switcher';
import './Switchers.scss';

export const Switchers = () => {
  const [switchersData, setSwitchersData] = useState([
    {
      title: 'Потенциально опасные операции',
      desc: 'Краткое описание того, что включает в себя это уведомление',
      isChecked: true,
    },
    {
      title: 'Итоги дня',
      desc: 'Краткое описание того, что включает в себя это уведомление',
      isChecked: true,
    },
    {
      title: 'Изменение NPS',
      desc: 'Краткое описание того, что включает в себя это уведомление',
      isChecked: true,
    },
    {
      title: 'Отклонение от выручки за месяц',
      desc: 'Краткое описание того, что включает в себя это уведомление',
      isChecked: true,
    },
    {
      title: 'Новые отзывы',
      desc: 'Краткое описание того, что включает в себя это уведомление',
      isChecked: true,
    },
    {
      title: 'Повышение закупочной цены на продукт',
      desc: 'Краткое описание того, что включает в себя это уведомление',
      isChecked: true,
    },
    {
      title: 'Повышение фудкоста',
      desc: 'Краткое описание того, что включает в себя это уведомление',
      isChecked: true,
    },
  ]);

  const handleSwitcherChange = (index, isChecked) => {
    setSwitchersData((prevData) => {
      const newData = [...prevData];
      newData[index].isChecked = isChecked;
      return newData;
    });
  };
  return (
    <div className="switch-container">
      <div className="switch-title">Настройка Уведомлений</div>
      <div className="switch-list">
        {switchersData.map((item, index) => (
          <Switcher
            key={index}
            title={item.title}
            desc={item.desc}
            isChecked={item.isChecked}
            setIsChecked={(isChecked) => handleSwitcherChange(index, isChecked)}
          />
        ))}
      </div>
    </div>
  );
};
