export const TOP_TEN_PRODUCTS_OPTION = [
  { value: 'value', type: 'type' },
  { value: 'value', type: 'type' },
  { value: 'value', type: 'type' },
];

export const CANCELLATIONS_TABLE_DATA = [
  {
    name: 'Бар',
    quantity: '72',
    const_sum: '4 076,51',
    sum: '13 761',
    percent: '33,09%',
    child_objects: [
      {
        name: 'Винная карта',
        quantity: '15',
        const_sum: '1 275,36',
        sum: '4 115',
        percent: '10,37%',
        isThirdRow: true,
        child_objects: [
          {
            name: 'Белое вино',
            quantity: '6',
            const_sum: '671,68',
            sum: '1 885',
            percent: '5,46%',
            items: [
              {
                name: 'Cono Sur Bicicleta Gewurztraminer',
                quantity: '6',
                const_sum: '671,68',
                sum: '1 885',
                percent: '5,46%',
                child_objects: [],
                nomenclatures: [],
              },
            ],
            nomenclatures: [],
          },
          {
            name: 'Игристое вино',
            quantity: '6',
            const_sum: '363,98',
            sum: '1460',
            percent: '2,96%',
            items: [
              {
                name: 'Lambrusco Rosso',
                quantity: '6',
                const_sum: '363,98',
                sum: '1460',
                percent: '2,96%',
                child_objects: [],
                nomenclatures: [],
              },
            ],
            nomenclatures: [],
          },
          {
            name: 'Красное вино',
            quantity: '3',
            const_sum: '239,70',
            sum: '770',
            percent: '1,95%',
            items: [
              {
                name: "Devil's Rock Pinot Noir",
                quantity: '2',
                const_sum: '134,85',
                sum: '510',
                percent: '1,10%',
                child_objects: [],
                nomenclatures: [],
              },
              {
                name: 'Nature Syrah безалкогольное вино',
                quantity: '1',
                const_sum: '104,85',
                sum: '260',
                percent: '0,85%',
                child_objects: [],
                nomenclatures: [],
              },
            ],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
      {
        name: 'Водка',
        quantity: '3',
        const_sum: '62,57',
        sum: '270',
        percent: '0,51%',
        items: [
          {
            name: 'Сябры',
            quantity: '3',
            const_sum: '62,57',
            sum: '270',
            percent: '0,51%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
      {
        name: 'Джин',
        quantity: '1',
        const_sum: '24,57',
        sum: '200',
        percent: '0,20%',
        items: [
          {
            name: 'Larios',
            quantity: '1',
            const_sum: '24,57',
            sum: '200',
            percent: '0,20%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
      {
        name: 'Допы',
        quantity: '4',
        const_sum: '95,14',
        sum: '280',
        percent: '0,77%',
        items: [
          {
            name: 'Сироп "Monin"',
            quantity: '4',
            const_sum: '95,14',
            sum: '280',
            percent: '0,77%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
      {
        name: 'Коктейли',
        quantity: '11',
        const_sum: '929,38',
        sum: '4 567',
        percent: '7,56%',
        isThirdRow: true,
        items: [
          {
            name: 'Campari Spritz',
            quantity: '1',
            const_sum: '197,99',
            sum: '430',
            percent: '1,61%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        child_objects: [
          {
            name: 'Авторские',
            quantity: '10',
            const_sum: '731,39',
            sum: '4 145',
            percent: '5,95%',
            items: [
              {
                name: 'Love',
                quantity: '2',
                const_sum: '143,99',
                sum: '770',
                percent: '1,17%',
                child_objects: [],
                nomenclatures: [],
              },
              {
                name: 'FLAMINGO',
                quantity: '2',
                const_sum: '133,14',
                sum: '865',
                percent: '1,08%',
                child_objects: [],
                nomenclatures: [],
              },
              {
                name: 'KOLIBRI',
                quantity: '2',
                const_sum: '126,85',
                sum: '810',
                percent: '1,03%',
                child_objects: [],
                nomenclatures: [],
              },
              {
                name: 'Blue haze',
                quantity: '1',
                const_sum: '126,85',
                sum: '455',
                percent: '1,03%',
                child_objects: [],
                nomenclatures: [],
              },
              {
                name: 'CAVIAR.',
                quantity: '1',
                const_sum: '95,42',
                sum: '455',
                percent: '0,78%',
                child_objects: [],
                nomenclatures: [],
              },
              {
                name: 'Brown',
                quantity: '1',
                const_sum: '60,28',
                sum: '400',
                percent: '0,49%',
                child_objects: [],
                nomenclatures: [],
              },
              {
                name: 'BERGAMO',
                quantity: '1',
                const_sum: '44,57',
                sum: '390',
                percent: '0,36%',
                child_objects: [],
                nomenclatures: [],
              },
            ],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
      {
        name: 'Кофе',
        quantity: '13',
        const_sum: '492,55',
        sum: '1 745',
        percent: '4,01%',
        items: [
          {
            name: 'Раф',
            quantity: '4',
            const_sum: '171,42',
            sum: '575',
            percent: '1,39%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Эспрессо-тоник',
            quantity: '2',
            const_sum: '111,71',
            sum: '160',
            percent: '0,91%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Бамбл',
            quantity: '3',
            const_sum: '107,99',
            sum: '430',
            percent: '0,88%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Флэт Уайт',
            quantity: '2',
            const_sum: '69,14',
            sum: '290',
            percent: '0,56%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Американо',
            quantity: '2',
            const_sum: '32,28',
            sum: '290',
            percent: '0,26%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
      {
        name: 'Лимонады',
        quantity: '1',
        const_sum: '64,00',
        sum: '170',
        percent: '0,52%',
        items: [
          {
            name: 'Фиджи',
            quantity: '1',
            const_sum: '64,00',
            sum: '170',
            percent: '0,52%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
      {
        name: 'Пиво',
        quantity: '1',
        const_sum: '68,00',
        sum: '145',
        percent: '0,55%',
        items: [
          {
            name: 'Балтика №0',
            quantity: '1',
            const_sum: '68,00',
            sum: '145',
            percent: '0,55%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
      {
        name: 'Фреши',
        quantity: '3',
        const_sum: '304,27',
        sum: '530',
        percent: '2,47%',
        items: [
          {
            name: 'Грейпрфут фреш',
            quantity: '2',
            const_sum: '251,42',
            sum: '350',
            percent: '2,05%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Апельсин фреш',
            quantity: '1',
            const_sum: '52,85',
            sum: '180',
            percent: '0,43%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
      {
        name: 'Cola / sprite / burn ...',
        quantity: '21',
        const_sum: '751,68',
        sum: '1 830',
        percent: '6,11%',
        items: [
          {
            name: 'Schweppes',
            quantity: '9',
            const_sum: '426,84',
            sum: '760',
            percent: '3,47%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Сок "Rich" Вишня',
            quantity: '5',
            const_sum: '97,14',
            sum: '460',
            percent: '0,79%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Bonaqua газированная',
            quantity: '3',
            const_sum: '94,57',
            sum: '280',
            percent: '0,77%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Coca-Cola Zero',
            quantity: '2',
            const_sum: '66,57',
            sum: '170',
            percent: '0,54%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Sprite',
            quantity: '1',
            const_sum: '33,14',
            sum: '80',
            percent: '0,27%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Fanta',
            quantity: '1',
            const_sum: '33,14',
            sum: '80',
            percent: '0,27%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
    ],
    nomenclatures: [],
  },
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    name: 'Кальяны',
    quantity: '3',
    const_sum: '486,83',
    sum: '3 600',
    percent: '3,96%',
    isSubRowFirst: true,
    items: [
      {
        name: 'НЕФТЬ',
        quantity: '1',
        const_sum: '166,28',
        sum: '1 460',
        percent: '1,35%',
        child_objects: [],
        nomenclatures: [],
      },
      {
        name: 'Неоклассический кальян',
        quantity: '1',
        const_sum: '160,56',
        sum: '1 200',
        percent: '1,31%',
        child_objects: [],
        nomenclatures: [],
      },
      {
        name: 'Классический кальян',
        quantity: '1',
        const_sum: '159,99',
        sum: '940',
        percent: '1,30%',
        child_objects: [],
        nomenclatures: [],
      },
    ],
    nomenclatures: [],
  },
  /////////////////////////////////////////////////////////////////////////////////////////////
  {
    name: 'Кухня',
    quantity: '89',
    const_sum: '7 739,61',
    sum: '30 475',
    percent: '62,95%',
    child_objects: [
      {
        name: 'Основное меню',
        quantity: '29',
        const_sum: '2 877,28',
        sum: '12 380',
        percent: '23,40%',
        items: [
          {
            name: 'Кокосовые сырники с шоколадом, манго и апельсиновой карамелью',
            quantity: '5',
            const_sum: '471,41',
            sum: '1 700',
            percent: '3,83%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Брауни с шоколадным муссом, вишней и соленым ирисом',
            quantity: '5',
            const_sum: '445,41',
            sum: '1 700',
            percent: '3,62%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Стейк лосось с морковным орзо, апельсином и бадьяном',
            quantity: '2',
            const_sum: '411,12',
            sum: '1 430',
            percent: '3,34%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Бургер с говяжьей котлетой и соусом BBQ',
            quantity: '3',
            const_sum: '372,84',
            sum: '1 890',
            percent: '3,03%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Тар-тар из лосося с кунжутным муссом',
            quantity: '2',
            const_sum: '317,70',
            sum: '1 200',
            percent: '2,58%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Мусс из белого шоколада с гречневым попкорном и манго',
            quantity: '4',
            const_sum: '267,42',
            sum: '1 260',
            percent: '2,18%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Салат с креветкой и апельсиновым песто',
            quantity: '2',
            const_sum: '192,56',
            sum: '1090',
            percent: '1,57%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Паста с прошутто, манго и горгонзолой',
            quantity: '2',
            const_sum: '161,13',
            sum: '970',
            percent: '1,31%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Салат с ростбифом, соусом Вителло и каперсом',
            quantity: '1',
            const_sum: '110,28',
            sum: '540',
            percent: '0,90%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Крем-суп из шампиньонов с трюфельной сметаной',
            quantity: '1',
            const_sum: '64,28',
            sum: '260',
            percent: '0,52%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Хумус с вяленным томатом',
            quantity: '1',
            const_sum: '38,86',
            sum: '200',
            percent: '0,32%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Домашний хлеб с ароматным маслом',
            quantity: '1',
            const_sum: '24,28',
            sum: '140',
            percent: '0,20%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
      {
        name: 'Роллы',
        quantity: '60',
        const_sum: '4 862,33',
        sum: '18 095',
        percent: '39,55%',
        isThirdRow: true,
        items: [
          {
            name: 'Филадельфия с авокадо',
            quantity: '7',
            const_sum: '1 324,79',
            sum: '3 630',
            percent: '10,78%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Ролл с тигровой креветкой, лососем и свежим томатом',
            quantity: '6',
            const_sum: '1 033,38',
            sum: '3 400',
            percent: '8,41%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Калифорния с креветкой, авокадо и огурцом',
            quantity: '8',
            const_sum: '851,96',
            sum: '3 890',
            percent: '6,93%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Ролл с сыром Чеддер, копченым лососем и свежим томатом',
            quantity: '4',
            const_sum: '516,55',
            sum: '2 000',
            percent: '4,20%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Бонито с копченным лососем и огурцом',
            quantity: '2',
            const_sum: '287,41',
            sum: '970',
            percent: '2,34%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Ролл с угрем унаги, авокадо и огурцом',
            quantity: '2',
            const_sum: '254,27',
            sum: '1 115',
            percent: '2,07%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Ролл с чукой и креветкой темпура',
            quantity: '2',
            const_sum: '206,56',
            sum: '860',
            percent: '1,68%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Ролл в огурце с курицей терияки и блинчиком Тамаго',
            quantity: '2',
            const_sum: '180,85',
            sum: '830',
            percent: '1,47%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        child_objects: [
          {
            name: 'Гарниры',
            quantity: '27',
            const_sum: '206,56',
            sum: '1 400',
            percent: '1,68%',
            items: [
              {
                name: 'Васаби мусс',
                quantity: '10',
                const_sum: '83,42',
                sum: '570',
                percent: '0,68%',
                child_objects: [],
                nomenclatures: [],
              },
              {
                name: 'Соевый соус',
                quantity: '12',
                const_sum: '82,28',
                sum: '490',
                percent: '0,67%',
                child_objects: [],
                nomenclatures: [],
              },
              {
                name: 'Вместе: соевый, имбирь, васаби',
                quantity: '1',
                const_sum: '18,00',
                sum: '140',
                percent: '0,15%',
                child_objects: [],
                nomenclatures: [],
              },
              {
                name: 'Имбирь',
                quantity: '3',
                const_sum: '14,00',
                sum: '140',
                percent: '0,11%',
                child_objects: [],
                nomenclatures: [],
              },
              {
                name: 'Унаги',
                quantity: '1',
                const_sum: '8,86',
                sum: '60',
                percent: '0,07%',
                child_objects: [],
                nomenclatures: [],
              },
            ],
          },
        ],
        nomenclatures: [],
      },
    ],
    nomenclatures: [],
  },
];

export const TABLE_DATA = [
  {
    name: 'Порча',
    quantity: '',
    sum: '11 743,70',
    percent: '32,67%',
    child_objects: [
      {
        name: ' склад Кухня',
        quantity: '',
        sum: '7 664,76',
        percent: '65,27%',
        items: [
          {
            name: 'Рис для суши (п/ф)',
            quantity: '17,388 кг',
            sum: '1 345,08',
            percent: '11,45%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Креветка очищенная (п/ф)',
            quantity: '0,535 кг',
            sum: '886,53',
            percent: '7,55%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Угорь очищенный (п/ф)',
            quantity: '0,397 кг',
            sum: '803,10',
            percent: '6,84%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Хлеб чиабатта (п/ф)',
            quantity: '2,398 кг',
            sum: '719,39',
            percent: '6,13%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Ростбиф (п/ф)',
            quantity: '0,582 кг',
            sum: '701,39',
            percent: '5,97%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Лосось копченный (п/ф)',
            quantity: '0,34 кг',
            sum: '490,26',
            percent: '4,17%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Томаго блин (п/ф)',
            quantity: '40 шт',
            sum: '414,27',
            percent: '3,53%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Говядина вырезка (ингредиент)',
            quantity: '0,4 кг',
            sum: '400,55',
            percent: '3,41%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Лосось гравлакс (п/ф)',
            quantity: '0,236 кг',
            sum: '373,12',
            percent: '3,18%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Тунец стружка (ингредиент)',
            quantity: '0,08 кг',
            sum: '279,70',
            percent: '2,38%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Сыр чеддер (ингредиент)',
            quantity: '0,2 кг',
            sum: '169,99',
            percent: '1,45%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Карамель (ингредиент)',
            quantity: '0,35 кг',
            sum: '133,99',
            percent: '1,14%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Сыр пармезан (ингредиент)',
            quantity: '0,172 кг',
            sum: '129,71',
            percent: '1,10%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Филе куриное терияки (п/ф)',
            quantity: '0,208 кг',
            sum: '121,71',
            percent: '1,04%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Айсберг (ингредиент)',
            quantity: '0,335 кг',
            sum: '114,85',
            percent: '0,98%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Мусс из темного шоколада (п/ф)',
            quantity: '0,102 кг',
            sum: '86,00',
            percent: '0,73%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Котлетное мясо с/м (ингредиент)',
            quantity: '0,25 кг',
            sum: '84,85',
            percent: '0,72%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Соус горгонзола (п/ф)',
            quantity: '0,095 кг',
            sum: '74,28',
            percent: '0,63%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Сметана (ингредиент)',
            quantity: '0,3 кг',
            sum: '64,00',
            percent: '0,54%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Мусс из белого шоколада (п/ф)',
            quantity: '0,07 кг',
            sum: '45,14',
            percent: '0,38%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Картофельное пюре (п/ф)',
            quantity: '0,243 кг',
            sum: '44,00',
            percent: '0,37%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Огурец свежий (ингредиент)',
            quantity: '0,375 кг',
            sum: '38,57',
            percent: '0,33%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Сухари Панко (ингредиент)',
            quantity: '0,138 кг',
            sum: '28,28',
            percent: '0,24%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Клубника с/м (ингредиент)',
            quantity: '0,075 кг',
            sum: '22,57',
            percent: '0,19%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Редис (ингредиент)',
            quantity: '0,063 кг',
            sum: '22,00',
            percent: '0,19%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Апельсиновая карамель (п/ф)',
            quantity: '0,14 кг',
            sum: '22,00',
            percent: '0,19%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Томат свежий (ингредиент)',
            quantity: '0,316 кг',
            sum: '20,00',
            percent: '0,17%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Груша свежая (ингредиент)',
            quantity: '0,1 кг',
            sum: '17,14',
            percent: '0,15%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Петрушка/укроп (ингредиент)',
            quantity: '0,02 кг',
            sum: '8,29',
            percent: '0,07%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Морковь (ингредиент)',
            quantity: '0,12 кг',
            sum: '4,00',
            percent: '0,03%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
      {
        name: 'склад Бар',
        quantity: '',
        sum: '4 078,94',
        percent: '34,73%',
        items: [
          {
            name: 'Лаймовый сок (п/ф)',
            quantity: '0,456 л',
            sum: '994,52',
            percent: '8,47%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Имбирный кордиал (п/ф)',
            quantity: '1,074 л',
            sum: '909,67',
            percent: '7,75%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Лимонный сок (п/ф)',
            quantity: '0,845 л',
            sum: '727,96',
            percent: '6,20%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Аквафаба (п/ф)',
            quantity: '3,456 л',
            sum: '666,54',
            percent: '5,68%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Клубничный кордиал (п/ф)',
            quantity: '0,817 л',
            sum: '458,55',
            percent: '3,90%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Манго кордиал (п/ф)',
            quantity: '0,476 л',
            sum: '321,70',
            percent: '2,74%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
    ],
    nomenclatures: [],
  },
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    name: 'Питание персонала',
    quantity: '',
    sum: '18 149,09',
    percent: '50,49%',
    child_objects: [
      {
        name: 'склад Кухня',
        quantity: '',
        sum: '18 149,09',
        percent: '50,49%',
        items: [
          {
            name: 'Филе ЦБ (ингредиент)',
            quantity: '19 кг',
            sum: '6 269,69',
            percent: '34,55%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Ростбиф (п/ф)',
            quantity: '2,23 кг',
            sum: '2 687,29',
            percent: '14,81%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Говядина вырезка (ингредиент)',
            quantity: '2,144 кг',
            sum: '2 147,04',
            percent: '11,83%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Язык говяжий (ингредиент)',
            quantity: '1,43 кг',
            sum: '888,53',
            percent: '4,90%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Рис штат (ингредиент)',
            quantity: '8 кг',
            sum: '829,10',
            percent: '4,57%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Мякоть окорочка ЦБ (ингредиент)',
            quantity: '2,2 кг',
            sum: '738,25',
            percent: '4,07%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Котлетное мясо с/м (ингредиент)',
            quantity: '2,07 кг',
            sum: '702,54',
            percent: '3,87%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Лук репчатый (ингредиент)',
            quantity: '7,72 кг',
            sum: '594,83',
            percent: '3,28%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Макароны спагетти (ингредиент)',
            quantity: '1,4 кг',
            sum: '464,26',
            percent: '2,56%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Сыр сулугуни (ингредиент)',
            quantity: '0,75 кг',
            sum: '387,12',
            percent: '2,13%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Кетчуп штат (ингредиент)',
            quantity: '1,68 кг',
            sum: '302,27',
            percent: '1,67%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Сыр чеддер (ингредиент)',
            quantity: '0,3 кг',
            sum: '255,13',
            percent: '1,41%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Молоко (ингредиент)',
            quantity: '2,95 л',
            sum: '159,42',
            percent: '0,88%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Картофель свежий (ингредиент)',
            quantity: '8 кг',
            sum: '153,42',
            percent: '0,85%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Перец свежий (ингредиент)',
            quantity: '0,65 кг',
            sum: '141,99',
            percent: '0,78%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Прошутто (ингредиент)',
            quantity: '0,15 кг',
            sum: '140,85',
            percent: '0,78%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Рис отваренный для суши  (п/ф)',
            quantity: '1,8 кг',
            sum: '139,71',
            percent: '0,77%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Морковь (ингредиент)',
            quantity: '4,078 кг',
            sum: '133,42',
            percent: '0,74%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Кукуруза консервированная (ингредиент)',
            quantity: '0,38 кг',
            sum: '100,28',
            percent: '0,55%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Яйцо (ингредиент)',
            quantity: '11 шт',
            sum: '97,71',
            percent: '0,54%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Булка для бургера  (п/ф)',
            quantity: '5 шт',
            sum: '96,28',
            percent: '0,53%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Шпинат (ингредиент)',
            quantity: '0,08 кг',
            sum: '81,71',
            percent: '0,45%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Мука (ингредиент)',
            quantity: '1,575 кг',
            sum: '73,14',
            percent: '0,40%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Сыр российский (ингредиент)',
            quantity: '0,078 кг',
            sum: '72,28',
            percent: '0,40%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Масло сливочное (ингредиент)',
            quantity: '0,1 кг',
            sum: '62,57',
            percent: '0,34%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Лук репчатый красный (ингредиент)',
            quantity: '1 кг',
            sum: '56,28',
            percent: '0,31%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Макароны штат (ингредиент)',
            quantity: '0,9 кг',
            sum: '45,14',
            percent: '0,25%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Айсберг (ингредиент)',
            quantity: '0,1 кг',
            sum: '34,28',
            percent: '0,19%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Крупа гречневая (ингредиент)',
            quantity: '0,45 кг',
            sum: '33,43',
            percent: '0,18%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Грибы шампиньоны (ингредиент)',
            quantity: '0,11 кг',
            sum: '28,00',
            percent: '0,15%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Соус Кимчи (ингредиент)',
            quantity: '0,055 кг',
            sum: '25,14',
            percent: '0,14%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Сливки 20% (ингредиент)',
            quantity: '0,1 л',
            sum: '24,86',
            percent: '0,14%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Масло растительное (ингредиент)',
            quantity: '0,15 л',
            sum: '20,28',
            percent: '0,11%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Цукини (ингредиент)',
            quantity: '0,12 кг',
            sum: '20,00',
            percent: '0,11%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Орегано специя (ингредиент)',
            quantity: '0,003 кг',
            sum: '18,00',
            percent: '0,10%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Перец чили свежий (ингредиент)',
            quantity: '0,008 кг',
            sum: '16,86',
            percent: '0,09%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Соус соевый (ингредиент)',
            quantity: '0,1 кг',
            sum: '15,71',
            percent: '0,09%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Орзо варенное (п/ф)',
            quantity: '0,1 кг',
            sum: '15,43',
            percent: '0,09%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Соус терияки (ингредиент)',
            quantity: '0,05 кг',
            sum: '12,00',
            percent: '0,07%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Приправа перец черный горошек (ингредиент)',
            quantity: '0,013 кг',
            sum: '11,14',
            percent: '0,06%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Чеснок (ингредиент)',
            quantity: '0,038 кг',
            sum: '10,86',
            percent: '0,06%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Огурец консервированный (ингредиент)',
            quantity: '0,041 кг',
            sum: '10,00',
            percent: '0,06%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Огурец свежий (ингредиент)',
            quantity: '0,085 кг',
            sum: '8,86',
            percent: '0,05%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Соус унаги (ингредиент)',
            quantity: '0,03 кг',
            sum: '7,43',
            percent: '0,04%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Сахар (ингредиент)',
            quantity: '0,08 кг',
            sum: '6,57',
            percent: '0,04%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Приправа перец чили хлопья (ингредиент)',
            quantity: '0,003 кг',
            sum: '4,57',
            percent: '0,03%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Соус BBQ  (п/ф)',
            quantity: '0,01 кг',
            sum: '2,29',
            percent: '0,01%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Вино красное (ингредиент)',
            quantity: '0,01 л',
            sum: '2,00',
            percent: '0,01%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Соль (ингредиент)',
            quantity: '0,055 кг',
            sum: '1,14',
            percent: '0,01%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
    ],
    nomenclatures: [],
  },
  /////////////////////////////////////////////////////////////////////////////////////////////
  {
    name: 'Бракераж',
    quantity: '',
    sum: '6 055,70',
    percent: '16,85%',
    child_objects: [
      {
        name: 'склад Кухня',
        quantity: '',
        sum: '5 352,02',
        percent: '88,38%',
        items: [
          {
            name: 'Ростбиф (п/ф)',
            quantity: '2,23 кг',
            sum: '2 687,29',
            percent: '44,38%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Креветка очищенная (п/ф)',
            quantity: '0,535 кг',
            sum: '886,53',
            percent: '14,64%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Угорь очищенный (п/ф)',
            quantity: '0,397 кг',
            sum: '803,10',
            percent: '13,26%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Лосось копченный (п/ф)',
            quantity: '0,34 кг',
            sum: '490,26',
            percent: '8,10%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Лосось гравлакс (п/ф)',
            quantity: '0,236 кг',
            sum: '373,12',
            percent: '6,16%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Булка для бургера  (п/ф)',
            quantity: '5 шт',
            sum: '96,28',
            percent: '1,59%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Орзо варенное (п/ф)',
            quantity: '0,1 кг',
            sum: '15,43',
            percent: '0,25%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
      {
        name: 'склад Бар',
        quantity: '',
        sum: '703,68',
        percent: '11,62%',
        items: [
          {
            name: 'CAVIAR. (блюдо)',
            quantity: '3 пц',
            sum: '291,41',
            percent: '4,81%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'FLAMINGO (блюдо)',
            quantity: '2 пц',
            sum: '138,28',
            percent: '2,28%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Манго (настойка) (п/ф)',
            quantity: '0,2 л',
            sum: '137,42',
            percent: '2,27%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'KOLIBRI (блюдо)',
            quantity: '2 пц',
            sum: '136,56',
            percent: '2,26%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
    ],
    nomenclatures: [],
  },
];

export const PAYMENTS_TABLE_DATA = [
  {
    name: 'Питание учредителей (фронт)',
    quantity: '15',
    const_sum: '1 774,48',
    sum: '6 560',
    percent: '59,41%',
    child_objects: [
      {
        name: 'Кухня',
        quantity: '10',
        const_sum: '1 358,50',
        sum: '5 870',
        percent: '45,48%',
        items: [
          {
            name: 'Стейк лосось с морковным орзо, апельсином и бадьяном',
            quantity: '2',
            const_sum: '411,12',
            sum: '1 430',
            percent: '13,76%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Бургер с говяжьей котлетой и соусом BBQ',
            quantity: '3',
            const_sum: '372,84',
            sum: '1 890',
            percent: '12,48%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Тар-тар из лосося с кунжутным муссом',
            quantity: '2',
            const_sum: '317,70',
            sum: '1 200',
            percent: '10,64%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Салат с креветкой и апельсиновым песто',
            quantity: '2',
            const_sum: '192,56',
            sum: '1 090',
            percent: '6,45%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Крем-суп из шампиньонов с трюфельной сметаной',
            quantity: '1',
            const_sum: '64,28',
            sum: '260',
            percent: '2,15%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
      {
        name: 'Бар',
        quantity: '5',
        const_sum: '415,98',
        sum: '690',
        percent: '13,93%',
        items: [
          {
            name: 'Эспрессо-тоник',
            quantity: '2',
            const_sum: '111,71',
            sum: '160',
            percent: '3,74%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Грейпрфут фреш',
            quantity: '2',
            const_sum: '251,42',
            sum: '350',
            percent: '8,42%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Апельсин фреш',
            quantity: '1',
            const_sum: '52,85',
            sum: '180',
            percent: '1,77%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
    ],
    nomenclatures: [],
  },
  {
    name: 'Бракераж(фронт)',
    quantity: '14',
    const_sum: '1 212,51',
    sum: '5 050',
    percent: '40,59%',
    child_objects: [
      {
        name: 'Кухня',
        quantity: '14',
        const_sum: '1 212,51',
        sum: '5 050',
        percent: '40,59%',
        items: [
          {
            name: 'Кокосовые сырники с шоколадом, манго и апельсиновой карамелью',
            quantity: '5',
            const_sum: '471,41',
            sum: '1700',
            percent: '15,78%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Брауни с шоколадным муссом, вишней и соленым ирисом',
            quantity: '5',
            const_sum: '445,41',
            sum: '1 700',
            percent: '14,91%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Домашний хлеб с ароматным маслом',
            quantity: '1',
            const_sum: '24,28',
            sum: '140',
            percent: '0,81%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Паста с прошутто, манго и горгонзолой',
            quantity: '2',
            const_sum: '161,13',
            sum: '970',
            percent: '5,39%',
            child_objects: [],
            nomenclatures: [],
          },
          {
            name: 'Салат с ростбифом, соусом Вителло и каперсом',
            quantity: '1',
            const_sum: '110,28',
            sum: '540',
            percent: '3,69%',
            child_objects: [],
            nomenclatures: [],
          },
        ],
        nomenclatures: [],
      },
    ],
    nomenclatures: [],
  },
];
