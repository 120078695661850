import React, { useState } from 'react';
import './HoverPlan.scss';

export const HoverPlan = () => {
  return (
    <div className="hover-plan_container">
      <p className="hover-plan_text">
        План<p className="hover-plan_text-percent"> 100%</p>
      </p>
    </div>
  );
};
