import { MultiSelectDropdown } from '../../../../../../components/common/MultiSelect/MultiSelect';
import './Header.scss';

export const Header = ({
  reasons,
  store,
  handleSelectedOptionsDynamicForReasons,
  handleSelectedOptionsDynamicForStore,
}) => {
  return (
    <div className="header-container" style={{ marginLeft: '20px', marginRight: '20px' }}>
      <div className="header-title">Динамика списаний</div>
      {store.length > 0 && reasons.length > 0 && (
        <div className="header-btns">
          <div className="header-selects">
            <MultiSelectDropdown
              options={reasons}
              onChange={handleSelectedOptionsDynamicForReasons}
              title="Причины"
            />
            <div>
              <MultiSelectDropdown
                options={store}
                onChange={handleSelectedOptionsDynamicForStore}
                secondBg
                isOneRow
                title="Списания"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
