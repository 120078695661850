import React, { useEffect, useState } from 'react';
import './CircleProgress.scss';

const CircularProgress = ({ size = 74, strokeWidth = 7, percentage, nps_name }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(percentage);
  }, [percentage]);

  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;

  const color = percentage > 50 ? '#84D9BF' : '#F56C7B';

  return (
    <div className="circle-progress-container">
      <svg width={size} height={size} viewBox={viewBox}>
        <circle
          fill="none"
          stroke="#F9F9F9"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <circle
          fill="none"
          stroke={color}
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
          strokeDasharray={[dash, circumference - dash]}
          strokeLinecap="round"
          style={{ transition: 'all 0.5s' }}
        />
        <text fill="black" fontSize="14px" x="50%" y="30%" dy="20px" textAnchor="middle">
          {`${Math.ceil(percentage)}%`}
        </text>
      </svg>
      <p className="nps_text">{nps_name}</p>
    </div>
  );
};

export default CircularProgress;
