import React from 'react';

export const Forward = () => {
  return (
    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9366 1.40311C14.8076 1.13397 14.5037 0.958496 14.1667 0.958496H5.83332C2.61167 0.958496 0 3.21573 0 6.00016C0 8.7846 2.61167 11.0418 5.83332 11.0418H13.3333C13.7936 11.0418 14.1667 10.7194 14.1667 10.3216C14.1667 9.9238 13.7936 9.60135 13.3333 9.60135H5.83332C3.53214 9.60135 1.66666 7.98903 1.66666 6.00016C1.66666 4.01128 3.53214 2.39897 5.83332 2.39897H12.1548L10.6608 3.69028C10.3353 3.97155 10.3353 4.42758 10.6608 4.70885C10.9862 4.9901 11.5138 4.9901 11.8393 4.70885L14.7559 2.18802C14.9942 1.98203 15.0655 1.67224 14.9366 1.40311Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};
