import { useState, useCallback, useRef } from 'react';
import { QrForDownloads } from './QrForDownloads/QrForDownloads';
import { Button } from '../../../../components/common/Button/Button';
import { ToastContainer, toast } from 'react-toastify';
import { TofuLogoBig } from '../../../../icons/tofu-logo-big';
import { EyeIcon } from '../../../../icons/reviewsPlatforms/eye-icon';
import { TofuModal } from './TofuModal/TofuModal';
import html2canvas from 'html2canvas';
import { aspectRatioOptions, colorOptions } from './const';
import 'react-toastify/dist/ReactToastify.css';
import jsPDF from 'jspdf';
import './Tofu.scss';

export const Tofu = ({ companyData }) => {
  const [isCompanyModal, isSetCompanyModal] = useState(false);

  const onCloseModal = useCallback(() => {
    isSetCompanyModal(false);
  }, []);

  const [selectedItemSide, setSelectedItemSide] = useState(null);
  const [selectedItemBg, setSelectedItemBg] = useState(null);
  const [selectedItemFormat, setSelectedItemFormat] = useState(null);

  const onOpenModal = useCallback(() => {
    if (selectedItemSide === null || selectedItemBg === null || selectedItemFormat === null) {
      toast.error('Выберите все пункты');
    } else {
      isSetCompanyModal(true);
    }
  }, [selectedItemSide, selectedItemBg, selectedItemFormat]);

  const handleItemClick = (item) => {
    setSelectedItemSide(item);
  };

  const handleItemBgClick = (item) => {
    setSelectedItemBg(item);
  };

  const handleItemFormatClick = (item) => {
    setSelectedItemFormat(item);
  };

  const tofuModalRef = useRef();

  const handleDownloadPNG = async () => {
    if (selectedItemSide === null || selectedItemBg === null || selectedItemFormat === null) {
      toast.error('Выберите все пункты');
    } else {
      const element = tofuModalRef.current;
      const canvas = await html2canvas(element, { useCORS: true, allowTaint: true });

      const data = canvas.toDataURL('image/png');
      const link = document.createElement('a');

      if (typeof link.download === 'string') {
        link.href = data;
        link.download = 'image.png';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    }
  };

  const downloadPDF = async () => {
    if (selectedItemSide === null || selectedItemBg === null || selectedItemFormat === null) {
      toast.error('Выберите все пункты');
      return;
    }

    const pdf = new jsPDF('portrait', 'pt', 'a4');

    const data = await html2canvas(tofuModalRef.current, {
      useCORS: true,
      allowTaint: true,
    });
    const img = data.toDataURL('image/png');
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    if (selectedItemSide === 'nineToSixteen') {
      pdf.addImage(img, 'PNG', 0, 0, 550, 850);
    } else {
      pdf.addImage(img, 'PNG', 0, 0, pdfWidth, pdfHeight);
    }
    pdf.save('shipping_label.pdf');
  };

  return (
    <div className="tofu-container">
      <div>
        <div>
          <TofuLogoBig width={48} height={48} />
        </div>
        <div className="tofu-title-logo">Tofu</div>
      </div>
      <div>
        <div className="tofu-title">Отзывы Tofu - макет с Qr-кодом</div>
        <div className="tofu-descripthion">
          Разместите Qr-код в Вашем заведении и гости начнут оставлять больше отзывов
        </div>
        <div className="tofu-wrapper">
          <div className="tofu-column">
            <div className="tofu-column-title">соотношение сторон*</div>
            <div className="tofu-column-row">
              {aspectRatioOptions.map((option) => (
                <div key={option.id}>
                  <div
                    className={`tofu-column-row__item ${
                      selectedItemSide === option.id ? 'selected' : ''
                    }`}
                    onClick={() => handleItemClick(option.id)}
                  >
                    {option.icon}
                  </div>
                  <div
                    className={`tofu-column-row__item-title ${
                      selectedItemSide === option.id ? 'selected' : ''
                    }`}
                  >
                    {option.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="tofu-column-row__item-desc">
            {selectedItemSide &&
              aspectRatioOptions.find((option) => option.id === selectedItemSide).description}
          </div>
        </div>
        <div className="tofu-column">
          <div className="tofu-column-title">цветовая гамма*</div>
          <div className="tofu-column-row">
            {colorOptions.map((option) => (
              <div key={option.id} className="tofu-column-row__item">
                <div
                  className={`tofu-column-row__item ${
                    selectedItemBg === option.id ? 'selected' : ''
                  }`}
                  onClick={() => handleItemBgClick(option.id)}
                >
                  {option.icon}
                </div>
                <div
                  className={`tofu-column-row__item-title ${
                    selectedItemBg === option.id ? 'selected' : ''
                  }`}
                >
                  {option.title}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="tofu-column">
          <div className="tofu-column-title">формат*</div>
          <div className="tofu-column-row">
            <div
              className={`tofu-column-format ${selectedItemFormat === 'PDF' ? 'selected' : ''}`}
              onClick={() => handleItemFormatClick('PDF')}
            >
              PDF
            </div>
            <div
              className={`tofu-column-format ${selectedItemFormat === 'PNG' ? 'selected' : ''}`}
              onClick={() => handleItemFormatClick('PNG')}
            >
              PNG
            </div>
          </div>
        </div>
        <div className="tofu-btns">
          <Button
            styleType="secondary"
            width={183}
            height={50}
            onClick={onOpenModal}
            customClass="preview-btn"
          >
            <div className="tofu-btns-icon">
              <EyeIcon />
            </div>
            Предпросмотр
          </Button>
          <Button
            styleType="primary"
            width={300}
            height={50}
            onClick={selectedItemFormat === 'PNG' ? handleDownloadPNG : downloadPDF}
          >
            Скачать макет
          </Button>
        </div>
      </div>
      <ToastContainer position="top-right" />
      {isCompanyModal && (
        <TofuModal
          isOpen={isCompanyModal}
          onClose={onCloseModal}
          companyData={companyData}
          selectedItemSide={selectedItemSide}
          selectedItemBg={selectedItemBg}
          selectedItemFormat={selectedItemFormat}
        />
      )}
      <div
        ref={tofuModalRef}
        id="#pdf"
        style={{ marginBottom: '0px', position: 'absolute', top: '-5500px' }}
      >
        <QrForDownloads
          companyData={companyData}
          selectedItemSide={selectedItemSide}
          selectedItemBg={selectedItemBg}
        />
      </div>
    </div>
  );
};
