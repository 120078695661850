import { useEffect, useState } from 'react';
import { reviewsApi } from '../../../../api/reviewsApi';
import Circle from '../../../../components/common/Circle/Circle';
import { ErrorWidget } from '../../../../components/common/ErrorWidget/ErrorWidget';
import { Select } from '../../../../components/common/Select/Select';
import { ERROR_TEXT_FOR_SELECT } from '../../../../const/commonConst';
import { sortData } from './helper';
import { StarsStatistics } from './StarsStatistic/StartsStatistic';
import './StarsWidget.scss';

export const StarsWidget = ({ npsTypeOptions, selectedDate, selectedPlatform, account_id }) => {
  const [serverData, setServerData] = useState([]);

  const [selectedOptionByStar, setSelectedOptionByStar] = useState({
    ...npsTypeOptions.find((item) => item.type === 'Общий'),
  });

  const handleSelectedOptionsByStarChange = (newSelectedOptions) => {
    reviewsApi
      .getFilteredReviewsByStars(account_id, selectedDate, newSelectedOptions.id, selectedPlatform)
      .then((res) => {
        setServerData(sortData(res.data));
      })
      .catch((err) => {
        console.log(err, 'catch');
      });
    setSelectedOptionByStar({ ...newSelectedOptions });
  };

  useEffect(() => {
    reviewsApi
      .getFilteredReviewsByStars(account_id, selectedDate, 0, selectedPlatform)
      .then((res) => {
        setServerData(sortData(res.data));
      })
      .catch((err) => {
        console.log(err, 'catch');
      });
  }, [account_id, selectedDate, selectedPlatform]);

  return (
    <div>
      <div className="container-wrapper">
        <p className="reviews__cloud-container-title">По звездам</p>
        <div>
          <Select
            options={npsTypeOptions}
            selectedOptions={selectedOptionByStar}
            setSelectedOptionsByStar={setSelectedOptionByStar}
            onChange={handleSelectedOptionsByStarChange}
            secondBg
          />
        </div>
      </div>
      {serverData.length === 0 ? (
        <ErrorWidget text={ERROR_TEXT_FOR_SELECT} withMargin />
      ) : (
        <div className="star-widget">
          <Circle sortedData={serverData} />
          <div className="star-widget-statistics">
            <StarsStatistics sortedData={serverData} />
          </div>
        </div>
      )}
    </div>
  );
};
