import React, { useEffect, useState } from 'react';
import { Tabs } from '../../../../../components/common/Tabs/Tabs';
import { TopTenProductsInfo } from '../../../../../components/reports/TopTenProductsInfo/TopTenProductsInfo';
import { Header } from './Header/Header';
import './TopTenWidget.scss';

const tabsData = [
  {
    id: 0,
    tabTitle: 'Самые списываемые продукты',
  },
  {
    id: 1,
    tabTitle: 'Самые часто отменяемые блюда',
  },
];

const TOP_TEN_PRODUCTS_DATA = [
  {
    banner_text: '10 списанных продуктов от общего',
    banner_text_bold: 'числа продуктов',
    left_column_title: 'Название продукта',
    middle_column_title: 'Расход',
    right_column_title: 'Сумма, ₽',
  },
  {
    banner_text: '10 самых часто отменяемых блюд от общего',
    banner_text_bold: 'количества блюд',
    left_column_title: 'Название блюда',
    middle_column_title: 'Отменено, пц.',
    right_column_title: 'Сумма, ₽',
  },
];

export const TopTenWidget = () => {
  const [data, setData] = useState({
    id: 1,
    tabTitle: 'По смайлику',
    percent: '69%',
    banner_text: '10 списанных продуктов от общего',
    banner_text_bold: 'числа продуктов',
    completed: '69',
    progress_bar_current_sum: '7 458,21',
    progress_bar_total_sum: '10 809,74',
    list: [
      { id: 1, product_name: 'Говядина мраморная', coast: '150 кг', sum: '150,00' },
      { id: 2, product_name: 'Креветки', coast: '15 кг', sum: '140,50' },
      { id: 3, product_name: 'Мидии', coast: '15 пц', sum: '123,89' },
      { id: 4, product_name: 'Анчоусы', coast: '0,957 кг', sum: '110,00' },
      { id: 5, product_name: 'Сельдерей', coast: '15 кг', sum: '98,50' },
      { id: 6, product_name: 'Паста', coast: '100 уп', sum: '70,00' },
      { id: 7, product_name: 'Картофель', coast: '15 кг', sum: '30,00' },
      { id: 8, product_name: 'Помидоры', coast: '15 кг', sum: '30,00' },
      { id: 9, product_name: 'Молоко', coast: '1000 л', sum: '30,00' },
      { id: 10, product_name: 'Яйца', coast: '100 шт', sum: '30,00' },
    ],
  });

  const [getOptions, setGetOptions] = useState({
    firstName: 'Все',
    secondName: 'Все',
  });
  const [getSecondOptions, setGetSecondOptions] = useState('Все');
  const [currentTab, setCurrentTab] = useState('0');

  useEffect(() => {
    if (currentTab == '0') {
      if (getOptions.firstName === 'Все' && getOptions.secondName === 'Все') {
        setData({
          tabTitle: 'По смайлику',
          percent: '58%',
          banner_text: '10 списанных продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '58',
          progress_bar_current_sum: '15 811,50',
          progress_bar_total_sum: '27 206,64',
          list: [
            { id: 1, product_name: 'Филе ЦБ (ингредиент)', coast: '19 кг', sum: '6 269,69' },
            {
              id: 2,
              product_name: 'Говядина вырезка (ингредиент)',
              coast: '2,544 кг',
              sum: '2 147,04',
            },
            { id: 3, product_name: 'Рис для суши (п/ф)', coast: '17,388 кг', sum: '1 345,08' },
            { id: 4, product_name: 'Лаймовый сок (п/ф)', coast: '0,456 л', sum: '994,52' },
            { id: 5, product_name: 'Имбирный кордиал (п/ф)', coast: '1,074 л', sum: '909,67' },
            { id: 6, product_name: 'Язык говяжий (ингредиент)', coast: '1,43 кг', sum: '888,53' },
            { id: 7, product_name: 'Креветка очищенная (п/ф)', coast: '1,07 кг', sum: '886,53' },
            { id: 8, product_name: 'Рис штат (ингредиент)', coast: '8 кг', sum: '829,10' },
            { id: 9, product_name: 'Угорь очищенный (п/ф)', coast: '0,794 кг', sum: '803,10' },
            {
              id: 10,
              product_name: 'Мякоть окорочка ЦБ (ингредиент)',
              coast: '2,2 кг',
              sum: '738,25',
            },
          ],
        });
      } else if (getOptions.firstName === 'Кухня' && getOptions.secondName === 'Все') {
        setData({
          tabTitle: 'По смайлику',
          percent: '68%',
          banner_text: '10 списанных продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '68',
          progress_bar_current_sum: '15 329,23',
          progress_bar_total_sum: '22 424,02',
          list: [
            { id: 1, product_name: 'Филе ЦБ (ингредиент)', coast: '19 кг', sum: '6 269,69' },
            {
              id: 2,
              product_name: 'Говядина вырезка (ингредиент)',
              coast: '2,544 кг',
              sum: '2 147,04',
            },
            { id: 3, product_name: 'Рис для суши (п/ф)', coast: '17,388 кг', sum: '1 345,08' },
            { id: 4, product_name: 'Язык говяжий (ингредиент)', coast: '1,43 кг', sum: '888,53' },
            { id: 5, product_name: 'Креветка очищенная (п/ф)', coast: '1,07 кг', sum: '886,53' },
            { id: 6, product_name: 'Рис штат (ингредиент)', coast: '8 кг', sum: '829,10' },
            { id: 7, product_name: 'Угорь очищенный (п/ф)', coast: '0,794 кг', sum: '803,10' },
            {
              id: 8,
              product_name: 'Мякоть окорочка ЦБ (ингредиент)',
              coast: '2,2 кг',
              sum: '738,25',
            },
            { id: 9, product_name: 'Хлеб чиабатта (п/ф)', coast: '2,398 кг', sum: '719,39' },
            {
              id: 10,
              product_name: 'Котлетное мясо с/м (ингредиент)',
              coast: '2,32 кг',
              sum: '702,54',
            },
          ],
        });
      } else if (getOptions.firstName === 'Бар' && getOptions.secondName === 'Все') {
        setData({
          tabTitle: 'По смайлику',
          percent: '100%',
          banner_text: '10 списанных продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '100',
          progress_bar_current_sum: '4 782,62',
          progress_bar_total_sum: '4 782,62',
          list: [
            { id: 1, product_name: 'Лаймовый сок (п/ф)', coast: '0,456 л', sum: '994,52' },
            { id: 2, product_name: 'Имбирный кордиал (п/ф)', coast: '1,074 л', sum: '909,67' },
            { id: 3, product_name: 'Лимонный сок (п/ф)', coast: '0,845 л', sum: '727,96' },
            { id: 4, product_name: 'Аквафаба (п/ф)', coast: '3,456 л', sum: '666,54' },
            { id: 5, product_name: 'Клубничный кордиал (п/ф)', coast: '0,817 л', sum: '458,55' },
            { id: 6, product_name: 'Манго кордиал (п/ф)', coast: '0,476 л', sum: '321,70' },
            { id: 7, product_name: 'CAVIAR. (блюдо)', coast: '3 пц', sum: '291,41' },
            { id: 8, product_name: 'FLAMINGO (блюдо)', coast: '2 пц', sum: '138,28' },
            { id: 9, product_name: 'Манго (настойка) (п/ф)', coast: '0,2 л', sum: '137,42' },
            { id: 10, product_name: 'KOLIBRI (блюдо)', coast: '2 пц', sum: '136,56' },
          ],
        });
      } else if (getOptions.firstName === 'Все' && getOptions.secondName === 'Порча') {
        setData({
          tabTitle: 'По смайлику',
          percent: '70%',
          banner_text: '10 списанных продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '70',
          progress_bar_current_sum: '8 244,44',
          progress_bar_total_sum: '11 743,70',
          list: [
            { id: 1, product_name: 'Рис для суши (п/ф)', coast: '17,388 кг', sum: '1 345,08' },
            { id: 2, product_name: 'Лаймовый сок (п/ф)', coast: '0,456 л', sum: '994,52' },
            { id: 3, product_name: 'Имбирный кордиал (п/ф)', coast: '1,074 л', sum: '909,67' },
            { id: 4, product_name: 'Креветка очищенная (п/ф)', coast: '0,535 кг', sum: '886,53' },
            { id: 5, product_name: 'Угорь очищенный (п/ф)', coast: '0,397 кг', sum: '803,10' },
            { id: 6, product_name: 'Лимонный сок (п/ф)', coast: '0,845 л', sum: '727,96' },
            { id: 7, product_name: 'Хлеб чиабатта (п/ф)', coast: '2,398 кг', sum: '719,39' },
            { id: 8, product_name: 'Ростбиф (п/ф)', coast: '0,582 кг', sum: '701,39' },
            { id: 9, product_name: 'Аквафаба (п/ф)', coast: '3,456 л', sum: '666,54' },
            { id: 10, product_name: 'Лосось копченный (п/ф)', coast: '0,34 кг', sum: '490,26' },
          ],
        });
      } else if (getOptions.firstName === 'Все' && getOptions.secondName === 'Питание персонала') {
        setData({
          tabTitle: 'По смайлику',
          percent: '86%',
          banner_text: '10 списанных продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '86',
          progress_bar_current_sum: '15 708,64',
          progress_bar_total_sum: '18 149,09',
          list: [
            { id: 1, product_name: 'Филе ЦБ (ингредиент)', coast: '19 кг', sum: '6 269,69' },
            { id: 2, product_name: 'Ростбиф (п/ф)', coast: '2,23 кг', sum: '2 687,29' },
            {
              id: 3,
              product_name: 'Говядина вырезка (ингредиент)',
              coast: '2,144 кг',
              sum: '2 147,04',
            },
            { id: 4, product_name: 'Язык говяжий (ингредиент)', coast: '1,43 кг', sum: '888,53' },
            { id: 5, product_name: 'Рис штат (ингредиент)', coast: '8 кг', sum: '829,10' },
            {
              id: 6,
              product_name: 'Мякоть окорочка ЦБ (ингредиент)',
              coast: '2,2 кг',
              sum: '738,25',
            },
            {
              id: 7,
              product_name: 'Котлетное мясо с/м (ингредиент)',
              coast: '2,07 кг',
              sum: '702,54',
            },
            { id: 8, product_name: 'Лук репчатый (ингредиент)', coast: '7,72 кг', sum: '594,83' },
            {
              id: 9,
              product_name: 'Макароны спагетти (ингредиент)',
              coast: '1,4 кг',
              sum: '464,26',
            },
            { id: 10, product_name: 'Сыр сулугуни (ингредиент)', coast: '0,75 кг', sum: '387,12' },
          ],
        });
      } else if (getOptions.firstName === 'Все' && getOptions.secondName === 'Бракераж') {
        setData({
          tabTitle: 'По смайлику',
          percent: '99%',
          banner_text: '10 списанных продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '99',
          progress_bar_current_sum: '6 040,27',
          progress_bar_total_sum: '6 055,70',
          list: [
            { id: 1, product_name: 'Ростбиф (п/ф)', coast: '2,23 кг', sum: '2 687,29' },
            { id: 2, product_name: 'Креветка очищенная (п/ф)', coast: '0,535 кг', sum: '886,53' },
            { id: 3, product_name: 'Угорь очищенный (п/ф)', coast: '0,397 кг', sum: '803,10' },
            { id: 4, product_name: 'Лосось копченный (п/ф)', coast: '0,34 кг', sum: '490,26' },
            { id: 5, product_name: 'Лосось гравлакс (п/ф)', coast: '0,236 кг', sum: '373,12' },
            { id: 6, product_name: 'CAVIAR. (блюдо)', coast: '3 пц', sum: '291,41' },
            { id: 7, product_name: 'FLAMINGO (блюдо)', coast: '2 пц', sum: '138,28' },
            { id: 8, product_name: 'Манго (настойка) (п/ф)', coast: '0,2 л', sum: '137,42' },
            { id: 9, product_name: 'KOLIBRI (блюдо)', coast: '2 пц', sum: '136,56' },
            { id: 10, product_name: 'Булка для бургера  (п/ф)', coast: '5 шт', sum: '96,28' },
          ],
        });
      } else if (getOptions.firstName === 'Кухня' && getOptions.secondName === 'Порча') {
        setData({
          tabTitle: 'По смайлику',
          percent: '83%',
          banner_text: '10 списанных продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '83',
          progress_bar_current_sum: '6 413,39',
          progress_bar_total_sum: '7 664,76',
          list: [
            { id: 1, product_name: 'Рис для суши (п/ф)', coast: '17,388 кг', sum: '1 345,08' },
            { id: 2, product_name: 'Креветка очищенная (п/ф)', coast: '0,535 кг', sum: '886,53' },
            { id: 3, product_name: 'Угорь очищенный (п/ф)', coast: '0,397 кг', sum: '803,10' },
            { id: 4, product_name: 'Хлеб чиабатта (п/ф)', coast: '2,398 кг', sum: '719,39' },
            { id: 5, product_name: 'Ростбиф (п/ф)', coast: '0,582 кг', sum: '701,39' },
            { id: 6, product_name: 'Лосось копченный (п/ф)', coast: '0,34 кг', sum: '490,26' },
            { id: 7, product_name: 'Томаго блин (п/ф)', coast: '40 шт', sum: '414,27' },
            {
              id: 8,
              product_name: 'Говядина вырезка (ингредиент)',
              coast: '0,4 кг',
              sum: '400,55',
            },
            { id: 9, product_name: 'Лосось гравлакс (п/ф)', coast: '0,236 кг', sum: '373,12' },
            { id: 10, product_name: 'Тунец стружка (ингредиент)', coast: '0,08 кг', sum: '279,70' },
          ],
        });
      } else if (getOptions.firstName === 'Бар' && getOptions.secondName === 'Порча') {
        setData({
          tabTitle: 'По смайлику',
          percent: '100%',
          banner_text: '10 списанных продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '100',
          progress_bar_current_sum: '4 078,94',
          progress_bar_total_sum: '4 078,94',
          list: [
            { id: 1, product_name: 'Лаймовый сок (п/ф)', coast: '0,456 л', sum: '994,52' },
            { id: 2, product_name: 'Имбирный кордиал (п/ф)', coast: '1,074 л', sum: '909,67' },
            { id: 3, product_name: 'Лимонный сок (п/ф)', coast: '0,845 л', sum: '727,96' },
            { id: 4, product_name: 'Аквафаба (п/ф)', coast: '3,456 л', sum: '666,54' },
            { id: 5, product_name: 'Клубничный кордиал (п/ф)', coast: '0,817 л', sum: '458,55' },
            { id: 6, product_name: 'Манго кордиал (п/ф)', coast: '0,476 л', sum: '321,70' },
            { id: 7, product_name: '', coast: '', sum: '' },
            { id: 8, product_name: '', coast: '', sum: '' },
            { id: 9, product_name: '', coast: '', sum: '' },
            { id: 10, product_name: '', coast: '', sum: '' },
          ],
        });
      } else if (
        getOptions.firstName === 'Кухня' &&
        getOptions.secondName === 'Питание персонала'
      ) {
        setData({
          tabTitle: 'По смайлику',
          percent: '86%',
          banner_text: '10 списанных продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '86',
          progress_bar_current_sum: '15 708,64',
          progress_bar_total_sum: '18 149,09',
          list: [
            { id: 1, product_name: 'Филе ЦБ (ингредиент)', coast: '19 кг', sum: '6 269,69' },
            { id: 2, product_name: 'Ростбиф (п/ф)', coast: '2,23 кг', sum: '2 687,29' },
            {
              id: 3,
              product_name: 'Говядина вырезка (ингредиент)',
              coast: '2,144 кг',
              sum: '2 147,04',
            },
            { id: 4, product_name: 'Язык говяжий (ингредиент)', coast: '1,43 кг', sum: '888,53' },
            { id: 5, product_name: 'Рис штат (ингредиент)', coast: '8 кг', sum: '829,10' },
            {
              id: 6,
              product_name: 'Мякоть окорочка ЦБ (ингредиент)',
              coast: '2,2 кг',
              sum: '738,25',
            },
            {
              id: 7,
              product_name: 'Котлетное мясо с/м (ингредиент)',
              coast: '2,07 кг',
              sum: '702,54',
            },
            { id: 8, product_name: 'Лук репчатый (ингредиент)', coast: '7,72 кг', sum: '594,83' },
            {
              id: 9,
              product_name: 'Макароны спагетти (ингредиент)',
              coast: '1,4 кг',
              sum: '464,26',
            },
            { id: 10, product_name: 'Сыр сулугуни (ингредиент)', coast: '0,75 кг', sum: '387,12' },
          ],
        });
      } else if (getOptions.firstName === 'Бар' && getOptions.secondName === 'Питание персонала') {
        setData({
          tabTitle: 'По смайлику',
          percent: '0%',
          banner_text: '10 списанных продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '0',
          progress_bar_current_sum: '0',
          progress_bar_total_sum: '0',
        });
      } else if (getOptions.firstName === 'Кухня' && getOptions.secondName === 'Бракераж') {
        setData({
          tabTitle: 'По смайлику',
          percent: '100%',
          banner_text: '10 списанных продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '100',
          progress_bar_current_sum: '5 352,02',
          progress_bar_total_sum: '5 352,02',
          list: [
            { id: 1, product_name: 'Ростбиф (п/ф)', coast: '2,23 кг', sum: '2 687,29' },
            { id: 2, product_name: 'Креветка очищенная (п/ф)', coast: '0,535 кг', sum: '886,53' },
            { id: 3, product_name: 'Угорь очищенный (п/ф)', coast: '0,397 кг', sum: '803,10' },
            { id: 4, product_name: 'Лосось копченный (п/ф)', coast: '0,34 кг', sum: '490,26' },
            { id: 5, product_name: 'Лосось гравлакс (п/ф)', coast: '0,236 кг', sum: '373,12' },
            { id: 6, product_name: 'Булка для бургера  (п/ф)', coast: '5 шт', sum: '96,28' },
            { id: 7, product_name: 'Орзо варенное (п/ф)', coast: '0,1 кг', sum: '15,43' },
          ],
        });
      } else if (getOptions.firstName === 'Бар' && getOptions.secondName === 'Бракераж') {
        setData({
          tabTitle: 'По смайлику',
          percent: '100%',
          banner_text: '10 портящихся продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '100',
          progress_bar_current_sum: '703,68',
          progress_bar_total_sum: '703,68',
          list: [
            { id: 1, product_name: 'CAVIAR. (блюдо)', coast: '3 пц', sum: '291,41' },
            { id: 2, product_name: 'FLAMINGO (блюдо)', coast: '2 пц', sum: '138,28' },
            { id: 3, product_name: 'Манго (настойка) (п/ф)', coast: '0,2 л', sum: '137,42' },
            { id: 4, product_name: 'KOLIBRI (блюдо)', coast: '2 пц', sum: '136,56' },
          ],
        });
      }
    } else if (currentTab == 1) {
      if (getSecondOptions == 'Все') {
        setData({
          tabTitle: 'По смайлику',
          percent: '50%',
          banner_text: '10 самых часто отменяемых блюд от общего',
          banner_text_bold: 'количества блюд',
          completed: '50',
          progress_bar_current_sum: '6 153,14',
          progress_bar_total_sum: '12 293,39',
          list: [
            { id: 1, product_name: 'Филадельфия с авокадо', coast: '7 пц', sum: '1 324,79' },
            {
              id: 2,
              product_name: 'Ролл с тигровой креветкой, лососем...',
              coast: '6 пц',
              sum: '1 033,38',
            },
            {
              id: 3,
              product_name: 'Калифорния с креветкой, авокадо и...',
              coast: '8 пц',
              sum: '851,96',
            },
            {
              id: 4,
              product_name: 'Cono Sur Bicicleta Gewurztraminer',
              coast: '6 пц',
              sum: '671,68',
            },
            {
              id: 5,
              product_name: 'Ролл с сыром Чеддер, копченым...',
              coast: '4 пц',
              sum: '516,55',
            },
            {
              id: 6,
              product_name: 'Кокосовые сырники с шоколадом...',
              coast: '5 пц',
              sum: '471,41',
            },
            {
              id: 7,
              product_name: 'Брауни с шоколадным муссом...',
              coast: '5 пц',
              sum: '445,41',
            },
            { id: 8, product_name: 'Schweppes', coast: '9 пц', sum: '426,84' },
            {
              id: 9,
              product_name: 'Стейк лосось с морковным орзо...',
              coast: '2 пц',
              sum: '411,12',
            },
            {
              id: 10,
              product_name: 'Бургер с говяжьей котлетой и соусом...',
              coast: '3 пц',
              sum: '372,84',
            },
          ],
        });
      } else if (getSecondOptions == 'Кухня') {
        setData({
          tabTitle: 'По смайлику',
          percent: '74%',
          banner_text: '10 самых часто отменяемых блюд от общего',
          banner_text_bold: 'количества блюд',
          completed: '74',
          progress_bar_current_sum: '5 745,16',
          progress_bar_total_sum: '7 739,61',
          list: [
            { id: 1, product_name: 'Филадельфия с авокадо', coast: '7 пц', sum: '1 324,79' },
            {
              id: 2,
              product_name: 'Ролл с тигровой креветкой, лососем...',
              coast: '6 пц',
              sum: '1 033,38',
            },
            {
              id: 3,
              product_name: 'Калифорния с креветкой, авокадо...',
              coast: '8 пц',
              sum: '851,96',
            },
            {
              id: 4,
              product_name: 'Ролл с сыром Чеддер, копченым...',
              coast: '4 пц',
              sum: '516,55',
            },
            {
              id: 5,
              product_name: 'Кокосовые сырники с шоколадом...',
              coast: '5 пц',
              sum: '471,41',
            },
            {
              id: 6,
              product_name: 'Брауни с шоколадным муссом...',
              coast: '5 пц',
              sum: '445,41',
            },
            {
              id: 7,
              product_name: 'Стейк лосось с морковным орзо...',
              coast: '2 пц',
              sum: '411,12',
            },
            {
              id: 8,
              product_name: 'Бургер с говяжьей котлетой и соусом...',
              coast: '3 пц',
              sum: '372,84',
            },
            {
              id: 9,
              product_name: 'Тар-тар из лосося с кунжутным...',
              coast: '2 пц',
              sum: '317,70',
            },
            {
              id: 10,
              product_name: 'Бонито с копченным лососем и...',
              coast: '2 пц',
              sum: '287,41',
            },
          ],
        });
      } else if (getSecondOptions == 'Бар') {
        setData({
          tabTitle: 'По смайлику',
          percent: '61%',
          banner_text: '10 самых часто отменяемых блюд от общего',
          banner_text_bold: 'количества блюд',
          completed: '61',
          progress_bar_current_sum: '2 495,31',
          progress_bar_total_sum: '4 066,94',
          list: [
            {
              id: 1,
              product_name: 'Cono Sur Bicicleta Gewurztraminer',
              coast: '6 пц',
              sum: '671,68',
            },
            {
              id: 2,
              product_name: 'Schweppes',
              coast: '9 пц',
              sum: '426,84',
            },
            {
              id: 3,
              product_name: 'Lambrusco Rosso',
              coast: '6 пц',
              sum: '363,98',
            },
            {
              id: 4,
              product_name: 'Грейпрфут фреш',
              coast: '2 пц',
              sum: '251,42',
            },
            {
              id: 5,
              product_name: 'Campari Spritz',
              coast: '1 пц',
              sum: '197,99',
            },
            {
              id: 6,
              product_name: 'Раф',
              coast: '4 пц',
              sum: '171,42',
            },
            {
              id: 7,
              product_name: 'Love',
              coast: '2 пц',
              sum: '143,99',
            },
            { id: 8, product_name: "Devil's Rock Pinot Noir", coast: '2 пц', sum: '134,85' },
            {
              id: 9,
              product_name: 'FLAMINGO',
              coast: '2 пц',
              sum: '133,14',
            },
            {
              id: 10,
              product_name: 'KOLIBRI',
              coast: '2 пц',
              sum: '126,85',
            },
          ],
        });
      } else if (getSecondOptions == 'Кальяны') {
        setData({
          tabTitle: 'По смайлику',
          percent: '100%',
          banner_text: '10 самых часто отменяемых блюд от общего',
          banner_text_bold: 'количества блюд',
          completed: '100',
          progress_bar_current_sum: '320,55',
          progress_bar_total_sum: '320,55',
          list: [
            { id: 1, product_name: 'Неоклассический кальян', coast: '1 пц', sum: '160,56' },
            {
              id: 2,
              product_name: 'Классический кальян',
              coast: '1 пц',
              sum: '159,99',
            },
          ],
        });
      }
    }
  }, [getOptions, currentTab, getSecondOptions]);
  return (
    <>
      <Header
        setGetOptions={setGetOptions}
        currentTab={currentTab}
        setGetSecondOptions={setGetSecondOptions}
      />
      <Tabs tabs={tabsData} currentTab={currentTab} setCurrentTab={setCurrentTab}>
        <TopTenProductsInfo data={data} topTenProductsData={TOP_TEN_PRODUCTS_DATA[currentTab]} />
      </Tabs>
    </>
  );
};
