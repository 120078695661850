import './TableAbout.scss';

export const TableAbout = ({ list, item }) => {
  return (
    <div>
      <div className="table-about__container">
        <div className="table-about__name">Блюдо</div>
        <div className="table-about__coast">Кол-во</div>
        <div className="table-about__sum">Сумма, р.</div>
      </div>
      <div className="table-about__wrapper">
        {list.map((item) => (
          <div className="table-about__info">
            <div className="table-about__info-name">{item.product_name}</div>
            <div className="table-about__info-coast">{item.coast}</div>
            <div className="table-about__info-sum">{item.sum}</div>
          </div>
        ))}
      </div>
      <div className="table-about__result">
        <div className='table-about__result-wrapper'>
          <div className="table-about__result-text">Итого</div>
          <div className="table-about__result-text">{item.sum} </div>
        </div>
        <div className='table-about__result-wrapper'>
          <div className="table-about__result-discount">Сумма скидки</div>
          <div className="table-about__result-text">0,00</div>
        </div>
      </div>
    </div>
  );
};
