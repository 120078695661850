import React from 'react';
import './TwoCardsItem.scss';
import { HoverPercent } from '../../../../pages/reportsPages/dayResult/components/ThreeCardsTop/HoverPercent/HoverPercent';
import { Hover } from '../../../common/Hover/Hover';

const twoCardsDataHover = [
  {
    percent: '7,05%',
    sum: '12 984,57',
  },
  {
    percent: '3,23%',
    sum: '12 500,43',
  },
];

export const TwoCardsItem = ({ item, index }) => {
  return (
    <div className="two-cards__wrapper">
      <div className="two-cards__title">{item.title}</div>
      <div className="two-cards__middle">
        <div className="two-cards__middle-percent">{item.percent}</div>
        <Hover
          index={index}
          containerClassName={`two-cards__middle-percent__diff ${item.color}`}
          hoverContainerClassName={'two-cards__middle-percent__hover'}
          title={item.percentDiff}
        >
          <HoverPercent dataHoverPercent={twoCardsDataHover} hoveredIndex={index} />
        </Hover>
      </div>
      <div className="two-cards__sum">
        Сумма <span className="two-cards__sum__bold">{item.sum} ₽</span>
      </div>
    </div>
  );
};
