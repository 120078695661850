import React, { useState, useEffect } from 'react';
import { StaffSettings } from './StaffSettings/StaffSettings';
import useSetBodyScroll from '../../hooks/useSetBodyScroll';
import { authApi } from '../../api/authApi';
import { settingsApi } from '../../api/settingsApi';
import { Loader } from '../../components/common/Loader/Loader';
import { PageHeader } from '../../components/common/PageHeader/PageHeader';
import { TabsSettings } from '../../components/settings/tabs/tabs';

export const SettingsUserInfo = () => {
  const [account_id, setAccount_id] = useState('');
  const [companyData, setCompanyData] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authApi.getAccountId();
        const accountId = response.data[0].id;
        setAccount_id(accountId);
        const [companyRes] = await Promise.all([
          settingsApi.getCompany(accountId),
          settingsApi.getAllPoints(accountId),
        ]);
        setCompanyData(companyRes.data);
      } catch (err) {
        console.log(err, 'catch');
      }
    };

    fetchData();
  }, []);

  const setBodyScroll = useSetBodyScroll();
  useEffect(() => {
    setBodyScroll(true);
    return () => {
      setBodyScroll(false);
    };
  }, [setBodyScroll]);

  return (
    <div className="settings-container">
      <PageHeader title="Настройки" />
      <TabsSettings tabIndex={1}>
        {companyData ? <StaffSettings companyData={companyData} /> : <Loader />}
      </TabsSettings>
    </div>
  );
};
