import React, { useCallback, useEffect, useRef, useState } from 'react';
import './periodsComparison.scss';
import { DATA } from '../tableNumbersConst';
import cn from 'classnames';
import { SettingsIcon } from '../../../../icons/like-for-like/settings-icon';
import { ParametersModal } from './parametersModal/ParameterModa';
import { PARAMETRS_DATA } from '../const';
import HoverTitle from './hoverTitle/HoverTitle';

export const PeriodComparison = ({
  gridColumns,
  columnsName,
  Component,
  propsForCreateItem,
  customStyle,
  getItemsApi,
  headerStyle,
  isFoodcost,
  isWriteOff,
  isHideModifiers,
  setIsHideModifiers,
  isMenuOpen,
  ...props
}) => {
  const className = 'bc-fused-table__item';
  const style = { gridTemplateColumns: gridColumns };
  const [parametrsList, setParametrsList] = useState(PARAMETRS_DATA);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnterTitle = (index) => {
    setIsHovered(true);
  };

  const handleMouseLeaveTitle = () => {
    setIsHovered(false);
  };

  const [items, setItems] = useState(DATA);
  const [isSettingsModal, isSetSettingsModal] = useState(false);

  useEffect(() => {
    if (!isSettingsModal) {
      const sortedItems = [...DATA].sort((a, b) => {
        const orderA = parametrsList.find((param) => param.text === a.name)?.order || 0;
        const orderB = parametrsList.find((param) => param.text === b.name)?.order || 0;
        return orderA - orderB;
      });

      const filteredItems = sortedItems.filter((item, index) => parametrsList[index]?.isChecked);
      setItems(filteredItems);
    }
  }, [isSettingsModal, parametrsList]);

  const onCloseModal = useCallback(() => {
    isSetSettingsModal(false);
  }, []);

  const onOpenModal = useCallback(() => {
    isSetSettingsModal(true);
  }, []);

  const tableRef = useRef();

  const handleScroll = () => {
    const tableContainer = tableRef.current;
    const tableHeader = tableContainer.querySelector('.table__header');

    if (tableContainer && tableHeader) {
      const rect = tableContainer.getBoundingClientRect();
      const isScrolled = rect.top < 0;

      if (isScrolled) {
        tableHeader.classList.add('sticky');
      } else {
        tableHeader.classList.remove('sticky');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <div className="table__container" ref={tableRef}>
        <div
          className="table__selects"
          style={{ justifyContent: isWriteOff ? 'right' : 'space-between' }}
        >
          <div className="table__selects-select"></div>
        </div>
        <div className="table__header" style={style}>
          {columnsName.map((columnName, index) => (
            <div className="table__column-title" key={index}>
              {columnName === 'Сетевой рост' ? (
                <div className={cn('table__header-group__container', {})}>
                  <div className="table__header-group__header">
                    <div className="table__header-group__header-title">
                      <HoverTitle isNetwork>Сетевой рост</HoverTitle>
                    </div>
                    <div className="table__header-group__header-stat">10 из 10</div>
                  </div>
                  <div className="table__header-group__wrapper">
                    <div className="table__header-group__title">Динамика (абс. знч.)</div>
                    <div className="table__header-group__title">Динамика, %</div>
                  </div>
                </div>
              ) : columnName === 'Сопоставимый рост' ? (
                <div className="table__header-group__container">
                  <div className="table__header-group__header">
                    <div className="table__header-group__header-title">
                      <HoverTitle>Сопостовимый рост</HoverTitle>
                    </div>
                    <div className="table__header-group__header-stat">4 из 10</div>
                  </div>
                  <div className="table__header-group__wrapper">
                    <div className="table__header-group__title">Динамика (абс. знч.)</div>
                    <div className="table__header-group__title">Динамика, %</div>
                  </div>
                </div>
              ) : columnName === 'Показатели' ? (
                <>
                  {isSettingsModal && (
                    <ParametersModal
                      isOpen={isSettingsModal}
                      onClose={onCloseModal}
                      parametrsList={parametrsList}
                      setParametrsList={setParametrsList}
                    />
                  )}
                  <div className="table__header-settings">
                    <div className="table__header-settings__title">Показатели</div>
                    <div
                      onClick={onOpenModal}
                      className="table__header-settings__icon"
                      onMouseEnter={() => handleMouseEnterTitle(index)}
                      onMouseLeave={handleMouseLeaveTitle}
                    >
                      {isHovered ? (
                        <div className="table__header-settings__hover">Настроить показатели</div>
                      ) : null}
                      <SettingsIcon fill={isHovered ? '#67A8F4' : '#C4C4C2'} />
                    </div>
                  </div>
                </>
              ) : (
                <div className="table__header-group__title"> {columnName}</div>
              )}
            </div>
          ))}
        </div>
        <div className="table__list-wrapper">
          {items.map((item) => (
            <Component
              item={item}
              className={className}
              style={{ ...style, ...customStyle }}
              {...props}
              key={item.id}
              isFoodcost={isFoodcost}
              isHideModifiers={isHideModifiers}
            />
          ))}
        </div>
      </div>
    </>
  );
};
