import React, { useEffect, useState } from 'react';
import { TopTenProductsInfo } from '../../../../components/reports/TopTenProductsInfo/TopTenProductsInfo';
import { Header } from './Header/Header';
import './TopTenWidget.scss';

const TOP_TEN_PRODUCTS_DATA = {
  banner_text: 'продаж 10 блюд от общей',
  banner_text_bold: 'выручки',
  banner_title: 'Топ 10 блюд по выручке',
  left_column_title: 'Название блюда',
  middle_column_title: 'Количество',
  right_column_title: 'Сумма, ₽',
};

export const TopTenWidget = () => {
  const [data, setData] = useState({
    tabTitle: 'По смайлику',
    percent: '32%',
    banner_text: '10 портящихся продуктов от общего',
    banner_text_bold: 'числа продуктов',
    completed: '32',
    progress_bar_current_sum: '757 033,50',
    progress_bar_total_sum: '2 311 431,50',
    list: [
      { id: 1, product_name: 'Классический кальян', coast: '182 пц', sum: '170 648,60' },
      { id: 2, product_name: 'Неоклассический кальян', coast: '107 пц', sum: '128 393,60' },
      {
        id: 3,
        product_name: 'Ролл с тигровой креветкой, лососем...',
        coast: '155 пц',
        sum: '82 795,90',
      },
      { id: 4, product_name: 'Филадельфия с авокадо', coast: '145 пц', sum: '71 456,40' },
      {
        id: 5,
        product_name: 'Стейк Рибай с трюфельным пюре и...',
        coast: '90 пц',
        sum: '62 854,00',
      },
      { id: 6, product_name: 'CAVIAR.', coast: '147 пц', sum: '60 339,80' },
      { id: 7, product_name: 'MASQUERADE | Без маски', coast: '185 пц', sum: '52 854,50' },
      {
        id: 8,
        product_name: 'Салат с креветкой и апельсиновым...',
        coast: '96 пц',
        sum: '45 054,90',
      },
      { id: 9, product_name: 'KOLIBRI', coast: '124 пц', sum: '43 197,80' },
      {
        id: 10,
        product_name: 'Ролл с сыром Чеддер, копченым...',
        coast: '92 пц',
        sum: '39 438,00',
      },
    ],
  });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Группа');

  useEffect(() => {
    switch (selectedOption) {
      case 'Все':
        setData({
          tabTitle: 'По смайлику',
          percent: '32%',
          banner_text: '10 портящихся продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '32',
          progress_bar_current_sum: '757 033,50',
          progress_bar_total_sum: '2 311 431,50',
          list: [
            { id: 1, product_name: 'Классический кальян', coast: '182 пц', sum: '170 648,60' },
            { id: 2, product_name: 'Неоклассический кальян', coast: '107 пц', sum: '128 393,60' },
            {
              id: 3,
              product_name: 'Ролл с тигровой креветкой, лососем и...',
              coast: '155 пц',
              sum: '82 795,90',
            },
            { id: 4, product_name: 'Филадельфия с авокадо', coast: '145 пц', sum: '71 456,40' },
            {
              id: 5,
              product_name: 'Стейк Рибай с трюфельным пюре и...',
              coast: '90 пц',
              sum: '62 854,00',
            },
            { id: 6, product_name: 'CAVIAR.', coast: '147 пц', sum: '60 339,80' },
            { id: 7, product_name: 'MASQUERADE | Без маски', coast: '185 пц', sum: '52 854,50' },
            {
              id: 8,
              product_name: 'Салат с креветкой и апельсиновым...',
              coast: '96 пц',
              sum: '45 054,90',
            },
            { id: 9, product_name: 'KOLIBRI', coast: '124 пц', sum: '43 197,80' },
            {
              id: 10,
              product_name: 'Ролл с сыром Чеддер, копченым лососе...',
              coast: '92 пц',
              sum: '39 438,00',
            },
          ],
        });
        break;
      case 'Кухня':
        setData({
          tabTitle: 'По смайлику',
          percent: '49%',
          banner_text: '10 портящихся продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '49',
          progress_bar_current_sum: '475 280,50',
          progress_bar_total_sum: '956 763,00',
          list: [
            {
              id: 1,
              product_name: 'Ролл с тигровой креветкой, лососем и...',
              coast: '155 пц',
              sum: '82 795,80',
            },
            { id: 2, product_name: 'Филадельфия с авокадо', coast: '145 пц', sum: '71 456,40' },
            {
              id: 3,
              product_name: 'Стейк Рибай с трюфельным пюре и...',
              coast: '90 пц',
              sum: '62 854,00',
            },
            {
              id: 4,
              product_name: 'Салат с креветкой и апельсиновым...',
              coast: '96 пц',
              sum: '45 054,90',
            },
            {
              id: 5,
              product_name: 'Ролл с сыром Чеддер, копченым лососе...',
              coast: '92 пц',
              sum: '39 438,00',
            },
            {
              id: 6,
              product_name: 'Карбонара с муссом пармезан',
              coast: '82 пц',
              sum: '37 483,80',
            },
            {
              id: 7,
              product_name: 'Ребра BBQ с трюфельным пюре',
              coast: '59 пц',
              sum: '36 455,30',
            },
            {
              id: 8,
              product_name: 'Калифорния с креветкой, авокадо и...',
              coast: '75 пц',
              sum: '34 241,10',
            },
            {
              id: 9,
              product_name: 'Бонито с копченным лососем и огурцом',
              coast: '78 пц',
              sum: '33 392,60',
            },
            {
              id: 10,
              product_name: 'Ролл с угрем унаги, авокадо и огурцом',
              coast: '64 пц',
              sum: '32 108,30',
            },
          ],
        });
        break;
      case 'Бар':
        setData({
          tabTitle: 'По смайлику',
          percent: '38%',
          banner_text: '10 портящихся продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '38',
          progress_bar_current_sum: '294 793,80',
          progress_bar_total_sum: '773 131,90',
          list: [
            { id: 1, product_name: 'CAVIAR.', coast: '147 пц', sum: '60 339,80' },
            { id: 2, product_name: 'KOLIBRI', coast: '124 пц', sum: '43 197,80' },
            { id: 3, product_name: 'FLAMINGO', coast: '85 пц', sum: '34 284,00' },
            { id: 4, product_name: 'Aperol Spritz', coast: '58 пц', sum: '33 141,20' },
            {
              id: 5,
              product_name: 'I Castelli Romeo e Giulietta Prosecco',
              coast: '72 пц',
              sum: '24 341,60',
            },
            {
              id: 6,
              product_name: 'SPOT 74²',
              coast: '64 пц',
              sum: '20 799,00',
            },
            { id: 7, product_name: 'Ballantine’s', coast: '90 пц', sum: '20 570,40' },
            { id: 8, product_name: 'Jim Beam', coast: '79 пц', sum: '19 799,00' },
            {
              id: 9,
              product_name: 'Лимонад от Шефа',
              coast: '81 пц',
              sum: '19 284,70',
            },
            { id: 10, product_name: 'Coca-Cola', coast: '226 пц', sum: '19 036,20' },
          ],
        });
        break;
      case 'Кальян':
        setData({
          tabTitle: 'По смайлику',
          percent: '99%',
          banner_text: '10 портящихся продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '99',
          progress_bar_current_sum: '358 553,50',
          progress_bar_total_sum: '360 067,70',
          list: [
            { id: 1, product_name: 'Классический кальян', coast: '182 пц', sum: '170 648,60' },
            { id: 2, product_name: 'Неоклассический кальян', coast: '107 пц', sum: '128 393,60' },
            {
              id: 3,
              product_name: 'ПИНА КОЛАДА С ПРЯНЫМ БАНАНОМ',
              coast: '18 пц',
              sum: '26 227,30',
            },
            { id: 4, product_name: 'ПЛЯЖ БУХТЫ ХАНАУМА', coast: '7 пц', sum: '11 199,40' },
            { id: 5, product_name: 'ТИБЕТСКИЕ ЯГОДЫ', coast: '6 пц', sum: '9 599,50' },
            { id: 6, product_name: 'Дегустация', coast: '2 пц', sum: '3 999,80' },
            { id: 7, product_name: 'НЕФТЬ', coast: '2 пц', sum: '2 914,10' },
            { id: 8, product_name: 'ТРОПИКВОЛКЕР', coast: '1 пц', sum: '1 914,20' },
            { id: 9, product_name: 'Кальян для Никсона', coast: '5 пц', sum: '1 828,50' },
            { id: 10, product_name: 'Кальян для Саши', coast: '6 пц', sum: '1 828,50' },
          ],
        });
        break;
      case 'Чайная карта':
        setData({
          tabTitle: 'По смайлику',
          percent: '92%',
          banner_text: '10 портящихся продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '92',
          progress_bar_current_sum: '45 466,30',
          progress_bar_total_sum: '49 323,30',
          list: [
            { id: 1, product_name: 'Те Гуань Инь', coast: '28 пц', sum: '9 256,70' },
            { id: 2, product_name: 'Манго Улун', coast: '19 пц', sum: '5 428,30' },
            { id: 3, product_name: 'Лунцзин «Колодец Дракона»', coast: '15 пц', sum: '5 142,60' },
            {
              id: 4,
              product_name: 'Лао Ча Тоу Юньнань Линцан 2012 г',
              coast: '15 пц',
              sum: '4 525,50',
            },
            { id: 5, product_name: 'Да Хун Пао', coast: '13 пц', sum: '4 085,50' },
            { id: 6, product_name: 'Персик Улун', coast: '14 пц', sum: '3 999,80' },
            { id: 7, product_name: 'Сибирский Иван-Чай', coast: '17 пц', sum: '3 885,50' },
            { id: 8, product_name: 'Эрл Грей', coast: '12 пц', sum: '3 085,60' },
            { id: 9, product_name: 'Молочный Улун', coast: '12 пц', sum: '3 085,60' },
            { id: 10, product_name: 'Люй Я Бао', coast: '10 пц', sum: '2 971,30' },
          ],
        });
        break;
      case 'STUFF ONLY':
        setData({
          tabTitle: 'По смайлику',
          percent: '96%',
          banner_text: '10 портящихся продуктов от общего',
          banner_text_bold: 'числа продуктов',
          completed: '96',
          progress_bar_current_sum: '4 479,80',
          progress_bar_total_sum: '4 679,80',
          list: [
            {
              id: 1,
              product_name: 'Филадельфия бекон для завтрака STUFF',
              coast: '6 пц',
              sum: '857,10',
            },
            { id: 2, product_name: 'Капучино стаф', coast: '29 пц', sum: '714,30' },
            { id: 3, product_name: 'Эспрессо стаф', coast: '28 пц', sum: '617,10' },
            { id: 4, product_name: 'Латте стаф', coast: '20 пц', sum: '582,80' },
            {
              id: 5,
              product_name: 'Блинчики с курицей и грибами STUFF',
              coast: '3 пц',
              sum: '514,30',
            },
            { id: 6, product_name: 'Скрэмбл с грибами STUFF', coast: '3 пц', sum: '285,70' },
            { id: 7, product_name: 'Сиропчик', coast: '15 пц', sum: '280,00' },
            {
              id: 8,
              product_name: 'Клаб-сэндвич с курицей и овощами...',
              coast: '1 пц',
              sum: '228,60',
            },
            { id: 9, product_name: 'Американо стаф', coast: '11 пц', sum: '228,60' },
            { id: 10, product_name: 'Завтрак по-домашнему STUFF', coast: '1 пц', sum: '171,40' },
          ],
        });
        break;
    }
  }, [selectedOption]);
  const [topTenBytExpenseSelect, setTopTenBYExpenseSelect] = useState([]);
  const [totalTopTenByExpenses, setTotalTopTenByExpenses] = useState();

  return (
    <div>
      <Header
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
      <TopTenProductsInfo
        data={data}
        topTenProductsData={TOP_TEN_PRODUCTS_DATA}
        selectedOption={selectedOption}
      />
    </div>
  );
};
