import { MultiSelectDropdown } from '../../../../../../components/common/MultiSelect/MultiSelect';
import { Select } from '../../../../../../components/common/Select/Select';
import { ThreeDotsIcon } from '../../../../../../icons/three-dots-icon';
import { TOP_TEN_PRODUCTS_OPTION } from '../../../const';
import './Header.scss';

export const Header = ({
  reasons,
  store,
  handleSelectedOptionsDynamicForReasons,
  handleSelectedOptionsDynamicForStore,
}) => {
  return (
    <div>
      <div className="header-container">
        <div className="header-title">Динамика фудкоста</div>
        <div className="header-selects">
          <MultiSelectDropdown
            options={reasons}
            onChange={handleSelectedOptionsDynamicForReasons}
            title="Группы"
          />
          <div>
            <MultiSelectDropdown
              options={store}
              onChange={handleSelectedOptionsDynamicForStore}
              secondBg
              isOneRow
              title="Причины"
            />
          </div>
        </div>
      </div>
      <div className="header-info">
        <ul>
          <li>Фудкост</li>
          <li>Фудкост со списанием и акциями</li>
        </ul>
      </div>
    </div>
  );
};
