import React from 'react';
export const NPS_TYPES = [
  {
    id: 1,
    question: (
      <>
        Оцените <strong>Бар</strong>{' '}
      </>
    ),
    rating: 0,
  },
  {
    id: 2,
    question: (
      <>
        Оцените <strong>Сервис</strong>
      </>
    ),
    rating: 0,
  },
  {
    id: 3,
    question: (
      <>
        Оцените <strong>Кальяны</strong>
      </>
    ),
    rating: 0,
  },
  {
    id: 4,
    question: (
      <>
        Оцените <strong>Кухню</strong>
      </>
    ),
    rating: 0,
  },
  {
    id: 5,
    question: (
      <>
        Оцените <strong>Интерьер</strong>
      </>
    ),
    rating: 0,
  },
  {
    id: 6,
    question: (
      <>
        Оцените <strong>Атмосферу</strong>
      </>
    ),
    rating: 0,
  },
  {
    id: 7,
    question: (
      <>
        Оцените <strong>Время ожидания</strong>
      </>
    ),
    rating: 0,
  },
  {
    id: 8,
    question: (
      <>
        Оцените <strong>Музыку</strong>
      </>
    ),
    rating: 0,
  },
  {
    id: 9,
    question: (
      <>
        Оцените <strong>Локацию</strong>
      </>
    ),
    rating: 0,
  },
];

export const marks = [
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
  },
  {
    value: 6,
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
  {
    value: 9,
  },
];

export const sliderStyles = {
  width: 290,
  height: 10,
  '& .MuiSlider-thumb': {
    width: 13,
    height: 13,
    backgroundColor: '#1E1E1E',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
    '&:hover': {
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
    },
  },
  '& .MuiSlider-valueLabel': {
    color: 'black',
    backgroundColor: 'transparent',
    border: 'none',
    marginTop: '10px',
  },
  '& .MuiSlider-root': {
    padding: '0px !important',
    backgroundColor: '#FFFFFF ',
  },
  '& .MuiSlider-rail': {
    color: 'white',
    opacity: '1',
  },
  '& .MuiSlider-mark': {
    color: '#dedbdb',
  },
  '& .MuiSlider-markActive': {
    display: 'none',
  },
  '& .MuiSlider-track ': {
    border: 'none',
  },

  // "& span.css-vfbm7x-MuiSlider-root": {
  // padding: "0px ",
  // backgroundColor: "#FFFFFF ",
  // },
  // "& span.css-6cwnna-MuiSlider-mark": {
  //   backgroundColor: "initial ",
  // },
  // "& span.css-1tfve6q-MuiSlider-mark": {
  //   backgroundColor: "#dedbdb ",
  // },
};

export const EMOJIS = [
  { index: 1, emoji: '1f629' },
  { index: 2, emoji: '1f641' },
  { index: 3, emoji: '1f610' },
  { index: 4, emoji: '1f60a' },
  { index: 5, emoji: '1f929' },
];
