import React from "react";

export const YandexIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18.2995 21.5H15.067V5.50055H13.6236C10.9808 5.50055 9.59835 6.82198 9.59835 8.79396C9.59835 11.0302 10.5538 12.067 12.5258 13.3885L14.1522 14.4863L9.47637 21.5H6L10.2082 15.2385C7.78901 13.5104 6.42692 11.8231 6.42692 8.97692C6.42692 5.41923 8.90714 3 13.6033 3H18.2791L18.2995 21.5Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};
