import { PageHeader } from '../../components/common/PageHeader/PageHeader';
import { TabsSettings } from '../../components/settings/tabs/tabs';
import './SettingsReports.scss';
import { Tabs } from './Tabs/Tabs';

export const SettingsReports = () => {
  return (
    <div className="settings-container">
      <PageHeader title="Настройки" />
      <TabsSettings tabIndex={3}>
        <Tabs />
      </TabsSettings>
    </div>
  );
};
