import { CloseIcon } from '../../../../../icons/close-icon';
import { useState } from 'react';
import { Modal } from '../../../../../components/common/ModalWindow/ModalWindow';
import './ParametersModal.scss';
import { Button } from '../../../../../components/common/Button/Button';
import { CheckBox } from '../../../../../components/common/CheckBox/CheckBox';
import { DragIcon } from '../../../../../icons/like-for-like/drag-icon';

export const ParametersModal = ({ isOpen, onClose, parametrsList, setParametrsList }) => {
  const [selectAll, setSelectAll] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const dragStartHandler = (e, card) => {
    setCurrentItem(card);
  };

  const dragLeaveHandler = (e) => {
    e.target.style.boxShadow = 'none'
    e.target.style.borderTop = 'none'
  };

  const dragEndHandler = (e) => {
    e.target.style.boxShadow = 'none'
    e.target.style.borderTop = 'none'
    if (e.target.className == 'parametr-modal__list-wrapper') {
      e.target.style.borderTop = 'none'
    }
    e.target.style.borderTop = 'none'
  };

  const dragOverHandler = (e) => {
    e.preventDefault();
    if (e.target.className == 'parametr-modal__list-wrapper') {
      e.target.style.borderTop = '1px solid black'
    }
  };

  const dropHandler = (e, card) => {
    e.preventDefault();
    e.target.style.borderTop = 'none'
    setParametrsList(
      parametrsList.map((c) => {
        if (c.id === card.id) {
          return { ...c, order: currentItem.order };
        }
        if (c.id === currentItem.id) {
          return { ...c, order: card.order };
        }
        return c;
      })
    );
    e.target.style.borderTop = 'none'
  };

  const handleCheckBoxChange = (item) => {
    setParametrsList(
      parametrsList.map((c) => {
        if (c.id === item.id) {
          return { ...c, isChecked: !c.isChecked };
        }
        return c;
      })
    );
  };

  const sortItems = (a, b) => {
    if (a.order > b.order) {
      return 1;
    } else {
      return -1;
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setParametrsList(
      parametrsList.map((item) => {
        return { ...item, isChecked: !selectAll };
      })
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} width={420}>
      <div className="parametr-modal__container">
        <div className="parametr-modal__header">
          <div className="parametr-modal__header-title">Настроить показатели</div>
          <div onClick={onClose} className="parametr-modal__header-icon">
            <CloseIcon />
          </div>
        </div>
        <div className="parametr-modal__desc">
          Выберите показатели, которые хотите отслеживать.
          <br />
          Вы также можете поменять строки местами
        </div>
        <div className="parametr-modal__list-wrapper">
          <div className="parametr-modal__list">
            <CheckBox checked={selectAll} onChange={handleSelectAll} />
            <div className="parametr-modal__item" style={{ cursor: 'pointer' }}>
              Выбрать все
            </div>
          </div>
        </div>
        <div className='parametr-modal__group'>
        {parametrsList.sort(sortItems).map((item) => (
          <div
            className="parametr-modal__list-wrapper"
            onDragStart={(e) => dragStartHandler(e, item)}
            onDragLeave={(e) => dragLeaveHandler(e)}
            onDragEnd={(e) => dragEndHandler(e)}
            onDragOver={(e) => dragOverHandler(e, item)}
            onDrop={(e) => dropHandler(e, item)}
            draggable={true}
          >
            <div key={item.text} className="parametr-modal__list">
              <CheckBox checked={item.isChecked} onChange={() => handleCheckBoxChange(item)} />
              <div className="parametr-modal__item">{item.text}</div>
            </div>
            <DragIcon />
          </div>
        ))}
          </div>
        <Button styleType="primary" onClick={onClose}>
          Сохранить
        </Button>
      </div>
    </Modal>
  );
};
