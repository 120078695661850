import React from 'react';

export const UploadIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8333 9.83309C14.2015 9.83309 14.5 10.1316 14.5 10.4998V13.1664C14.5 13.9028 13.9031 14.4998 13.1667 14.4998H3.83333C3.09695 14.4998 2.5 13.9028 2.5 13.1664V10.4998C2.5 10.1316 2.79848 9.83309 3.16667 9.83309C3.53485 9.83309 3.83333 10.1316 3.83333 10.4998V13.1664H13.1667V10.4998C13.1667 10.1316 13.4651 9.83309 13.8333 9.83309ZM9.08927 2.14935L11.7998 4.85993C12.0602 5.12028 12.0602 5.54239 11.7998 5.80274C11.5395 6.06308 11.1174 6.06308 10.857 5.80274L9.16667 4.11238V10.4998C9.16667 10.868 8.8682 11.1664 8.5 11.1664C8.1318 11.1664 7.83333 10.868 7.83333 10.4998V4.11238L6.14298 5.80274C5.88263 6.06308 5.46052 6.06308 5.20017 5.80274C4.93982 5.54239 4.93982 5.12028 5.20017 4.85993L7.91073 2.14935C8.2362 1.82392 8.7638 1.82392 9.08927 2.14935Z"
        fill="#67A8F4"
      />
    </svg>
  );
};
