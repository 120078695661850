import { useState } from 'react';
import { PeriodsComparisonTable } from './tabsTable/periodsComparisonTable/PeriodsComparisonTable';
import { RestaurantComparisonTable } from './tabsTable/restaurantComparisonTable/RestaurantComparisonTable';
import './likeForLike.scss';

export const LikeForLike = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTab1 = () => {
    setActiveTab('tab1');
  };
  const handleTab2 = () => {
    setActiveTab('tab2');
  };
  return (
    <div className="like-for-like">
      <div className="like-for-like__head">
        <div className="like-for-like__head-title"> Like for like</div>
        <div className="like-for-like__head-left">
          <ul className="like-for-like__nav">
            <li className={activeTab === 'tab1' ? 'active' : ''} onClick={handleTab1}>
              Сравнение периодов
            </li>
            <li className={activeTab === 'tab2' ? 'active' : ''} onClick={handleTab2}>
              Сравнение заведений
            </li>
          </ul>
        </div>
      </div>
      <div></div>
      <div className="outlet">
        {activeTab === 'tab1' && <PeriodsComparisonTable />}
        {activeTab === 'tab2' && <RestaurantComparisonTable />}
      </div>
    </div>
  );
};
