import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import './Switch.scss';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, isChecked }) => ({
  width: 45,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1,
    marginTop: 3,
    width: 18,
    height: 18,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(25px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: isChecked
          ? '#67A8F4'
          : theme.palette.mode === 'dark'
          ? '#F6F6F6'
          : 'rgba(103, 168, 244, 1)',
        border: 0,
        opacity: 1, // Set opacity to 1
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 2.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 2.7 : 2.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: isChecked ? '#67A8F4' : theme.palette.mode === 'light' ? '#F6F6F6' : '#39393D',
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    opacity: 1,
  },
}));

export default function CustomizedSwitches({ setIsChecked, isChecked, handleClose }) {
  const handleChange = (event) => {
    setIsChecked(event.target.checked);
    // setTimeout(() => {
    //   handleClose();
    // }, 350);
  };

  return (
    <div className="switch-nps-container">
      <p className="switch-nps-text">Оценка по разделам</p>
      <FormGroup>
        <FormControlLabel
          control={
            <IOSSwitch
              sx={{ m: 1 }}
              checked={isChecked}
              onChange={handleChange}
              isChecked={isChecked}
            />
          }
          sx={{ marginRight: 0 }} // Add this line to remove margin-right
        />
      </FormGroup>
    </div>
  );
}
