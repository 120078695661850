import React from 'react';

export const ReportIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17 2C18.6569 2 20 3.34315 20 5V21C20 21.3844 19.7797 21.7348 19.4332 21.9013C19.0867 22.0678 18.6755 22.021 18.3753 21.7809L16.5 20.2806L14.6247 21.7809C14.2268 22.0992 13.6532 22.0674 13.2929 21.7071L12 20.4142L10.7071 21.7071C10.3468 22.0674 9.7732 22.0992 9.37531 21.7809L7.5 20.2806L5.6247 21.7809C5.32453 22.021 4.91328 22.0678 4.56681 21.9013C4.22035 21.7348 4 21.3844 4 21V5C4 3.34315 5.34315 2 7 2H17ZM12 12H9C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H12C12.5523 14 13 13.5523 13 13C13 12.4477 12.5523 12 12 12ZM15 8H9C8.44772 8 8 8.44772 8 9C8 9.51283 8.38604 9.93551 8.88338 9.99327L9 10H15C15.5523 10 16 9.55228 16 9C16 8.48717 15.614 8.06449 15.1166 8.00673L15 8Z"
        fill="#F56C7B"
      />
    </svg>
  );
};
