import React from "react";

export const TofuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4.55249 3.05993C4.55249 2.54183 4.9725 2.12183 5.4906 2.12183H5.6424C6.1605 2.12183 6.58051 2.54183 6.58051 3.05993V10.1171C6.58051 10.6352 6.1605 11.0553 5.6424 11.0553H5.4906C4.9725 11.0553 4.55249 10.6352 4.55249 10.1171V3.05993Z"
        fill="#1E1E1E"
      />
      <path
        d="M8.20902 2.12183C8.72712 2.12183 9.14713 2.54183 9.14713 3.05993L9.14713 3.11955C9.14713 3.63765 8.72712 4.05766 8.20902 4.05766L2.95064 4.05766C2.43254 4.05766 2.01254 3.63765 2.01254 3.11955L2.01254 3.05993C2.01254 2.54183 2.43254 2.12183 2.95064 2.12183L8.20902 2.12183Z"
        fill="#1E1E1E"
      />
      <path
        d="M21.6816 6.51694C21.6816 9.01158 19.5327 11.0339 16.8819 11.0339C14.2312 11.0339 12.0823 9.01158 12.0823 6.51694C12.0823 4.0223 14.2312 2 16.8819 2C19.5327 2 21.6816 4.0223 21.6816 6.51694ZM13.9394 6.51694C13.9394 8.04635 15.2568 9.28617 16.8819 9.28617C18.507 9.28617 19.8245 8.04635 19.8245 6.51694C19.8245 4.98754 18.507 3.74771 16.8819 3.74771C15.2568 3.74771 13.9394 4.98754 13.9394 6.51694Z"
        fill="#1E1E1E"
      />
      <path
        d="M2 14.1457C2 13.6276 2.42 13.2076 2.93811 13.2076H3.08991C3.60801 13.2076 4.02802 13.6276 4.02802 14.1457V20.9999C4.02802 21.518 3.60801 21.938 3.08991 21.938H2.93811C2.42001 21.938 2 21.518 2 20.9999V14.1457Z"
        fill="#1E1E1E"
      />
      <path
        d="M8.08124 13.2076C8.59934 13.2076 9.01935 13.6276 9.01935 14.1457L9.01935 14.2054C9.01935 14.7235 8.59934 15.1435 8.08124 15.1435L2.98501 15.1435C2.46691 15.1435 2.04691 14.7235 2.04691 14.2054L2.04691 14.1457C2.04691 13.6276 2.46691 13.2076 2.98502 13.2076L8.08124 13.2076Z"
        fill="#1E1E1E"
      />
      <path
        d="M8.08124 16.3752C8.59934 16.3752 9.01935 16.7952 9.01935 17.3133L9.01935 17.3729C9.01935 17.891 8.59934 18.311 8.08124 18.311L2.98501 18.311C2.46691 18.311 2.04691 17.891 2.04691 17.3729L2.04691 17.3133C2.04691 16.7952 2.46691 16.3752 2.98502 16.3752L8.08124 16.3752Z"
        fill="#1E1E1E"
      />
      <path
        d="M21.0289 17.2235C21.5099 17.2235 21.9083 17.617 21.8165 18.0892C21.7534 18.4143 21.6546 18.733 21.5214 19.0399C21.2715 19.6158 20.9052 20.1391 20.4435 20.5798C19.9817 21.0206 19.4336 21.3702 18.8303 21.6088C18.2269 21.8473 17.5803 21.9701 16.9273 21.9701C16.2743 21.9701 15.6277 21.8473 15.0243 21.6088C14.421 21.3702 13.8729 21.0206 13.4111 20.5798C12.9494 20.1391 12.5831 19.6158 12.3332 19.0399C12.2 18.733 12.1012 18.4143 12.0381 18.0892C11.9463 17.617 12.3447 17.2235 12.8257 17.2235L13.2476 17.2235C13.7287 17.2235 14.1053 17.6242 14.2652 18.0779C14.2855 18.1357 14.308 18.1929 14.3325 18.2494C14.4736 18.5747 14.6805 18.8702 14.9413 19.1192C15.2021 19.3681 15.5117 19.5656 15.8525 19.7003C16.1932 19.8351 16.5585 19.9044 16.9273 19.9044C17.2961 19.9044 17.6614 19.8351 18.0021 19.7003C18.3429 19.5656 18.6525 19.3681 18.9133 19.1192C19.1741 18.8702 19.381 18.5747 19.5221 18.2494C19.5466 18.1929 19.5691 18.1357 19.5894 18.0779C19.7493 17.6242 20.1259 17.2235 20.607 17.2235H21.0289Z"
        fill="#1E1E1E"
      />
      <ellipse
        cx="20.9329"
        cy="14.225"
        rx="1.06557"
        ry="1.01713"
        fill="#1E1E1E"
      />
      <ellipse
        cx="13.0203"
        cy="14.225"
        rx="1.06557"
        ry="1.01713"
        fill="#1E1E1E"
      />
    </svg>
  );
};
