import { useCallback, useState } from 'react';
import { SettingsIcon } from '../../../../../icons/like-for-like/settings-icon';
import { PARAMETRS_DATA } from '../../const';
import { ParametersModal } from '../../periodsComparison/parametersModal/ParameterModa';
import { HoverInfoTable } from '../../periodsComparison/tableItem/HoverInfoTable/HoverInfoTable';
import './ParamentrsTable.scss';

export const ParamentrsTable = () => {

  const [isHovered, setIsHovered] = useState(false);
  const [parametrsList, setParametrsList] = useState(PARAMETRS_DATA);
  const [isSettingsModal, isSetSettingsModal] = useState(false);
  const onCloseModal = useCallback(() => {
    isSetSettingsModal(false);
  }, []);

  const onOpenModal = useCallback(() => {
    isSetSettingsModal(true);
  }, []);


  const handleMouseEnterTitle = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveTitle = () => {
    setIsHovered(false);
  };
  return (
    <div className="paramentrs-table__container">
      <div className="paramentrs-table__header">
        <>
          {isSettingsModal && (
            <ParametersModal
              isOpen={isSettingsModal}
              onClose={onCloseModal}
              parametrsList={parametrsList}
              setParametrsList={setParametrsList}
            />
          )}
          <div className="paramentrs-table__header">
            <div className="paramentrs-table__header-title">Показатели</div>
            <div
              onClick={onOpenModal}
              className="paramentrs-table__header-icon"
              onMouseEnter={() => handleMouseEnterTitle()}
              onMouseLeave={handleMouseLeaveTitle}
            >
              {isHovered ? (
                <div className="paramentrs-table__header-hover">Настроить показатели</div>
              ) : null}
              <SettingsIcon fill={isHovered ? '#67A8F4' : '#C4C4C2'} />
            </div>
          </div>
        </>{' '}
      </div>
      <div className="paramentrs-table__wrapper">
        {PARAMETRS_DATA.map((item) => (
          <div className="paramentrs-table__item">
            {item.text === 'Сумма' ? (
              <div className="paramentrs-table__icon-wrapper">
                <div>{item.text}</div>{' '}
                <div className="paramentrs-table__icon">
                  <HoverInfoTable right={6} />
                </div>
              </div>
            ) : (
              item.text
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
