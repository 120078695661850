import React, { useEffect, useState, useRef } from 'react';

export const InfiniteScroll = (props) => {
  const containerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const callbackFunction = async (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting && props.next && !isLoading) {
      setIsLoading(true);
      await props.loadMore();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.backToTop && containerRef.current && props.root) {
      props.root.scrollTop = 0;
    }
  }, [props.backToTop]);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, props);
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef, props]);

  return (
    <>
      {props.children}
      <div ref={containerRef} />
    </>
  );
};
