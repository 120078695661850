import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import './Button.scss';

export const Button = ({
  children,
  customClass,
  onClick,
  color = 'green',
  width,
  height = 50,
  disabled = false,
  styleType,
  size = 'medium',
  transparent,
  isLoading,
  className,
  icon,
  ...otherProps
}) => {
  const getPadding = () => {
    switch (size) {
      case 'medium':
        return '15px 30px';
      case 'small':
        return '10px 15px';
      default:
        return '15px 30px';
    }
  };

  return (
    <button
      disabled={disabled}
      style={{ width: width, height: height }}
      onClick={onClick}
      className={cn(
        { primary: styleType === 'primary' },
        { secondary: styleType === 'secondary' },
        { delete: styleType === 'delete' },
        { 'light-blue': styleType === 'light-blue' },
        { small: styleType === 'small' },
        { about: styleType === 'about' },
        customClass,
        'bc-button '
      )}
      {...otherProps}
    >
      <div className="button-container">
        {children}
        <div className='button-icon'>{icon}</div>
      </div>
    </button>
  );
};

Button.propTypes = {
  color: PropTypes.oneOf(['green', 'blue', 'gray', 'red']),
  disabled: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  children: PropTypes.any,
  customClass: PropTypes.string,
  transparent: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small']),
  styleType: PropTypes.string,
};
