import { ItemInfo } from './ItemCheque/ItemCheque';

import './TableCheque.scss';

export const TableCheque = ({ list, onCloseFirstModal }) => {
  return (
    <div>
      <div className="table-cheque__container">
        <div className="table-cheque__name">Номер чека</div>
        <div className="table-cheque__table">Стол</div>
        <div className="table-cheque__time">Время</div>
        <div className="table-cheque__sum">Сумма, ₽</div>
        <div className="table-cheque__about">Подробнее</div>
      </div>
      <div className="table-cheque__wrapper">
        {list.map((item, index) => (
          <ItemInfo key={item.id} item={item} index={index} onCloseFirstModal={onCloseFirstModal} />
        ))}
      </div>
    </div>
  );
};
