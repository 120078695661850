import React from 'react';

export const AverageChecque = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="146"
      height="119"
      viewBox="0 0 146 119"
      fill="none"
    >
      <path
        d="M1 22.8981V113C1 116.314 3.68628 119 6.99998 119H139C142.314 119 145 116.314 145 113V67.0157C126.811 64.5169 107 50.7546 80 31.1839C64.5652 19.9961 60 7.09763 29 12.1152C14 16.6314 1 22.8981 1 22.8981Z"
        fill="url(#paint0_linear_12386_76036)"
        fill-opacity="0.5"
      />
      <path
        d="M145 67C129.024 67 101.5 49.5334 71.5 25.0793C49.6067 7.23337 36.7483 6.1154 1 23.0832"
        stroke="#F0F6FE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12386_76036"
          x1="195.987"
          y1="-3.03091"
          x2="191.506"
          y2="119.569"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F0F6FE" />
          <stop offset="1" stop-color="#F0F6FE" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
