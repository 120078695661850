export const ERROR_TEXT = 'Нет данных за выбранный период <br/>Выберите другой период';
export const ERROR_TEXT_FOR_SELECT = 'Нет данных за выбранный период<br/>или в выбранном разделе<br/>Выберите другой период или раздел';
export const RESTARAUNT_TYPE_OPTHIONS = [
    { label: 'Кафе', value: 'CAFE', index: 0 },
    { label: 'Ресторан', value: 'RESTAURANT', index: 1 },
    { label: 'Бар', value: 'BAR', index: 2 },
    { label: 'Кофейня', value: 'COFFEE_POINT', index: 3 },
    { label: 'Бистро', value: 'BISTRO', index: 4 },
    { label: 'Столовая', value: 'CANTEEN', index: 5 },
    { label: 'Клуб', value: 'CLUB', index: 6 },
    { label: 'Фастфуд', value: 'FAST_FOOD', index: 7 },
    { label: 'Банкетный зал', value: 'BANQUETING_HALL', index: 8 },
    { label: 'Пиццерия', value: 'PIZZA', index: 9 },
    { label: 'Суши-бар', value: 'SUSHI_BAR', index: 10 },
    { label: 'Чайная', value: 'TEA', index: 11 },
    { label: 'Спортбар', value: 'SPORT_BAR', index: 12 },
    { label: 'Служба доставки', value: 'DELIVERY_SERVICE', index: 13 },
    { label: 'Пекарня', value: 'BAKERY', index: 14 },
    { label: 'Паб', value: 'PUB', index: 15 },
    { label: 'Кальянная', value: 'HOOKAH', index: 16 },
    { label: 'Кейтеринг', value: 'CATERING', index: 17 },
    { label: 'Детское кафе', value: 'CHILD_CAFE', index: 18 },
    { label: 'Гастробар', value: 'GASTRO_BAR', index: 19 },
    { label: 'Бургерная', value: 'BURGERS', index: 20 },
    { label: 'Антикафе', value: 'ANTI_CAFE', index: 21 },
];
  
export const COUNT_OF_WORKERS_OPTHION = [
    { label: '1-5', value: 'FROM_1_TO_5', index: 0 },
    { label: '6-10', value: 'FROM_6_TO_10', index: 1 },
    { label: '10-20', value: 'FROM_10_TO_20', index: 2 },
    { label: '20-50', value: 'FROM_20_TO_50', index: 3 },
    { label: '50+', value: 'MORE_THAN_50', index: 4 },
  ];