export const trimUrl = (url) => {
    let parts = url.split('/');
    let baseIndex = 0;
    for (let i = 0; i < parts.length; i++) {
        if (parts[i] === "api" && parts[i+1] === "v1") {
            baseIndex = i+2;
            break;
        }
    }
    return '/' + parts.slice(baseIndex).join('/');
  }
  