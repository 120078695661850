export const TABLE_COLUMNS_TITLE = [
  {
    title: 'Сотрудник',
  },
  {
    title: 'Главная',
  },
  {
    title: 'Отзывы',
  },
  {
    title: 'Отчёты',
  },
  {
    title: 'Ассистент',
  },
  {
    title: 'Настройки',
  },
];

export const test = [
  {
    deparment: 'Управление',
    staff: [
      {
        avatar:
          'https://cdn.obyasnyaem.ru/upload/iblock/727/s8u9fbkewhthcggwp8kzeu73a8pb3ygz/RIA_8415684.HR.jpg',
        name: 'Владимир Владмирович Путин',
        role: 'Президент',
        index: 0,
      },
      {
        avatar:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg/250px-Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg',
        name: 'Владимир Владмирович Путин',
        role: 'Президент',
        index: 1,
      },
    ],
  },
  {
    deparment: 'Манагер',
    staff: [
      {
        avatar:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg/250px-Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg',
        name: 'Владимир Владмирович Путин',
        role: 'Президент',
        index: 2,
      },
      {
        avatar:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg/250px-Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg',
        name: 'Владимир Владмирович Путин',
        role: 'Президент',
        index: 3,
      },
    ],
  },
  {
    deparment: 'Админ',
    staff: [
      {
        avatar:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg/250px-Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg',
        name: 'Владимир Владмирович Путин',
        role: 'Президент',
        index: 4,
      },
      {
        avatar:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg/250px-Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg',
        name: 'Владимир Владмирович Путин',
        role: 'Президент',
        index: 5,
      },
      {
        avatar:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg/250px-Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg',
        name: 'Владимир Владмирович Путин',
        role: 'Президент',
        index: 6,
      },
    ],
  },
  {
    deparment: 'Персонал',
    staff: [
      {
        avatar:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg/250px-Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg',
        name: 'Владимир Владмирович Путин',
        role: 'Президент',
        index: 7,
      },
      {
        avatar:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg/250px-Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg',
        name: 'Владимир Владмирович Путин',
        role: 'Президент',
        index: 8,
      },
      {
        avatar:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg/250px-Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg',
        name: 'Владимир Владмирович Путин',
        role: 'Президент',
        index: 9,
      },
      {
        avatar:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg/250px-Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg',
        name: 'Владимир Владмирович Путин',
        role: 'Президент',
        index: 10,
      },
      {
        avatar:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg/250px-Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg',
        name: 'Владимир Владмирович Путин',
        role: 'Президент',
        index: 11,
      },
      {
        avatar:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg/250px-Vladimir_Putin_September_5%2C_2022_%28cropped%29.jpg',
        name: 'Владимир Владмирович Путин',
        role: 'Президент',
        index: 12,
      },
    ],
  },
];

export const REPORTS = [
  {
    title: 'Фудкост',
  },
  {
    title: 'Опасные операции',
  },
  {
    title: 'Списания',
  },
  {
    title: 'Отчет по столам',
  },
  {
    title: 'Продажи',
  },
  {
    title: 'Отчет по сотрудникам',
  },
  {
    title: 'Итоги дня',
  },
  {
    title: 'Отчет по скидкам',
  },
];
