import React from 'react';
import { BlankStarIcon } from '../../../../../icons/star/blank-star-icon';
import { FillStarIcon } from '../../../../../icons/star/fill-star-icon';
import { SemiBlankStarIcon } from '../../../../../icons/star/semi-blank-star-icon';
import './ReviewsWindowHeader.scss';

export const ReviewsWindowHeader = ({ reviews, rating, count }) => {
  const totalStars = 5;
  const filledStars = Math.floor(rating);
  const hasHalfStar = typeof rating === 'number' && rating.toFixed(1) % 1 !== 0;


  const stars = Array(totalStars)
    .fill()
    .map((_, index) => {
      if (index < filledStars) {
        return <FillStarIcon key={index} />;
      } else if (index === filledStars && hasHalfStar) {
        return <SemiBlankStarIcon key={index} />;
      } else {
        return <BlankStarIcon key={index} />;
      }
    });
  return (
    <div className="reviews-window-header__container">
      <div className="reviews-window-header__content">
        <div className="reviews-window-header__left-side">
          {typeof rating === 'number' ? (
            <div className="reviews-window-header__common-rate">{rating.toFixed(1)}</div>
          ) : (
            <div className="reviews-window-header__common-rate">0</div>
          )}
        </div>
        <div className="reviews-window-header__right-side">
          <div className="reviews-window-header__stars">
            {' '}
            {stars.map((star, index) => (
              <div key={index} style={{ paddingRight: '5px' }}>
                {star}
              </div>
            ))}
          </div>
          <div className="reviews-window-header__reviews-count">
            <span className="reviews-window-header__reviews-count-number">{count}</span> отзывов
          </div>
        </div>
      </div>
    </div>
  );
};
