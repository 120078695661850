import { useState } from 'react';
import { CompaniesSelect } from '../../companiesSelect/companiesSelect';
import { PeriodComparison } from '../../periodsComparison/periodsComparison';
import { TableItem } from '../../periodsComparison/tableItem/tableItem';
import { PeriodSelect } from '../../periodSelect/PeriodSelect';
import { Switcher } from '../../switcher/Switcher';
import './PeriodsComparisonTable.scss';

export const  PeriodsComparisonTable = ({ isMenuOpen }) => {
  const TableSectionInnerReasons = [
    'compare_period',
    'report_period',
    'network_growth',
    'network_dynamic',
    'comparable_growth',
    'comparable_dynamic',
  ];
  const [selectedOptions, setSelectedOptions] = useState([]);
  return (
    <div className="like-for-like__container">
      <div className="like-for-like__selects">
        <div className="like-for-like__selects-left">
          <CompaniesSelect options={selectedOptions} setOptions={setSelectedOptions} isFirstTable={true} />
          <PeriodSelect />
        </div>
        <div>
          <Switcher />
        </div>
      </div>
      <PeriodComparison
        marginLeft={0}
        gridColumns={
          isMenuOpen ? '31.4% 13% 11.5% 22.1% 22.22%' : '31.33% 12.9% 11.6% 22.1% 22.22%'
        }
        columnsName={[
          'Показатели',
          'За период сравнения',
          'За отчетный период',
          'Сетевой рост',
          'Сопоставимый рост',
        ]}
        Component={TableItem}
        TableSectionInner={TableSectionInnerReasons}
        isMenuOpen={isMenuOpen}
      />
    </div>
  );
};
