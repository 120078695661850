import React from 'react';

export const SettingsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icons">
        <path
          id="settings"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 4C18 3.44772 17.5523 3 17 3C16.4477 3 16 3.44772 16 4V5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H16V8C16 8.55228 16.4477 9 17 9C17.5523 9 18 8.55228 18 8V7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H18V4ZM4 11C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13H6V14C6 14.5523 6.44772 15 7 15C7.55228 15 8 14.5523 8 14V13H20C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11H8V10C8 9.44772 7.55228 9 7 9C6.44772 9 6 9.44772 6 10V11H4ZM3 18C3 17.4477 3.44772 17 4 17H16V16C16 15.4477 16.4477 15 17 15C17.5523 15 18 15.4477 18 16V17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H18V20C18 20.5523 17.5523 21 17 21C16.4477 21 16 20.5523 16 20V19H4C3.44772 19 3 18.5523 3 18Z"
          fill="#C4C4C2"
        />
      </g>
    </svg>
  );
};
