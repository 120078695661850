import React, { useRef, useState } from 'react';
import { UploadIcon } from '../../../icons/upload-icon';
import { Button } from '../Button/Button';
import { DefaultUserIcon } from '../../../icons/default-user';
import { CompanyDefaultLogo } from '../../../icons/company-default';
import 'react-toastify/dist/ReactToastify.css';
import './UploadPhotoCopmonent.scss';

export const UploadPhotoCopmonent = ({ isUser, setPhoto, avatar }) => {
  const hiddenFileInput = useRef(null);
  const [previewImg, setPreviewImg] = useState('');
  const [sizeError, setSizeError] = useState(false);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleInputChange = (e) => {
    const uploadedFile = e.target.files[0];

    if (uploadedFile) {
      if (uploadedFile.size <= 2194304) {
        setPreviewImg(URL.createObjectURL(uploadedFile));
        setPhoto(uploadedFile);
        setSizeError(false);
      } else {
        setSizeError(true);
      }
    }
  };

  return (
    <div>
      <div className={sizeError ? 'upload-form__photo-error' : 'upload-form__photo'}>
        <div className="upload-form__photo-img">
          {previewImg ? (
            <img src={previewImg} alt="Uploaded" />
          ) : avatar ? (
            <img src={avatar} alt="Avatar" />
          ) : isUser ? (
            <DefaultUserIcon height={100} width={100} />
          ) : (
            <CompanyDefaultLogo />
          )}
        </div>
        <div className="upload-form__photo-info">
          <div className="upload-form__photo-text">
            Изображение должно быть в формате JPG, JPEG, PNG и не должно превышать размер 2 Mb
          </div>
          <Button styleType="secondary" onClick={handleClick} height={28} width={238}>
            <div className="upload-form__photo-upload-icon">
              <UploadIcon />
            </div>
            Загрузить
          </Button>

          <input
            accept=".jpg, .jpeg, .png"
            type="file"
            ref={hiddenFileInput}
            onChange={handleInputChange}
            style={{ display: 'none' }}
          />
        </div>
      </div>

      {sizeError ? <div className="register-error">Размер изображения превышает 2 Mb</div> : null}
    </div>
  );
};
